import React from 'react';
import Card from '../UI/Card/Card';
import {Link} from 'react-router-dom';
import classes from './Users.module.css';

import { BiSolidUser } from "react-icons/bi";

const UserListItem = (props) => {

    let style = classes.Users;

    let name = props.user.userName ;


    let borderColor = {'borderColor' : 'lightgray'};
    if (props.user.pernr){

        
        const objIndex = props.therapists.findIndex((obj => obj.PERNR === props.user.pernr));       
        if (objIndex !== -1){
            borderColor = {'borderColor' : props.therapists[objIndex].color,
                            'borderLeftWidth' : '10px'};
        }

        
    }

    
    if (props.user.inactive){
        
        style = classes.UsersInactive;    
        name = name + ' (inaktiv)'
    }
  

    return <Card style={style} cssStyle={borderColor}>
                <div className={classes.CardContent}>&nbsp; 
                <Link  to={{ pathname: '/setup/users/' + props.user.userName }}><BiSolidUser />{name}</Link></div>
            </Card>

}

export default UserListItem;