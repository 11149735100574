import React from 'react';
import Table from './Table';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableHeaderCell from './TableHeaderCell';
import PaginatedList from '../PaginatedList/PaginatedList';

const TablePaginated = (props) => (
    <Table>
        <TableHeader>
            <TableRow>
                {props.tableConfig.columns && props.tableConfig.columns.map((column, index) => {
                    return (
                        <TableHeaderCell key={index}>{column.colLabel}</TableHeaderCell>
                )})}                             
            </TableRow>
        </TableHeader>
            <PaginatedList 
                items={props.items}
                renderItems={props.tableConfig.renderLines} 
                itemsPerPage={props.tableConfig.maxLines}
                table={true}>
            </PaginatedList>
    </Table>
    );

export default TablePaginated;