
import React from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';

import GlobalContext  from '../../context/GlobalContext';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DetailContainer from '../Layout/DetailContainer';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';

import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import TaskMenu from './TaskMenu';

import Status from '../MUI/Status/Status';

import classes from './Task.module.css';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import DateField from '../MUI/DateField/DateField';
import UserDD from '../MUI/DropDown/UserDD';
import DateUtility from '../../hoc/DateUtility';
import MUIPatientInput from '../MUI/MUIInput/MUIPatientInput';
import MUIPrescriptionInput from '../MUI/DropDown/MUIPrescriptionInput';
import TaskUtil from './TaskUtil';
import InvoiceLink from '../UI/InvoiceLink/InvoiceLink';
import AppointmentLinkMui from '../MUI/AppointmentLink/AppointmentLinkMui';


const config = { 
    dueDate: {
            id: "dueDate",
            label: "Fälligkeit",
            required: true,
            type:"date"  
    } ,
    patientID : {
        id: "patientID",
        label: "Patient",
        required:false, 
    },
    prescriptionID : {
        id: "prescriptionID",
        label: "Rezept",
        required:false,                 
    }
}

const Task = (props) => {

    const history = useHistory();
    const { taskID } = useParams();
    const globalContext = useContext(GlobalContext);
    
    const [task, setTask] = useState(null);
    const [patient, setPatient] = useState(null);
    const [appointment, setAppointment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');

   


    /* initialize component ********************************************************/
    
    useEffect(() => {
        if (props.new){
            
            const query = new URLSearchParams(props.location.search);
            const prescriptionID = query.get(TaskUtil.C_PARAM_PRESCRIPTION);
            const patientID = query.get(TaskUtil.C_PARAM_PATIENT);
            let subject = query.get(TaskUtil.C_PARAM_SUBJECT);
            if (!subject){
                subject = "";
            }
            const appointmentID = query.get(TaskUtil.C_PARAM_APPOINTMENT);
            const invoiceID = query.get(TaskUtil.C_PARAM_INVOICE);
                   

            const newTask = {
                status: TaskUtil.C_STAT_OPEN,
                subject: subject,
                description: "",
                patientID: patientID,
                appointmentID: appointmentID,
                prescriptionID: prescriptionID,
                RGNUM: invoiceID,
                dueDate: new Date()
            };
            setTask(newTask);
        }
        else{
            // read task from context
            const objIndex = globalContext.tasks.findIndex((obj => obj.id === parseInt(taskID)));
            if (objIndex !== -1){
                const t = globalContext.tasks[objIndex];
                setTask(t);
            }
            else{
                //error 
                console.log("error");
            }
        }
        }, [taskID]);
        
    //read patient and appointment data
    useEffect(() => {
        if (task){
            //patient
            if (task.patientID && 
                (!patient || patient.ID !== task.patientID )){
                    let pat =  globalContext.getPatient(task.patientID);
                    setPatient(pat);
            }
            // appointment 
            if (task.appointmentID && 
                (!appointment || appointment.ID !== task.appointmentID )){
                    
                    let app = globalContext.getAppointment(task.appointmentID);
                    setAppointment(app);

                }

        } 
        
        }, [task]);        
    /***end: initialize component *************************************************** */


    const saveHandler = () =>{
        
        if (props.new){
            createUser();
            return;
        }
        else{
            updateTask();
        }
    }


    

     
    const releaseHandler= () => {
        updateTask(TaskUtil.C_STAT_PROCESSED);

    }
    
    const deleteHandler = () => {
    
    // update logic 
    setLoading(true);
    task.targetStatus = TaskUtil.C_STAT_DELETED;
    axios.put('/task/' + task.id   , task)
    .then (response => {

        const updatedTask = response.data.data;

        globalContext.updateTask(updatedTask);

        setLoading(false);

        const message = {
            type: 'S',
            text: 'Aufgabe gelöscht'
        }
        globalContext.showMessage(message);                             
        history.goBack();
            })
    .catch (error => {
        console.log(error); 
        setShowError(true);
        setErrorResponse(error.response);
        setErrorTitle("Fehler beim Löschen der Aufgabe");
        setLoading(false);
    });  
        }

    const updateTask= (targetStatus) => {

        // update logic 
        setLoading(true);
        if (targetStatus){
            task.targetStatus = targetStatus;
        }
        axios.put('/task/' + task.id   , task)
        .then (response => {
            const updatedTask = response.data.data;

            globalContext.updateTask(updatedTask);

            setTask(updatedTask);
            setLoading(false);

            if (targetStatus){
                const message = {
                    type: 'S',
                    text: 'Aufgabe aktualisiert'
                }
                globalContext.showMessage(message);                             
                history.goBack();
            }


        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Speichern des Benutzerkontos");
            setLoading(false);
        });  

    }

    const createUser = () =>{

        setLoading(true);
        axios.post('/task'  , task)
        .then (response => {
            const newTask = response.data.data;

            globalContext.updateTask(newTask);

            //setTherapist(newTherapist);
            //setLoading(false);
            history.replace('/task/' + newTask.id);

        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Anlegen der Aufgabe");
            setLoading(false);
        });  
        
    }

    const changeDateHandler = (event, field) => {
                
        const updatedForm = {...task};
        updatedForm[field] = DateUtility.getISODate(new Date(event));
        setTask(updatedForm);
    }
  
    const inputChangedHandler = (value, inputID) =>{


        const updatedForm = { ...task };
        updatedForm[inputID] = value;
        setTask(updatedForm);
    }


    let content = null; 
    let status = null;

        if (task) {

            let patientUI = null;
            let prescriptionUI = null;
            let invoiceUI = null;
            let appointmentUI = null;

            if (patient){
                patientUI = <MUIPatientInput  {...config.patientID}
                                                value={patient.displayName}
                                                readOnly
                                                patientID={task.patientID} />
            }

            if (task.prescriptionID){

                prescriptionUI = <MUIPrescriptionInput {...config.prescriptionID}
                                                        readOnly
                                                        options={[]}
                                                        value={task.prescriptionID}                        
                        />  
            }

            if (task.RGNUM){

                invoiceUI = <InvoiceLink    key= {'invoiceID'} 
                                            
                                            value={task.RGNUM}   
                                            label='Rechnung' 
                                            readOnly={true} 
                                    />
            }

            if (appointment){
                
                appointmentUI = <AppointmentLinkMui appointment={appointment}  />;

            }
        

            content = <React.Fragment>
                            
                            <ApiErrorMessage    show={showError} 
                                                modalClosed={() => { setShowError(false) }} 
                                                errorResponse={errorResponse} 
                                                title={errorTitle}
                                                />    
                            <DetailContainer> 
                                <FactsMui>               
                                    <MUIInput       id={'subject'}
                                                    label='Aufgabe' 
                                                    value={task.subject}
                                                    fullWidth
                                                    required
                                                    onChange={ (event) => inputChangedHandler(event.target.value, 'subject') }
                                                    />   
                                    <MUIInput       id={'description'}
                                                    label='Beschreibung/Kommentar' 
                                                    value={task.description}
                                                    fullWidth
                                                    multiline
                                                    minRows={3}
                                                    onChange={ (event) => inputChangedHandler(event.target.value, 'description') }
                                                    />  
                                    <DoubleField>
                                        <DateField  {...config.dueDate}
                                                    value={task.dueDate} 
                                                    onChange={(event) => changeDateHandler(event, 'dueDate')}
                                        />     
                                        <UserDD     value={task.userName} 
                                                    label="Benutzer"
                                                    required={true}
                                                    onChange={(event) => inputChangedHandler(event.target.value, 'userName')}  />
                                    </DoubleField>    
                                    <DoubleField>
                                        {patientUI}
                                        {prescriptionUI}
                                        {invoiceUI}
                                        {appointmentUI}
                                    </DoubleField>
                                    <DoubleField>
                                        <MUIInput       id={'created_at'}
                                                        label='Angelegt am' 
                                                        value={task.created_at}
                                                        readOnly
                                                        />    
                                        <UserDD     value={task.created_by} 
                                                    label="Angelegt von"
                                                    readOnly
                                                    />
                                    </DoubleField>    



                                    {/**
                                    <FormControl fullWidth>
                                        <TherapeutDD    value={user.pernr}
                                                        label="Zugeordneter Therapeut (optional)"
                                                        onChange={(event) => inputChangedHandler(event.target.value, 'pernr')} />  
                                        <FormHelperText>Bei der Anlage neuer Termine wird der zugeordnete Therapeut vorbelegt. Die "heute-Ansicht" wird auf diesen Therapeuten gefiltert</FormHelperText>                                                              
                                    </FormControl>    */}                                        
                                                  
                                                                                                                                                  
                                </FactsMui>
                            </DetailContainer>
                        </React.Fragment>
        let s = globalContext.status.find((element)  => element.STATUS_ID === task.status)
        status = <Status    value={s.STATUS_TXT} lookupValue={task.status} />

        }

        let title = "Aufgabe bearbeiten";
        if (props.new){
            title = "Aufgabe anlegen";
        }





        return <React.Fragment>
                    <Modal show={loading}><Spinner/></Modal>
                    <SubMenuMui  history={history}>
                            <TaskMenu   saveHandler={() => saveHandler()} 
                                        deleteHandler={() => deleteHandler()} 
                                        releaseHandler={() => releaseHandler()} 
                                        closeHandler={() => updateTask(TaskUtil.C_STAT_COMPLETED)} 
                                        resetHandler={() => updateTask(TaskUtil.C_STAT_OPEN)} 
                                        task={task}
                                        new={props.new} />
                    </SubMenuMui>  
                    <h2 className={classes.Title}>{title} &nbsp; {status} </h2> 
                    {content}
                </React.Fragment>    

}
export default Task; 