import React, { useContext, useEffect } from 'react';
import StatsTherapist from './StatsTherapists';

import GlobalContext from '../../context/GlobalContext';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import DropDown from '../MUI/DropDown/DropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from './Statistics.module.css';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography } from '@mui/material';
import MUIInput from '../MUI/MUIInput/MUIInput';
import DoubleField from '../MUI/MUIContainer/DoubleField';

const C_HOURS_PER_MONTH = 1;
const C_REVENUE_PER_MONTH = 2;

const C_PERIOD_MONTHS = 1;
const C_PERIOD_QUARTERS = 2;
const C_PERIOD_YEARS = 3;

const reportTypeConfig = {
    id: "reports",
    label: "Einheit",
    options: [  {value: C_HOURS_PER_MONTH, label: 'Stunden'},
                {value: C_REVENUE_PER_MONTH, label:'Umsatz'}
            ]      
};

const periodConfig = {
    id: "period",
    label: "Periode",
    options: [  {value: C_PERIOD_MONTHS, label: 'Monate'},
                {value: C_PERIOD_QUARTERS, label:'Quartale'},
                {value: C_PERIOD_YEARS, label:'Jahre'}
            ]      
};


const Statistics = (props) => {

    const [report, setReport] = React.useState(C_HOURS_PER_MONTH);
    const [period, setPeriod] = React.useState(C_PERIOD_MONTHS);
    const [stacked, setStacked] = React.useState(true);
    const [showBars, setShowBars] = React.useState(true);   
    const [average, setAverage] = React.useState(true);
    const [movAvg, setMovAvg] = React.useState(6);
    const [noPeriods, setNoPeriods] = React.useState(18);

    const globalContext = useContext(GlobalContext);
    //const history = useHistory();

    /* initialize component ********************************************************/
    useEffect(() => {
        if (!globalContext.performanceStatsLoaded  &&
            !globalContext.performanceStatsLoading){

                globalContext.loadPerformanceStats();
            }
        }, []);
    /***end: initialize component *************************************************** */


    return <React.Fragment>
                <Modal show={globalContext.performanceStatsLoading} modalClosed={() => {}}><Spinner/></Modal>
                <div className={classes.SelectReport}>
                <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography>Einstellungen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                        <DropDown {...reportTypeConfig}
                                value={report}
                                onChange={(event) => setReport(event.target.value)} />
                    <DoubleField>
                        <FormControlLabel control={ <Switch checked={showBars} onChange={() => setShowBars(!showBars)} />    } 
                                                            label="Zeige Balkendiagramm" />                           
                        <FormControlLabel control={ <Switch checked={stacked} onChange={() => setStacked(!stacked)} />    } 
                                                            label="Gestapelt" />   
                    </DoubleField>
                    <DoubleField>
                        <FormControlLabel control={ <Switch checked={average} onChange={() => setAverage(!average)} />    } 
                                                            label="Gleitender Durchschnitt" />   
                        <MUIInput id={'movAvg'}
                                    label='Anzahl Perioden für gleit. Durchschnitt' 
                                    value={movAvg}
                                    onChange={(event) => setMovAvg(event.target.value) } 
                                    />    
                    </DoubleField>
                    <DoubleField>
                        <DropDown {...periodConfig}
                                value={period}
                                onChange={(event) => setPeriod(event.target.value)  } />                        
                        <MUIInput id={'noPeriods'}
                                        label='Anzahl Perioden' 
                                        value={noPeriods}
                                        onChange={(event) => setNoPeriods(event.target.value) } 
                                        />   
                    </DoubleField>
                    </AccordionDetails>
                    </Accordion>

                </div>
                <StatsTherapist performanceStats={globalContext.performanceStats} 
                                therapists={globalContext.therapeut}
                                reportType={report}
                                stacked={stacked}
                                showBars={showBars} 
                                average={average}
                                movAvg={movAvg}
                                period={period}
                                noPeriods={noPeriods}
                                title={reportTypeConfig.options.find(option => option.value === report).label}
                                />    
             </React.Fragment>;
}

export { C_HOURS_PER_MONTH, C_REVENUE_PER_MONTH, C_PERIOD_MONTHS, C_PERIOD_QUARTERS, C_PERIOD_YEARS };
export default Statistics;