import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Input from '../Input/Input';
import {FaAddressCard} from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import Tooltip from '../Tooltip/Tooltip';
import classes from './PatientInput.module.css';
import Auxiliary from '../../../hoc/Auxiliary';
import PatientSearchHelp from '../../Patients/SearchHelp';


const PatientInput = (props) => {
    
    const [showSearchHelp, setShowSearchHelp] = useState(0);



    //const patientSelect = useCallback((id) => {
    const patientSelect = (id) => {
        // do something with a, b and props.x
        setShowSearchHelp(false);
        props.selectPatientHandler(id);
      };

    
    const iconSize = '20';
    let patientDetailButton = null;
    let editButton = null;
    if (props.value){
        patientDetailButton = ( 
                        <Tooltip content='Patientenakte'> 
                            <Link to={{ pathname: '/patients/' + props.patientID }}>
                                <FaAddressCard  className={classes.Icon} size={iconSize}/>
                            </Link>
                        </Tooltip>);
    }

    if (!props.readOnly){
        editButton =    ( <Tooltip content='Patient ändern'> 
                                <div  onClick={() => setShowSearchHelp(true) } className={classes.Search}>
                                    <RiEdit2Fill  className={classes.Icon} size={iconSize}/>
                                </div>
                            </Tooltip>);
    }

    const suffix = (
        <Auxiliary>
            {patientDetailButton}
            {editButton}
        </Auxiliary>
    );

    return (
            <Auxiliary>
                <PatientSearchHelp showSearchHelp={showSearchHelp}
                                    modalClosed={() => setShowSearchHelp(false)}
                                    patientClickHandler={patientSelect} />                
                <Input  key= {props.idx} 
                        elementType= {props.elementType} 
                        elementConfig={props.elementConfig} 
                        styleConfig= {props.styleConfig} 
                        value={props.value} 
                        label={props.label} 
                        changed={(event) => props.changed(event, props.idx)} 
                        readOnly={true} 
                        touched={true}
                        invalid={props.invalid}
                        suffix={suffix}
                        >                   
                </Input>   
            </Auxiliary>    
            )

}


export default PatientInput;