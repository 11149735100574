
import React from 'react';
import classes from './Layout.module.css';



const DetailContainer = (props) => {

    let cssClass = classes.DetailContainer;
    if (props.type){
        cssClass = classes[props.type];
    }

    return  (

        <div className={cssClass}>
            {props.children}
        </div>
    );

}


export default DetailContainer;




