import React from 'react';
import classes from './Input.module.css';


const richText = (props) => {

        return (
           
              <fieldset>
              <legend>{props.label}</legend>
                <div className={classes.RichText} contentEditable={false} dangerouslySetInnerHTML={createMarkup(props.htmlText)} ></div>
            </fieldset>
            
        );
}

function createMarkup(htmlText) {
    return {__html: htmlText};
  }
export default richText;    