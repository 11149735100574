
import React, {Component} from 'react';

import GlobalContext from '../../context/GlobalContext';
import AppointmentListPaginated from './AppointmentListPaginated';
import Calendar from './Calendar/Calendar';
import ListMenuMui from './ListMenuMui';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import AppointmentUtil from './AppointmentUtil';

class Appointments extends Component{

    static AGENDA_VIEW = 1;
    static CALENDAR_VIEW = 2;


    static contextType = GlobalContext;
    dateTmp = null;
    state = { 
        controls: {
            patientFilter: { 
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Patient/Betreff...'
                },
            },
            hideStatusFilter: true,
            /*view: Appointments.AGENDA_VIEW,*/
            toggelView: true,
            toggelViewHandler: (param1, param2) => this.handleToggleView(param1, param2)
            
        },
        therapeutFilter: [],
        patientFilter: null,
        selectedAppointmentFilter: 0,
        appView: null
    }


    handleToggleView = (event, newView) => {

        
        this.context.setAppView(newView);

    }

    createAppointment = (date, from, to) => {
        let fixedAppType = '';
        if (this.props.fixedAppType){
            fixedAppType = '&fixedAppType=' + this.props.fixedAppType;
        }
        this.props.history.push('/appointments/new?date='+date+'&from='+from+'&to='+to+fixedAppType);
    }

    render() {
        let items = this.props.appointments;
        if (this.state.filteredItems){
            items = this.state.filteredItems;
        }      
        let menu = null;
        menu = <SubMenuMui ><ListMenuMui fieldControls={this.state.controls} appView={this.props.appView}/></SubMenuMui>;

        let content = null;
        let appView = this.props.appView;
        if (!appView){
            appView = this.context.appView;
        }


        if ( appView === Appointments.AGENDA_VIEW ){
            content = <AppointmentListPaginated {...this.props} 
                                                appointments={items}
                                                view='overview' 
                                                pageSize={50}  />
        }
        else{
            content = <Calendar {...this.props}  
                                appointments={items} 
                                freeTimeSlots={[]} 
                                navAppointment={true}
                                select={this.createAppointment}
                                startDay={this.context.calStartDay}
                                storeStartDay={this.context.setCalStartDay}
                                />

        }

        
        return( <React.Fragment>
                    {menu}
                    {content}
                </React.Fragment>
        );

    }

    componentDidMount(){

        if (!this.props.embedded){
            window.scrollTo(0, 0);    
        }

        this.filterAppointments();
        if (this.props.view === 'overview'){

            const controls =  {...this.state.controls};
            controls.hideStatusFilter = false;
            this.setState({ controls: controls });
        }
    }

    componentDidUpdate(){
        if ( JSON.stringify(this.context.therapeutFilter) !== this.state.therapeutFilter ||
             this.context.patientFilter !== this.state.patientFilter  ||
             this.context.statusFilter !== this.state.statusFilter ||
             this.context.selectedAppointmentFilter !== this.state.selectedAppointmentFilter ||
             this.context.appView !== this.state.appView
            ){
            this.filterAppointments();
        }
    }


    filterAppointments = () => {
        let filteredAppointments = [];

        const regex = RegExp(this.context.patientFilter, 'i');
    
        filteredAppointments = this.props.appointments
                                    .filter(appointment =>  ( this.context.therapeutFilter.includes( appointment.therapeutID )  ||
                                                              appointment.appType === AppointmentUtil.C_TYPE_HOLIDAY ) && // Therapeutenfilter
                                                            ( regex.test(appointment.patientName) || regex.test(appointment.subject)) &&// Patientenfilter    
                                                              this.overviewFilter(appointment)
                                                              ); 

        this.setState({ filteredItems : filteredAppointments,
                        therapeutFilter: JSON.stringify(this.context.therapeutFilter),
                        patientFilter: this.context.patientFilter,
                        statusFilter : this.context.statusFilter,
                        selectedAppointmentFilter : this.context.selectedAppointmentFilter,
                        appView : this.context.appView
                      });
    }

    overviewFilter(appointment){
        let showAppointment = true;

        if (this.props.view === 'overview'){
            showAppointment = false;
            const today = new Date();

            const selectedFilter = this.context.appointmentFilter[this.context.selectedAppointmentFilter] ;

            if ( selectedFilter.itemId === '#1'){
                //all appointment as of today
                //without status filter: show all apps of today and in the future
                if (this.context.appView === Appointments.AGENDA_VIEW){
                    //agenda view: filter past appointments
                    today.setHours(0,0,0);
                    if (new Date(appointment.date) >= today ){
                        showAppointment = true;
                    }
                }
                else {
                    //calendar view: show everything
                    showAppointment = true;
                }

            }
            else if ( selectedFilter.itemId === '#2'){
                //all past, open apppointments
                if (appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
                    // filter status: show only today and past with selected status
                    today.setHours(23,59,59);
                    
                    if (new Date(appointment.date) <= today && 
                        parseInt(appointment.statusID) === parseInt(AppointmentUtil.C_STAT_DRAFT) ){
                            showAppointment = true;
                        }
                }
            }
            else if ( selectedFilter.itemId === '#3'){
                //all app. without patient
                if (appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT && !appointment.patientID){
                    
                    if (this.context.appView === Appointments.AGENDA_VIEW){
                        //agenda view: filter past appointments
                        today.setHours(0,0,0);
                        if (new Date(appointment.date) >= today ){
                            showAppointment = true;
                        }
                    }
                    else {
                        //calendar view: show everything
                        showAppointment = true;
                    }
                }

            }
            else if ( selectedFilter.itemId === '#4'){
                //all app. without patient
                if (appointment.appType === AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT || appointment.appType === AppointmentUtil.C_TYPE_HOLIDAY){
                    
                    if (this.context.appView === Appointments.AGENDA_VIEW){
                        //agenda view: filter past appointments
                        today.setHours(0,0,0);
                        if (new Date(appointment.date) >= today ){
                            showAppointment = true;
                        }
                    }
                    else {
                        //calendar view: show everything
                        showAppointment = true;
                    }
                }

            }

             /*   

            if ( this.context.statusFilter === 0 ){

                //without status filter: show all apps of today and in the future
                if (this.context.appView === Appointments.AGENDA_VIEW){
                    //agenda view: filter past appointments
                    today.setHours(0,0,0);
                    if (new Date(appointment.date) >= today ){
                        showAppointment = true;
                    }
                }
                else {
                    //calendar view: show everything
                    showAppointment = true;
                }

            }
            else{
                if (appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
                    // filter status: show only today and past with selected status
                    today.setHours(23,59,59);
                    if (new Date(appointment.date) <= today && 
                        appointment.statusID === this.context.statusFilter ){
                            showAppointment = true;
                        }
                }

            }

            */
        }

        return showAppointment;
    }
   
}

export default Appointments;