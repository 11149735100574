import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';





const CheckBox = (props) =>  {

    const handleOnChange = (event, id) => {
        if (!props.readOnly){
            props.onChange(event, props.id);
        }
    }
    return (    
        <div style={{width: 100 + '%'}}>
            <FormControlLabel control={<Checkbox    {...props}
                                                    checked={props.checked} 
                                                     
                                                    onChange={ (event) =>  handleOnChange(event, props.id) } />}  
                            label={props.label} /> 
        </div>
      

    ); 

}
export default CheckBox;