


class DateUtility {

    static TYPE_LONG = 1;     //weekday: 'short' , year: 'numeric', month: '2-digit', day: '2-digit'
    static TYPE_MM_DD = 2;    //month: '2-digit', day: '2-digit'
    static TYPE_YY_MM_DD = 3; //year: 'numeric', month: '2-digit', day: '2-digit'
    static TYPE_WEEKDAY = 4; //weekday: 'short'
    static TYPE_SHOWDATE = 5; //weekday: 'short', month: 'short', day: '2-digit'
    static TYPE_LONG_CHAR = 6;

    static formatDate(date, format = DateUtility.TYPE_LONG) { 
        let options = {  weekday: 'short' , year: 'numeric', month: '2-digit', day: '2-digit' };
        if (format === DateUtility.TYPE_MM_DD){
            options = { month: '2-digit', day: '2-digit' };
        }
        else if (format === DateUtility.TYPE_YY_MM_DD){
            options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        }
        else if (format === DateUtility.TYPE_WEEKDAY){
            options = {  weekday: 'short'};
        }
        else if (format === DateUtility.TYPE_SHOWDATE){
            options = {  weekday: 'short', month: 'short', day: '2-digit' };
        }
        else if (format === DateUtility.TYPE_LONG_CHAR){
            options =  {  weekday: 'short' , year: 'numeric', month: 'short', day: '2-digit'  };
        }        
        const newDate = new Date( date);
        return newDate.toLocaleDateString('de-DE', options);
    }

    /**
     * Returns date in format YYYY-MM-DD
     * @param {*} date 
     * @returns 
     */
    static getISODate(date){

        
        return  date.getFullYear() + '-' + 
                String(date.getMonth() + 1).padStart(2, '0') + '-' +   //get month returns index starting with Jan = 0
                String(date.getDate()).padStart(2, '0') ;

    }

    static getTimeFormat(date){
        const d = new Date(date);

        return String(d.getHours()).padStart(2, '0')  + ':' + String(d.getMinutes()).padStart(2, '0');
    }

    /**
     * Returns a new date with "number" added days
     * @param {*} date 
     * @param {*} number 
     * @returns 
     */
    static addDays(date, number) {
        let targetDate = new Date(date);
        targetDate.setDate(targetDate.getDate() + number);
        return targetDate;
    }

    /**
     * Calculates duration of two times (hh:mm)in hours
     * @param {*} time1 
     * @param {*} time2 
     * @returns 
     */
    static calcDuration(time1, time2) {
        
        const time_start = new Date();
        const time_end = new Date();
        const value_start = time1.split(':');
        const value_end = time2.split(':');
    
        time_start.setHours(value_start[0], value_start[1], 0, 0)
        time_end.setHours(value_end[0], value_end[1], 0, 0)
        
        return (time_end - time_start) / ( 60 * 60 * 1000);// millisecond 

}


}



export default DateUtility;