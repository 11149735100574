import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AuthContext from '../../context/GlobalContext';
import Tooltip from '../UI/Tooltip/Tooltip';
import Caption from '../UI/Card/Caption';
import Card from '../UI/Card/Card';
import PrescriptionDetailBasics from './PrescriptionDetailBasics';

class PrescriptionListItem extends Component{

    styleConfig = {     inputStyle: 'InputDetail',
                        labelStyle: 'LabelLightFixed',
                        inputElementStyle:'InputElementDetail2' };            

    configDate = { type: 'dateWithoutTime' };
    configDecimal = { type: 'decimal' };

    static contextType = AuthContext;

    render(){  

        var header = null;
        let diagnose = this.props.prescription.diagnose;
        if (diagnose){
            diagnose = diagnose.replace(/(<([^>]+)>)/gi, "");
        }

        if (this.props.patientDetail  ){

        }
        else{
   
        }
        header = (    <Caption > 
                            <Link  to={{ pathname: '/prescriptions/' + this.props.prescription.ID }}>
                                <Tooltip content={'Rezept Details'}>
                                    R{this.props.prescription.ID + ' - ' + diagnose /*this.props.prescription.displayname*/} 
                                </Tooltip>
                            </Link>
                        </Caption>);
        return(
                <Card >
                    {header}   
                    <PrescriptionDetailBasics prescription={this.props.prescription} readOnly={true} />
                </Card>
        );
    }
}
export default PrescriptionListItem;
