import React from 'react';
import { IoAddCircleOutline } from "react-icons/io5";
import { IconButton } from '@mui/material';
import classes from './Calendar.module.css';
import DateUtility from '../../../hoc/DateUtility';

const SelectedCell = (props) => {


    /*
    if (!config || !config.app){
        return null;
    }
    */
    let height = 60 ;


    height = height - 2;
    const heightProperty = height + 'px';

    const  button =   <IconButton size={'small'} >
                            <IoAddCircleOutline />                            
                        </IconButton>    ;

    const text = <div className={classes.CellSelektorText}> 
                            {DateUtility.formatDate(props.selectedCell.date, DateUtility.TYPE_SHOWDATE) + ' ' + props.selectedCell.time}
                        </div>        ;      
    
    return  <div className={classes.CellSelektor}  style={{height : heightProperty}}  onClick={props.selectCell} >
                {button}
                {text}
            </div>
}

export default SelectedCell;