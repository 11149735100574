import React from 'react';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import ModalHeader from '../Modal/ModalHeader';
import ModalContent from '../Modal/ModalContent';
import ModalFooter from '../Modal/ModalFooter';
import SessionHandler from '../../../context/SessionHandler';
import Auxiliary from '../../../hoc/Auxiliary';

const ApiErrorMessage = (props) => {

    var errorMessage = '';
    var techErrorMessage = null;
    var saveAnyway = null;

    if (props.errorResponse){
        //set user message from backend

        if (props.errorResponse.data.messages){
            props.errorResponse.data.messages.forEach(message => {
                errorMessage = (<Auxiliary>{errorMessage} <br/> {message} </Auxiliary>);
            });
            
        }
        else{
            errorMessage = "Ein Fehler ist aufgetreten.";
        }

        //set technical message                    
        techErrorMessage = "http-code: "+ props.errorResponse.status + " "+ props.errorResponse.statusText;

        if (props.errorResponse.status === 409 && SessionHandler.authSensitiveData() ){
 
            saveAnyway = (<Button btnType="EditMenuButton" click={props.saveAnyway} >Trotzdem Speichern</Button>);
        }
        

    }

    return (

        <Modal show={props.show}  >
            <ModalHeader title={props.title} onClose={props.modalClosed}/>
            <ModalContent >
            <br></br>
            <div>{errorMessage}</div>
            <br></br>
            <code>{techErrorMessage}</code>
            <br></br>
            </ModalContent>
            <ModalFooter>
                {saveAnyway}    
                <Button btnType="EditMenuButton" click={props.modalClosed} >Ok</Button>
            </ModalFooter>
        </Modal>


    );

}


export default ApiErrorMessage;