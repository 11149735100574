
import React from 'react';
import classes from './Input.module.css';

const DATA_TYPE_DATE = 'date';
const DATA_TYPE_DATE_WO_TIME = 'dateWithoutTime';
const DATA_TYPE_CURRENCY = 'currency';
const DATA_TYPE_DECIMAL = 'decimal';

function formatDate(date){

    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var dateTmp  = new Date(date);
    return dateTmp.toLocaleDateString("de-DE", options);
}

function formatDateNoDayname(date){

    if (date && date !== ''){
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var dateTmp  = new Date(date);
        return dateTmp.toLocaleDateString("de-DE", options);
    }
    else{
        return '';
    }
}



const Input = (props) => {
    
    let inputElement = null;
    let validationError = null;

    const configLocal = {...props.elementConfig};

    //initialisie value property
    var value = props.value || "";


    //elementType initialisieren
    var elementType = null;
    if (props.elementType){
        elementType = props.elementType;
    }
    else{
        //default
        elementType = 'input';
    }


    //
    var suffix = null;
    var prefix = null;
    let negativeCurrency = false;
    //type 
    if (configLocal){

        //Date
        if (configLocal.type === DATA_TYPE_DATE  || configLocal.type === DATA_TYPE_DATE_WO_TIME){
            configLocal.pattern = '(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}';
            if (props.readOnly && configLocal.type === DATA_TYPE_DATE){
                configLocal.type = 'text';
                if (configLocal.type === DATA_TYPE_DATE){
                    value = formatDate(value, props.readOnly);
                }
                else{
                    value = formatDateNoDayname(value, props.readOnly);
                }
            }
            else{
                configLocal.type = DATA_TYPE_DATE;
            }
            
        }
        //currency
        if (configLocal.type === DATA_TYPE_CURRENCY || configLocal.type === DATA_TYPE_DECIMAL  ){

            if (props.readOnly){
                value = parseFloat(value);//.toFixed(2);    
                if (configLocal.type === DATA_TYPE_CURRENCY){

                    if (value === 0){
                        value = '-';
                    }
                    else{
                        if (value < 0){
                            negativeCurrency = true;
                        }
                        const formatter = new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                            // These options are needed to round to whole numbers if that's what you want.
                            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                          });
                          value  = formatter.format(value);
                    }

                }
            }    
            else{
                configLocal.type = 'number';
                configLocal.step=".01";
            }
        }

    } 

    
    suffix = props.suffix;
    prefix = props.prefix;
    

    var inputStyle = classes.Input;

    const inputClasses = [classes.InputElement];
    var labelClasses = [classes.Label];

    if (props.styleConfig){
        if (props.styleConfig.inputStyle){
            inputStyle = classes[props.styleConfig.inputStyle];
        }
        if (props.styleConfig.inputElementStyle){
            inputClasses.push(classes[props.styleConfig.inputElementStyle]);
        }
        
        if(props.styleConfig.labelStyle){
            labelClasses.push(classes[props.styleConfig.labelStyle]);
        }        
    }

    //console.log(props);
    let  invalid = false;
    if (props.invalid && props.touched){
        inputClasses.push(classes.Invalid);
        //validationError = <p className={classes.ValidationError}>Ungültiger Wert</p>
        invalid = true;
    }    

    if (negativeCurrency){
        inputClasses.push(classes.Negative);
    }

    if (props.readOnly){
        
        if (!invalid){
            inputClasses.push(classes.ReadOnly);
        }

        if (elementType === 'select'){
            elementType = 'input';
            if (props.elementConfig.display_value){
                value = props.elementConfig.display_value;
            }
        }
    }


    // Label style


    switch (elementType){
        case ('input'):
            inputElement = <input   className={inputClasses.join(' ')} 
                                    {...configLocal} 
                                    value={value}
                                    onChange={props.changed}
                                    readOnly={props.readOnly}
                                    />;
            break;
        case ('textArea'):
            inputElement = <textarea className={inputClasses.join(' ')}
                                     {...configLocal}  
                                     value={value}
                                     onChange={props.changed}
                                     />
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')} 
                    onChange={props.changed}
                    value={value}>
                    {props.elementConfig.options.map( option =>(
                        <option value={option.value}
                                key={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            break;
        case ('checkbox'):
                inputElement = <input   className={classes.CheckBox} 
                                        {...configLocal} 
                                        onChange={props.changed}
                                        readOnly={props.readOnly}
                                        disabled={props.readOnly}
                                        checked={value}
                                        type='checkbox'
                                        />;
            break;            
        default: 
            inputElement = <input className={inputClasses.join(' ')} 
                                  {...configLocal}  
                                  value={value}
                                  onChange={props.changed}/>;
    }
    return (
        <div className={inputStyle}>
            <label className={labelClasses.join(' ')}>{props.label}</label>
            <div className={classes.Block}>{prefix}{inputElement} {suffix}</div>
            {validationError}
        </div>
    );
    }


export default Input;