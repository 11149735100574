import React, { useState } from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { FaSave } from "react-icons/fa";






import { RiDeleteBin6Line } from "react-icons/ri";
import { IconButton, Tooltip } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiTask } from "react-icons/bi";
import { BiTaskX } from "react-icons/bi";


import YesCancelModal from '../UI/Modal/YesCancelModal';
import TaskUtil from './TaskUtil';

import SessionHandler from '../../context/SessionHandler';

const TaskMenu = (props) => {

    const [confirmationWindow, setConfirmationWindow] = useState(false);
   


    let popup = null;

    let buttonSave = null;
    let buttonRealease = null;
    let buttonDelete = null;
    let buttonReset = null;
    let buttonClose = null;



    buttonSave =  ( <Tooltip title="Aufgabe speichern">
                            <span>
                            <IconButton onClick={props.saveHandler}   >
                                    <FaSave />
                            </IconButton>  
                            </span>
                    </Tooltip> );

    if (props.task){
        if (props.task.status === TaskUtil.C_STAT_OPEN){
            buttonRealease =  ( <Tooltip title="Aufgabe auf bearbeitet setzen">
                                    <span>
                                        <IconButton onClick={props.releaseHandler}   >
                                                <FaRegCircleCheck />
                                        </IconButton>  
                                    </span>
                                </Tooltip> );
        }
    
    
        if (props.task.status === TaskUtil.C_STAT_PROCESSED){
            buttonReset =    (  <Tooltip title="Status Entwurf zurücksetzen">
                                    <span>
                                    <IconButton onClick={props.resetHandler}  >
                                            <BiTaskX />
                                    </IconButton>  
                                    </span>
                                </Tooltip> );     

            //close task is only allowed for creator or admin
            if ((props.task && props.task.created_by === SessionHandler.getUser().userName ) || 
                    SessionHandler.authSetup() ){                                
                                
                    buttonClose   =    (  <Tooltip title="Aufgabe schliessen">
                                            <span>
                                            <IconButton onClick={props.closeHandler}  >
                                                    <BiTask />
                                            </IconButton>  
                                            </span>
                                        </Tooltip> );     
                    }
        }



    }

    if (confirmationWindow){

        popup = (  <YesCancelModal  clickYes={() =>  { setConfirmationWindow(false); props.deleteHandler()}} 
                            clickCancel={() => setConfirmationWindow(false)}
                            title=" Aufgabe löschen" >
                            Willst du diese Aufgabe löschen?
                    </YesCancelModal>  );
    }



    let buttonsLeft = <React.Fragment> 
                        {buttonSave}
                        {buttonRealease}
                        {buttonReset}
                        {buttonClose}
                    </React.Fragment>;

    

    if (!props.new){

        // delete is only allowed if the user is admin, or if the user created the task
        if ((props.task && props.task.created_by === SessionHandler.getUser().userName ) || 
            SessionHandler.authSetup() ){

            buttonDelete =    <Tooltip title="Löschen">
                                    <span>
                                        <IconButton onClick={() => setConfirmationWindow(true)}  disabled={props.disabled} >
                                            <RiDeleteBin6Line   />
                                        </IconButton>  
                                    </span>
                                </Tooltip>


        }
    }


    return (
        <div className={classes.SubMenuContainer}>
            {popup}
            <div className={classes.SubMenuLeft}>

                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonDelete}
            </div>
        </div>
        );
}

export default TaskMenu;