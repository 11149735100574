import React from 'react';
import classes from './Card.module.css';


const Caption = (props) => {


    return (
        <div className={classes.Caption}>
            {props.children}
        </div>
    );
    }

export default Caption;