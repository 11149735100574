import React  from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import { PureComponent } from 'react/cjs/react.production.min';
import PayrollListItem from './PayrollListItem';
import Spinner from '../UI/Spinner/Spinner';
import GlobalContext from '../../context/GlobalContext';
import SubMenu from '../Navigation/SubMenu/SubMenu';
import ListMenu from './ListMenu';
import Modal from '../UI/Modal/Modal';
import axios from 'axios';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import Input from '../UI/Input/Input';
import TablePaginated from '../UI/Table/TablePaginated';

class PayrollList extends PureComponent{

    static contextType = GlobalContext;

    delMonth = null;

    state = { 
        controls: {
            filter: {
                value: '',
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Filter...'
                },
                valid: true,
                touched: false
            },     
        },
        filteredItems: null, 
        triggerPopup: false,
        loading : false,
        showError: false,
        errorTitle: null,
        errorResponse: null,
        confirmDelete: false,
        message: "",        
        payrollItem: null,
        triggerMonth: null,
        tableConfig: {
            columns : [
                { 
                    colName:  'payrollMonth',
                    colLabel: 'Monat' 
                },
                { 
                    colName:  'firstName' ,
                    colLabel: 'Mitarbeiter' 
                },
                { 
                    colName:  'grossAmount' ,
                    colLabel: 'Bruttogehalt' 
                },
                { 
                    colName:  'created_at' ,
                    colLabel: 'Erstellt am' 
                },
                { 
                    colName:  'funcDownload' ,
                    colLabel: ''                         
                },                
                { 
                    colName:  'funcDelete' ,
                    colLabel: ''                         
                },
            ],
            maxLines : 30,         
            renderLines : (items) => this.renderItems(items),
        }        
    }

    modalClosed = (event) => {
        this.setState({ showError: false,
                        confirmDelete: false, 
                        triggerPopup: false } );
    }    

    inputChangedHandler = (event, inputID) =>{
        this.setState({triggerMonth : event.target.value });
    }


    triggerPayrollHandler = () => {
        const d = new Date();
        let triggerMonth = d.getFullYear() + '.' +  (d.getMonth() + 1).toString().padStart(2, "0");


        this.setState({ triggerPopup : true,
                        triggerMonth: triggerMonth });
    }

    triggerPayroll = () => {

        this.setState({ triggerPopup : false,
                         loading: true });

        const formData = {};
        formData['payrollMonth'] = this.state.triggerMonth;

        axios.post('/payroll',  formData)   
        .then (response => {
            this.delMonth = null;
            
            this.setState({ loading: false });
            this.context.loadPayrollList();
            this.context.resetAbsencesLoaded();

            let text = '';
            response.data.messages.forEach(message => {
                text = text + ";" + message;              
            });
            
            var errorTitle = "Ergebnis Entgeltabrechnung";                
            this.setState({loading: false,
                            payrollItem : null,
                            showError: true,
                            errorResponse: response ,
                            errorTitle: errorTitle });              
        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler bei Berechnung der Entgeltabrechnung";                
            this.setState({loading: false,
                            payrollItem : null,
                            showError: true,
                            errorResponse: error.response ,
                            errorTitle: errorTitle });              
        });      

    }    
    

    deleteHandler = (payrollItem) => {

        const mes = 'Willst du die Entgeltabrechnung ' + payrollItem.payrollMonth  + ' von ' + payrollItem.firstName + ' wirklich löschen?';

        this.setState({ confirmDelete: true ,
                        message: mes ,
                        payrollItem: payrollItem
        } );        
    }

     
    deletePayrollResult = () => {
        this.setState({ confirmDelete: false ,
                        loading: true,
                        message: '' 
                        } );      
        axios.delete('/payroll/' + this.state.payrollItem.payrollMonth + '/' + this.state.payrollItem.PERNR  )   
        .then (response => {
            this.context.deletePayrollItem(this.state.payrollItem.ID);
            this.context.resetAbsencesLoaded();
            this.setState({ loading: false, 
                            payrollItem : null});
            this.delMonth = null;
            const message = {
                type: 'S',
                text: 'Entgeltabrechung gelöscht'
            }
            this.context.showMessage(message);                      

        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler beim Löschen der Entgeltabrechnung";                
            this.setState({loading: false,
                            payrollItem : null,
                            showError: true,
                            errorResponse: error.response ,
                            errorTitle: errorTitle });              
        });      

        
    }    

    downloadHandler = (payrollItem) => {
        this.setState({loading: true});
        axios.get('/payroll/' + payrollItem.payrollMonth + '/' + payrollItem.PERNR + '/file', { responseType: 'arraybuffer' } )   
        .then (response => {

            this.setState({loading: false});
            this.downloadBinary(payrollItem, response);
        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler beim Download der Rechnung";                
            this.setState({loading: false,
                            showError: true,
                            errorResponse: error.response ,
                            errorTitle: errorTitle });              
        });      
                
    }

    downloadBinary = (payrollItem, response) => {

        const url = window.URL.createObjectURL(
            new Blob([response.data], {/*type: 'application/pdf'*/}),
         );
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute(
            'download',
            payrollItem.payrollMonth + '_' + payrollItem.firstName + '.pdf',
         );
   
         // Append to html link element page
         document.body.appendChild(link);
   
         // Start download
         link.click();
   
         // Clean up and remove the link
         link.parentNode.removeChild(link);
    }

    
    renderItems = (items) => {
        return (
            <Auxiliary>
            {items && items.map((payrollItem, index) => {
                
                 if (!this.delMonth || this.delMonth < payrollItem.payrollMonth){
                    this.delMonth = payrollItem.payrollMonth;
                }
                
                return (
                <PayrollListItem 
                    tableConfig={this.state.tableConfig}
                    payrollItem={payrollItem}
                    key={index}
                    downloadHandler={this.downloadHandler} 
                    deleteHandler={this.deleteHandler}
                    deleteAllowed={this.delMonth ===  payrollItem.payrollMonth}
                />
            )})}
            </Auxiliary>
        );
    }
    

    render() {
        if (false && this.context.payrollLoading  && !this.context.payrollLoaded ){
            return  (<Spinner/>);
        }
        else{

            let triggerPopup = null;
            if (this.state.triggerPopup){
                
                triggerPopup = (    <YesCancelModal  
                                        clickYes={this.triggerPayroll} 
                                        clickCancel={this.modalClosed}
                                        title="Entgeltberechnung starten" >
                                            {this.state.message}
                                            <Input key= {'payrollMonth'}  
                                                   
                                                   value={this.state.triggerMonth} 
                                                   label='Abrechnungsmonat'   
                                                   changed={(event) => this.inputChangedHandler(event, 'payrollMonth')} 
                                                   readOnly={false} 
                                                   />
                                    </YesCancelModal> 
                );

            }

            const menu = (    <SubMenu popupMenu={false} history={this.props.history}>
                                <ListMenu   controls={this.state.controls} 
                                            changed={(event) => this.filterHandler(event)}
                                            
                                            triggerPayroll={this.triggerPayrollHandler} 
                                            />
            </SubMenu>
            );

            let items = this.context.payrollList;
            if (this.state.controls.filter.value !== ''){
                items = this.state.filteredItems;
            }

            var confirmationPopup = null;
            if (this.state.confirmDelete){
                confirmationPopup = (  <YesCancelModal  clickYes={this.deletePayrollResult} 
                                                        clickCancel={this.modalClosed}
                                                        title="Abfrage" >{this.state.message}</YesCancelModal>  );
            }            

            return(
                <Auxiliary>
                    {menu}
                    {triggerPopup}
                    {confirmationPopup}
                    <ApiErrorMessage 
                            show={this.state.showError} 
                            modalClosed={this.modalClosed} 
                            errorResponse={this.state.errorResponse} 
                            title={this.state.errorTitle}
                            >
                    </ApiErrorMessage>                    
                    <Modal show={( this.state.loading || this.context.payrollLoading  || !this.context.payrollLoaded ) 
                                    && !this.state.showError}><Spinner/></Modal>
                    <h3>Entgeltberechnung</h3>
                    <TablePaginated tableConfig={this.state.tableConfig} 
                                    items={items}/>                                    
                </Auxiliary>
            );
        }
    }
    
    componentDidMount(){
        
        if (!this.context.payrollLoaded){
            this.context.loadPayrollList();
        }
        window.scrollTo(0, 0);  
    }


     filterHandler = (event)  => {
        
        
        let controls = { ...this.state.controls}; 
        controls.filter.value = event.target.value;
        const filteredItems = this.getFilteredItems(controls.filter.value);
        this.setState({ filteredItems: filteredItems});                
        
        
    }

    getFilteredItems = (filterValue) => {
        const regex = RegExp(filterValue, 'i');
        return this.context.payrollList
                    .filter(   payrollItem =>   ( regex.test(payrollItem.firstName) || regex.test(payrollItem.payrollMonth))  
                                            
                    );        
    }    


}

export default PayrollList;
        