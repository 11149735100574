import React  from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import { PureComponent } from 'react/cjs/react.production.min';
import Absence from './Absence';
import Spinner from '../UI/Spinner/Spinner';
import GlobalContext from '../../context/GlobalContext';

import SubMenu from '../Navigation/SubMenu/SubMenu';
import ListMenu from './ListMenu';
import Modal from '../UI/Modal/Modal';
import axios from 'axios';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import TablePaginated from '../UI/Table/TablePaginated';
import AbsenceRequest from './AbsenceRequest';

class Absences extends PureComponent{

    static contextType = GlobalContext;

    state = { 
        controls: {
            filter: {
                value: '',
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Filter...'
                },
                valid: true,
                touched: false
            },     
        },
        filteredItems: null,         
        loading : false,
        confirmDelete: false,
        message: "",        
        deleteItem: null,       
        requestPopup: false, 
        newAbsence: {
            PERNR: null,
            absenceType: null,
            date: null,
        },    
        showError: false,
        errorTitle: null,
        errorResponse: null,        
        tableConfig: {
            columns : [
                { 
                    colName:  'date',
                    colLabel: 'Datum' 
                },
                { 
                    colName:  'firstName' ,
                    colLabel: 'Mitarbeiter' 
                },
                { 
                    colName:  'Name' ,
                    colLabel: 'Abwesenheitsart' 
                },
                { 
                    colName:  'MAABR' ,
                    colLabel: 'Entgeltabrechnung' 
                },
                { 
                    colName:  'funcDelete' ,
                    colLabel: ''                         
                },
            ],
            maxLines : 30,         
            renderLines : (items) => this.renderLines(items),
        }
    }

    deleteHandler = (item) => {
        const mes = 'Willst du die Abwesenheit vom ' + item.date  + ' von ' + item.firstName + ' wirklich löschen?';
        this.setState({ confirmDelete: true ,
                        message: mes ,
                        deleteItem: item
        } );        
    }

    newAbsence = () => {
        this.setState({ requestPopup: true });
    }

    createAbsence = () => {
        
        this.setState({ requestPopup: false ,
                        loading: true
                        } );      

        const formData = {...this.state.newAbsence };
        //appointment data

        axios.post('/absences' , formData  )   
            .then (response => {
                this.context.addAbsence(response.data.data);
                this.setState({ loading: false, 
                                newAbsence : {}});
                const message = {
                    type: 'S',
                    text: 'Neue Abwesenheit gespeichert'
                }
                this.context.showMessage(message);                      
            })
            .catch (error => {
                console.log(error); 
                this.setState({loading: false});
                var errorTitle = "Fehler beim Speichern der Abwesenheit";                
                this.setState({ loading: false,
                                showError: true,
                                errorResponse: error.response ,
                                errorTitle: errorTitle });              
        });              
    }

    deleteAbsence = () => {
        this.setState({ confirmDelete: false ,
                        loading: true,
                        message: '' 
                        } );      
        axios.delete('/absences/' + this.state.deleteItem.ID  )   
        .then (response => {

            this.context.deleteAbsence(this.state.deleteItem.ID);
            this.setState({ loading: false, 
                            deleteItem : null});
            
            const message = {
                type: 'S',
                text: 'Abwesenheit gelöscht'
            }
            this.context.showMessage(message);                      

        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler beim Löschen der Abwesenheit";                
            this.setState({ loading: false,
                            deleteItem : null,
                            showError: true,
                            errorResponse: error.response ,
                            errorTitle: errorTitle });              
        });      
    }   
    
    inputChangedHandler = (event, field) =>{

        const newAbsence  = {...this.state.newAbsence};
        newAbsence[field] = event.target.value;

        this.setState({newAbsence: newAbsence});
    }

    renderLines = (items) => {
        return (
            <Auxiliary>
            {items && items.map((absence, index) => {
                return (
                    <Absence tableConfig={this.state.tableConfig}
                             key={index}
                             item={absence}
                             deleteHandler={this.deleteHandler} />
            )})}
            </Auxiliary>
        );
    }

    modalClosed = (event) => {
        this.setState({ showError: false,
                        confirmDelete: false, 
                        triggerPopup: false,
                        requestPopup: false } );
    }        

    render() {
        let items =  this.context.absences;
        if (this.state.filteredItems){
            items = this.state.filteredItems;
        }
        /****************************************************************************************** */
        // manage modal content
        
        let modalContent = null;
        if (this.state.loading || this.context.absencesLoading  || !this.context.absencesLoaded){
            modalContent = <Modal show={true}><Spinner/></Modal>;
        }
        else if (this.state.confirmDelete){
            modalContent = (  <YesCancelModal   clickYes={this.deleteAbsence} 
                                                clickCancel={this.modalClosed}
                                                title="Abfrage" >
                                    {this.state.message}
                             </YesCancelModal>  );
        }
        else if (this.state.requestPopup){

            modalContent = ( <YesCancelModal   clickYes={this.createAbsence} 
                                               clickCancel={this.modalClosed}
                                               title="Neue Abwesenheit erfassen" > 
                                    <AbsenceRequest newAbsence={this.state.newAbsence}
                                                    inputChangedHandler={this.inputChangedHandler}/>
                            </YesCancelModal>  );
        }
        else if (this.state.showError){
            modalContent = (  <ApiErrorMessage 
                                show={this.state.showError} 
                                modalClosed={this.modalClosed} 
                                errorResponse={this.state.errorResponse} 
                                title={this.state.errorTitle}
                                />
                             );

        }
        /****************************************************************************************** */
        return(
            <Auxiliary>  
                <SubMenu popupMenu={false} history={this.props.history}>
                        <ListMenu   controls={this.state.controls} 
                                    changed={(event) => this.filterHandler(event)}
                                    newAbsence={this.newAbsence} />
                </SubMenu>                            
                {modalContent}
                <h3>Abwesenheiten</h3>
                <TablePaginated tableConfig={this.state.tableConfig} 
                                items={items}/>
            </Auxiliary>
        );
    }

    componentDidMount(){
        
        if (!this.context.absencesLoaded){
            this.context.loadAbsences();
        }
        window.scrollTo(0, 0);  
    }



    filterHandler = (event)  => {
        
        
        let controls = { ...this.state.controls}; 
        controls.filter.value = event.target.value;
        const filteredItems = this.getFilteredItems(controls.filter.value);
        this.setState({ filteredItems: filteredItems});                
        
        
    }

    getFilteredItems = (filterValue) => {
        const regex = RegExp(filterValue, 'i');
        return this.context.absences
                    .filter(   absence =>   ( regex.test(absence.firstName) || regex.test(absence.Name) || regex.test(absence.MAABR))  
                                            
                    );        
    }        

}


export default Absences;