import React  from 'react';

import classes from './Calendar.module.css';
import Event from './Event';

const Events = (props) => {

    let content = null;

    if (props.config && props.config.apps){
    
        const events = props.config.apps.map(app => (
            <Event key={app.ID} appointment={app} selected={props.selected} navAppointment={props.navAppointment} dayHours={props.dayHours}></Event>)
        );
        
        let className = classes.EventsContainer;
        if (props.fullDayRow){
            className = classes.FullDayEventsContainer;
        }

        content = <div className={className}>{events}</div>;
    }
    

    return content;

}

export default Events;