import React, {useState} from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import YesCancelModal from '../Modal/YesCancelModal';
import classes from './FotoUpload.module.css';

import Resizer from "react-image-file-resizer";
import Modal from '../Modal/Modal';
import ModalContent from '../Modal/ModalContent';
import Input from '../Input/Input';




const FotoUpload = (props) => {
    /*************************************************** */
    /** local state */
    const [preview, setPreview] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);

    /*************************************************** */
    /** local event handlers */
    const handleStoreFile = (binary) => {
        setPreview(false);
        
        binary.description = description;
        
        setDescription('');
        props.storeFile(binary);

        //setFile(null);

    };

    const styleConfig = {   
      inputStyle: 'InputDetailColumn',
      labelStyle: 'LabelLight',
      inputElementStyle:'InputElementDetailColumn'
                        };

    const resizeFile = (file) =>
        new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            1000,
            1000,
            "JPEG",
            90,
            0,
            (uri) => {
              resolve(uri);
            },
            "file"
          );
        });


    const handleCaptureAsync = async (event) => {

      if (event.target.files  && event.target.files.length !== 0){
        const file = event.target.files[0];
        setShowSpinner(true);
        //const image = file;
        //type: "application/pdf"
        //console.log(file);

        let image = file;
        
        const tmpIsImage = file.type.includes('image');
        

        if ( tmpIsImage ){
          image = await resizeFile(file);
        }

        setFile(image);
        setIsImage(tmpIsImage);

        if (tmpIsImage || props.withDescription){
          setPreview( true );
        }
        else{
          handleStoreFile(image);
        }
        
        setShowSpinner(false);

      }
    }  

    let descr = null;
    if (props.withDescription){
      descr = <Input key= {'description'}  
                    elementType= 'input'   
                    styleConfig={styleConfig}
                    value={description} 
                    changed={(event) => setDescription(event.target.value)} 
                    readOnly={false} 
                    label='Beschreibung (optional)'  
                    />;

    }

    let img = null;
    if (isImage){

      img = <img className={classes.Image} src={URL.createObjectURL(file)}  alt='Bild Rezept'/>;

    }


    let popup = null;
    if (preview ){
        popup = ( 
            <YesCancelModal show={true} 
                            clickCancel={() => setPreview(false)}
                            clickYes={() => handleStoreFile( file )}    
                            title='Möchtest du das Foto hochladen?'
                            modalType='BigModal'
                            >
                {img}
                {descr}
            </YesCancelModal>
        );
    }

    let label = null;
    if (props.label){
      label = (<label>{props.label}</label>);
    }


    return (
        <Auxiliary>
            <Modal show={showSpinner}><ModalContent >Resizing...</ModalContent></Modal>
            {popup}
            {/*<AiOutlineCloudUpload  className={classes.Icon}/>*/}
            <div className={classes.Upload}>
            {label}
            <input
                className={classes.Upload}
                accept="image/*"
                id="fileUpload"
                type="file"
                //capture
                //change="setImage"
                //capture="environment"
                disabled={props.disabled}
                onChange={(event) => handleCaptureAsync(event)}
            />   
            </div>
        </Auxiliary>
    );
}


export default FotoUpload;