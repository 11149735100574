import React from 'react';
import Input from '../UI/Input/Input';
import DetailContainer from '../Layout/DetailContainer';
import Facts from '../UI/Card/Facts';
import PatientInput from '../UI/PatientInput/PatientInput';
import RichText from '../UI/RichText/RichText';
import Auxiliary from '../../hoc/Auxiliary';


const prescriptionDetailBasics = (props) => {

    const styleConfig = {   inputStyle: 'InputDetail',
                            labelStyle: 'LabelLightFixed',
                            inputElementStyle:'InputElementDetail' };                                        

    const configDate = { type: 'dateWithoutTime' };
    const configDecimal = { type: 'decimal' };
    
    let readOnly = true; 
    if (props.controls){
        readOnly = props.controls.readOnly;
    }
    
    let patient = null;
    let diagnose = null;
    let note = null;
    let readOnlyPatient = true;
    
    patient = ( <PatientInput idx= {'patient'} 
                    elementType= 'input' 
                    styleConfig= {styleConfig} 
                    value={props.prescription.displayname} 
                    patientID={props.prescription.patient}  
                    label='Patient' 
                    selectPatientHandler={(patientId) => props.selectPatientHandler(patientId)}
                    readOnly={readOnlyPatient}
                    touched={true}
                    invalid={props.controls && !props.controls.patientValid}                                                        
                    />
                );
    
    if (props.allDetails){        
        if (!props.prescription.ID){
           readOnlyPatient = false;
        }

        patient = ( <PatientInput idx= {'patient'} 
        elementType= 'input' 
        styleConfig= {styleConfig} 
        value={props.prescription.displayname} 
        patientID={props.prescription.patient}  
        label='Patient' 
        selectPatientHandler={(patientId) => props.selectPatientHandler(patientId)}
        readOnly={readOnlyPatient}
        touched={true}
        invalid={props.controls && !props.controls.patientValid}                                                        
        />
        );
        
        diagnose = (<RichText   elemID= {'diagnose'}  
                                htmlText={props.prescription.diagnose} 
                                label='Diagnose' 
                                readOnly={readOnly} 
                                onChange={props.richTextChangeHandler} 
                                toolbarHidden={true}/>);

        note = ( <RichText  elemID= {'comment'}  
                            htmlText={props.prescription.comment} 
                            label='Notiz' 
                            readOnly={readOnly} 
                            onChange={props.richTextChangeHandler} 
                            toolbarHidden={true}/>                                            )
    }
    
    return(
            <Auxiliary>
                {diagnose}
                <DetailContainer>                                                                                                
                    <Facts >
                        {patient}
                        <Input  key= {'status'} 
                                elementType= 'input' 
                                styleConfig= {styleConfig} 
                                value={props.prescription.statusText} 
                                label='Status' 
                                readOnly={true} />
                        <Input  key= {'date'} 
                                elementType= 'input' 
                                elementConfig={configDate}
                                styleConfig= {styleConfig} 
                                value={props.prescription.date} 
                                changed={(event) => props.inputChangedHandler(event, 'date')}
                                label='Datum' 
                                readOnly={readOnly} 
                                touched={true}
                                invalid={props.controls &&  !props.controls.dateValid}
                                />          

                        </Facts>    
                        <Facts>    
                        <Input  key= {'hours'} 
                                elementType= 'input' 
                                elementConfig={configDecimal}
                                styleConfig= {styleConfig} 
                                value={props.prescription.hours} 
                                label='Gesamt' 
                                changed={(event) => props.inputChangedHandler(event, 'hours')}
                                readOnly={readOnly} 
                                touched={true}
                                invalid={props.controls && !props.controls.hoursValid}                                
                                />                                
                        <Input  key= {'consumed'} 
                                elementType= 'input' 
                                elementConfig={configDecimal}
                                styleConfig= {styleConfig} 
                                value={props.prescription.consumed} 
                                label='Verbraucht' 
                                readOnly={true} />                                                  
                        <Input  key= {'leftOpen'} 
                                elementType= 'input' 
                                elementConfig={configDecimal}
                                styleConfig= {styleConfig} 
                                value={props.prescription.leftOpen} 
                                label='Offen' 
                                readOnly={true} />
                    </Facts>                                                                                                                                                                                 
                    </DetailContainer> 
                    {note}
                </Auxiliary>
    );
}
export default prescriptionDetailBasics;
