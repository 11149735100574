import React from 'react';
import MUIInput from '../MUI/MUIInput/MUIInput';

const Password = (props) => {

    let content = null;

    let error = false;
    let helperText = "";

    if (props.user.password !== props.user.password_confirmation ){
        error = true;
        helperText = "Passwörter sind unterschiedlich";
    }
    else if (props.user.password && props.user.password.length < 8  ){
        error = true;
        helperText = "Das Passwort muss mindestens 8 Zeichen haben";
    }

    content = <React.Fragment>
                    <MUIInput     id={'password'}
                                        label='Passwort' 
                                        value={props.user.password}
                                        type="password"
                                        fullWidth
                                        required
                                        error={error}
                                        helperText={helperText}
                                        onChange={ (event) => props.inputChangedHandler(event.target.value, 'password') }
                                        />
                    <MUIInput     id={'passwordConfirmation'}
                                        label='Passwort wiederholen' 
                                        value={props.user.password_confirmation}
                                        type="password"
                                        fullWidth
                                        required
                                        error={error}
                                        helperText={helperText}
                                        onChange={ (event) => props.inputChangedHandler(event.target.value, 'password_confirmation') }
                                        />                                        
        
        
            </React.Fragment>  ; 
  

    return content;

}

export default Password;