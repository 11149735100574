import React from 'react';



const notFound = () => ( 

    <div><h1>404 Not found</h1> </div>

);


export default notFound; 