
import React from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import GlobalContext from '../../context/GlobalContext';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DetailContainer from '../Layout/DetailContainer';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import PraxisMenu from './PraxisMenu';

const Praxis = (props) => {

    const history = useHistory();
    const { PRXID } = useParams();
    const globalContext = useContext(GlobalContext);
    
    const [praxis, setPraxis] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');

    /* initialize component ********************************************************/
    useEffect(() => {
        
        if (props.new){
            const newPraxis = {
                PRXID : null,
                PRAXIS_NAME : '',
                address : "",
                city : "",
                country : '',
                inactive : false,
                online : false,
                postcode : "", 
                link : ""

            };
            setPraxis(newPraxis);

        }
        else{
            // read therapist from context

            const objIndex = globalContext.praxis.findIndex((obj => obj.PRXID === parseInt(PRXID)));
            if (objIndex !== -1){
                const t = globalContext.praxis[objIndex];
                setPraxis(t);
            }
        }

        
        }, [PRXID]);
    /***end: initialize component *************************************************** */
    
    const inputChangedHandler = (event, inputID) =>{

        const updatedForm = { ...praxis };
        updatedForm[inputID] = event.target.value;
        setPraxis(updatedForm);
    }



    const inputChangedHandlerSwitch = (event, inputID) =>{
        const updatedForm = { ...praxis };
        updatedForm[inputID] = event.target.checked;
        setPraxis(updatedForm);
    }    
        

    const deleteHandler = () =>{
                // update logic 
        setLoading(true);
        axios.delete('/praxis/' + praxis.PRXID )
        .then (response => {

            globalContext.deletePraxis( praxis.PRXID);

            setLoading(false);

            const message = {
                type: 'S',
                text: 'Praxis gelöscht'
            }
            globalContext.showMessage(message);                             
            history.goBack();
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Löschen der Praxis");
            setLoading(false);
        });  
      

    }

    const saveHandler = () =>{
        
        if (props.new){
            createPraxis();
            return;
        }
        
        // update logic 
        setLoading(true);
        axios.put('/praxis/' + praxis.PRXID   , praxis)
        .then (response => {
            const updatedPraxis = response.data.data;

            globalContext.updatePraxis(updatedPraxis);

            setPraxis(updatedPraxis);
            setLoading(false);
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Speichern der Praxis");
            setLoading(false);
        });  

        

    }

    

    const createPraxis = () =>{
        
        setLoading(true);
        axios.post('/praxis'  , praxis)
        .then (response => {
            const newPraxis = response.data.data;

            globalContext.updatePraxis(newPraxis);

            history.replace('/setup/praxis/' + newPraxis.PRXID);

        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Anlegen der Praxis");
            setLoading(false);
        });  
        
    }

    let content = null;
    if (praxis){

        content = <React.Fragment>
                    <h1>{praxis.PRAXIS_NAME}</h1>
                    <ApiErrorMessage    show={showError} 
                                    modalClosed={() => { setShowError(false) }} 
                                    errorResponse={errorResponse} 
                                    title={errorTitle}
                                    />    
                    <DetailContainer> 
                        <FactsMui>               
                            <MUIInput       id={'PRAXIS_NAME'}
                                            label='Praxisname' 
                                            value={praxis.PRAXIS_NAME}
                                            fullWidth
                                            required
                                            onChange={ (event) => inputChangedHandler(event, 'PRAXIS_NAME') }
                                            />    
                            <MUIInput       id={'address'}
                                            label='Adresse' 
                                            value={praxis.address}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'address') }
                                            />   
                            <MUIInput       id={'postcode'}
                                            label='Postleitzahl' 
                                            value={praxis.postcode}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'postcode') }
                                            />
                            <MUIInput       id={'city'}
                                            label='Stadt' 
                                            value={praxis.city}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'city') }
                                            /> 
                            <MUIInput       id={'link'}
                                            label='Google Maps Link' 
                                            value={praxis.link}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'link') }
                                            />                                             
                            { !props.new && 
                            <React.Fragment>
                            <FormControl>
                                <FormControlLabel control={  <Switch    checked={praxis.online}
                                                                        onChange={(event) => inputChangedHandlerSwitch(event, 'online')}
                                                                        />    } 
                                                label="Onlinebuchungstool" />   
                                <FormHelperText>Falls Onlinebuchungstool aktiviert ist, ist der Standort im Onlinebuchungstool sichtbar</FormHelperText>      
                            </FormControl>    
                            <FormControl>                  
                                <FormControlLabel control={  <Switch    checked={praxis.inactive}
                                                                        onChange={(event) => inputChangedHandlerSwitch(event, 'inactive')}
                                                                        />    } 
                                                label="Inaktiv" />         
                                <FormHelperText>Falls der Standort inaktiv ist, kann dieser nicht mehr einem Therapeuten zugeordnet werden.</FormHelperText>                                                                                  
                            </FormControl>    
                            </React.Fragment>
                            }
                            
                                                                                                                                                                                                                                  
                        </FactsMui>
                    </DetailContainer>                    

                </React.Fragment>



    }

    return <React.Fragment>
                <Modal show={loading}><Spinner/></Modal>
                <SubMenuMui  history={history}>
                        <PraxisMenu    saveHandler={() => saveHandler()}
                                          deleteHandler={() => deleteHandler()}  /> 
                </SubMenuMui>   
                {content}
            </React.Fragment>    

}

export default Praxis;