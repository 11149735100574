import React, { Component } from 'react';

import Auxiliary from '../Auxiliary';

import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import ModalHeader from '../../components/UI/Modal/ModalHeader';
import ModalContent from '../../components/UI/Modal/ModalContent';
import ModalFooter from '../../components/UI/Modal/ModalFooter';

const withErrorHandler = (WrappedComponent, axios) => {

    return class extends Component{

        _isMounted = false;


        state = {
            error: null
        }

        componentDidMount(){
            this._isMounted = true;
            axios.interceptors.request.use(req => {
                //console.log(req);
                if (this._isMounted){
                    this.setState({error: null});
                }
                
                return req;
            });
            axios.interceptors.response.use(res => res, error => {
                if (this._isMounted){
                    this.setState({error: error});
                }
                return Promise.reject(error);
            });
        }

        componentWillUnmount(){
            this._isMounted = false;
        }

        errorConfirmedHandler = () => {
            //console.log('errorConfirmedHandler')
            this.setState({error: null});
        }

        render (){
            /*
            console.log(JSON.stringify(this.state.error));
            if (this.state.error){
                console.log(this.state.error.config);
            }
            */
            
            return ( 
                <Auxiliary>
                    <Modal show={this.state.error}  >
                        <ModalHeader title="Fehler" onClose={this.errorConfirmedHandler}/>
                        <ModalContent >
                            <code >{this.state.error ? this.state.error.message : null}</code>
                        </ModalContent>
                        <ModalFooter>
                            <Button btnType="EditMenuButton" click={this.errorConfirmedHandler} >Ok</Button>
                        </ModalFooter>
                    </Modal>

                    <WrappedComponent {...this.props} />
                </Auxiliary>
                );
        }
    }
}


export default withErrorHandler;