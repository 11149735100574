import {  RiCheckboxBlankCircleLine , RiCheckboxCircleLine } from  "react-icons/ri";


import classes from './Task.module.css';

class TaskUtil{


    static C_STAT_OPEN  = 11;
    static C_STAT_PROCESSED = 20; 
    static C_STAT_DELETED = 99;
    static C_STAT_COMPLETED = 97;

  
    static C_PARAM_PRESCRIPTION = "prescriptionID";
    static C_PARAM_PATIENT = "patientID";
    static C_PARAM_APPOINTMENT = "appointmentID";
    static C_PARAM_INVOICE = "RGNUM";
    static C_PARAM_SUBJECT = "Subject";


    static getStatusIcon(status) {
        
        switch (status) {
            case  TaskUtil.C_STAT_OPEN:
                return (<RiCheckboxBlankCircleLine className={classes.IconOpen}/>);
            //case  InvoiceUtil.C_STAT_OVERDUE:
            //    return (<RiErrorWarningLine className={classes.IconOverdue}/>);
            case  TaskUtil.C_STAT_PROCESSED:
                return (<RiCheckboxCircleLine className={classes.IconSuccess}/>);     
           /*
                case  TaskUtil.C_STAT_WRITEOFF:
                return (<RiCloseCircleLine className={classes.IconWriteOff}/>);     
            default:
                if ( InvoiceUtil.isOverDue(status) ){
                    return (<RiErrorWarningLine className={classes.IconOverdue}/>);
                }
                else if ( InvoiceUtil.isDunningStatus(status)){
                    return (<AiOutlineFileExclamation className={classes.IconDunned}/>);
                }
                else{
                    return null;
                }
                */
                default:
                
        } 
    }      


}


export default TaskUtil;