import React from 'react';
//import classes from '../Navigation/SubMenu/SubMenu.module.css';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import { RiCheckboxBlankCircleLine, RiErrorWarningLine , RiCloseCircleLine } from  "react-icons/ri";
import {  AiOutlineFileExclamation } from  "react-icons/ai";
import Auxiliary from '../../hoc/Auxiliary';
import classes from './Invoice.module.css';
import Tooltip from '../UI/Tooltip/Tooltip';
import InvoiceUtil from './InvoiceUtil'; 



const ListMenu = (props) =>  {
        
    //Patientfilter
    const styleConfig = {
        inputStyle: 'InputDetail', //InvItemInputWrapper',
        labelStyle: 'LabelNone',//InvItemLabel',
        inputElementStyle:'InputElemMenu'//'InvItemInputElement'
    };           
    

    return (
        <Auxiliary >
            <li className={classes.SubMenuInput}>
                
                <Input  key='filter'
                        elementType={props.controls.filter.elementTyp}
                        elementConfig={props.controls.filter.elementConfig}
                        value={props.filter.textFilter}
                        styleConfig={styleConfig}
                        changed={(event) => props.filterText(event)}/>            
                        
            </li>
            {/*
            <li className={classes.EditButton}>
                
                    <Tooltip content={'Bezahlt'}><Button active={props.filter.statusFilter.includes(InvoiceUtil.C_STAT_PAID) ? 1 : undefined} 
                            btnType={'StatusFilterButton'}
                            click={() => props.filterStatus(InvoiceUtil.C_STAT_PAID)}  
                            >
                                <RiCheckboxCircleLine className={classes.IconSuccess} />
                    </Button></Tooltip>
                
            </li>    
            */}
            <li className={classes.EditButton}>
                <Tooltip content={'Offen'}><Button active={props.filter.statusFilter.includes(InvoiceUtil.C_STAT_OPEN)? 1 : undefined} 
                        btnType={'StatusFilterButton'}
                        click={() => props.filterStatus(InvoiceUtil.C_STAT_OPEN)}  
                        >
                            <RiCheckboxBlankCircleLine className={classes.IconOpen}/>
                </Button></Tooltip>
            </li>    
            <li className={classes.EditButton}>
                <Tooltip content={'Gemahnt'}><Button active={props.filter.statusFilter.includes(InvoiceUtil.C_STAT_DUNN_L1)? 1 : undefined} 
                        btnType={'StatusFilterButton'}
                        click={() => props.filterStatus(InvoiceUtil.C_STAT_DUNN_L1)}  
                        >
                            <AiOutlineFileExclamation className={classes.IconDunned}/>
                </Button></Tooltip>
            </li>              
            <li className={classes.EditButton}>
                <Tooltip content={'Überfällig'}><Button active={props.filter.statusFilter.includes(InvoiceUtil.C_STAT_OVERDUE)? 1 : undefined} 
                        btnType={'StatusFilterButton'}
                        click={() => props.filterStatus(InvoiceUtil.C_STAT_OVERDUE)}  
                        >
                            <RiErrorWarningLine className={classes.IconOverdue}/>
                </Button></Tooltip>
            </li>    
  
            <li className={classes.EditButton}>
                <Tooltip content={'Abgeschrieben'}><Button active={props.filter.statusFilter.includes(InvoiceUtil.C_STAT_WRITEOFF)? 1 : undefined} 
                        btnType={'StatusFilterButton'}
                        click={() => props.filterStatus(InvoiceUtil.C_STAT_WRITEOFF)}  
                        >
                            <RiCloseCircleLine className={classes.IconWriteOff}/>
                </Button></Tooltip>
            </li>                                                
        </Auxiliary>
        );
    }

export default ListMenu;