
import React from 'react';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import Button from '../Button/Button';
import ModalFooter from './ModalFooter';




const YesCancelModal = (props) => {
    
   

    let modalType = 'Frameless';
    if (props.modalType){
        modalType = props.modalType;
    }

    return (
    <Modal show={true}  type={modalType}>
        <ModalHeader title={props.title} onClose={props.clickCancel}/>  
        <ModalContent >
            <div>{props.children}</div>
        </ModalContent>      
        <ModalFooter>
            <Button btnType="EditMenuButton" click={props.clickYes} >Ja</Button>
            <Button btnType="EditMenuButton" click={props.clickCancel} >Abbrechen</Button>
        </ModalFooter>
    </Modal>

)};

export default YesCancelModal;