import React, {Component} from 'react';
import GlobalContext from '../../context/GlobalContext';
import Auxiliary from '../../hoc/Auxiliary';
import classes from './Invoice.module.css';
import Spinner from '../UI/Spinner/Spinner';
import axios from 'axios';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import InvoiceUtil from './InvoiceUtil';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import AppointmentUtil from '../Appointments/AppointmentUtil';
import InvoiceDetailViewMUI from './InvoiceDetailViewMUI';
import InvoiceMenuMui from './InvoiceMenuMui';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import Status from '../MUI/Status/Status';
import InvoiceAppList from './InvoiceAppList';


class Invoice extends Component{

    static contextType = GlobalContext;
    isComponentMounted = false;
    newInvoice = false;
    state = {
        loading: false,
        invoice: null,
        patient: null,
        appointments: [],
        appLoading: false, 
        showError: false,
        confirmDelete: false,
        errorMessage: '',
        errorTitle: '',    
        errorResponse: null,
        portoService: { PREIS: 0 },
        buttonControl: {
            saveDisabled: true,
            buttonDownload: true,
            buttonDunning: true,
            buttonDelete: true,
            buttonSendEmail: false,
            buttonDownDunning1Disabled: true,
            buttonDownDunning2Disabled: true
        },
        controls: {
            typeConfig: { options: [] },
            prescriptionConfig: { options: [] },
            amountValid: true,
            invoiceTypeValid: true,
            therapeutValid: true
        }
    };


    commentChangeHandler = (htmlText, elemID) =>{
        const buttonControl = { ...this.state.buttonControl};
        const updatedForm = { ...this.state.invoice };
        updatedForm[elemID] = htmlText;
        if (!this.newInvoice){
            buttonControl.saveDisabled = false;
        }
        this.setState({ invoice: updatedForm,
                        buttonControl: buttonControl          });
    }  

    dateInputChangeHandler = (value, inputID) => {
        const updatedForm = { ...this.state.invoice };
        
        updatedForm[inputID] = value;
        
        if (inputID === 'paymentDate') {    
            if (parseFloat(updatedForm.paymentAmount) === 0 ){
                updatedForm.paymentAmount = updatedForm.amount;
            }
        }
        this.setState({ invoice: updatedForm });        
    }

    inputChangedHandler = (event, inputID) =>{
        const updatedForm = { ...this.state.invoice };
        let buttonControl = { ...this.state.buttonControl};
        let controls = {...this.state.controls};
        let appointments = [ ...this.state.appointments ];

        if (event.target.type === "checkbox"){
            //special handling for checkboxes... 
            updatedForm[inputID] = event.target.checked;
            if (inputID === 'porto') {    
                updatedForm['amount'] = this.calcInvoiceTotal(appointments, updatedForm[inputID]);
            }            
            
        }
        else if (inputID === 'prescriptionID') {    
            updatedForm[inputID] = event.target.value;
            this.selectPrescription(event.target.value, appointments, updatedForm);
            buttonControl = this.getButtonControl(updatedForm);
            this.setControlValidity(controls, updatedForm);
        }
        else if (inputID === 'invoiceType') {    
            updatedForm[inputID] = event.target.value;
            buttonControl = this.getButtonControl(updatedForm);
            this.setControlValidity(controls, updatedForm);
        }        
        else if (inputID === 'therapeut') {    
            updatedForm[inputID] = event.target.value;
            //buttonControl = this.getButtonControl(updatedForm);
            this.setControlValidity(controls, updatedForm);
        }                
        else if (inputID === 'paymentDate') {    
            updatedForm[inputID] = event.target.value;
            if (parseFloat(updatedForm.paymentAmount) === 0 ){
                updatedForm.paymentAmount = updatedForm.amount;
            }
        }
        else{
            updatedForm[inputID] = event.target.value;
        }
        if (!this.newInvoice){
            buttonControl.saveDisabled = false;
        }
        
        this.setState({ invoice: updatedForm,
                        appointments: appointments,
                        buttonControl: buttonControl  ,
                        controls: controls
                            });
    }

    appointmentSelectHandler = (event, appointmentID) =>{
        
        let appointments = [ ...this.state.appointments ];
        const invoice = { ...this.state.invoice };

        const objIndex = appointments.findIndex((obj => obj.ID === appointmentID));      
        
        if (objIndex !== -1){
            appointments[objIndex].selected = event.target.checked;   
        }

        invoice.amount = this.calcInvoiceTotal(appointments, invoice.porto);

        const controls = {...this.state.controls};

        this.setControlValidity(controls, invoice);

        this.setState({ appointments: appointments,
                        invoice: invoice,
                        buttonControl: this.getButtonControl(invoice) ,
                        controls: controls
                    });        
    }
    saveHandler = () => {

        if (this.newInvoice){
            this.createInvoice();
        }
        else{
            this.updateInvoice();
        }

    }
    createInvoice = () => {
        this.setState({loading: true});
        const invoice = this.state.invoice;
        invoice.appointments = [];
        const appointments = this.state.appointments;
        appointments.forEach(appointment => {

            if (appointment.selected){

                invoice.appointments.push(appointment);
            }
        });
        //create
        axios.post('/invoices' , invoice)
        .then (response => {
            //this.context.login(response.data);
            const invoice = response.data.data.invoice;
            const appointments = response.data.data.appointments;
            const patient = response.data.data.patient;
            patient.appLoaded = true;
            const prescription = response.data.data.prescription;

            this.context.updateInvoice(invoice);
            this.context.updateAppointment(appointments);
            this.context.updatePatient(patient);
            if (prescription){
                this.context.updatePrescription(prescription);
            }
            this.props.history.replace('/financeMenu/invoices/' + invoice.invoiceID);
            //this.setState({ loading: false });
        })
        .catch (error => {
            console.log(error.response); 
            var errorTitle = "Fehler beim Erstellen der Rechnung";
            this.setState({ loading: false,
                            showError: true,
                            errorResponse:  error.response,
                            errorTitle: errorTitle });                
        });        


    }

    updateInvoice = () => {
        this.setState({loading: true});
        const invoice = this.state.invoice;
        //update
        axios.put('/invoices/' + invoice.invoiceID , invoice)
            .then (response => {
                //this.context.login(response.data);
                const invoiceRefreshed = response.data.data;
                if (invoiceRefreshed){
                        const buttonControl = { ...this.state.buttonControl};
                        buttonControl.saveDisabled = true;
                        this.setState({ loading: false, 
                                        invoice: invoiceRefreshed,
                                        buttonControl:  this.getButtonControl(invoiceRefreshed)   
                                    });      
                        this.context.updateInvoice(invoiceRefreshed);
                        const message = {
                            type: 'S',
                            text: 'Rechnung gespeichert'
                        }
                        this.context.showMessage(message);     
                    }                                             
            })
            .catch (error => {
                console.log(error); 
                
                
                var errorTitle = "Fehler beim Speichern der Rechnung";
                this.setState({ loading: false,
                                showError: true,
                                errorResponse:  error.response,
                                errorTitle: errorTitle });                
            });
    }

    pdfHandler = () => {
        
        if (this.state.invoice){
            this.setState({loading: true});
            axios.get('/invoices/' + this.state.invoice.invoiceID + '/pdf', { responseType: 'arraybuffer' } )   
            .then (response => {
                this.setState({loading: false});
                this.downloadBinary(response, this.state.invoice.invoiceID + '.pdf');
            })
            .catch (error => {
                console.log(error); 
                this.setState({loading: false});
                
                var errorTitle = "Fehler beim Erstellen des pdf Dokuments";                
                this.setState({loading: false,
                                showError: true,
                                errorResponse: error.response ,
                                errorTitle: errorTitle });    
                                        
            });      
        }
    }

    dunningHandler = () => {

        this.setState({loading: true});
        const formData = {};
        formData['invoiceID'] = this.state.invoice.invoiceID;
        
        //update
        axios.post('/dunning' , formData)
            .then (response => {
                //this.context.login(response.data);
                const invoiceRefreshed = response.data.data;
                if (invoiceRefreshed){
                        this.setState({ loading: false, 
                                        invoice: invoiceRefreshed,
                                        buttonControl:  this.getButtonControl(invoiceRefreshed)   
                                        
                                    });      
                        this.context.updateInvoice(invoiceRefreshed);
                        const message = {
                            type: 'S',
                            text: 'Mahnung erstellt'
                        }
                        this.context.showMessage(message);     
                    }                                             
            })
            .catch (error => {
                console.log(error); 
                console.log(error.response); 
                var errorTitle = "Fehler beim Erstellen der Mahnung";
                this.setState({ loading: false,
                                showError: true,
                                errorResponse:  error.response,
                                errorTitle: errorTitle });                
            });

    }

    downloadDunningHandler = (dunningLevel) => {
        this.setState({loading: true});
        if (this.state.invoice){
            axios.get('/invoices/' + this.state.invoice.invoiceID + '/dunning/' + dunningLevel, { responseType: 'arraybuffer' } )   
            .then (response => {

                this.setState({loading: false});
                this.downloadBinary(response, this.state.invoice.invoiceID + '_' + dunningLevel + '.pdf');
            })
            .catch (error => {
                console.log(error); 
                this.setState({loading: false});
                
                var errorTitle = "Fehler beim Download der Mahnung";                
                this.setState({loading: false,
                               showError: true,
                               errorResponse: error.response ,
                               errorTitle: errorTitle });              
            });      
        }        


    }

    downloadHandler = () => {
        this.setState({loading: true});
        if (this.state.invoice){
            axios.get('/invoices/' + this.state.invoice.invoiceID + '/file', { responseType: 'arraybuffer' } )   
            .then (response => {

                this.setState({loading: false});
                this.downloadBinary(response, this.state.invoice.invoiceID + '.pdf');
            })
            .catch (error => {
                console.log(error); 
                this.setState({loading: false});
                
                var errorTitle = "Fehler beim Download der Rechnung";                
                this.setState({loading: false,
                               showError: true,
                               errorResponse: error.response ,
                               errorTitle: errorTitle });              
            });      
        }        
    }

    downloadBinary = (response, fileName) => {
        const url = window.URL.createObjectURL(
            new Blob([response.data], {/*type: 'application/pdf'*/}),
         );
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute(
            'download',
            fileName
            
         );
   
         // Append to html link element page
         document.body.appendChild(link);
   
         // Start download
         link.click();
   
         // Clean up and remove the link
         link.parentNode.removeChild(link);
    }

    deleteHandler = () => {
        
        this.setState({ confirmDelete: true } );
    }

    statusResetHandler = () => {

        //console.log('resetHandler');
        this.setState({loading: true});
        //update
        axios.put('/invoices/' + this.state.invoice.invoiceID + '/status' )
            .then (response => {
                //this.context.login(response.data);
                const invoiceRefreshed = response.data.data;
                if (invoiceRefreshed){
                        //const buttonControl = { ...this.state.buttonControl};
                        //buttonControl.saveDisabled = true;
                        this.setState({ loading: false, 
                                        invoice: invoiceRefreshed,
                                        buttonControl:  this.getButtonControl(invoiceRefreshed)   
                                    });      
                        this.context.updateInvoice(invoiceRefreshed);
                        const message = {
                            type: 'S',
                            text: 'Rechnungstatus zurückgesetzt'
                        }
                        this.context.showMessage(message);     
                    }                                             
            })
            .catch (error => {
                console.log(error); 
                
                
                var errorTitle = "Fehler beim Zurücksetzen des Rechnungsstatus";
                this.setState({ loading: false,
                                showError: true,
                                errorResponse:  error.response,
                                errorTitle: errorTitle });                
            });        


    }

    sendEmailHandler = () =>{
        this.setState({loading: true });

        axios.post('/invoices/' + this.state.invoice.invoiceID + '/email' )
        .then (response => {
            this.setState({loading: false });
            const message = {
                type: 'S',
                text: 'Rechnung wurde per E-Mail versendet'
            }
            this.context.showMessage(message);                             
        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler beim Versenden der Rechnung";                
            this.setState({loading: false,
                           showError: true,
                           errorResponse: error.response ,
                           errorTitle: errorTitle });    
                                   
        });     
                  
    }

    deleteFunction = () => {
        this.setState({loading: true,
                        confirmDelete: false });
        const invoiceID = this.state.invoice.invoiceID;

        axios.delete('/invoices/' + invoiceID )   
        .then (response => {
            this.context.deleteInvoice(invoiceID);
            const appointments = response.data.data.appointments;
            const patient = response.data.data.patient;
            patient.appLoaded = true;
            const prescription = response.data.data.prescription;

            this.context.updateAppointment(appointments);
            this.context.updatePatient(patient);
            if (prescription){
                this.context.updatePrescription(prescription);
            }            

            const message = {
                type: 'S',
                text: 'Rechnung gelöscht'
            }
            this.context.showMessage(message);                             
            this.props.history.goBack();
        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler beim Löschen der Rechnung";                
            this.setState({loading: false,
                           showError: true,
                           errorResponse: error.response ,
                           errorTitle: errorTitle });    
                                   
        });      

    }

    modalClosed = (event) => {
        this.setState({ showError: false,
                        confirmDelete: false } );
    }    
    
    render(){
        let content = null;
        if (this.state.invoice){

            var confirmationPopup = null;
            if (this.state.confirmDelete){
                confirmationPopup = (  <YesCancelModal  clickYes={this.deleteFunction} 
                                                        clickCancel={this.modalClosed}
                                                        title="Rechnung Löschen" >
                                            Willst du diese Rechnung wirklich löschen? <br/><br/> Die Rechnung wird unwiderruflich von der Datenbank und dem Dateisystem gelöscht. 
                                        </YesCancelModal>  );
            }


            let appList = (<Spinner/>);
            if (!this.state.appLoading){
                    appList =   <Auxiliary>
                                    <h5>Behandlungstermine</h5>
                                    <InvoiceAppList  appointments={this.state.appointments} 
                                                      selectable={this.newInvoice}
                                                      selectHandler={this.appointmentSelectHandler}
                                                      />
                                </Auxiliary>
            }

            let title = <h3>Rechnung erstellen</h3>;
            
            if (!this.newInvoice){
                title = <h3 className={classes.Title}>Rechnung  {this.state.invoice.invoiceID}  &nbsp;
                        <Status    value={this.state.invoice.statusText} lookupValue={this.state.invoice.status} /></h3>;
            }

            let email = null;
            if (this.state.patient){
                email = this.state.patient.email;
            }
            content = (<Auxiliary>
                    <Modal show={this.state.loading}><Spinner/></Modal>
                    <ApiErrorMessage 
                            show={this.state.showError} 
                            modalClosed={this.modalClosed} 
                            errorResponse={this.state.errorResponse}  
                            title={this.state.errorTitle} 
                            />    
                    {confirmationPopup}  
                    
                    <SubMenuMui history={this.props.history}>
                        <InvoiceMenuMui    writeOffHandler={this.writeOffHandler} 
                                            saveHandler={this.saveHandler}
                                            downloadHandler={this.downloadHandler}
                                            deleteHandler={this.deleteHandler}
                                            dunningHandler={this.dunningHandler}
                                            downloadDunningHandler={this.downloadDunningHandler}
                                            pdfHandler={this.pdfHandler}
                                            resetStatus={this.statusResetHandler}
                                            buttonControl={this.state.buttonControl}
                                            sendEmail={this.sendEmailHandler}
                                            email={email}
                                            invoice={this.state.invoice}
                                            newInvoice={this.newInvoice}
                        /> 
                    </SubMenuMui>                 
                {title}
                
                <InvoiceDetailViewMUI invoice={this.state.invoice}
                                   patient={this.state.patient}
                                   newInvoice={this.newInvoice}
                                   getStatusIcon={(status) => this.getStatusIcon(status)}
                                   textChangeHandler={(htmlText, elemID) => this.commentChangeHandler(htmlText, elemID)}
                                   inputChangedHandler={(event, inputID) => this.inputChangedHandler(event, inputID)}
                                   dateInputChangeHandler={(value, inputID) => this.dateInputChangeHandler(value, inputID)}
                                   downloadDunningHandler={(dunningLevel) => this.downloadDunningHandler(dunningLevel)}
                                   controls = {this.state.controls}
            />                        
                        {appList}
                </Auxiliary>);
        }
        else{
            content = (<div>Rechnung nicht gefunden...</div>);
        }

        return content;
    }


    componentDidMount(){
        this.isComponentMounted = true;
        if (this.props.match.path === '/financeMenu/invoices/:id'){
            const invoiceID = this.props.match.params.id;
            this.loadState(invoiceID);
        }
        else{
            // create invoice case 
            this.newInvoice = true;
            let patientID = this.props.match.params.id;
            if (patientID){
                this.initInvoice(patientID);
            }
        }
    }

    componentWillUnmount(){
        this.isComponentMounted = false;
    }  

    initInvoice = (patientID) => {
        const patient = this.context.getPatient(patientID);
        if (patient){
            
            const invoiceNew = {   
                comment : '',
                diagnose: '',
                invoiceType: 'Physio',
                displayName: patient.displayName,
                invoiceDate: new Date().toISOString().split('T')[0],
                patientID: parseInt(patientID),
                therapeut: 0,
                porto: false,
            };

            let appointments = [];
            let appLoading = false;
            let controls = null;
            appointments = this.getAppointmentsFromContext(true, patientID);
            controls = this.getControls(appointments);
            this.selectPrescription(controls.prescriptionConfig.defaultPrescription, appointments, invoiceNew );
            this.setControlValidity(controls, invoiceNew );
            const portoService = this.context.getPortoService();
            this.setState({ invoice: invoiceNew ,
                            patient: patient,
                            controls: controls,
                            appointments: appointments, 
                            appLoading: appLoading,
                            portoService : portoService ,
                            buttonControl: this.getButtonControl(invoiceNew)
            });                       
        }

    }

    loadState = (invoiceID) => {
        //read from global context
        const invoice = this.context.getInvoiceForID(invoiceID);

        if (invoice){
            const patient = this.context.getPatient(invoice.patientID);
            let appointments = [];
            let appLoading = false;
            // if loaded --> show
            if (patient.appLoaded){
                appointments = this.getAppointmentsFromContext(false, invoice.patientID, invoiceID );
            }
            else{
                //this.loadAppointments(patient);
                appLoading = true;
            }
            this.setState({ invoice: invoice ,
                            patient: patient,
                            appointments: appointments, 
                            appLoading: appLoading,
                            buttonControl: this.getButtonControl(invoice, patient)
                        });   
            
            if (appLoading){
                this.loadAppointments(patient);
            }
        }
    }

    loadAppointments(patient){
        this.context.loadAppointmentsForPatient(patient)
        .then (response => {
            if (this.isComponentMounted){

                const appointments = this.getAppointmentsFromContext(this.newInvoice, patient.ID, this.state.invoice.invoiceID );
                const controls = this.getControls(appointments);
                if (this.newInvoice){
                    const invoice = {...this.state.invoice};
                    this.selectPrescription(controls.prescriptionConfig.defaultPrescription, appointments, invoice );
                    
                    this.setState({ appointments: appointments,
                        controls: controls,
                        invoice: invoice,
                        appLoading: false,
                        buttonControl: this.getButtonControl(invoice)
                    });        
                }
                else{
                    this.setState({ appointments: appointments,
                                    appLoading: false      });        
                }
            }
        })
        .catch (error => {
            if (this.isComponentMounted){
                var errorTitle = "Fehler beim Laden der Termine";
                
                this.setState({ appLoading: false,
                                showError: true,
                                errorResponse:  error.response,
                                errorTitle: errorTitle });           
            }   
                            
        });

    }

    getAppointmentsFromContext(newInvoice, patientID = null, invoiceID = null){

        let appointments = [];
        if (newInvoice){
            
            const today = new Date(  );
            let appDate = null;
            today.setHours(23,59,59);

            let appTemp = [];
            appTemp = this.context.getAppointmentForPatient(patientID);
            
            appTemp.forEach(app => {
                appDate = new Date(app.date);
                if (( app.statusID === AppointmentUtil.C_STAT_RELEASED || 
                      app.statusID === AppointmentUtil.C_STAT_DRAFT) && 
                      appDate <= today
                      ){
                    appointments.push(app);
                }
            });
        }
        else{
            appointments =  this.context.getAppointmentForInvoice(invoiceID);
        }
        
        appointments.forEach(appointment => {
            appointment.total = AppointmentUtil.calculatePrice(appointment);
        });

        return appointments;
    }

    getButtonControl(invoice, patient){

        let saveDisabled = true;
        let buttonDownload = false;
        let buttonDunning = false;
        let buttonDunningDisabled = true;
        let buttonDownDunning1Disabled = true;
        let buttonDownDunning2Disabled = true;
        let buttonDelete = false  ;      
        let buttonDeleteDisabled = true  ;      
        let buttonSendEmail = false ;      
        let buttonResetDisabled = true;


        if (this.newInvoice){
            //--> for create Invoice only
            
            const amount = parseFloat(invoice.amount);
            if (amount  && invoice.invoiceType ) {
                saveDisabled = false;
            }
        }
        else{
            buttonDownload = true;
            buttonDelete = true;
            buttonDunning = true;
            saveDisabled = false;
            if ( InvoiceUtil.isOverDue(invoice.status)){
                buttonDunningDisabled = false;
            }
            if ( invoice.status !== InvoiceUtil.C_STAT_PAID){
                buttonDeleteDisabled = false;
            }

            if ( invoice.status !== InvoiceUtil.C_STAT_OPEN){
                buttonResetDisabled = false;
            }

            let localPat = patient;

            if (!patient){
                localPat = this.state.patient;
            }

            if (localPat){
                buttonSendEmail = localPat.invoiceDelivery === 'email';    
            }

            if (invoice.D1Date){
                buttonDownDunning1Disabled = false;
            }
            if (invoice.D2Date){
                buttonDownDunning2Disabled = false;
            }            
            
        }

        return {
            saveDisabled: saveDisabled,
            buttonDownload: buttonDownload,
            buttonDunning: buttonDunning,
            buttonDunningDisabled: buttonDunningDisabled,
            buttonDelete: buttonDelete,
            buttonDeleteDisabled: buttonDeleteDisabled,
            buttonResetDisabled: buttonResetDisabled,
            buttonSendEmail: buttonSendEmail,
            buttonDownDunning1Disabled: buttonDownDunning1Disabled,
            buttonDownDunning2Disabled: buttonDownDunning2Disabled
        };
    }

    getPrescriptionDisplayValue(prescriptionID){
        
        let returnValue = prescriptionID;
        if (this.newInvoice){
    
            const prescription = this.context.getPrescription(prescriptionID);
            if (prescription){
                const preConsumedNum = parseFloat(prescription.consumed);
                returnValue = prescriptionID + ' (' + preConsumedNum.toLocaleString() + ('/') + prescription.hours + ')';
            }
            
        }

        return returnValue;

    }
  
    setControlValidity(controls, invoice){
        
        const amount = parseFloat(invoice.amount);

        controls.amountValid = (amount) ? true : false; 
        controls.invoiceTypeValid = (invoice.invoiceType) ? true : false; 
        if ((invoice.invoiceType === InvoiceUtil.C_TYPE_HP || invoice.invoiceType === InvoiceUtil.C_TYPE_SZ_HP) && 
             ( !invoice.therapeut || invoice.therapeut === 0 )){
            controls.therapeutValid = false;
        }
        else{
            controls.therapeutValid = true;
        }
    }

    getControls(appointments ){

        const preOptions = [];
        let defaultPrescription = '';

        if (appointments){
            appointments.forEach(appointment => {
                var objIndex = preOptions.findIndex((obj => obj.value === appointment.prescriptionID));      
                if (objIndex === -1){
                    const elem = {
                        value : appointment.prescriptionID,
                        label : this.getPrescriptionDisplayValue(appointment.prescriptionID)
                    };
                    preOptions.push(elem);
                    if (appointment.prescriptionID && appointment.prescriptionID !== '' &&
                        ( defaultPrescription === '' || appointment.prescriptionID < defaultPrescription)
                        ){
                        defaultPrescription = appointment.prescriptionID;
                    }
                }
            });
        }

        const controls =  {
            typeConfig: { options: [{value: null, displayValue: ''},
                                    {value: InvoiceUtil.C_TYPE_PHYSIO, displayValue: InvoiceUtil.C_TYPE_PHYSIO},
                                    {value: InvoiceUtil.C_TYPE_HP, displayValue:InvoiceUtil.C_TYPE_HP},
                                    {value: InvoiceUtil.C_TYPE_SZ, displayValue:InvoiceUtil.C_TYPE_SZ},
                                    {value: InvoiceUtil.C_TYPE_SZ_HP, displayValue:InvoiceUtil.C_TYPE_SZ_HP}
                                ],
                             },
            prescriptionConfig: { options: preOptions,
                                  defaultPrescription: defaultPrescription 
                                }
        }

        return controls;
    }

    configAppointments(appointments, prescriptionID){
        appointments.forEach(appointment => {

            if ( ((appointment.prescriptionID === null && ( prescriptionID === '' || !prescriptionID ) )   || 
                parseInt(appointment.prescriptionID, 10) === parseInt(prescriptionID, 10)) &&
                appointment.total > 0 &&
                appointment.statusID === AppointmentUtil.C_STAT_RELEASED
                ){
                appointment.selected = true;
                appointment.selectable = true;
            }
            else{
                appointment.selectable = false;
                appointment.selected = false;
            }

        });
        return appointments;
    }

    calcInvoiceTotal(appointments, porto = false){

        let invoiceTotal = 0;
        appointments.forEach(appointment => {
            if (appointment.selected){
                invoiceTotal = invoiceTotal + appointment.total;
            }
        });

        if (porto && this.state.portoService){
            // calculate dynamically only during invoice creation. 
            // an existing invoice includes the porto in the service items of one of the appointments
            
            invoiceTotal = invoiceTotal + parseFloat(this.state.portoService.PREIS);
            
        }

        return invoiceTotal;
    }

    selectPrescription(prescriptionID, appointments, invoice){

        invoice.prescriptionID = prescriptionID;
        this.configAppointments(appointments, prescriptionID);
        invoice.amount = this.calcInvoiceTotal(appointments, invoice.porto);
        
        const prescription = this.context.getPrescription(invoice.prescriptionID);
        invoice.diagnose = '';
        if (prescription){
            if (prescription.diagnose){
                invoice.diagnose = prescription.diagnose.replace(/(<([^>]+)>)/gi, "");
            }
            invoice.invoiceType = InvoiceUtil.C_TYPE_PHYSIO
        }
        else{
            invoice.invoiceType = '';
        }
    }

    getStatusIcon = (status) => {

        return InvoiceUtil.getStatusIcon(status);
}    

}

export default Invoice;