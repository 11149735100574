import React from 'react';

import Spinner from '../../UI/Spinner/Spinner';
import TimeslotList from './TimeslotList';

const FreeBusyList = (props) => {

    var content = null;
    if (props.loading){
        content =  <Spinner/>  ;
    }
    else{
        content =  ( <TimeslotList slots={props.freeTimeSlots} select={props.select}/>);
    }

    return content;
    

}

export default FreeBusyList;