import React from 'react';

import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle';

const toolbar = (props) => (

    <header className={classes.ToolbarArea}>
        <div className={classes.Toolbar}>
            <DrawerToggle clicked={props.drawerToggelClicked}/>
            <Logo/>
            <nav className={classes.DesktopOnly}>
                <NavigationItems/>
            </nav>
        </div>
    </header>

);

export default toolbar;