import React, {useContext} from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';

import GlobalContext from '../../context/GlobalContext';
import { BiFilterAlt } from "react-icons/bi";
import { BsArrowsAngleContract} from "react-icons/bs";
import Auxiliary from '../../hoc/Auxiliary';
import ToggleAppView from './Calendar/ToggleAppView';


const ListMenu = (props) =>  {
        
    const globalContext = useContext(GlobalContext);

    // Therapeutenfilter
    var thFilter = [];
    for (var i=0, iLen=globalContext.therapeut.length; i<iLen; i++) {   
        const th = globalContext.therapeut[i];
        if (th.PERNR !== 9){ //9 is the dummy PERNR
            var active = null;
            if (globalContext.therapeutFilter.includes(th.PERNR)){
                active = 1;
            }
            //var butType = th.vorname.concat("Button");
            const butType = 'TherapistButton';
            const style = {'borderColor' : th.color};
            /*
            var button = (  <li key={th.PERNR} className={classes.EditButton}>
                                <Button active={active} 
                                        btnType={butType}
                                        click={(event) => globalContext.filterTherapeut(event, th.PERNR)}  
                                        >
                                    {th.vorname}
                                </Button>
                            </li>);
                            */
            var button = ( 
                <Button active={active} 
                        btnType={butType}
                        style={style}
                        click={(event) => globalContext.filterTherapeut(event, th.PERNR)}  
                        key={th.PERNR} 
                        >
                    {th.vorname}
                </Button> );                           
            thFilter.push(button);   
        }
    }


    //Statusfilter
    /**/
    let buttonFilter = null;
    if (!props.fieldControls.hideStatusFilter){
        buttonFilter = (<li className={classes.EditButton}><Button active={globalContext.statusFilter !== 0 ? 1 : undefined} btnType="TableButton" click={(event) => globalContext.filterStatus(event)}  ><BiFilterAlt  className={classes.Icon}/></Button></li>);
    }
    
    
    let currentDay = null;
    if (props.goToToday){
        currentDay = (
            <li className={classes.GotoToday}>
                <Button active='1' btnType="TableButton" click={(event) => props.goToToday() }  >
                    <BsArrowsAngleContract  className={classes.Icon}/>
                </Button>
            </li>);
    }
    

    //Patientfilter
    const styleConfig = {
        inputStyle: 'InputDetail', //InvItemInputWrapper',
        labelStyle: 'LabelNone',//InvItemLabel',
        inputElementStyle:'InputElemMenu'//'InvItemInputElement'
    };       

    let toggleView = null;
    if (props.fieldControls.toggelViewHandler){
        toggleView =    <li className={classes.EditButton}>
                            <ToggleAppView view={props.fieldControls.view} onChange={props.fieldControls.toggelViewHandler} />
                        </li>
        //toggleView = <Switch color="primary" checked={props.fieldControls.agenda}   onChange={() => props.fieldControls.toggelViewHandler()}></Switch>
    }

    return (
        <Auxiliary >
            {buttonFilter}
            <li className={classes.SubMenuInput}>
                <Input  elementType={props.fieldControls.patientFilter.elementTyp}
                        elementConfig={props.fieldControls.patientFilter.elementConfig}
                        value={globalContext.patientFilter}
                        styleConfig={styleConfig}
                        changed={(event) => globalContext.filterPatient(event)}/>            
            </li>
            <div className={classes.ThFilter}>
                {thFilter}
            </div>
            {currentDay}    
            {toggleView}

        </Auxiliary>
        );
    }

export default ListMenu;