import React, { Component } from 'react';
import Input from '../components/UI/Input/Input';
import Button from '../components/UI/Button/Button';
import Auxiliary from '../hoc/Auxiliary';
import Modal from '../components/UI/Modal/Modal';
import classes from './Auth.module.css';
import Spinner from '../components/UI/Spinner/Spinner';
import withErrorHandler from '../hoc/withErrorHandler/withErrorHandler';
import axios from 'axios';
import GlobalContext from '../context/GlobalContext';
//import SessionHandler from '../context/SessionHandler';

class Auth extends Component{

    static contextType = GlobalContext;

    state = {
        controls: {
            username: {
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'username'
                },
                value: '',
                label: 'Benutzername',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementTyp: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'password'
                },
                value: '',
                label: 'Passwort',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            },
            /*
            Mein Kommentar
            und noch einen Kommentar
            language: {
                elementTyp: 'select',
                elementConfig: {
                    options: [
                        {value: 'DE', displayValue: 'Deutsch'},
                        {value: 'EN', displayValue: 'Englisch'},
                        {value: 'FR', displayValue: 'Französisch'},
                    ]
                },
                validation: {},
                value: 'DE',
                label: 'Anmeldesprache',
                valid: true,
                touched: false
            }         
            */               
        },
        formIsValid: false,
        loading: false,
    }

    checkValidity(value, rules){
        let isValid = true;
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if(rules.minLength){
            isValid = value.length >= rules.minLength && isValid;
        }

        return isValid;

    }

    inputChangedHandler = (event, inputID) =>{
        //console.log(event.target.value);
        // clone
        const updatedForm = {
            ...this.state.controls
        }
        //clone deeply
        const updatedFormElement = { ...updatedForm[inputID] };
        
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedForm[inputID] = updatedFormElement;
        
        let  formIsValid = true;
        for (let inputID in updatedForm){
            formIsValid = updatedForm[inputID].valid && formIsValid;
        }

        this.setState({controls: updatedForm, formIsValid: formIsValid});
        //console.log(updatedFormElement);
    }

    loginHandler = (event)  => {
        //prevents a server http cycle
        event.preventDefault();
        this.setState({loading: true});
        //console.log(this.state.controls);
        const formData = {};

        for (let formElementID in this.state.controls){
            formData[formElementID] = this.state.controls[formElementID].value
        }
        formData['grant_type'] = 'password';
        //formData['client_id'] = SessionHandler.CLIENT_ID;
        //formData['client_secret'] = SessionHandler.CLIENT_SECRET; 

        //console.log(formData);

        axios.post('/oauth/token', formData)
            .then (response => {
                //console.log(response);
                this.setState({loading: false});
                this.context.login(response.data);
            })
            .catch (error => {
                console.log('errorhandler Auth.js');
                //console.log('axios->error');
                //console.log(error); 
                this.setState({loading: false});
            });
    }

    render(){
        const formElementArray = [];
        for (let key in this.state.controls){
            formElementArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let content = (
            <form onSubmit={this.loginHandler}>
            {formElementArray.map(formElement => (
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementTyp}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        label={formElement.config.label}
                        invalid={!formElement.config.valid}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                    />
            ))}     
            <Button btnType="Success" clicked={this.loginHandler} disabled={!this.state.formIsValid}>Login</Button>
            </form>    
        );
        if (this.state.loading){
            content = (
                <Auxiliary>
                    <Modal show={true}>
                        <Spinner/>  
                    </Modal>                        
                </Auxiliary>
            );
        }
        //console.log('render');
        return(
            <div className={classes.Auth}>
                {content}
                <div className={classes.Version}>{process.env.REACT_APP_NAME + ' v'  + process.env.REACT_APP_VERSION}</div>
            </div>
        );
    } 
}

export default withErrorHandler(Auth, axios);