
import React from 'react';
import classes from './MUIContainer.module.css';


const DoubleField = (props) => {




    return (
        <div className={classes.FieldRow}>
            {props.children}
        </div> );
    }


export default DoubleField;





