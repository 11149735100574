import React from 'react';
//import './Praxis.css';
import { Chip } from '@mui/material';
import classes from '../Therapists.module.css';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import TherapistAvatar from '../TherapistAvatar';

const TherapistChip = (props) => {

  
    const handleClick = () => {
       
        //const add = !props.inactive;
        

        props.handleChangeTherapists(props.therapist.PERNR, props.inactive)


       
    }

    let variant = "outlined";
    let color = "primary";

    let iconSelect = <CheckCircleIcon color="primary" />
    if (props.inactive){
        variant = "filled";
        iconSelect = <RadioButtonUncheckedIcon color="disabled"/>
        color = "default";

        // if readonly and inactive, do not show
        if (props.readOnly){
            return null;
        }
    }

    let chip =  <Chip 
                        icon={ <TherapistAvatar therapist={props.therapist}  small/> }
                        label={props.therapist.vorname + " " + props.therapist.nachname}
                        variant={variant}
                        color={color}
                        onClick={handleClick}
                        deleteIcon={iconSelect}
                        onDelete={handleClick}
                        />
    if (props.readOnly){
        chip = <Chip 
                        icon={ <TherapistAvatar therapist={props.therapist}  small/> }
                        label={props.therapist.vorname + " " + props.therapist.nachname}
                        variant={variant}
                        //color={color}
                        />
    }

        
    return <div className={classes.PraxisChip}>
                {chip}
            </div>
}

export default TherapistChip;