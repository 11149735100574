import React from 'react';

import DetailContainer from '../Layout/DetailContainer';
import Auxiliary from '../../hoc/Auxiliary';
import {  HiDownload } from "react-icons/hi";
import InvoiceUtil from './InvoiceUtil';
import MUIInput from '../MUI/MUIInput/MUIInput';
import MUIPatientInput from '../MUI/MUIInput/MUIPatientInput';
import DateField from '../MUI/DateField/DateField';
import InvoiceType from '../MUI/DropDown/InvoiceType';
import Currency from '../MUI/MUIInput/Currency';
import { IconButton,  Tooltip } from '@mui/material';
import MUIPrescriptionInput from '../MUI/DropDown/MUIPrescriptionInput';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import FactsMui from '../UI/Card/FactsMui';
import DropDown from '../MUI/DropDown/DropDown';
import CheckBox from '../MUI/MUIInput/CheckBox';
import TherapeutDD from '../MUI/DropDown/TherapeutDD';


const config = {
        invoiceID : {
            id: "invoiceID",
            label: "Rechnungsnummer",
            required:false, 
            error: false
        },
        patientID : {
                id: "patientID",
                label: "Patient",
                required:false, 
        },
        prescriptionID : {
                id: "prescriptionID",
                label: "Rezept",
                required:false,                 
        },
        invoiceDate: {
                id: "invoiceDate",
                label: "Rechnungsdatum",
                required:false, 
                type:"date"   
            },    
        paymentDate: {
                id: "paymentDate",
                label: "Zahldatum",
                required:false, 
                type:"date"   
        },
        paymentAmount: {
                id: "paymentAmount",
                label: "Betrag bezahlt",
                required:false, 
                type:"numeric"   
        },
        payDuration: {
                id: "payDuration",
                label: "Zahldauer",

        },
        openAmount: {
                id: "openAmount",
                label: "Offener Betrag",
                type:"numeric"   
        }   ,
        amount: {
                id: "amount",
                label: "Rechnungsbetrag",
                type:"numeric"   
        } ,
        status: {
                id: "status",
                label: "Status"
        } ,       
        dunningLevel1: {
                id: "D1Date",
                label: "Mahnstufe 1",
                type:"date"  
        } ,
        dunningLevel2: {
                id: "D2Date",
                label: "Mahnstufe 2",
                type:"date"  
        } ,        
        invoiceDelivery:{
                id: "invoiceDelivery",
                label: "Rechungsversand" ,
                options: [  {value: '', label: ''},
                            {value: 'post', label: 'Post'},
                            {value: 'email', label: 'E-Mail'},
                        ]
            } ,                    

};


const InvoiceDetailViewMUI = (props) => {

    const recipient = props.patient.billToName === null ? props.patient.displayName : props.patient.billToName;


    let dunningDownload1 = null;
    if (props.invoice.D1Date){
        dunningDownload1 =      <Tooltip title="Mahnung herunterladen">
                                        <IconButton
                                                onClick={() =>  props.downloadDunningHandler(InvoiceUtil.C_DUNNING_LEVEL_1)}
                                                edge="end" >
                                        <HiDownload className='IconMUI' />
                                        </IconButton>
                                </Tooltip>;
    }

    let dunningDownload2 = null;
    if (props.invoice.D2Date){
        dunningDownload2 =  <Tooltip title="Mahnung herunterladen">
                                <IconButton
                                        onClick={() =>  props.downloadDunningHandler(InvoiceUtil.C_DUNNING_LEVEL_2)}
                                        edge="end" >
                                <HiDownload className='IconMUI' />
                                </IconButton>
                        </Tooltip>;
    }    

    const addressString = recipient + '\n' + props.patient.address + '\n' + props.patient.postCode + ' ' + props.patient.city;

    let payDurationString = '0 Tage';
    if ( props.invoice.payDuration){
         payDurationString =  props.invoice.payDuration + ' Tage' ;
    }
    
    return (
        <Auxiliary>

        <DetailContainer> 
     
            <FactsMui>               
                <MUIInput         id={'address'}
                                  label='Rechnungsadresse' 
                                  value={addressString}
                                  readOnly={true} 
                                  multiline
                                  rows={3}
                                  fullWidth
                                  
                                  />                        
                <MUIPatientInput  {...config.patientID}
                                readOnly={true} 
                                value={props.patient.displayName}
                                patientID={props.patient.ID}
                       />   
                <DoubleField>
                        <DropDown  {...config.invoiceDelivery}
                                        value={props.patient.invoiceDelivery}
                                        readOnly  />
                        <CheckBox       checked={props.invoice.porto} 
                                        readOnly={!props.newInvoice}
                                        disabled={!props.newInvoice}
                                        label="Porto" 
                                        onChange={ (event) => props.inputChangedHandler(event, 'porto') }
                        />
                </DoubleField>                            
                <DoubleField>
                        <MUIInput  {...config.invoiceID}
                                value={props.invoice.invoiceID} 
                                readOnly={!props.newInvoice} 
                                onChange={(event) => props.inputChangedHandler(event, 'invoiceID')}
                        />
                        <DateField  {...config.invoiceDate}
                                value={props.invoice.invoiceDate} 
                                readOnly={!props.newInvoice} 
                                onChange={(value) => props.dateInputChangeHandler(value, 'invoiceDate')}
                        />     
                </DoubleField>                          
                <DoubleField>
                        <InvoiceType    value={props.invoice.invoiceType}
                                readOnly={!props.newInvoice} 
                                onChange={(event) => props.inputChangedHandler(event, 'invoiceType')}
                                error={!props.controls.invoiceTypeValid} 
                                >
                        </InvoiceType>                                           
                        <TherapeutDD
                        value={props.invoice.therapeut}
                        readOnly={!props.newInvoice} 
                        error={!props.controls.therapeutValid}
                        onChange={(event) => props.inputChangedHandler(event, 'therapeut')} />                        
                        
                </DoubleField>
                <DoubleField>
                <MUIPrescriptionInput {...config.prescriptionID}
                                readOnly={!props.newInvoice} 
                                options={props.controls.prescriptionConfig.options}
                                value={props.invoice.prescriptionID} 
                                onChange={(event) => props.inputChangedHandler(event, 'prescriptionID')}
                        />              
                </DoubleField>
                <DoubleField>
                        <Currency {...config.amount}
                                        value={props.invoice.amount} 
                                        readOnly={true} 
                                        error={!props.controls.amountValid} 
                                        />        
                                        
                        <MUIInput       {...config.payDuration}
                                value={payDurationString} 
                                readOnly={true} 
                       />     
                </DoubleField>


                </FactsMui>  
                <FactsMui>                        
                <MUIInput id={'diagnose'}
                                  label='Diagnose' 
                                  value={props.invoice.diagnose}
                                  readOnly={!props.newInvoice} 
                                  multiline
                                  minRows={3}
                                  onChange={(event) => props.inputChangedHandler(event, 'diagnose')} 
                                  />                                 
                {!props.newInvoice && 
                        <Auxiliary>
                        <DoubleField>
                                <DateField  {...config.paymentDate}
                                            value={props.invoice.paymentDate} 
                                            readOnly={false} 
                                            onChange={(value) => props.dateInputChangeHandler(value, config.paymentDate.id)}
                                />        
                                <Currency {...config.paymentAmount}
                                          value={props.invoice.paymentAmount} 
                                          readOnly={false} 
                                          onChange={(event) => props.inputChangedHandler(event, config.paymentAmount.id)}
                                />                                        
                        </DoubleField>  
                        <CheckBox       checked={props.invoice.writeOff} 
                                        label="Offenen Betrag abschreiben" 
                                        onChange={ (event) => props.inputChangedHandler(event, 'writeOff') } />                   

                        </Auxiliary>
                }                                              
                 
                        <MUIInput id={'comment'}
                                  label='Anmerkung' 
                                  value={props.invoice.comment}
                                  readOnly={false} 
                                  multiline
                                  minRows={2}
                                  onChange={(event) => props.inputChangedHandler(event, 'comment')} 
                                  />                                                                               
                    
                    {!props.newInvoice && 
                    <Auxiliary>
                        <DoubleField>
                        <DateField  {...config.dunningLevel1}
                                    value={props.invoice.D1Date} 
                                    readOnly={true} 
                                    prefix={dunningDownload1}
                                    onChange={(event) => props.inputChangedHandler(event, config.dunningLevel1.id)}
                        />     
                        <DateField  {...config.dunningLevel2}
                                    value={props.invoice.D2Date} 
                                    readOnly={true} 
                                    prefix={dunningDownload2}
                                    onChange={(event) => props.inputChangedHandler(event, config.dunningLevel2.id)}
                        /> 
                        </DoubleField>

                     </Auxiliary>       
                        }       

        </FactsMui>                                                                                                                                                                             
            </DetailContainer>  
           
            </Auxiliary>

    );


}

export default InvoiceDetailViewMUI;