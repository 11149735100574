import React, {useState} from 'react';
import classes from './InvItemCnt.module.css';
import InvoiceItems from '../InvoiceItems';
import Input from '../../UI/Input/Input';
import Modal from '../../UI/Modal/Modal';
import ServiceCatalogueMui from '../../ServiceCatalogue/ServiceCatalogueMui';
import { IoAddCircleOutline } from "react-icons/io5";
import { GrSearchAdvanced } from "react-icons/gr";

import ModalHeader from '../../UI/Modal/ModalHeader';
import ModalContent from '../../UI/Modal/ModalContent';
import { IconButton, Tooltip } from '@mui/material';

const InvoiceItemContainer = (props) => {
    
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function closeModal(){
        setModalIsOpen(false);
    }

    const styleConfigSmall = {
        inputStyle: 'InputDetail',
        labelStyle: 'LabelLight',
        inputElementStyle:'InputElemMenu'
    };          

    var total = 0;
    if (props.items){
        for (var i=0; i < props.items.length; i++){
            total = total + props.items[i]['ANZAHL'] * props.items[i]['PREIS'];
        }
    }
    if (!total){
        total = "0";
    }
    
    
    
    var buttons = null;
    if (!props.config.readOnly){
        
        var propsalDisable = true;

        buttons = ( <div className={classes.Buttons}>
                        <Tooltip title="Neue Leistung hinzufügen">
                            <span>
                                <IconButton onClick={() => setModalIsOpen(true)} >
                                    <IoAddCircleOutline />
                                </IconButton>  
                            </span>
                        </Tooltip>
                        { !props.config.hideProposal &&
                            <Tooltip title="Leistungen vorschlagen">
                                <span>
                                    <IconButton onClick={() => props.config.onGetProposal} disabled={propsalDisable}>
                                        <GrSearchAdvanced />
                                    </IconButton>  
                                </span>
                            </Tooltip>                        
                           

                        }
                    </div>);
    }

    var serviceCatalogue = null;
    if (modalIsOpen){
        serviceCatalogue = (    <Modal show={modalIsOpen} modalClosed={closeModal} type='BigModal'>
                                    <ModalHeader onClose={closeModal} title="Leistungskatalog"></ModalHeader>
                                    <ModalContent>
                                        <ServiceCatalogueMui onAdd={props.config.onAdd} disabledItems={props.items}  modalClosed={closeModal}></ServiceCatalogueMui>
                                    </ModalContent>
                                </Modal>
                    );
    }

    
    return  (
        <fieldset className={classes.InvoiceItemsAppointment}> 
            
            <div  className={classes.ItemsTitle}>Leistungen</div>
            <div className={classes.Menu}>
                
                {buttons}
                <div className={classes.Price}><Input label='Gesamtpreis' styleConfig= {styleConfigSmall}  elementConfig={{type:'currency'}} changed={null} value={total}   readOnly={true} /></div>
            </div>
            
            <InvoiceItems   invoiceItems={props.items} 
                            config={props.config} ></InvoiceItems>
            {serviceCatalogue}
        </fieldset>            
        );
    };
    
export default InvoiceItemContainer;
