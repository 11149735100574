import React, { useState, useEffect }  from 'react';
import classes from './Calendar.module.css';
import { BsFillSkipEndFill, BsFillSkipForwardFill, BsFillSkipBackwardFill , BsFillSkipStartFill, BsCaretDownFill} from "react-icons/bs";

import { IconButton, Slider, Tooltip } from '@mui/material';
import SelectedCell from './SelectedCell';
import Spinner from '../../UI/Spinner/Spinner';
import DateUtility from '../../../hoc/DateUtility';
import Events from './Events';
import AppointmentUtil from '../AppointmentUtil';



const getTimeFromRowId = (rowId, startTime) =>{
    const timeInt = rowId * 0.5 + startTime;
    let minutes = '00';
    if (rowId % 2 !== 0){
        minutes = '30';
    }
    const hour = Math.floor(timeInt);   
    return String(hour).padStart(2, '0')  + ':' + minutes;
}

const getRowIdFromTime = (time, startTime) => {

    const timeArray = time.split(':');
    let hour = parseInt(timeArray[0]);
    let rowId = (hour - startTime) * 2;
    
    if (timeArray[1] !== '00'){
        rowId = rowId + 1;
    }
    return rowId;
}

const getColIdFromDate = (date, firstDay) => {
    const d = new Date(date);
    const f = new Date(firstDay);
    const diff = dateDiffInDays(f, d);
    return diff;
}

const getColIdFromAppointment = (appointment, firstDay, maxColId) => {

    const result = [];

    let date = new Date(appointment.date);
    const dateTo = new Date(appointment.dateTo);

    while (date <= dateTo){
        let colId = getColIdFromData(date, appointment.frequency, firstDay);
        
        if (colId > maxColId){
            break;
        }
        
        result.push(colId);
        date = DateUtility.addDays(date, 1);
    }

    return result;

}

const getColIdFromData = (date, frequency, firstDay) => {

    const colIdTmp = getColIdFromDate(date, firstDay);
    let colId = -1;
    let mod = -1;
    if (frequency === AppointmentUtil.C_FREQ_WEEKLY){
        mod = 7;
    }
    if (mod > 0){
        //in case of recurrence, calc. colId with modulo
        colId = colIdTmp % mod;
        if (colId < 0){
            colId = colId + mod;
        }
    }
    else{
        colId = colIdTmp;
    }
    return colId 
}



const  dateDiffInDays = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  const initFirstDay = (startDay) => {

    if (startDay){
        return startDay;
    }
    else{
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const day = today.getDay() || 7; // Get current day number, converting Sun. to 7
        
        if( day !== 1 ){// Only manipulate the date if it isn't Mon.
            today.setHours(-24 * (day - 1));   // Set the hours to day number minus 1
        }                                     //   multiplied by negative 24
    
        if ( day >= 6 ){
            today = DateUtility.addDays(today, 7);
        }
        return today;
    }

}


const Calendar = (props) => {

    const [startTime] = useState(7);
    const [endTime] = useState(21);
    const [firstDay, setFirstDay] = useState(() => initFirstDay(props.startDay));
    const [dayCols, setDayCols] = useState(5);
    const [selectedCell, setSelectedCell] = useState({  colId : -1, 
                                                        rowId : -1,
                                                        date: null, 
                                                        time: null
                                                    });

    const [matrix, setMatrix] = useState([[],[],[],[],[],[],[]]);


    const getFullDayRowId = () =>{
        return (endTime - startTime) * 2 + 1;
    }

    //Set matrix
    useEffect(() => {
        let duration = 0;
        const mtx = new Array(dayCols);
        for (let colId = 0; colId < dayCols; colId++){
            mtx[colId] = new Array(26);
        }
        const endOfWeek = DateUtility.addDays(firstDay, dayCols - 1);
        endOfWeek.setHours(23, 59, 59, 0);
/****************************************************************************** */
/* Free time slots */
        let exit = false;
        for (let i=0; i< props.freeTimeSlots.length || exit; i++ ){
            const freeSlot = props.freeTimeSlots[i];
            const freeDate = new Date( freeSlot.date);
            if ( freeDate >= firstDay && freeDate <= endOfWeek ){
                const rowId = getRowIdFromTime(freeSlot.from, startTime);
                const colId = getColIdFromDate(freeSlot.date, firstDay);

                duration = DateUtility.calcDuration(freeSlot.from, freeSlot.to);
                //let rowSpan = duration * 2;
                let freeRow = rowId;
                while (duration > 0){
                    mtx[colId][freeRow] = { free : true };
                    duration = duration - 0.5;
                    freeRow = freeRow + 1;
                }
            }
            if (freeSlot.date > endOfWeek){
                //exit if dates are older
                exit = true;
            }    
        }
/****************************************************************************** */
/* Appointments */
        exit = false;
        const fullDayRow = getFullDayRowId();
        for (let i = 0; i < props.appointments.length || exit; i++){
            const app = props.appointments[i];
            
                const appDate = new Date( app.date);

                if ( appDate <= endOfWeek ){
                    
                    const colIds = getColIdFromAppointment(app, firstDay, dayCols); 

                    //loop over colIds
                    for (let j = 0; j < colIds.length; j++){

                        const colId = colIds[j];

                        if (colId < dayCols && colId >= 0  ){
                            // if colId is in the visible range -> show it
                            
                            if (app.frequency){
                                //check if the date is valid in case of recurrency
                                const temp = DateUtility.addDays(firstDay, colId);  
                                if (temp > new Date(app.frequencyUntil)){
                                    continue;
                                }
                            }
                            
                            //get row  ID
                            let rowId = getRowIdFromTime(app.from, startTime);
                            if ( app.fullDay ){
                                // holidays are placed in the first row (timeslot)
                                rowId = fullDayRow;
                            }

                            let obj = mtx[colId][rowId];
                            //init apps array
                            if (!obj){
                                obj = { apps: [] };
                            }
                            else if (!obj.apps){
                                obj.apps = [];
                            }
                            obj.apps.push(app);
                            mtx[colId][rowId] = obj;
                        }
                    }
            }

            if (appDate.date > endOfWeek){
                //exit if dates are older
                exit = true;
            }    
            }   
        setMatrix(mtx);

    }, [firstDay,
        startTime, 
        dayCols, 
        props.freeTimeSlots, 
        props.appointments] );

    const getCell = (rowId, colId) => {
        let cellSelektor = null;
        if (!matrix[colId]){
            return null;
        }
        let cellConfig = matrix[colId][rowId];
        if (!cellConfig){
            cellConfig = {};
        }
        const classArray = [classes.Cell];
        const selected = selectedCell.colId === colId && selectedCell.rowId === rowId;
        if ( selected ){
            cellSelektor = <SelectedCell selectedCell={selectedCell}
                                         selectCell={() => handleSelectCell(rowId, colId)} />;
        }
        
        if (matrix[colId] && matrix[colId][rowId] ){
            
            if (cellConfig.free && !selected){
                classArray.push(classes.FreeCell);
            }
            
            if (cellConfig.app ){
                if (!selected){
                    classArray.push(classes.BusyCell);
                    
                }
            }
        }

        return  <td  className={classArray.join(' ')} key={rowId + ':' + colId}  onClick={() => handleClick(rowId, colId)}>   
                    <Events config={cellConfig} navAppointment={props.navAppointment}/>
                    {cellSelektor}
                </td>;
    }
    
    const getRows = ( ) => {
        let rows = [];

        const cntRows = ( endTime - startTime ) * 2;

        for (let i = 0; i < cntRows; i++) {
            let time = '';
            if ( i % 2 === 0 ) {
                //time = (i/2 + 8)  + ':00';
                time = String((i/2 + startTime)).padStart(2, '0');
            }
            else if ( i === selectedCell.rowId ){
                //time = (Math.floor(i/2) + 8)  + ':30';
            }

            let timeClass = classes.TimeCol;
            if (i === selectedCell.rowId){
                timeClass = classes.TimeColSelected;
            }

            const cells = [];
            for (let x = 0; x < dayCols; x++){
                cells.push(getCell(i, x ));
            }
    
            let row =   <tr key={i} className={classes.Row}> 
                            <td className={timeClass}>{time}</td>
                            {cells}
                        </tr>;
            rows.push(row);
        }
        return rows;
    }



    const handleClick = (rowId, colId) =>{        
            setSelectedCell({   colId : colId, 
                rowId: rowId,
                date: DateUtility.addDays(firstDay, colId),
                time: getTimeFromRowId(rowId, startTime)});

    }

    const handleSelectCell = (rowId, colId) =>{        
        const date = DateUtility.addDays(firstDay, colId);
        const from = getTimeFromRowId(rowId, startTime);
        const fromSplit = from.split(':');
        let fromHour = parseInt(fromSplit[0]);
        let toHour = fromHour +  1;
        const to = String(toHour).padStart(2, '0')  + ':' + fromSplit[1];
        
        props.select(DateUtility.getISODate(date), from, to);
    }


    const navigate = (days) => {
        const newStartDate = DateUtility.addDays(firstDay, days);
        setSelectedCell({rowId: -1, colId: -1, date : null, time: null});
        //setPrintDate('');
        setFirstDay(newStartDate);   
        
        if (props.storeStartDay){
            props.storeStartDay(newStartDate);
        }
    }

    const navigateToday = () => {
        const startDay = initFirstDay(null);
        setFirstDay(startDay); 
        if (props.storeStartDay){
            props.storeStartDay(startDay);
        }  
    }

    const getTableCaptions = () =>{
        const captions = [];
        const width = 100 / dayCols ;
        for (let i = 0; i < dayCols; i++){
            captions.push(getTableCaption(i, width));
        }
        return captions;
    }

    const getTableCaption = (index, width) => {

        const day = DateUtility.addDays(firstDay, index);
        let className = classes.Caption;
        if (index === selectedCell.colId){
            className = classes.CaptionSelected
        }

        return <th className={className}  style={{width : width + '%'}} key={'th'+index}>{DateUtility.formatDate(day, DateUtility.TYPE_WEEKDAY)}<br/>{DateUtility.formatDate(day, DateUtility.TYPE_MM_DD)}</th>

    }

    const getFullDayEvents = () => {

        const fullDayRowId = getFullDayRowId();
        const fullDayRow = [];
        for (let i = 0; i < dayCols; i++){
           
            fullDayRow.push(getFullDayCell(i, fullDayRowId));
        }        
        return fullDayRow;
    }

    const getFullDayCell = (colId, rowId) =>{
        let className = classes.Caption;
        let content = null;
        
        if (matrix[colId] && matrix[colId][rowId] && matrix[colId][rowId].apps ){
            content = <Events config={matrix[colId][rowId]} navAppointment={props.navAppointment} fullDayRow={true} />
        }

        return <th className={className} key={'th'+colId}>{content}</th>
    }

    const calTable = 
        <table className={classes.WeekTab}>
            <thead className={classes.HeaderRow}>
                <tr>
                    <th className={classes.TimeCol}></th>
                    {getTableCaptions()}
                </tr>
                <tr>
                    <th className={classes.TimeCol}></th>
                    {getFullDayEvents()}
                </tr>              
            </thead>
            <tbody className={classes.WeekTabContent}>
               {getRows(props, handleClick)}                          
            </tbody>
        </table>
        
        let spinner = null;
        if (props.loading){
            spinner = <Spinner type='Small'/>;
        }

        const  valuetext = (value) => {
            return value;
          }

          const handleChange = (event, newValue) => {
            setDayCols(newValue);
          };

        return  <React.Fragment>
                    {spinner}
                    <div className={classes.CalTitle}>
                        <div className={classes.NextButtons}>
                            <Tooltip title={'- 1 Woche'}>
                                <IconButton onClick={() => navigate(-7)}><BsFillSkipBackwardFill /></IconButton>    
                            </Tooltip>          
                            <Tooltip title={'- 1 Tag'}>                                 
                                <IconButton onClick={() => navigate(-1)}><BsFillSkipStartFill /></IconButton>         
                            </Tooltip>       
                            <Tooltip title={'Aktuelle Woche'}>                                 
                                <IconButton onClick={() => navigateToday()}><BsCaretDownFill /></IconButton>         
                            </Tooltip>                              
                                                                 
                            
                        </div>
                        <div className={classes.NextButtons}>    
                            <Tooltip title={'+ 1 Tag'}>
                                    <IconButton onClick={() => navigate(1)}><BsFillSkipEndFill /></IconButton>
                            </Tooltip>                       
                            <Tooltip title={'+ 1 Woche'}>
                                    <IconButton onClick={() => navigate(7)}><BsFillSkipForwardFill /></IconButton>
                            </Tooltip>                   

                        </div>
                    </div>
                    <div className={classes.CalContainer}>
                        {calTable}

                    </div>
                    <div className={classes.SliderBox}>
                                    <div className={classes.SliderText}>{dayCols} Tage</div>
                                    <div className={classes.Slider}>
                                        <Slider aria-label="Temperature"
                                                value={dayCols}
                                                onChange={handleChange}
                                                getAriaValueText={valuetext}
                                                valueLabelDisplay="auto"
                                                step={1}
                                                marks
                                                min={1}
                                                max={7}
                                                />
                                    </div>  
                                  </div>
                    

                </React.Fragment>;
}

export default Calendar;