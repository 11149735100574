import React from 'react';
import PatientListItem from './PatientListItem';
import ListMenu from './ListMenu';
import SubMenu from '../Navigation/SubMenu/SubMenu';
import Auxiliary from '../../hoc/Auxiliary';
import PaginatedList from '../UI/PaginatedList/PaginatedList';
import { PureComponent } from 'react/cjs/react.production.min';
import PatientInvListItem from './PatientInvListItem';



class PatientList extends PureComponent{

    state = { 
        controls: {
            filter: {
                value: '',
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Suche...'
                },
                valid: true,
                touched: false
            },     
        },
        birthdayFilter: false   ,
        invoicingFilter: false,
        filteredItems: null,
        pageSize: 30
        
    }

    

    renderItems = (items) => {
        return (
            <Auxiliary>
            {items && items.map((patient, index) => {
                if (this.props.invoicing){
                    
                    return (<PatientInvListItem
                        patient={patient}
                        key={patient.ID}
                        showDetail={true}
                        searchHelp={this.props.searchHelp}
                        clicked={this.props.patientClickHandler}
                    />);
                }
                else{
                    return (<PatientListItem 
                                patient={patient}
                                key={patient.ID}
                                showDetail={false}
                                searchHelp={this.props.searchHelp}
                                clicked={this.props.patientClickHandler}
                            />);
                }
                
            })}
            </Auxiliary>
        );
    }    

    render() {
            let items = this.props.patients;
            if (this.state.filteredItems){
                items = this.state.filteredItems;
            }           

            const menu = (<ListMenu   controls={this.state.controls} 
                            changed={(event) => this.filterHandler(event)}
                            searchHelp={this.props.searchHelp} 
                            birthdayFilter={this.state.birthdayFilter}
                            filterBirthday={() => this.filterBirthday()}
                            invoicing={this.props.invoicing}
                            invoicingFilter={this.state.invoicingFilter}
                            filterInvoicing={() => this.filterInvoicing()}
                            />);
            return(
                <Auxiliary>
                    <SubMenu popupMenu={this.props.searchHelp}>
                        {menu}
                    </SubMenu>
                    <PaginatedList 
                        items={items}
                        renderItems={this.renderItems} 
                        itemsPerPage={this.state.pageSize}>
                    </PaginatedList>   
                </Auxiliary>
            );
    }

    filterBirthday = (event) => {
        const newValue = !this.state.birthdayFilter;
        const filter = {
            displayName:  this.state.controls.filter.value,
            birthdayFilter: newValue,
            invoicingFilter: this.state.invoicingFilter
        };
        const filteredItems=  this.getFilteredItems(filter);

        this.setState({birthdayFilter: newValue,
                        filteredItems: filteredItems });
    }   


    filterInvoicing = (event) => {
        const newValue = !this.state.invoicingFilter;
        const filter = {
            displayName:  this.state.controls.filter.value,
            birthdayFilter: this.state.birthdayFilter,
            invoicingFilter: newValue
        };
        const filteredItems=  this.getFilteredItems(filter);

        this.setState({invoicingFilter: newValue,
                        filteredItems: filteredItems });
    }       
    
    filterHandler = (event)  => {
        
        //let controls = [ ...this.state.controls];
        let controls = this.state.controls;
        controls.filter.value = event.target.value;

        const filter = {
            displayName: controls.filter.value,
            birthdayFilter: this.state.birthdayFilter,
            invoicingFilter: this.state.invoicingFilter
        };

        const filteredItems=  this.getFilteredItems(filter);

        this.setState({ controls: controls,
                        filteredItems: filteredItems});
    }

    getFilteredItems = (filter) => {
        const regex = RegExp(filter.displayName, 'i');
        
        const filteredList =  this.props.patients
                    .filter(patient =>  regex.test(patient.displayName)    && 
                                        ( !filter.birthdayFilter  || this.hasBirthday(patient) ) &&
                                        ( !filter.invoicingFilter || (patient.invoicing && this.followUpDate(patient) ))
                                        );
                                        
                            

        return filteredList;
    }    

    followUpDate = (patient) =>{
        let showPatient = true;
        
        if (patient.followUpDate){
            const followUp = new Date(patient.followUpDate + 'T00:00:00');
            
            
            const today = new Date();
            if (followUp > today){
                showPatient = false;
            }
        }
        return showPatient;
    }

    hasBirthday = (patient) => {
        let hasBirthday = false;
        if (patient.birthday){
            const birthdate = new Date(patient.birthday + 'T00:00:00');
            const today = new Date();
            //const today = new Date('2022-04-07T00:00:00');
            if (birthdate.getMonth() ===  today.getMonth() &&
                birthdate.getDate() ===  today.getDate()  ){                
                    hasBirthday = true;
                }
        }
        return hasBirthday;
    }


    componentDidMount(){
        window.scrollTo(0, 0);    
        
        if (this.props.invoicing){
            this.filterInvoicing(null);
            this.setState({ pageSize : 15
                             });
            
        }
        
    }
}

export default PatientList;