import React from 'react';
import classes from './Card.module.css';


const Facts = (props) => {


    return (
        <fieldset className={classes.Facts}>
            {props.children}
        </fieldset>
    );
    }

export default Facts;