
import React, {Component} from 'react';
import AppointmentListItem from './AppointmentListItem';
import GlobalContext from '../../context/GlobalContext';
import ListMenu from './ListMenu';
import Auxiliary from '../../hoc/Auxiliary';
import classes  from './Appointment.module.css';
import Layout from '../Layout/Layout';
import SubMenu from '../Navigation/SubMenu/SubMenu';
import AppointmentInvoiceListItem2 from './AppointmentInvoiceListItem2';

class AppointmentList extends Component{

    dateTmp = null;

    static contextType = GlobalContext;

    //myRef = React.createRef();
    state = { 
        controls: {
            patientFilter: {
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Patient...'
                },
                valid: true,
                touched: false
            }
        },
    }
    constructor(props) {
        super(props);
        this.todayRef = React.createRef();
      }    



    render() {
        this.dateTmp = null;

        if (this.props.patientDetail){
            //don't filter appointments 
            return this.props.appointments
                    .sort(function(a,b){
                        return new Date(b.date) - new Date(a.date) ;
                    })
                    .map((appointment, index) => {
                return (
                    <AppointmentListItem 
                            appointment={appointment}
                            patientDetail={this.props.patientDetail}
                            key={appointment.ID}     
                            hideDocu={this.props.hideDocu}                 
                            />
                    );
            });
        }
        else if (this.props.invoiceDetail){
            //don't filter appointments 
            return this.props.appointments
                    .sort(function(a,b){
                        return new Date(b.date) - new Date(a.date) ;
                    })
                    .map((appointment, index) => {
                return (
                    <AppointmentInvoiceListItem2 
                            appointment={appointment}
                            patientDetail={this.props.patientDetail}
                            selectable={this.props.selectable}
                            key={appointment.ID}     
                            hideDocu={this.props.hideDocu}        
                            selectHandler={this.props.selectHandler}         
                            />
                    );
            });
        }        
        else{
            // appointment list: show only the last 3 months and group by date
            const regex = RegExp(this.context.patientFilter, 'i');
            var today = new Date();
            today.setHours(23,59,59);

            var limit = new Date( new Date().getFullYear(), new Date().getMonth() - 3,  new Date().getDate() );

             
            let filteredAppointments = this.props.appointments
                .filter(appointment =>  ( new Date(appointment.date) >= limit ) &&   // date filter
                                        ( this.context.statusFilter === 0 || 
                                            ( appointment.statusID === this.context.statusFilter  && new Date(appointment.date) <= today)) &&  // status filter to today
                                        ( this.context.therapeutFilter.includes( appointment.therapeutID )) && // Therapeutenfilter
                                        ( regex.test(appointment.patientName) || regex.test(appointment.subject))); // Patientenfilter                
            
            let anchorDate = this.findClosestToToday(filteredAppointments);

            
            var list = filteredAppointments.map((appointment, index) => {
                        var dateElement = null;
                        if (this.dateTmp !== appointment.date ){
                            var datePrint = Layout.formatDate(appointment.date);

                            if ( !this.isAnchorDate(anchorDate, new Date(appointment.date)) ){
                                dateElement = (<div key={appointment.date} className={classes.Date} >{datePrint}</div>);
                            }
                            else{
                                const key = appointment.date;
                                dateElement = (<div key={key} className={classes.Date} ref={this.todayRef}>{datePrint}</div>);
                                
                            }

                            this.dateTmp = appointment.date;
                        }      

                        //show only the first 200 entries
                        return (
                            <Auxiliary key={appointment.ID}> 
                            {dateElement}
                            <AppointmentListItem 
                                    appointment={appointment}
                                    patientDetail={this.props.patientDetail}
                                    key={appointment.ID}                
                                    />
                            </Auxiliary>
                            );
                    });

            return (
                <Auxiliary>
                    <SubMenu>
                        <ListMenu   fieldControls={this.state.controls} goToToday={() => this.scrollToBottom()}/>
                    </SubMenu>
                    
                        {list}
                    
                </Auxiliary>
            );
        }
    }


    findClosestToToday(appointmentList){
        const today = new Date();
        today.setHours(23,59,59);
        let closestDate = new Date();
        var arrayLength = appointmentList.length;
        let currentDate = null;
        for (var i = 0; i < arrayLength; i++) {
            currentDate = new Date(appointmentList[i].date);
            if ( currentDate > today ){
                return closestDate;
            }
            else{
                closestDate = currentDate;
            }
        }
        return  closestDate;

    }

    isAnchorDate(anchorDate, date){
        
        if ( date.getFullYear() === anchorDate.getFullYear() &&
             date.getMonth() === anchorDate.getMonth() &&
             date.getDate() === anchorDate.getDate() )
            return true;
        else
            return false;
    }

    componentDidMount(){
        this.scrollToBottom();
        //this.scrollItem.current.scrollIntoView();
    }
    componentDidUpdate(){
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        if (this.todayRef != null){
            if (this.todayRef.current != null){
                this.todayRef.current.scrollIntoView();
                window.scrollBy(0, -105);
                
            }
        } 
      }
}

export default AppointmentList;