
import React from 'react';
import classes from './Modal.module.css';
import globalClasses from '../../../globalStyle.css'
import { IoClose } from "react-icons/io5";
import { IconButton } from '@mui/material';


const ModalHeader = (props) => {
    let closeButton = null;
    if (props.onClose){
        closeButton = <IconButton onClick={props.onClose} > <IoClose className={globalClasses.IconMui} /> </IconButton>   ;
    }

    return (
        <div className={classes.ModalHeader}>
            {props.title}
            <div className={classes.HeaderFunctions}>{props.children}{closeButton}</div>
        </div>
    );
    }

export default ModalHeader;