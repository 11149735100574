
import React, {Component} from 'react';

import Auxiliary from '../../hoc/Auxiliary';
import PaginatedList from '../UI/PaginatedList/PaginatedList';
import AppointmentListItem from './AppointmentListItem';
import Layout from '../Layout/Layout';
import classes from './Appointment.module.css';

class AppointmentListPaginated extends Component{

    renderItems = (items) => {
        this.dateTmp = null;
        return (
            <Auxiliary>
            {items && items.map((appointment, index) => {
                let item = null;
                if (this.props.view === 'overview' || this.props.view === 'invoicing') {
                    let dateElement = null;
                    if (this.dateTmp !== appointment.date ){
                        const datePrint = Layout.formatDate(appointment.date);
                        dateElement = (<div key={appointment.date} className={classes.Date} >{datePrint}</div>);

                        this.dateTmp = appointment.date;
                    }    
                    item = (  <Auxiliary key={appointment.ID}> 
                                {dateElement}
                                <AppointmentListItem 
                                    appointment={appointment}
                                    patientDetail={this.props.patientDetail}
                                    key={appointment.ID}                 />
                                </Auxiliary>
                                
                                );

                }    
                else if (this.props.view === 'patientDetail') {
                    item = (<AppointmentListItem 
                                appointment={appointment}
                                patientDetail={true}
                                key={appointment.ID}     
                    />);
                }
                return item;
            }
            )}
            </Auxiliary>
        );
    }  

    render() {
        let items = this.props.appointments;

        let pageSize = 20;
        if (this.props.pageSize){
            pageSize = this.props.pageSize;
        }
        
        return(
            <div className={classes.PaginatedList}>
                <PaginatedList 
                    items={items}
                    renderItems={this.renderItems} 
                    itemsPerPage={pageSize}>
                </PaginatedList>   
            </div>
        );

    }

    componentDidMount(){

        if (!this.props.embedded){
            window.scrollTo(0, 0);    
        }

    }
}

export default AppointmentListPaginated;