
import React from 'react';


import { Box } from '@mui/material';
import AppointmentInvoiceListItem2 from '../Appointments/AppointmentInvoiceListItem2';


const InvoiceAppList  = (props) => { 

    return  <Box sx={{ flexGrow: 1 }}>
                    {props.appointments
                        .sort(function(a,b){
                            return new Date(b.date) - new Date(a.date) ;
                        }) 
                        .map((appointment, index) => {
                            return (
                                <AppointmentInvoiceListItem2 
                                        appointment={appointment}
                                        selectable={props.selectable}
                                        key={appointment.ID}     
                                        selectHandler={props.selectHandler}         
                                        />
                                );
                            })}
            </Box>   
}

export default InvoiceAppList;

