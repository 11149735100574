import React from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { FaSave } from "react-icons/fa";
import { IconButton, Tooltip } from '@mui/material';

const ClientMenu = (props) => {
        //const [confirmationWindow, setConfirmationWindow] = useState(false);
        /*
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        */
        
        let buttonsLeft = null;
        let buttonsRight = null;

    return (
        <div className={classes.SubMenuContainer}>
            <div className={classes.SubMenuLeft}>
                <Tooltip title="Speichern">
                    <span>
                        <IconButton onClick={props.saveHandler} >
                            <FaSave />
                        </IconButton>  
                    </span>
                </Tooltip>
                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
}

export default ClientMenu;