
import React, {useContext } from 'react';
import classes from './DrawerToggle.module.css';
import { Badge } from '@mui/material';
import { RxHamburgerMenu } from "react-icons/rx";
import GlobalContext from '../../../context/GlobalContext';


const DrawerToggle = (props) => {

    const globalContext = useContext(GlobalContext);
    return        <div onClick={props.clicked} className={classes.DrawerToggle}>
                        <Badge badgeContent={globalContext.myOpenTasksCnt} color="warning" invisible={globalContext.myOpenTasksCnt === 0}>
                            <RxHamburgerMenu className={classes.BurgerMenu}/>
                        </Badge>
                    </div>
              
            


}


export default DrawerToggle;



