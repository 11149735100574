import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Auxiliary from '../../../hoc/Auxiliary';
import TableBody from '../Table/TableBody';
import TableFooter from '../Table/TableFooter';
import './PaginatedList.css';


const PaginatedList = (props) => {

    var itemsPerPage = 50;
  if (props.itemsPerPage ){
     itemsPerPage = props.itemsPerPage;
  }  

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        setItemOffset(0);
        //console.log('useEffect  props.items ');
    }, [props.items])

  useEffect(() => {
      /*
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(props.items.slice(itemOffset, endOffset));
    //props.setCurrentItems(props.items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.items.length / itemsPerPage));
    */
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(props.items.slice(itemOffset, endOffset));
      //props.setCurrentItems(props.items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(props.items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, props.items]);


  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % props.items.length;
    //console.log(    `User requested page number ${event.selected}, which is offset ${newOffset}`  );
    window.scrollTo(0, 0);  
    setItemOffset(newOffset);
  };

  let listItem = (<ReactPaginate
                      breakLabel="..."
                      nextLabel=">>"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      pageCount={pageCount}
                      previousLabel="<<"
                      renderOnZeroPageCount={null}
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      containerClassName='pagination'
                      pageClassName='page-item'
                      pageLinkClassName='page-link'
                      previousClassName='page-item'
                      previousLinkClassName='page-link'
                      nextClassName='page-item'
                      nextLinkClassName='page-link'
                      activeClassName='active'
                    />);

  if (props.table){
    //wrap the list item in a table row
    return ( <Auxiliary>   
      <TableBody>
          {props.renderItems(currentItems)}
      </TableBody>
      <TableFooter>
        <tr ><td colSpan={99}>{listItem}</td></tr>
      </TableFooter>
    </Auxiliary> );
  }
else{
  return (
    <Auxiliary>     
      {props.renderItems(currentItems)}
      {listItem}
    </Auxiliary>
  );
}
 


}

export default PaginatedList;