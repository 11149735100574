import React  from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';

import {  FaSave } from "react-icons/fa";

import { IconButton } from '@mui/material';

const ServiceChainMenu = (props) =>  {

    let buttonsLeft = null;
    let buttonsRight = null;
    

    return (
        <div className={classes.SubMenuContainer}>
            <div className={classes.SubMenuLeft}>
                <IconButton onClick={props.saveHandler} disabled={props.saveDisabled}  >
                    <FaSave />
                </IconButton>  
                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
    }

export default ServiceChainMenu;