import { ToggleButton, ToggleButtonGroup  } from '@mui/material';
import React from 'react';
import { BsCalendar3, BsListUl } from "react-icons/bs";

import Appointments from '../Appointments';


const ToggleAppView = (props) => {


    return <ToggleButtonGroup 
                size="small"
                value={props.view}
                exclusive
                onChange={props.onChange } >
            <ToggleButton value={Appointments.AGENDA_VIEW} >
                <BsListUl/>
            </ToggleButton>
            <ToggleButton value={Appointments.CALENDAR_VIEW} >
            <BsCalendar3 />
            </ToggleButton>
        </ToggleButtonGroup>


}


export default ToggleAppView;