import React from 'react';
import classes from './SubMenu.module.css';
import Button from '../../UI/Button/Button';
import { IoIosUndo } from "react-icons/io";
import Auxiliary from '../../../hoc/Auxiliary';




const SubMenu = (props) =>  {
    
    function navigateBack(props){
        props.history.goBack();
    }

    var navBack = null;
    if (props.history){
        navBack = (<li className={classes.EditButton}><Button btnType="TableButton" click={() => navigateBack(props)} disabled={false} ><IoIosUndo /></Button></li>       );
    }

    let cssClass = classes.SubMenu;
    if (props.popupMenu){
        cssClass = classes.PopupMenu;
    }

    return (
        <Auxiliary>
            <div className={cssClass}>
                <ul className={classes.SubMenuButtons}>
                    {navBack}
                    {props.children}
                </ul>
            </div>
            <div className={classes.Placeholder}></div>
        </Auxiliary>
        );
    }

export default SubMenu;