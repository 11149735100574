import React from 'react';
import classes from './Spinner.module.css';

const Spinner = (props) => {
    
    const classArray = [classes.Loader]
    if (props.type){
        classArray.push(classes[props.type]);
    }

    return <div className={classArray.join(' ')}>Loading...</div>

    }

export default Spinner;