import React, { useState } from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IconButton, Tooltip } from '@mui/material';

import {Link} from 'react-router-dom';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import { BsCalendarPlus } from 'react-icons/bs';

const AppQueueMenu = (props) => {

    const [confirmationWindow, setConfirmationWindow] = useState(false);

    let popup = null;
    if (confirmationWindow){

        popup = (  <YesCancelModal  clickYes={() =>  { setConfirmationWindow(false); props.deleteHandler()}} 
                            clickCancel={() => setConfirmationWindow(false)}
                            title="Wartelisteneintrag löschen" >
                            Willst du den Wartelisteneintrag unwideruflich löschen?
                    </YesCancelModal>  );
    }

    
    let buttonNewAppointment = null;

    if (props.patient  && props.patient.ID){
    buttonNewAppointment =  ( <Tooltip title="Neuen Termin anlegen">
                                <span>
                                    <Link to={{ pathname: '/appointments/new/'.concat(props.patient.ID)}}>
                                        <IconButton    >
                                            <BsCalendarPlus />
                                        </IconButton>  
                                    </Link>
                                </span>
                                </Tooltip>  );  
    }



        let buttonsLeft = <React.Fragment> 
                            <Tooltip title="Speichern">
                                <span>
                                    <IconButton onClick={props.saveHandler} >
                                        <FaSave />
                                    </IconButton>  
                                </span>
                            </Tooltip>
                            {buttonNewAppointment}
                        </React.Fragment>;
        let buttonsRight = <React.Fragment> 
                            <Tooltip title="Löschen">
                                <span>
                                    <IconButton onClick={() => setConfirmationWindow(true)} >
                                        <RiDeleteBin6Line   />
                                    </IconButton>  
                                </span>
                            </Tooltip>
                        
                        </React.Fragment>;

    return (
        <div className={classes.SubMenuContainer}>
            {popup}
            <div className={classes.SubMenuLeft}>

                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
}

export default AppQueueMenu;