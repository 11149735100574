import React, { useState} from 'react';
import Modal from '../UI/Modal/Modal';
import ModalHeader from '../UI/Modal/ModalHeader';
import ModalContent from '../UI/Modal/ModalContent';
import ModalFooter from '../UI/Modal/ModalFooter';
import Button from '../UI/Button/Button';
import {QRCodeSVG} from 'qrcode.react';
import qrClasses from './Patient.module.css';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { RiMailSendLine } from "react-icons/ri";
import { FiExternalLink } from "react-icons/fi";
import YesCancelModal from '../UI/Modal/YesCancelModal';


const RegLinkWindow = (props) =>  {

    const [confirmationWindow, setConfirmationWindow] = useState(false);


    let popup = null;
    if (props.showQRCode){
        if (confirmationWindow){
            popup = (  <YesCancelModal  clickYes={() => props.sendRegLink()} 
                                        clickCancel={() => setConfirmationWindow(false)}
                                        title="Email senden" >
                            Willst du dem Patienten eine E-Mail mit dem Registrierungslink schicken? <br/><br/> Die E-Mail wird an die E-Mail Adresse im Patientstamm geschickt. 
                        </YesCancelModal>  );
        }
        else{
            popup =   <Modal show={props.showQRCode}  >
                        <ModalHeader title='Registrierungslink' onClose={props.modalClosed}/>
                        <ModalContent >
                            
                            <QRCodeSVG value={props.regLink} />
                            <br/>
                            <div className={qrClasses.QRCodeButton}>Link öffnen 
                                <a href={props.regLink} target='_blank'  rel='noreferrer'>
                                        <FiExternalLink className={classes.Icon}/>
                                </a>
                            </div>
                            <div className={qrClasses.QRCodeButton}>Link per E-Mail versenden 
                                <div  onClick={() => setConfirmationWindow(true) } className={qrClasses.Cursor}>
                                    <RiMailSendLine className={classes.Icon}/>
                                </div>                        
                            </div>
                        </ModalContent> 
                        <ModalFooter>
                            <Button btnType="EditMenuButton" click={props.modalClosed} tooltip={'Reg. Link per E-Mail verschicken'}>Schließen</Button>
                        </ModalFooter>
                    </Modal>
        }
    }

    return popup;

}

export default RegLinkWindow;



