import React from 'react';
import DropDown from './DropDown';


const praxisConfig = {
        id: "praxis",
        label: "Praxis"
        
    };


const PraxisDD = (props) =>  {
 
    //const globalContext = useContext(GlobalContext);
    
    praxisConfig.options = props.options;//globalContext.therapeutOptions;

    let value = props.value;
    if (!value){
        value = "";
    }

    praxisConfig.error = props.error;
    
    return (  <DropDown {...praxisConfig}
                        value={value}
                        readOnly={props.readOnly}
                        onChange={props.onChange} />
                );
                
}
export default PraxisDD;