import React from 'react';

import '../../../globalStyle.css'; 

import { BiFilterAlt } from "react-icons/bi";
import { HiFilter } from "react-icons/hi";

import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Button from '../../UI/Button/Button';


const  FilterButton = (props) => {



    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
     
    const selectedFilter = props.filterOptions[props.selectedFilter];

    return (
        <React.Fragment>
        <Button  btnType="FilterButton" click={handleClick}  >
                <HiFilter  />{selectedFilter.itemId}
        </Button>
        <Menu
            id="invoice-menu"
            anchorEl={anchorEl}
            open={open}

            onClose={handleClose} >
            {props.filterOptions.map((item, index) => {
                let icon =  <BiFilterAlt className='Icon'/>;
                let selected = false;
                if (index === props.selectedFilter){
                   icon = <HiFilter className='Icon' />;
                   selected = true;
                }
                let divider = <Divider />;
                
                if (index === props.filterOptions.length - 1){
                    divider = null;
                }
                return (
                    [<MenuItem selected={selected}
                        key={item.itemId}
                        onClick={(event) => {handleClose(); props.handleSelect(index) /*props.clickHandler(event, index)*/}}
                    >
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ style: { whiteSpace: "normal" } }} > {item.itemId} - {item.text} </ListItemText>
                        
                    
                    </MenuItem>, divider]
                )})}

        </Menu>
    </React.Fragment>
    );
}

export default FilterButton;
