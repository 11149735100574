import React from 'react';
//import './Praxis.css';
import { Chip } from '@mui/material';
import classes from '../Therapists.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const PraxisChip = (props) => {

  
    const handleClick = () => {
       
        //const add = !props.inactive;
        

        props.handleChangeTheraCalender(props.praxis.PRXID, props.inactive)


       
    }

    let variant = "outlined";
    let color = "primary";

    let iconSelect = <CheckCircleIcon color="primary" />
    if (props.inactive){
        variant = "filled";
        iconSelect = <RadioButtonUncheckedIcon color="disabled"/>
        color = "default";
        // if readonly and inactive, do not show
        if (props.readOnly){
            return null;
        }
    }

    let chip = <div className={classes.PraxisChip}>
                    <Chip 

                        icon={ <LocationOnIcon /> }
                        label={props.praxis.PRAXIS_NAME}
                        variant={variant}
                        color={color}
                        onClick={handleClick}
                        deleteIcon={iconSelect}
                        onDelete={handleClick}
                        />
                </div>
   
   if (props.readOnly){
        chip = <Chip 
                        icon={<LocationOnIcon />  }
                        label={props.praxis.PRAXIS_NAME}
                        variant={variant}
                        //color={color}
                        />
    }                
        
    return chip;
}

export default PraxisChip;