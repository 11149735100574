import React from 'react';
import Card from '../UI/Card/Card';

import { useContext } from 'react';
import {Link} from 'react-router-dom';
import classes from './AppQueue.module.css';
import Caption from '../UI/Card/Caption';
import TherapistChipList from '../Therapist/TherapistChipList/TherapistChipList';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TheraCalendars from '../Therapist/TheraCalendar/TheraCalendars';
import DayList from './DayList';

import GlobalContext from '../../context/GlobalContext';

const AppQueueListItem = (props) => {

    const globalContext = useContext(GlobalContext);
   
    let borderColor = {'borderColor' : 'lightgray'};
    let therapistDirect = null;
    if (props.appQueueItem.therapists.length === 1){

        //get therapists from global context
        const objIndex = globalContext.therapeut.findIndex((obj => obj.PERNR === props.appQueueItem.therapists[0].PERNR));
        if (objIndex !== -1){
            borderColor = {'borderColor' : globalContext.therapeut[objIndex].color,
                            'borderLeftWidth' : '10px'};
        }
    }
    else{
        therapistDirect =   <TherapistChipList  therapists={props.appQueueItem.therapists}    
                                handleChangeTherapists={() => {}}
                                readOnly
                                headless    
                                    /> 

    }


    return <Card  cssStyle={borderColor}> 
            <Caption > 
            <Link  to={{ pathname: '/appQueue/' + props.appQueueItem.id }}>{props.patient.displayName}</Link>
                </Caption>
                <div className={classes.CardContent}>&nbsp; 
                    {props.appQueueItem.comment}
                    {therapistDirect}
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography>Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TherapistChipList  therapists={props.appQueueItem.therapists}    
                                        handleChangeTherapists={() => {}}
                                        readOnly
                                        headless    
                                               /> 
                            <br/>
                            <TheraCalendars     theraCalendars={props.appQueueItem.praxis} 
                                                handleChangeTheraCalender={() => {}}
                                                readOnly
                                                headless
                                                />  
                            <br/>
                            <DayList    appQueueItem={props.appQueueItem}
                                        readOnly
                                        headless
                                        changeDayHandler={() => {}}/>                                                                                                  
                        </AccordionDetails>
                    </Accordion>                                               
                </div>
            </Card>
}

export default AppQueueListItem;