import React, {useContext} from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import Caption from '../UI/Card/Caption';
import Tile from '../UI/Tile/Tile';
import classes from './FinanceMenu.module.css';
import TileContainer from '../UI/Tile/TileContainer';

import { FaFileInvoice, FaRegCalendarCheck} from "react-icons/fa";
import { GoGraph} from "react-icons/go";

import { RiErrorWarningLine  } from  "react-icons/ri";
import { BsFileEarmarkPlus} from "react-icons/bs";


import Input from '../UI/Input/Input';
import GlobalContext from '../../context/GlobalContext';

const FinanceMenu = (props) => {

    
    const globalContext = useContext(GlobalContext);
    
    const styleConfig = {   inputStyle: 'InputDetail',
                            labelStyle: 'LabelLightFixed',
                            inputElementStyle:'InputElementDetail' };            

    
    const configDecimal = { type: 'currency' };

    let suffixOverDue = null;
    if (globalContext.KPI.cntOverdueInvoice > 0 ){
        suffixOverDue =  ( <RiErrorWarningLine className={classes.IconOverdue}/>  );
    }

    return (
    <Auxiliary>
    <h3>Rechnungen</h3>
    <TileContainer>
        <Tile link='/financeMenu/invoices'>
            <Caption>Offene Rechnungen</Caption>
            <FaFileInvoice  className={classes.Icon}/>
            <Input  key= {'open'} 
                    elementType= 'input' 
                    elementConfig={configDecimal}
                    styleConfig= {styleConfig} 
                    value={globalContext.KPI.openInvoices} 
                    label='Σ offene' 
                    readOnly={true} 
                    />            
            <Input  key= {'dunned'} 
                    elementType= 'input' 
                    styleConfig= {styleConfig} 
                    value={globalContext.KPI.cntDunnedInvoice} 
                    label='# gemahnt' 
                    readOnly={true} 
                    />       
            
            <Input  key= {'overdue'} 
                    elementType= 'input' 
                    styleConfig= {styleConfig} 
                    value={globalContext.KPI.cntOverdueInvoice} 
                    label='# überfällig' 
                    suffix={suffixOverDue}
                    readOnly={true} 
                    />                          
        </Tile>

        <Tile link='/financeMenu/invoicing'>
            <Caption>Rechnung erstellen</Caption>
            <BsFileEarmarkPlus  className={classes.Icon}/>
                       
            <Input  key= {'toBeInvoiced'} 
                    elementType= 'input' 
                    styleConfig= {styleConfig} 
                    value={globalContext.KPI.cntInvoicingPat} 
                    label='Abzurechnende Patienten' 
                    readOnly={true} 
                    />                       
            
        </Tile>        
        <Tile link='/financeMenu/released'>
            <Caption>Abzurechnende Termine</Caption>
            <FaRegCalendarCheck  className={classes.Icon}/>
            <Input  key= {'open'} 
                    elementType= 'input' 
                    elementConfig={configDecimal}
                    styleConfig= {styleConfig} 
                    value={globalContext.KPI.sumReleased} 
                    label='Summe Termine' 
                    readOnly={true} 
                    />             
        </Tile>
        {/*
        <Tile >
            <Caption>Überfällige Rechnungen</Caption>
            <MdWarningAmber  className={classes.Icon}/>
        </Tile>  
    */}  
        <Tile link='/financeMenu/statistics' >
            <Caption>Auswertung</Caption>
            <GoGraph  className={classes.Icon}/>
            <div>Auswertungen, Statistiken, Berichte</div>
        </Tile>              
        
    </TileContainer   >
    {/*
    <h3>Personalabrechnung</h3>
    <TileContainer   >
    <Tile >
            <Caption>Provisionsberechnung</Caption>
            <AiOutlineEuro  className={classes.Icon}/>
        </Tile>    
    </TileContainer   >
     */}
    </Auxiliary>
);

    }

export default FinanceMenu;

