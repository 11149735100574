import React from 'react';
import DetailContainer from '../Layout/DetailContainer';
import Auxiliary from '../../hoc/Auxiliary';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import MUIPatientInput from '../MUI/MUIInput/MUIPatientInput';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';
import DateField from '../MUI/DateField/DateField';


const prescriptionDetailBasicsMui = (props) => {
    
    let readOnly = true; 
    if (props.controls){
        readOnly = props.controls.readOnly;
    }
    
    let patient = null;
    let diagnose = null;
    let note = null;
    let readOnlyPatient = true;


    patient = <MUIPatientInput   id="patientID"
                label="Patient"
                required={true} 
                patientID={props.prescription.patient}
                value={props.prescription.displayname} 
                readOnly={readOnlyPatient}
                selectPatientHandler={(patientId) => props.selectPatientHandler(patientId)}
/>                
    
    if (props.allDetails){        
        if (!props.prescription.ID){
           readOnlyPatient = false;
        }

        patient = <MUIPatientInput   id="patientID"
                                     label="Patient"
                                     required={true} 
                                     patientID={props.prescription.patient}
                                     value={props.prescription.displayname} 
                                     readOnly={readOnlyPatient}
                                     selectPatientHandler={(patientId) => props.selectPatientHandler(patientId)}
                    />

        diagnose =  <MUIInput   id={'diagnose'}
                                label='Diagnose' 
                                value={props.prescription.diagnose}
                                readOnly={readOnly} 
                                onChange={(event) => props.inputChangedHandler(event, 'diagnose')}
                                multiline
                                minRows={2}
                                fullWidth
                            />     
        note =  <MUIInput   id={'comment'}
                            label='Notiz' 
                            value={props.prescription.comment}
                            readOnly={readOnly} 
                            onChange={(event) => props.inputChangedHandler(event, 'comment')}
                            multiline
                            minRows={2}
                            fullWidth
                        />                               
    }
    
    return(
            <Auxiliary>
                
                <DetailContainer> 
                <FactsMui>   
                    
                    {patient}  
                       <DoubleField>   
                        <DateField  id={'date'}
                                    label='Datum' 
                                    value={props.prescription.date} 
                                    readOnly={readOnly} 
                                    required
                                    onChange={(value) => props.inputChangedHandler(value, 'date')}
                        /> 
                        <MUIInput   id={'hours'}
                                    value={props.prescription.hours} 
                                    label='Std. Gesamt' 
                                    onChange={(event) => props.inputChangedHandler(event, 'hours')}
                                    readOnly={readOnly} 
                                    suffix={'h'}
                                    required
                                />
                         </DoubleField>      
                         
                        <DoubleField>          

                            <MUIInput   id={'consumed'}
                                        value={props.prescription.consumed} 
                                        label='Verbraucht' 
                                        suffix={'h'}
                                        readOnly={true} 
                                />
                            <MUIInput   id={'leftOpen'}
                                        value={props.prescription.leftOpen} 
                                        label='Offen' 
                                        suffix={'h'}
                                        readOnly={true} 
                                />                                                                                
                            </DoubleField>                                                                 
                    </FactsMui>   
                    <FactsMui> 
                            {diagnose}
                            {note} 
                        </FactsMui>                                                                                                                                                                   
                    </DetailContainer> 
                </Auxiliary>
    );
}
export default prescriptionDetailBasicsMui;
