
import React, {useContext} from 'react';
import classes  from './Appointment.module.css';
import Card from '../UI/Card/Card';
import GlobalContext from '../../context/GlobalContext';

import AppointmentLink from '../MUI/AppointmentLink/AppointmentLink';
import PrescriptionLink from '../MUI/PrescriptionLink/PrescriptionLink';
import CheckBox from '../MUI/MUIInput/CheckBox';
import MUIInput from '../MUI/MUIInput/MUIInput';

const AppointmentInvoiceListItem2  = (props) => { 

    const globalContext = useContext(GlobalContext);

    var borderStyle = classes.Therapist;
    

    let amountText = '- €';
    if (props.appointment.total>0){
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          });
        amountText  = formatter.format(props.appointment.total);
    }

    /************************************************ */
    const time_start = new Date();
    const time_end = new Date();
    const value_start = props.appointment.from.split(':');
    const value_end = props.appointment.to.split(':');

    time_start.setHours(value_start[0], value_start[1], 0, 0)
    time_end.setHours(value_end[0], value_end[1], 0, 0)

    const duration = (time_end - time_start) / 3600000;// millisecond 
    const durationText = duration.toLocaleString() + 'h';    


    amountText = durationText + ' / ' + amountText;
    /************************************************ */

    let prescription = null;
    let diagnose = null;



    let checkBox = null;
    const cssClasses = [];
    cssClasses.push(classes.ItemContainer);

    


    if (props.selectable){
        //cssClasses.push(borderStyle);
        checkBox = <div className={classes.CheckBoxContainer} >
                    <CheckBox  checked={props.appointment.selected} 
                                readOnly={!props.appointment.selectable}
                                disabled={!props.appointment.selectable}
                                onChange={ (event) => props.selectHandler(event, props.appointment.ID) }/>
                    </div>;
        
        if (!props.appointment.selectable){
            cssClasses.push(classes.ItemUnselectable);
        }

        if (props.appointment.prescriptionID ){
            prescription = <PrescriptionLink prescriptionID={props.appointment.prescriptionID}/>;
    
        }
        else if  (props.appointment.diagnose  && props.appointment.diagnose.length > 10) {
            // show DIAGNOSE
            diagnose = ( 

                    <MUIInput id={'diagnose'}
                                    label='Diagnose' 
                                    value={props.appointment.diagnose.replace(/(<([^>]+)>)/gi, "")}
                                    readOnly={true} 
                                    multiline
                                    />                        
            );
        }
    
    }

    const therapist = globalContext.getTherapist(props.appointment.therapeutID);
    let borderColor = {'borderColor' : '#F5F5F5'};
    if (therapist){
        borderColor = {'borderColor' : therapist.color};
    }
        

    return  <Card style={borderStyle} cssStyle={borderColor} >
                <div className={cssClasses.join(' ')} > 
                    <div className={classes.Left}>
                        {checkBox}
                        <div className={classes.MainContent}>
                            <AppointmentLink appointment={props.appointment} invoiceDate={true} />
                            
                            {prescription}
                            
                        </div>
                    </div>
                    <div className={classes.AmountContainer}>{amountText}</div>
                </div>
                {diagnose}
            </Card>      
}

export default AppointmentInvoiceListItem2;

