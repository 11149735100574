import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';


export function getTextFieldVariant(readOnly) {
    let variant = "standard" ;
    
    if (!readOnly){
        variant = "filled" ;
    }
    return variant;
}

const MUIInput = (props) =>  {

    let value = props.value;
    if (!value){
        value = "";
    }
    
    let error = false;
    if (props.error){
        error = props.error;
    }
    else if (props.required){
        if (!value || value === ''){
            error = true;
        }
    }

    
    
    let inputProps = { };

    
    if (props.InputProps){
        inputProps = props.InputProps;
    }
    
    inputProps.readOnly = props.readOnly;


    if (props.suffix){
        inputProps.endAdornment = <InputAdornment position="start">{props.suffix}</InputAdornment>;
    }

    if (props.prefix){
        inputProps.startAdornment = <InputAdornment position="start">{props.prefix}</InputAdornment>;
    }

    let fullWidth = true;
    if (props.fullWidth){
        fullWidth = props.fullWidth;
    }

    const variant = getTextFieldVariant(props.readOnly);


    return (    <TextField  
                        {...props} 
                        variant={variant}
                        margin="dense"
                        size="small"
                        value={ value}
                        error={error}
                        fullWidth={fullWidth}
                        InputProps={inputProps}
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => props.onChange(event, props.id)}         
                        sx={{ mr: 1  }}
                        /> );
}
export default MUIInput;