import React from 'react';

import classes from './AppQueue.module.css';
import { Slider, Switch } from '@mui/material';
import DateUtility from '../../hoc/DateUtility';



  const getNumberTwoDigits = (number) => {
    return String(number).padStart(2, '0');
  }

  const from = 7;
  const to = 21;

const DaySelector = (props) => {

    const switchDay = () => {

        const day = props.day;
        day.active = !day.active;
        props.handleChangeDay(day);

    }

    const handleSliderChange = (event, newValue) => {

        const day = props.day;
        day.from = newValue[0];
        day.to = newValue[1];
        props.handleChangeDay(day);
        
    }

    const sunday = new Date('2023-11-05'); // this is a sunday
    const day = DateUtility.addDays(sunday, props.day.day);
    const dayName = DateUtility.formatDate(day, DateUtility.TYPE_WEEKDAY);
    
    day.setDate(day.getDate() + props.day.day);


    const fromTo = [props.day.from, props.day.to];

    const fromToText = getNumberTwoDigits(props.day.from) + ':00 - ' + getNumberTwoDigits(props.day.to) + ':00';

    let timeSelection = null;
    if (props.day.active){
        timeSelection = <div className={classes.TimeSelection}>
                            <div className={classes.Time}>{fromToText}</div>
                            <div className={classes.Slider}>
                                
                            <Slider getAriaLabel={() => 'Zeit von-bis'}
                                value={fromTo}
                                onChange={handleSliderChange}
                                valueLabelDisplay="auto"
                                step={1}
                                marks
                                min={from}
                                max={to}
                                disabled={!props.day.active}
                            />
                            </div>
                        </div>


    }
    else if(props.readOnly){  
      return null;
    }

    let selector = <div className={classes.DaySelector}>
                      <div className={classes.DayName}>{dayName}</div>
                      <Switch checked={props.day.active} onChange={switchDay}/> 
                    
                          
                          {timeSelection}
                  </div>     

    if (props.readOnly){
      selector = <div className={classes.DaySelector}>
                    <div className={classes.DayName}>{dayName}</div>&nbsp;&nbsp;&nbsp;
                    <div className={classes.TimeSelection}>
                      <div className={classes.Time}>{fromToText}</div>
                    </div>
                </div>   


    }

    return  selector;



}

export default DaySelector;
