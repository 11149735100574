import React  from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrDownload  } from "react-icons/gr";

import Button from '../UI/Button/Button';
import TableLine from '../UI/Table/TableLine';
import Auxiliary from '../../hoc/Auxiliary';

const Attachment = (props) => {
    
    const attachment = {...props.item };
        
    attachment.funcDelete = ( 
        <Button btnType="TableButton"  
                tooltip='Datei löschen'
                click={() => props.deleteAttachmentHandler(props.item) }
                disabled={false} >
                    <RiDeleteBin6Line />
        </Button>
    );        

    attachment.funcDownload = ( 
        <Button btnType="TableButton"  
                tooltip='Datei herunterladen'
                click={() => props.downloadAttachmentHandler(props.item )}
                disabled={false} >
                    <GrDownload />
        </Button>
    );            
    


    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const date = new Date(attachment.created_at); 
    attachment.created_at =  date.toLocaleDateString("de-DE", options);     
    
     
    return (
        <Auxiliary>
            <TableLine tableConfig={props.tableConfig}
                    item={attachment} />
        </Auxiliary>
        );
    }


export default Attachment;