import React, {Component} from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import {  GrDocumentPdf } from "react-icons/gr";
import Button from '../UI/Button/Button';
import classes from './Payroll.module.css';
import TableLine from '../UI/Table/TableLine';

class PayrollListItem extends Component{


    render(){  


        const payrollItem = {...this.props.payrollItem};

        const createdAt = new Date(this.props.payrollItem.created_at);
        let createdAtString = '';
        if (createdAt){
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                   
            createdAtString = createdAt.toLocaleDateString("de-DE", options);     
            payrollItem.created_at = createdAtString;

        }
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          });

        payrollItem.grossAmount  = formatter.format(this.props.payrollItem.grossAmount);        
    
        /** functions */
        if (this.props.deleteAllowed){
            payrollItem.funcDelete = (
                <Button btnType="TableButton"  
                    tooltip='Entgeltberechung löschen'
                    click={() => this.props.deleteHandler(this.props.payrollItem)}
                    disabled={false} >
                    <RiDeleteBin6Line className={classes.Icon}/>
                </Button>
                    );
        }          

        payrollItem.funcDownload = ( <Button btnType="TableButton" 
                                        click={() => this.props.downloadHandler(this.props.payrollItem)} 
                                        tooltip={'PDF downloaden'} >
                                    <GrDocumentPdf  className={classes.Icon}/>
                                    </Button> );

        return (  <TableLine   tableConfig={this.props.tableConfig}
                                item={payrollItem} /> );
    }
}


export default PayrollListItem;