import React  from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { IoAddCircleOutline } from "react-icons/io5";
import { MdPlaylistAdd } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';

const CatalogueMenu = (props) =>  {


    //const [confirmationWindow, setConfirmationWindow] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    let buttonsLeft = null;
    let buttonsRight = null;
    buttonsRight = <React.Fragment>                
                    <IconButton onClick={handleClick} >
                        <HiDotsVertical />
                    </IconButton>
                    <Menu
                        id="invoice-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose} >
                        <MenuItem onClick={() => { handleClose(); props.createHandler()} } >
                            <ListItemIcon>
                                <IoAddCircleOutline   />
                            </ListItemIcon>
                            <ListItemText>Leistung anlegen</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(); props.createChainHandler()} } >
                            <ListItemIcon>
                                <MdPlaylistAdd   />
                            </ListItemIcon>
                            <ListItemText>Leistungskette anlegen</ListItemText>
                        </MenuItem>
                    </Menu>
                </React.Fragment>    

    return (
        <div className={classes.SubMenuContainer}>
            <div className={classes.SubMenuLeft}>
                <Tooltip title="Neue Leistung anlegen">
                    <span>
                        <IconButton onClick={props.createHandler}   >
                            <IoAddCircleOutline />
                        </IconButton>  
                    </span>
                </Tooltip>
                <Tooltip title="Neue Leistungskette anlegen">
                    <span>
                        <IconButton onClick={props.createChainHandler}   >
                            <MdPlaylistAdd />
                        </IconButton>  
                    </span>
                </Tooltip>                
                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
    }

export default CatalogueMenu;