import React, {Component} from 'react';
import classes  from './Patient.module.css';
import {Link} from 'react-router-dom';
import { MdPhone, MdEmail, MdSms, MdEvent } from "react-icons/md";
import Auxiliary from '../../hoc/Auxiliary';

import {FaAddressCard} from "react-icons/fa";
import Tooltip from '../UI/Tooltip/Tooltip';




class PatientListItem extends Component{

    render(){  
        const iconSize = '20';

        let links = null;

        if (!this.props.searchHelp){

            let contact = null;
            let phone = null;
            let email = null;
            let createApp = null;            

            contact = (   <Tooltip content='Patientenakte'> 
                                    <div className={classes.ActionIcon}>
                                        <Link to={{ pathname: '/patients/' + this.props.patient.ID }}>
                                            <FaAddressCard className={classes.Icon} size={iconSize}/>
                                        </Link>
                                    </div>
                                </Tooltip>);


            
            if (this.props.patient.phone){
                const phoneLink = 'tel:' + this.props.patient.phone;
                

                const smsLink = 'sms:' + this.props.patient.phone;
                phone = (<Auxiliary>
                            <Tooltip content={'Anruf '+ this.props.patient.phone}>
                                <div className={classes.ActionIcon}>
                                    <a href={phoneLink}><MdPhone className={classes.Icon} size={iconSize}/></a>
                                </div>
                            </Tooltip>
                            <Tooltip content={'SMS '+ this.props.patient.phone}>
                                <div className={classes.ActionIcon}><a href={smsLink}><MdSms className={classes.Icon} size={iconSize}/></a></div>
                            </Tooltip>
                        </Auxiliary>);
            }

            
            if (this.props.patient.email){
                const emailLink = 'mailto:' + this.props.patient.email;
                email = (<Tooltip content={'E-Mail:  '+ this.props.patient.email}>
                            <div className={classes.ActionIcon}><a href={emailLink}><MdEmail className={classes.Icon} size={iconSize}/></a></div>
                        </Tooltip>);

            }        
            
            createApp = (<Tooltip content={'Neuen Termin anlegen'}>
                                <div className={classes.ActionIcon}>
                                    <Link to={{ pathname: '/appointments/new/'.concat(this.props.patient.ID)}}>
                                        <MdEvent className={classes.Icon} size={iconSize}/>
                                    </Link>
                                </div>
                            </Tooltip>);


            links = (<div className={classes.ActionList}>
                        {contact}  
                        {phone} 
                        {email}
                        {createApp}
                    </div> );

        }
        return(
                <div className={classes.PatientListItem} >
                    <div className={classes.Name} onClick={() => this.props.clicked(this.props.patient.ID)}>
                              
                            {this.props.patient.displayName}
                    </div>
                    {links} 
                </div>
        );
    }
}
export default PatientListItem;
