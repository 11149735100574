import React, {useContext } from 'react';
import GlobalContext from '../../../context/GlobalContext';
import DropDown from './DropDown';


const userConfig = {
        id: "user",
        label: "Benutzer"
        
    };


const UserDD = (props) =>  {
 
    const globalContext = useContext(GlobalContext);

    userConfig.options = globalContext.userNamesOptions;

    let value = props.value;
    if (!value){
        value = "";
    }

    if (props.label){
        userConfig.label = props.label;
    }

    userConfig.error = props.error;
    
    return (  <DropDown {...userConfig}
                        value={value}
                        readOnly={props.readOnly}
                        onChange={props.onChange} />
                );
                
}
export default UserDD;