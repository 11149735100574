import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary';

import classes from './Email.module.css';
import { MdEmail } from "react-icons/md";
import MUIInput from '../../MUI/MUIInput/MUIInput';

const email = (props) => {
    
    var icons = null;
    if (props.value && props.readOnly){
        const emailLink = 'mailto:' + props.value;        
        const iconSize = '17';
        icons = (<Auxiliary>
                    <div className={classes.ActionIcon}>
                        <a href={emailLink}><MdEmail className={classes.Icon} size={iconSize}/></a>
                    </div>
                </Auxiliary>);
    }


    return (
        <div className={classes.Email}>

            <MUIInput   id={props.idx}    
                        onChange={(event) => props.onChange(event, props.key)} 
                        value={props.value} 
                        label={props.label} 
                        readOnly={props.readOnly}
                        required={props.requrired}
                        prefix={icons} />  
            
        </div>
            )

}


export default email;