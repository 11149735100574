import React from 'react';
import TableCell from './TableCell';
import TableRow from './TableRow';

const TableLine = (props) => (
    <TableRow>
         {props.tableConfig.columns && props.tableConfig.columns.map((column, index) => {
                    return (
                        <TableCell key={index} label={column.colLabel}>{props.item[column.colName]}</TableCell>
                )})}      

    </TableRow>
    );

export default TableLine;
