import React from 'react';
import Card from '../UI/Card/Card';
import {Link} from 'react-router-dom';
import classes from './Task.module.css';



import { IoMdAlarm } from "react-icons/io";



import TaskUtil from './TaskUtil';
import Caption from '../UI/Card/Caption';
import DateUtility from '../../hoc/DateUtility';
import {  Chip } from '@mui/material';


const TaskListItem = (props) => {
    /*
    let style = classes.Users;

    
    */
    let name = props.task.subject ;
    let borderColor = {'borderColor' : 'lightgray'};
    let userChip = null;
    
   //get border color if assignee is a therapist
    const userIndex = props.userNames.findIndex((obj => obj.userName === props.task.userName));
    if (userIndex !== -1){
        let pernr = props.userNames[userIndex].pernr;
        userChip =  <Chip label={props.userNames[userIndex].name} />
        if (pernr){
            const therapistIndex = props.therapists.findIndex((obj => obj.PERNR  === pernr));       
            if (therapistIndex !== -1){
                let color = props.therapists[therapistIndex].color;
                borderColor = {'borderColor' : color,
                                'borderLeftWidth' : '10px'};
                //userChip =  <Chip label={props.userNames[userIndex].name} 
                //                  color={color}/>                                
            }
        }
    }
    
    //get class name alarm
    let classAlarm = classes.Icon;
    //classAlarm = classes.IconOverDue;
    /*
    if (props.user.inactive){
        
        style = classes.UsersInactive;    
        name = name + ' (inaktiv)'
    }
    */
    const sx = {};

    
    sx.width = 30;
    sx.height = 30;
    sx.fontSize =  "12pt";
    
    return <Card cssStyle={borderColor}>
            <Caption >
                <Link  to={{ pathname: '/task/' + props.task.id }}>
                    {TaskUtil.getStatusIcon(props.task.status)} 
                    {name}</Link>  
            </Caption> 
                <div className={classes.CardContent}>
                    <div className={classes.DueDate}>
                        <IoMdAlarm className={classAlarm}/> 
                        {DateUtility.formatDate(props.task.dueDate, DateUtility.TYPE_LONG_CHAR  )}
                    </div>
                    {userChip}
                </div>

            </Card> 

}

export default TaskListItem;