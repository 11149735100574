import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'

export default class IdleTimeOut extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);

    this.state={
      isTimedOut: false
    }
  }

  render() {
    return (
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 15}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={1000 * 5}
        />
    )
  }

  handleOnAction (event) {
      /*
      console.log('user did something');
      console.log('time remaining', this.idleTimer.getRemainingTime() / 1000);
      */
  }

  handleOnActive (event) {
    window.location.reload();
    /*
    if (this.idleTimer.getRemainingTime() === 0){
      console.log('fire refresh app!!');
      window.location.reload();
    }
    else{
      console.log('user is active', event);
      console.log('time remaining', this.idleTimer.getRemainingTime());
    }
    */
  }

  handleOnIdle (event) {
    console.log('user is idle');
    console.log('last active', new Date( this.idleTimer.getLastActiveTime()).toLocaleDateString());
    //this.setState({isTimedOut: true});
  }
}