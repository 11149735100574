import React from 'react';
import classes from './Tile.module.css';
import { useHistory } from "react-router-dom";

const Tile = (props) => {

    const history = useHistory();

    return (
        
        <div className={classes.Tile} onClick={() => history.push(props.link)}>
                {props.children}
        </div>
        
    );
    }

export default Tile;