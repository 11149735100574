import React from 'react';
import Card from '../UI/Card/Card';

import {Link} from 'react-router-dom';
import classes from './Therapists.module.css';
import TherapistAvatar from './TherapistAvatar';

const TherapistsListItem = (props) => {

    let borderColor = {'borderColor' : '#F5F5F5'};
    if (props.therapist){
        borderColor = {'borderColor' : props.therapist.color};
    }

    let style = classes.Therapist;

    let name = props.therapist.vorname ;
    if (props.therapist.nachname){
        name = name + ' ' + props.therapist.nachname;
    }

    if (props.therapist.inactive){
        
        style = classes.TherapistInactive;    
        name = name + ' (inaktiv)'
    }


    return <Card style={style} cssStyle={borderColor}>
                <div className={classes.CardContent}><TherapistAvatar therapist={props.therapist}/>&nbsp; 
                <Link  to={{ pathname: '/setup/therapists/' + props.therapist.PERNR }}>{name}</Link></div>
            </Card>

}

export default TherapistsListItem;