import React from 'react';
import MUIInput from '../MUIInput/MUIInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const DateField = (props) =>  {

    return      <DatePicker
                    {...props}
                    views={['year', 'month', 'day']}
                    renderInput={(params) =>  <MUIInput {...params} 
                                                        readOnly={props.readOnly} 
                                                        prefix={props.prefix} 
                                                        required={props.required}
                                                        helperText={props.helperText}
                                                        value={props.value}
                                                        onChange={() => {/* direct input not possible */} }
                                                        />   } 
                    
                    />    
            
}
export default DateField;