import React, {useContext}  from 'react';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import PatientList from './PatientList';
import GlobalContext from '../../context/GlobalContext';
import ModalHeader from '../UI/Modal/ModalHeader';
import ModalContent from '../UI/Modal/ModalContent';
import ModalFooter from '../UI/Modal/ModalFooter';


const SearchHelp = (props) =>  {
    const globalContext = useContext(GlobalContext);

    let searchHelp = null;
    if (props.showSearchHelp){
        //patientClickHandler={this.openPatientHandler}
        searchHelp = (
            <Modal show={true}  type='BigModal'>
                <ModalHeader title='Patientensuche' onClose={props.modalClosed}/>
                <ModalContent type="Scrollable">
                    <PatientList 
                        patients={globalContext.patients} 
                        searchHelp={true}
                        patientClickHandler={props.patientClickHandler}    />
                </ModalContent> 
                <ModalFooter>
                    {props.function1}
                    <Button btnType="EditMenuButton" click={props.modalClosed} >Abrechen</Button>
                </ModalFooter>
            </Modal>
        );
    }

    return  searchHelp;
}


export default SearchHelp;