import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Tooltip from '../UI/Tooltip/Tooltip';
import Caption from '../UI/Card/Caption';
import Button from '../UI/Button/Button';
import classes from './Patient.module.css';
import Card from '../UI/Card/Card';
import Input from '../UI/Input/Input';
import Facts from '../UI/Card/Facts';
import DetailContainer from '../Layout/DetailContainer';
import {  FaFileInvoice, FaAddressCard } from "react-icons/fa";

import GlobalContext from '../../context/GlobalContext';
import AppointmentUtil from '../Appointments/AppointmentUtil';




class PatientInvListItem extends Component{
    
    static contextType = GlobalContext;

    state = {
        total : '0.00',
        //oldestAppointment: null,
    }

    render(){  
        const styleConfig = {
            inputStyle: 'InputDetail',
            labelStyle: 'LabelLight',
            inputElementStyle:'InputElementDetail'
        };

        const createButton =  ( <Link  to={{ pathname: '/financeMenu/invoices/new/' + this.props.patient.ID}}>
                                    <Button btnType="TableButton" disabled={false} tooltip="Rechnung erstellen"><FaFileInvoice className={classes.Icon}/></Button>
                                </Link>       );

        const header = (    <Caption > 
                                {createButton}
                                {this.props.patient.displayName } 
                                <Link  to={{ pathname: '/patients/' + this.props.patient.ID}}>
                                    <Tooltip content={'Patientenakte'}>
                                     <FaAddressCard  className={classes.Icon} size={20}/> 
                                    </Tooltip>
                                </Link>
                            </Caption>);
        const configDecimal = { type: 'currency' };

       return(
        <Card >
            {header}   
            <DetailContainer> 
                <Facts>  
                        <Input  key= {'cntReleased'} 
                            elementType= 'input' 
                            styleConfig= {styleConfig} 
                            value={this.state.cnt} 
                            label='Abzurechnende Termine' 
                            readOnly={true} />    
                </Facts>             
                <Facts> 
                    <Input  key= {'total'} 
                            elementType= 'input' 
                            styleConfig= {styleConfig} 
                            elementConfig={configDecimal}
                            value={this.state.total} 
                            label='Betrag' 
                            readOnly={true} />                                                         
                                                           
                </Facts>          
            </DetailContainer> 
        </Card>
        );

    }


    componentDidMount(){

        
        this.initState();
        

    }


    initState(){
        let total = 0; 
        let cnt = 0;
        
        //let oldestAppointment = '9999-12-31';

        let appointments = this.context.getAppointmentForPatient(this.props.patient.ID, AppointmentUtil.C_STAT_RELEASED);
        
        appointments.forEach(appointment => {
            const price = AppointmentUtil.calculatePrice(appointment);
            
            total = total + price;
            cnt = cnt + 1;
            /*
            if (appointment.date < oldestAppointment){
                oldestAppointment = appointment.date;
            }
            */
        });        
        this.setState({
            total: total,
            cnt: cnt
        });
    }
}
export default PatientInvListItem;
