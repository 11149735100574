import React, { useState, useEffect, useRef } from "react";
import "./Tooltip.css";

const Tooltip = (props) => {
  const  mounted = useRef(false);;
  let timeout;
  
  const [active, setActive] = useState(false);

  const showTip = () => {
    //setActive(true);
    timeout = setTimeout(() => {
      if (mounted.current){
        setActive(true);
      }
      
    }, props.delay || 400);
    
  };

  const hideTip = () => { 
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(() => {
    // Update the document title using the browser API
    mounted.current = true;
    return () => {
      mounted.current = false;}
      
  }); 
  

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          {props.content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;