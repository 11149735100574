import React, { useContext } from 'react';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';

import {useHistory} from 'react-router-dom';
import ListMenu from './ListMenu';
import GlobalContext from '../../context/GlobalContext';
import PraxisListItem from './PraxisListItem';

const Praxen = (props) => {

    const history = useHistory();

    const globalContext = useContext(GlobalContext);

    
    const list = globalContext.praxis.map((p, index) => (
        <PraxisListItem
            praxis={p}
            key={p.PRXID}/>
    ))
   
    

    return  <React.Fragment>
                <SubMenuMui  history={history}>
                        <ListMenu     /> 
                </SubMenuMui>   
                <h3>Standorte bearbeiten</h3>
                {list}
            </React.Fragment>

}

export default Praxen;
