import React from 'react';
import InvoiceUtil from '../../Invoices/InvoiceUtil';
import DropDown from './DropDown';


const invoiceTypeConfig = {
        id: "invoiceType",
        options: [  {value: '', label: ''},
                    {value: InvoiceUtil.C_TYPE_PHYSIO, label: InvoiceUtil.C_TYPE_PHYSIO},
                    {value: InvoiceUtil.C_TYPE_HP, label:InvoiceUtil.C_TYPE_HP},
                    {value: InvoiceUtil.C_TYPE_SZ, label:InvoiceUtil.C_TYPE_SZ},
                    {value: InvoiceUtil.C_TYPE_SZ_HP, label:InvoiceUtil.C_TYPE_SZ_HP} ]      
    };


const InvoiceType = (props) =>  {
 
    let value = props.value;
    if (!value){
        value = "";
    }

    let label = "Rechnungstyp";
    
    if (props.label){
        label = props.label;
    }

    invoiceTypeConfig.error = props.error;
    
    return (  <DropDown {...invoiceTypeConfig}
                        label={label}
                        value={props.value}
                        readOnly={props.readOnly}
                        onChange={props.onChange} />
                );
                
}
export default InvoiceType;