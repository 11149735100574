import React from 'react';
import MUIInput from './MUIInput';


const Currency = (props) =>  {


    if (props.readOnly){
        let value = parseFloat(props.value);//.toFixed(2);    
        if (value === 0){
            value = '-';
        }
        else{
        const formatter = new Intl.NumberFormat('de-DE', {
            //style: 'currency',
            //currency: 'EUR',
            // These options are needed to round to whole numbers if that's what you want.
            minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });
            value  = formatter.format(value);
        }
        return ( <MUIInput {...props} value={value} prefix={'€'}/>);
    }  
    else{
        return ( <MUIInput {...props} type='number' prefix={'€'}/>);
    }

    
    


                
}
export default Currency;