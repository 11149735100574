import React, { useContext, useState } from 'react';

import GlobalContext from '../../context/GlobalContext';
import classes from './ServiceCatalogue.module.css';
import InvoiceItems from '../InvoiceItems/InvoiceItems';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import CatalogueMenu from './CatalogueMenu';
import {useHistory} from 'react-router-dom';
import Modal from '../UI/Modal/Modal';
import ModalHeader from '../UI/Modal/ModalHeader';
import ModalContent from '../UI/Modal/ModalContent';
import InvoiceItemCreate from '../InvoiceItems/InvoiceItemCreate';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const ServiceCatalogueMui = (props) => {
    const hist = useHistory();
    const globalContext = useContext(GlobalContext);
    
    const [createInvItem, setCreateInvItem] = useState(false);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    
    const invConfig = {
        reduced: true,
        readOnly: true,
        addButton: true,
        editButton: false,
        onAdd: props.onAdd,
        deletable: false,
        catalogue: true,
        disabledItems: props.disabledItems,
        setup: props.setup
    };    

    let history = null;
    if (props.setup){

        invConfig.addButton = false;
        invConfig.deletable = true;
        invConfig.editButton = true;
        history = hist;

    }

    let createPopup = null;
    if (createInvItem){
      createPopup = (
          <Modal show={createInvItem} modalClosed={() => setCreateInvItem(false)} >
              <ModalHeader onClose={() => setCreateInvItem(false)} title="Neue Leistung Anlegen"></ModalHeader>
              <ModalContent>
                  <InvoiceItemCreate onClose={() => setCreateInvItem(false)}/>
              </ModalContent>
          </Modal>
      );
  }

    return  (
            <React.Fragment>
                { props.setup &&
                   <SubMenuMui  history={history}>
                        <CatalogueMenu    createHandler={() => setCreateInvItem(true)} 
                                          createChainHandler={() => hist.push('/setup/serviceChain/new')}
                        /> 
                    </SubMenuMui>   
                    }
                    {createPopup}


                { props.setup &&
                    <h3>Leistungkatalog</h3>
                }
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs   value={value} 
                                onChange={handleChange} 
                                aria-label="basic tabs example"   
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                >
                            <Tab label="Leist.kette" {...a11yProps(0)} />
                            <Tab label="Physio" {...a11yProps(1)} />
                            <Tab label="HP" {...a11yProps(2)} />
                            <Tab label="SZ" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <div className={classes.CatalogueContent}><InvoiceItems invoiceItems={globalContext.serviceChains} config={invConfig} ></InvoiceItems> </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className={classes.CatalogueContent}><InvoiceItems invoiceItems={globalContext.physioServices} config={invConfig} ></InvoiceItems> </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div className={classes.CatalogueContent}><InvoiceItems invoiceItems={globalContext.hpServices} config={invConfig} ></InvoiceItems> </div>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <div className={classes.CatalogueContent}><InvoiceItems invoiceItems={globalContext.szServices} config={invConfig} ></InvoiceItems> </div>
                    </TabPanel>                
                </Box>  
            </React.Fragment>
        );
    };
    
export default ServiceCatalogueMui;
