import React, {useContext} from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';

import GlobalContext from '../../context/GlobalContext';
import ToggleAppView from './Calendar/ToggleAppView';
import FilterButton from '../MUI/Button/FilterButton';



const ListMenuMui = (props) =>  {
       
    const globalContext = useContext(GlobalContext);


    // Therapeutenfilter
    var thFilter = [];
    for (var i=0, iLen=globalContext.therapeut.length; i<iLen; i++) {   
        const th = globalContext.therapeut[i];
        if (!th.inactive &&  !(th.vorname === '-')){ //- is the dummy PERNR
            var active = null;
            if (globalContext.therapeutFilter.includes(th.PERNR)){
                active = 1;
            }

            const butType = 'TherapistButton';
            const style = {'borderColor' : th.color};

            var button = ( 
                <Button active={active} 
                        btnType={butType}
                        style={style}
                        click={(event) => globalContext.filterTherapeut(event, th.PERNR)}  
                        key={th.PERNR} 
                        >
                    {th.vorname}
                </Button> );                           
            thFilter.push(button);   
        }
    }


    //Statusfilter
    /**/
    let buttonFilter = null;
    if (!props.fieldControls.hideStatusFilter){
        /*
        buttonFilter = (<React.Fragment><li className={classes.EditButton}>
                            <Button active={globalContext.statusFilter !== 0 ? 1 : undefined} btnType="TableButton" click={(event) => globalContext.filterStatus(event)}  >
                                    <BiFilterAlt  className={classes.Icon}/>
                            </Button>
                        </li>
                        <li>
                            <FilterButton  filterOptions={globalContext.appointmentFilter}/>      

                        </li>
                        </React.Fragment>
                        
                        );*/
        buttonFilter = <li><FilterButton  
                                filterOptions={globalContext.appointmentFilter}
                                selectedFilter={globalContext.selectedAppointmentFilter}
                                handleSelect={(index) => globalContext.selectedAppointmentFilterHandler(index)}
                                />  </li>
    }
    
    

    //Patientfilter
    const styleConfig = {
        inputStyle: 'InputDetail', //InvItemInputWrapper',
        labelStyle: 'LabelNone',//InvItemLabel',
        inputElementStyle:'InputElemMenu'//'InvItemInputElement'
    };       

    let toggleView = null;
    if (props.fieldControls.toggelViewHandler && !props.appView){
        toggleView =  <ToggleAppView view={globalContext.appView} onChange={props.fieldControls.toggelViewHandler} />
    }

    return (
        
            <div className={classes.SubMenuContainer}>
                <div className={classes.SubMenuLeft}>
                        {buttonFilter}
                        
                        <Input  elementType={props.fieldControls.patientFilter.elementTyp}
                                elementConfig={props.fieldControls.patientFilter.elementConfig}
                                value={globalContext.patientFilter}
                                styleConfig={styleConfig}
                                changed={(event) => globalContext.filterPatient(event)}/>            
                    
                    <div className={classes.ThFilter}>
                        {thFilter}
                    </div>                    
                </div>
                <div className={classes.SubMenuRight}>
                    {toggleView}            
                </div>
            </div>      
        );
    }

export default ListMenuMui;