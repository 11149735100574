import React, { useContext, useEffect, useState } from 'react';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';

import {useHistory} from 'react-router-dom';
import ListMenu from './ListMenu';
import GlobalContext from '../../context/GlobalContext';
import TaskListItem from './TaskListItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tab, Tabs, Typography } from '@mui/material';
import TaskUtil from './TaskUtil';
import PropTypes from 'prop-types';
import SessionHandler from '../../context/SessionHandler';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



  const getAccordion = (taskList, title, expanded, userNames, therapists, handleExpandChange, expandedProperty) => {

    if (!taskList){
        return null;
    }

    let taskListUI = taskList.map((task, index) => (
        <TaskListItem
            task={task}
            userNames={userNames}
            therapists={therapists}
            key={task.id}/>
    ))
    const taskCnt = taskList.length;
    if (taskCnt === 0){
        taskListUI = <div>Keine Aufgaben</div>;
    }

    return <Accordion expanded={expanded} onChange={handleExpandChange(expandedProperty)}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />} >
                    <Typography><b>{title + ' (' + taskCnt + ')'}</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {taskListUI}
                </AccordionDetails>
            </Accordion>
  }


const Tasks = (props) => {

    const history = useHistory();

    const globalContext = useContext(GlobalContext);


    const [myOpen, setMyOpen] = useState(null);
    const [myProcessed, setMyProcessed] = useState(null);
    const [allOpen, setAllOpen] = useState(null);
    const [allProcessed, setAllProcessed] = useState(null);    


    /* initialize component ********************************************************/
    useEffect(() => {

        const myOpenTmp = [];
        const myProcessedTmp = [];
        const allOpenTmp = [];
        const allProcessedTmp = [];

        for (let index = 0; index < globalContext.tasks.length; index++) {
            const task = globalContext.tasks[index];
            
            if (task.userName === SessionHandler.getUser().userName){
                //task is from the current user
                if (task.status === TaskUtil.C_STAT_OPEN){
                    myOpenTmp.push(task);
                    allOpenTmp.push(task);
                }
                else if(task.status === TaskUtil.C_STAT_PROCESSED){
                    myProcessedTmp.push(task);
                    allProcessedTmp.push(task);
                }
            }
            else{
                if (task.status === TaskUtil.C_STAT_OPEN){
                    allOpenTmp.push(task);
                }
                else if(task.status === TaskUtil.C_STAT_PROCESSED){
                    allProcessedTmp.push(task);
                }            
            }
        }

        setMyOpen(myOpenTmp);
        setMyProcessed(myProcessedTmp);
        setAllOpen(allOpenTmp);
        setAllProcessed(allProcessedTmp);

    }, [globalContext.tasks]);
/*  end initialize component ********************************************************/

    // tab-change
    const handleTabChange = (event, newValue) => {
        
        //store value in globalContext
        const copy = {...globalContext.taskUICT};
        copy.selectedTab = newValue;
        globalContext.setTaskUICT(copy);
      };

    // tab-change
    const handleExpandChange = (panel) => (event, isExpanded) => {
        
        const copy = {...globalContext.taskUICT};

        copy[panel] = isExpanded;


        globalContext.setTaskUICT(copy);

      };      


    return  <React.Fragment>
                <SubMenuMui  history={history}>
                        <ListMenu     /> 
                </SubMenuMui>  
                <h3>Aufgaben</h3>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs   value={globalContext.taskUICT.selectedTab} 
                            onChange={handleTabChange} 
                            aria-label="basic tabs example"   
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            >
                        <Tab label="Meine Aufgaben" {...a11yProps(0)} />
                        <Tab label="Alle Aufgaben" {...a11yProps(1)} />                       
                    </Tabs>
                </Box>
                <TabPanel value={globalContext.taskUICT.selectedTab} index={0}>
                    {getAccordion(myOpen, "Offene Aufgaben", globalContext.taskUICT.myOpenExpanded, globalContext.userNames, globalContext.therapeut, handleExpandChange, "myOpenExpanded")}
                    {getAccordion(myProcessed, "Bearbeitete Aufgaben", globalContext.taskUICT.myProcessedExpanded, globalContext.userNames, globalContext.therapeut, handleExpandChange, "myProcessedExpanded")}
                </TabPanel>
                <TabPanel value={globalContext.taskUICT.selectedTab} index={1}>
                    {getAccordion(allOpen, "Offene Aufgaben", globalContext.taskUICT.allOpenExpanded, globalContext.userNames, globalContext.therapeut, handleExpandChange, "allOpenExpanded")}
                    {getAccordion(allProcessed, "Bearbeitete Aufgaben",  globalContext.taskUICT.allProcessedExpanded, globalContext.userNames, globalContext.therapeut, handleExpandChange, "allProcessedExpanded")}
                </TabPanel>
                </Box>                    
                   
            </React.Fragment>
}

export default Tasks;
