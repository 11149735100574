
import React from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import GlobalContext from '../../context/GlobalContext';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useState } from 'react';
import DetailContainer from '../Layout/DetailContainer';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import AppQueueMenu from './AppQueueMenu';
import { useMemo } from 'react';
import MUIPatientInput from '../MUI/MUIInput/MUIPatientInput';
import DateField from '../MUI/DateField/DateField';
import DateUtility from '../../hoc/DateUtility';
import TheraCalendars from '../Therapist/TheraCalendar/TheraCalendars';
import TherapistChipList from '../Therapist/TherapistChipList/TherapistChipList';
import DayList from './DayList';

const AppQueueItem = (props) => {

    const history = useHistory();
    const { appQueueId, id } = useParams();
    const globalContext = useContext(GlobalContext);
    
    const [appQueueItem, setAppQueueItem] = useState(null);
    const [patient, setPatient] = useState(null);   
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');



    /* initialize component ********************************************************/  
    useMemo(() => {

        if (props.new){
            let patient = null;
            if (id){
                patient = globalContext.getPatient(id);
            }
            else{
                patient = {
                    ID: null,
                    displayName: '' };
            }

            const a = {
                patientID: patient.ID,
                validTo: DateUtility.getISODate(DateUtility.addDays(new Date(), 20)),
                days: [
                    {day: 1, active: true, from: 8, to: 18},
                    {day: 2, active: true, from: 8, to: 18},
                    {day: 3, active: true, from: 8, to: 18},
                    {day: 4, active: true, from: 8, to: 18},
                    {day: 5, active: true, from: 8, to: 18}
                ],
                therapists: [],
                praxis: []
            }
            //default: all therapists
            for (let i=0; i < globalContext.therapeut.length; i++){
                if (!globalContext.therapeut[i].inactive && !(globalContext.therapeut[i].vorname === '-'  )){
                    const newItem = { PERNR: globalContext.therapeut[i].PERNR }
                    a.therapists.push(newItem);
                }
            }
            //default all praxis
            for (let i=0; i < globalContext.praxis.length; i++){
                if (!globalContext.praxis[i].inactive){
                    const newItem = { PRXID: globalContext.praxis[i].PRXID }
                    a.praxis.push(newItem);
                }
            }


            setPatient(patient);
            setAppQueueItem(a);

        }
        else{
            const a = globalContext.getAppQueueItem(appQueueId);
            if (a){
                const p = globalContext.getPatient(a.patientID);
                setAppQueueItem(a);
                setPatient(p);
            }
            else{
                //?? error ?? 
                console.log("error - no appQueueItem found:" + appQueueId);
            }
        }

      }, []);    
    /***************************************************************************** */


    const saveHandler = () => {
        if (props.new){
            createHandler();
        }
        else{
            updateHandler();
        }
    }

    const updateHandler = () => {
        setLoading(true);
        axios.put('/appQueue/' + appQueueItem.id, appQueueItem)
        .then(response => {
            setLoading(false);
            const a = response.data.data;

            setAppQueueItem(a);
            globalContext.updateAppQueueItem(a);
        })
        .catch (error => {
            setLoading(false);
            setErrorResponse(error.response);
            setErrorTitle('Fehler beim Speichern');
            setShowError(true);
        });
    }


    const createHandler = () => {
        setLoading(true);
        axios.post('/appQueue', appQueueItem)
        .then(response => {
            setLoading(false);
            const a = response.data.data;

            setAppQueueItem(a);
            globalContext.updateAppQueueItem(a);
            history.replace('/appQueue/' + a.id );
        })
        .catch (error => {
            setLoading(false);
            setErrorResponse(error.response);
            setErrorTitle('Fehler beim Anlegen des neuen Wartelisteneintrags');
            setShowError(true);
        });
    }

    const deleteHandler = () => {
            setLoading(true);
            axios.delete('/appQueue/' + appQueueItem.id)
            .then(response => {
                setLoading(false);
                globalContext.deleteAppQueueItem(appQueueItem.id);
                const message = {
                    type: 'S',
                    text: 'Wartelisteneintrag gelöscht'
                }
                globalContext.showMessage(message);                             
                history.goBack();
            })
            .catch (error => {
                setLoading(false);
                setErrorResponse(error.response);
                setErrorTitle('Fehler beim Löschen des Wartelisteneintrags');
                setShowError(true);
            });
        }   

    const handleChangeTheraCalender = (PRXID, add) => {
        const updatedForm = { ...appQueueItem };
        let newList = [];
        if (add){
            // old list + new entry
            newList = updatedForm.praxis;
            const newItem = { PRXID: PRXID }
            newList.push(newItem);
        }
        else{
            //remove: old list - this entry
            for (let i = 0; i <  updatedForm.praxis.length; i++ ){

                if (parseInt(updatedForm.praxis[i].PRXID) !== parseInt(PRXID)){
                    newList.push(updatedForm.praxis[i]);
                }
            }
        }
        updatedForm.praxis = newList;
        setAppQueueItem(updatedForm);
    }

    const handleChangeTherapists = (PERNR, add) => {
        const updatedForm = { ...appQueueItem };
        let newList = [];
        if (add){
            // old list + new entry
            newList = updatedForm.therapists;
            const newItem = { 
                                PERNR : PERNR
                             }
            newList.push(newItem);
        }
        else{
            //remove: old list - this entry
            for (let i = 0; i <  updatedForm.therapists.length; i++ ){

                if (parseInt(updatedForm.therapists[i].PERNR) !== parseInt(PERNR)){
                    newList.push(updatedForm.therapists[i]);
                }
            }
        }

        updatedForm.therapists = newList;

        setAppQueueItem(updatedForm);

    }       
    
    

    const selectPatientHandler = (patientId) => {

        const a = {...appQueueItem};
        a.patientID = patientId;

        const p = globalContext.getPatient(patientId);
        setAppQueueItem(a);
        setPatient(p);
    }

    const changeHandler = (value, field) => {
            
            const a = {...appQueueItem};

            a[field] = value;
            setAppQueueItem(a);
        }   

    const changeDayHandler = (day) => {
        
        const a = {...appQueueItem};

        a.days[day.day - 1] = day;

        setAppQueueItem(a);
    }           
    
    const changeDateHandler = (event, field) => {
            
            const a = {...appQueueItem};
            a[field] = DateUtility.getISODate(new Date(event));
            setAppQueueItem(a);
        }
    
    let content = null;

    if (appQueueItem){
        content = <React.Fragment>
                    <h1>Wartelisteneintrag bearbeiten</h1>
                    <ApiErrorMessage    show={showError} 
                                    modalClosed={() => { setShowError(false) }} 
                                    errorResponse={errorResponse} 
                                    title={errorTitle}
                                    />    
                    <DetailContainer> 
                        <FactsMui>  
                        <MUIPatientInput    id={'patientID'} 
                                            label="Patient"
                                            required={true} 
                                            patientID={appQueueItem.patientID}
                                            value={patient.displayName} 
                                            selectPatientHandler={(patientId) => selectPatientHandler(patientId)}
                                            />   
                        <DateField          label='gültig bis'
                                            value={appQueueItem.validTo}
                                            key={'validTo'}
                                            required
                                            onChange={(event) => changeDateHandler(event, 'validTo')}       
                                    />  
                        </FactsMui>
                        <FactsMui>  
                            <MUIInput id={'comment'}
                                    label='Anmerkung'
                                    value={appQueueItem.comment}
                                    multiline
                                    minRows={3}
                                    onChange={(event) => changeHandler(event.target.value, 'comment')} 
                                    />    

                        </FactsMui>
                    </DetailContainer>
                    <DetailContainer> 
                        <FactsMui>                                            
                            <DayList    appQueueItem={appQueueItem}
                                        changeDayHandler={changeDayHandler}/>   
                            <TherapistChipList  therapists={appQueueItem.therapists}            
                                                handleChangeTherapists={handleChangeTherapists}/> 
                            <TheraCalendars     theraCalendars={appQueueItem.praxis} 
                                                handleChangeTheraCalender={handleChangeTheraCalender}/>  
                        </FactsMui>
                    </DetailContainer>
                </React.Fragment>


 
    }

    return <React.Fragment>
                <Modal show={loading}><Spinner/></Modal>
                <SubMenuMui  history={history}>
                        <AppQueueMenu   saveHandler={() => saveHandler()}
                                        deleteHandler={() => deleteHandler()}
                                        patient={patient}  /> 
                </SubMenuMui>   
                {content}
            </React.Fragment>    
}

export default AppQueueItem;