import React from 'react';
import classes from './Tile.module.css';


const TileContainer = (props) => {


    return (
        <div className={classes.TileContainer}>
            {props.children}
        </div>
    );
    }

export default TileContainer;