import React, {  useContext, useState, useEffect }  from 'react';
import Button from '../../UI/Button/Button';
import GlobalContext from '../../../context/GlobalContext';
import Modal from '../../UI/Modal/Modal';
import axios from 'axios';
import classes from './AppointmentSearch.module.css';
import ModalContent from '../../UI/Modal/ModalContent';
import ModalFooter from '../../UI/Modal/ModalFooter';
import ModalHeader from '../../UI/Modal/ModalHeader';
import FreeBusyList from './FreeBusyList';
import Calendar from '../Calendar/Calendar';
import Appointments from '../Appointments';
import ToggleAppView from '../Calendar/ToggleAppView';
import AppointmentUtil from '../AppointmentUtil';



const AppCalSearchPopup = (props) => {

    const [freeTimeSlots, setFreeTimeSlots] = useState([]);
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState(Appointments.AGENDA_VIEW);
    
    
    
    const globalContext = useContext(GlobalContext);

    //Read freebusy data                                                        
    useEffect(() => {
        let mounted = true;
        axios.get('/timeslot/' + props.appointment.therapeutID + '/praxis/'+ props.appointment.praxisID )
        .then (response => {
            if (mounted){
                const slots = response.data.data;
                setFreeTimeSlots( slots );
                setLoading(false);
            }   
        })
        .catch (error => {
            console.log(error);
            if (mounted){            
                setLoading(false);
            }
        });
        return () => {
            // cancel the subscription
            mounted = false;
        };

    }, [props.appointment.therapeutID, 
        props.appointment.praxisID, ]);

    const prxIndex =  globalContext.praxis.findIndex((obj => obj.PRXID === parseInt(props.appointment.praxisID)));  

    let title = 'Terminsuche: ' + globalContext.getTherapist(parseInt(props.appointment.therapeutID)).vorname;
    title = title + '/ ' + globalContext.praxis[prxIndex].PRAXIS_NAME;

    let searchComponent = null;
    if (view === Appointments.CALENDAR_VIEW ){
        // get filtered appointment list
        const apps = [];
        const appointments = globalContext.appointments;
        for (let i = 0; i < appointments.length; i++){
            const app = appointments[i];
            if (parseInt(app.therapeutID) === parseInt(props.appointment.therapeutID) && 
                parseInt(app.praxisID)  === parseInt(props.appointment.praxisID) &&
                app.appType !== AppointmentUtil.C_TYPE_ATTENDANCE ){
                apps.push(app);
            }
        }   

        searchComponent = <Calendar {...props} 
                                    appointments={apps}
                                    freeTimeSlots={freeTimeSlots} 
                                    loading={loading}/>;
    }
    else{
        searchComponent = <FreeBusyList  {...props} freeTimeSlots={freeTimeSlots} loading={loading}/>
    } 
    
    return  <Modal show={true}  type='FullWidth'>
                <ModalHeader title={title} onClose={props.modalClosed}>
                    <div className={classes.SwitchContainer}>
                        <ToggleAppView view={view} onChange={(event, newView) => setView(newView)} />
                    </div>
                </ModalHeader>
                <ModalContent type="Scrollable">
                    {searchComponent}
                </ModalContent> 
                <ModalFooter>
                    <Button btnType="EditMenuButton" click={props.modalClosed} >Abrechen</Button>
                </ModalFooter>
            </Modal>;
    
}

export default AppCalSearchPopup;