
import React from 'react';
import classes  from './AppointmentLink.module.css';



import StatusIcon from '../Status/StatusIcon';
import { Link } from 'react-router-dom';

const AppointmentLink  = (props) => { 

    let datum = props.appointment.date;
    if (props.invoiceDate && props.appointment.invoiceDate){

        datum = props.appointment.invoiceDate;
    }

    const options = {  weekday: 'short' , year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date( datum);

    let printDate = date.toLocaleDateString('de-DE', options);
    if (props.invoiceDate && props.appointment.invoiceDate){
        printDate = printDate + '*';
    }


    return  <div className={classes.DateContainer}>
                <StatusIcon value={props.appointment.statusText} lookupValue={props.appointment.statusID}/>
                <Link to={{ pathname: '/appointments/' + props.appointment.ID}}>
                    {printDate}
                </Link>    
            </div>
}

export default AppointmentLink;

