import React from 'react';
import { EditorState, ContentState,  convertToRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import classes from './RichText.module.css';
import  '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


class RichText extends React.Component {
    
    constructor(props) {
        super(props);
        let editorState = EditorState.createEmpty();
        var html = props.htmlText ;
        if (html){
            html = this.translateAccessFont(html);
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
              editorState = EditorState.createWithContent(contentState);
            }    
        }
        this.state =  { editorState: editorState,
                        html: html
        };
    }

    translateAccessFont(html){
        var translated = html.replace(/<font color=red>\b([^<>]*)<\/font>/i, '<span style="color: rgb(209,72,65);">$1</span> ');
        return translated;
    }
    
    onEditorStateChange = (editorState) => {
        var htmlText = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        this.setState({
        
            editorState: editorState,
            html: htmlText
          });
        this.props.onChange(htmlText, this.props.elemID);
      };   
      
    render(){
        var richTextArea = null;
        if (this.props.readOnly){
            richTextArea = ( 
                <div className={classes.RichText} contentEditable={false} dangerouslySetInnerHTML={this.createMarkup(this.props.htmlText)} ></div>
            );
        }




        else{
            richTextArea = ( 
                    <Editor editorState={this.state.editorState}
                        wrapperClassName={classes.Wrapper}
                        editorClassName={classes.Editor}
                        toolbarClassName={classes.Toolbar}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbarHidden={this.props.toolbarHidden}
                        toolbar={{options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                                inline: { inDropdown: false, 
                                            options: ['bold', 'italic', 'underline', 'strikethrough'], },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true }}}        
                        />  
            );
        }
        return (  
            <fieldset>
            <legend>{this.props.label}</legend>
                {richTextArea} 
            </fieldset> );
    }

     createMarkup(htmlText) {
      return {__html: htmlText};
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.htmlText !== prevProps.htmlText  && 
            this.props.htmlText!== this.state.html ){
            //console.log('update EditorState');
            var html = this.props.htmlText ;
            let editorState = EditorState.createEmpty();
            if (html){
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                editorState = EditorState.createWithContent(contentState);
                }        
            }
            this.setState({ editorState: editorState,
                            html : html});
        }
        

    }
}


export default RichText;

