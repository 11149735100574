import React from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import {Link} from 'react-router-dom';
import { MdAddCircle } from "react-icons/md";
import { FaBirthdayCake } from 'react-icons/fa';
import { BiFilterAlt } from "react-icons/bi";
import Auxiliary from '../../hoc/Auxiliary';
import SessionHandler from '../../context/SessionHandler';


const listMenu = (props) =>  {
        

    //Patientfilter
    const styleConfig = {
        inputStyle: 'InputDetail', //InvItemInputWrapper',
        labelStyle: 'LabelNone',//InvItemLabel',
        inputElementStyle:'InputElemMenu'//'InvItemInputElement'
    };       

    var create = null;
    let birthdayFilter = null;
    let invoicingFilter = null;


    if (props.invoicing){
        //to be invoiced list
        invoicingFilter = (  <li className={classes.EditButton}>
            <Button active={props.invoicingFilter ? 1 : undefined} 
                    btnType="TableButton" 
                    click={(event) => props.filterInvoicing()}
                    tooltip="Abzurechnende Patienten"  >
                        <BiFilterAlt  />
            </Button>
        </li>);
    }
    else {
        //patient list
        if (!props.searchHelp){
            create = (  <li className={classes.SubMenuButton}>
                            <Link to={{ pathname: '/patients/new/'}}>
                                <Button btnType="EditMenuButton" disabled={false} ><MdAddCircle className={classes.Icon}/>Neu</Button>
                            </Link>
                        </li>);
        }
        
        
        if (SessionHandler.authSensitiveData() && !props.searchHelp){
            birthdayFilter = (  <li className={classes.EditButton}>
                                    <Button active={props.birthdayFilter ? 1 : undefined} 
                                            btnType="TableButton" 
                                            click={(event) => props.filterBirthday()}  >
                                                <FaBirthdayCake  />
                                    </Button>
                                </li>);
        }
    }
  
    return (
        <Auxiliary >
            {birthdayFilter}
            <li className={classes.SubMenuInput}>
                <Input  elementType={props.controls.filter.elementTyp}
                        elementConfig={props.controls.filter.elementConfig}
                        value={props.controls.filter.value}
                        styleConfig={styleConfig}
                        changed={(event) => props.changed(event)}/>            
            </li>
            {create}
            {invoicingFilter}
        </Auxiliary>
        );
    }

export default listMenu;