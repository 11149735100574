import React from 'react';

import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import Auxiliary from '../../../hoc/Auxiliary';
import Backdrop from '../../Layout/Backdrop';
import classes from './SideDrawer.module.css';

const sideDrawer = (props) => {

    let  attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open){
        attachedClasses =  [classes.SideDrawer, classes.Open];
    }

    return(
        <Auxiliary>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')}>
                <div className={classes.Logo}>
                    <Logo/>
                </div>
                <nav>
                    <NavigationItems clicked={props.closed}/>
                </nav>
            </div>
        </Auxiliary>
    );

};

export default sideDrawer;