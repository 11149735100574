import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import classes from './Button.module.css';


const Button = (props) => {
    
    var classNames = classes.Button;
    let button = null;

    if (props.active){
        classNames = [classNames, classes[props.btnType], classes['active']].join(' ');
    }
    else{
        classNames = [classNames, classes[props.btnType]].join(' ');
    }


    button = <button className={classNames}
                        onClick={props.click}
                        active={props.active}
                        disabled={props.disabled}
                        style={props.style}
                        >
                            {props.children}
                    </button> ;

    if (props.tooltip && !props.disabled ){
        button = (  <Tooltip content={props.tooltip}> 
                        {button}        
                    </Tooltip>  );
    }
    return button;
    }


export default Button;

