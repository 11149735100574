import React from 'react';
import classes from './Card.module.css';


const card = (props) => {

    const css = [classes.Card];
    if (props.style){
        css.push(props.style)    ;
    }
    

    return (
        <div className={css.join(' ')} style={props.cssStyle}>
            {props.children}
        </div>
    );
    }

export default card;