import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import PatientDetail from './PatientDetail';
import Input from '../UI/Input/Input';
import DetailContainer from '../Layout/DetailContainer';
import RichText from '../UI/RichText/RichText';
import Email from '../UI/Email/Email';
import SessionHandler from '../../context/SessionHandler';
import { RiErrorWarningLine  } from  "react-icons/ri";
import DropDown from '../MUI/DropDown/DropDown';
import classes from './Patient.module.css';

import MUIInput from '../MUI/MUIInput/MUIInput';
import FactsMui from '../UI/Card/FactsMui';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import DateField from '../MUI/DateField/DateField';
import MuiPhone from '../MUI/MUIInput/MuiPhone';
import CheckBox from '../MUI/MUIInput/CheckBox';
import Tooltip from '../UI/Tooltip/Tooltip';

//static config 
const config = {
    styleConfig: {
        inputStyle: 'InputDetail',
        labelStyle: 'LabelLight',
        inputElementStyle:'InputElementDetail'
    },
    styleConfigColumn: {
        inputStyle: 'InputDetailColumn',
        labelStyle: 'LabelLight',
        inputElementStyle:'InputElementDetailColumn'
    },    
    originCodeConfig: {
        options: [
            {value: '', label: ''},
            {value: 'Arzt', label: 'Arzt'},
            {value: 'Patient', label: 'Patient'},
            {value: 'Internet', label: 'Internet'},
            {value: 'Heb.Praxis', label: 'Heb.Praxis'},
            {value: 'Sonstige', label: 'Sonstige'},
        ] },
    invoiceDelivery:{
        id: "invoiceDelivery",
        label: "" ,
        options: [  {value: '', label: ''},
                    {value: 'post', label: 'Post'},
                    {value: 'email', label: 'E-Mail'},
                ]
    } ,            
    photoAgreement:{
        id: "photoAgreement",
        label: "" ,
        options: [ {value: false, label: 'Nein'},
                    {value: true, label: 'Ja'},
                ]
    } ,                
    birthdayConfig: { type: 'date' },
    dataProtectionConfig: { type: 'date' },
    followUpConfig: { type: 'date' }
};   

const PatientDetailViewMui = (props) =>  {
    
    //var cntOpen = parseInt(props.patient.cntDraft, 10) + parseInt(props.patient.cntReleased, 10) ;
    
    //const navigateToPrescription = useCallback(() => history.push('/prescriptions/' + props.value ), [history, props.value]);

    let prescription = null;
    if (props.activePrescription){
        var preResult = PatientDetail.formatPrescription(props.activePrescription);
        var preLabel = 'Rezept ('.concat(props.activePrescription.ID).concat(')');
        prescription = ( <Input key= 'prescription' 
                                elementType= 'input' 
                                styleConfig= {config.styleConfig} 
                                value={props.activePrescription.ID + ' ' + preResult + ' (h)'} 
                                label={preLabel} 
                                readOnly={true} />);

        
        prescription = <MUIInput  id='prescription' 
                                  readOnly
                                  value={props.activePrescription.ID + ' ' + preResult + ' (h)'} 
                                  label='Rezept'

                             />

    }   

    let displayname = props.patient.displayName;
    let helper = '';
    
    if (!props.patient.dataProtectionAgreement && props.patient.ID){

        displayname = ( <Tooltip content="Behandlungsvertrag und Datenschutz fehlen!">
                            {props.patient.displayName}
                            <RiErrorWarningLine className={classes.IconRed}/> 
                        </Tooltip> );

        helper = "Behandlungsvertrag und Datenschutz fehlen!";
    }

    return (
        <Auxiliary>
            <address>
                <div className={classes.Title}>{displayname}</div>
            </address>  
            <DetailContainer>
                <FactsMui>
                    <h5>Patientendaten</h5>        
                    <MUIInput   id={PatientDetail.TITLE}     
                                onChange={(event) => props.onChange(event, PatientDetail.TITLE)} 
                                value={props.patient.title} 
                                label='Titel' 
                                readOnly={props.readOnly} 
                            />
                    <MUIInput   id={PatientDetail.FIRST_NAME}     
                                onChange={(event) => props.onChange(event, PatientDetail.FIRST_NAME)} 
                                value={props.patient.firstName} 
                                label='Vorname' 
                                readOnly={props.readOnly} 
                                required
                            />     
                    <MUIInput   id={PatientDetail.LAST_NAME}     
                                onChange={(event) => props.onChange(event, PatientDetail.LAST_NAME)} 
                                value={props.patient.lastName} 
                                label='Nachname' 
                                readOnly={props.readOnly} 
                                required
                            />      

                    <DoubleField>
                        <DateField  label='Geburtstag'
                                        value={props.patient.birthday} 
                                        readOnly={props.readOnly} 
                                        onChange={(event) =>  props.onChange(event, PatientDetail.BIRTHDAY)}
                                /> 
                        <MUIInput   id={PatientDetail.INSURANCE}     
                                    onChange={(event) => props.onChange(event, PatientDetail.INSURANCE)} 
                                    value={props.patient.insurance} 
                                    label='Krankenkasse' 
                                    readOnly={props.readOnly} 
                                />  
                    </DoubleField>   
                    <h5>Kontaktdaten/Kommunikation</h5>         
                    <DoubleField>
                        <MuiPhone   id={PatientDetail.PHONE} 
                                    value={props.patient.phone} 
                                    label='Telefonnummer' 
                                    requrired={true}
                                    readOnly={props.readOnly}
                                    onChange={(event) => props.onChange(event, PatientDetail.PHONE)} 
                                />  
                        <MuiPhone   id={PatientDetail.PHONE2} 
                                    value={props.patient.phone2} 
                                    label='Telefonnummer (2)' 
                                    requrired={false}
                                    readOnly={props.readOnly}
                                    onChange={(event) => props.onChange(event, PatientDetail.PHONE2)} 
                                />                                                                                                                          
                    </DoubleField>
                    <Email idx= {PatientDetail.EMAIL}                             
                            styleConfig= {config.styleConfigColumn} 
                            onChange={(event) => props.onChange(event, PatientDetail.EMAIL)} 
                            value={props.patient.email} 
                            label='E-Mail' 
                            readOnly={props.readOnly} 
                            requrired={true}
                            />
                            
                    <Email idx= {PatientDetail.EMAIL2}                            
                            styleConfig= {config.styleConfigColumn} 
                            onChange={(event) => props.onChange(event, PatientDetail.EMAIL2)} 
                            value={props.patient.email2} 
                            label='E-Mail (2)' 
                            readOnly={props.readOnly} />    
                    <DoubleField>
                            <DropDown {...config.invoiceDelivery}
                                    value={props.patient.invoiceDelivery}
                                    readOnly={props.readOnly}
                                    label="Rechnungsversand"
                                    onChange={(event) => props.onChange(event, PatientDetail.INVOICE_DELIVERY)} />

                            <CheckBox    checked={props.patient.emailNotification} 
                                        readOnly={props.readOnly}
                                        disabled={props.readOnly}
                                        label="Erinnerungs-E-Mail" 
                                        onChange={ (event) =>  props.onChange(event, PatientDetail.EMAIL_NOTIFICATION) }
                            />  
                    </DoubleField>                                            

                </FactsMui>                                        
                <FactsMui >
                    <h5>Adresse</h5>   
                    <MUIInput   id={PatientDetail.ADDRESS}     
                                    onChange={(event) => props.onChange(event, PatientDetail.ADDRESS)} 
                                    value={props.patient.address} 
                                    label='Strasse/Hausnummer' 
                                    readOnly={props.readOnly} 
                                />                      
                    <DoubleField>
                        <MUIInput   id={PatientDetail.POSTCODE}     
                                        onChange={(event) => props.onChange(event, PatientDetail.POSTCODE)} 
                                        value={props.patient.postCode} 
                                        label='Postleitzahl' 
                                        readOnly={props.readOnly} 
                                    />   
                        <MUIInput   id={PatientDetail.CITY}     
                                        onChange={(event) => props.onChange(event, PatientDetail.CITY)} 
                                        value={props.patient.city} 
                                        label='Ort' 
                                        readOnly={props.readOnly} 
                                    />    
                                                                                                                    
                    </DoubleField>
                    <MUIInput   id={PatientDetail.COUNTRY}     
                                            onChange={(event) => props.onChange(event, PatientDetail.COUNTRY)} 
                                            value={props.patient.country} 
                                            label='Land' 
                                            readOnly={props.readOnly} 
                                        />   
                    <MUIInput   id={PatientDetail.BILL_TO_NAME}     
                                        onChange={(event) => props.onChange(event, PatientDetail.BILL_TO_NAME)} 
                                        value={props.patient.billToName} 
                                        label='Name Rechnungsempfänger' 
                                        readOnly={props.readOnly} 
                                    />                      
                    
                    <h5>Infos/Abrechnung/Vertrag</h5>   
                    <DoubleField>
                        <MUIInput   id={PatientDetail.SHEET_NO}     
                                            onChange={(event) => props.onChange(event, PatientDetail.SHEET_NO)} 
                                            value={props.patient.sheetNo} 
                                            label='Lakennummer' 
                                            readOnly={props.readOnly} 
                                        />     
                        {prescription}
                    </DoubleField>  
                    <DoubleField>
                        <DropDown   options={config.originCodeConfig.options}
                                    value={props.patient.originCode}
                                    readOnly={props.readOnly}
                                    onChange={(event) => props.onChange(event, PatientDetail.ORIGIN_CODE)}
                                    label='Herkunft/Empfehlung'   />
                        <MUIInput   id={PatientDetail.ORIGIN_TEXT}     
                                            onChange={(event) => props.onChange(event, PatientDetail.ORIGIN_TEXT)} 
                                            value={props.patient.originText} 
                                            label='Freitext' 
                                            readOnly={props.readOnly}     />                                 

                    </DoubleField>
                    <DoubleField>
                        <CheckBox    checked={props.patient.invoicing} 
                                    readOnly={props.readOnly}
                                    disabled={props.readOnly}
                                    label="Patienten abrechnen" 
                                    onChange={ (event) =>  props.onChange(event, PatientDetail.INVOICING) }
                                />  
                    <DateField  label='Wiedervorlage Abrechnung'
                                        value={props.patient.followUpDate} 
                                        readOnly={props.readOnly} 
                                        onChange={(event) => props.onChange(event, PatientDetail.FOLLOW_UP_DATE)}
                                /> 
                    </DoubleField>
                    <DoubleField>
                    <CheckBox    checked={props.patient.photoAgreement} 
                                        readOnly={props.readOnly}
                                        disabled={props.readOnly}
                                        label="Einverständnis Foto" 
                                        onChange={ (event) =>  props.onChange(event, PatientDetail.PHOTO_AGREEMENT) }
                                    />  
                        
                        <DateField  label={'Vetragsdatum'}
                                        value={props.patient.dataProtectionAgreement} 
                                        readOnly={props.readOnly} 
                                        onChange={(event) => props.onChange(event, PatientDetail.DATA_PROTECTION_DATE)}
                                        helperText={helper}
                                                                       />    
                        
                       

                               
                            
                    </DoubleField> 
             
                </FactsMui>  
            </DetailContainer>       
            <p></p>
            {SessionHandler.authSensitiveData() &&
                <RichText elemID={PatientDetail.NOTE} htmlText={props.patient.note} label='Erstanamnese' readOnly={props.readOnly} onChange={props.richTextChangeHandler}/>            
            }
            </Auxiliary>

    );
}

export default PatientDetailViewMui;