import React, { useState } from 'react';
import Input from '../UI/Input/Input';
import classes from './InvoiceItems.module.css';
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdAddCircle } from "react-icons/md";
import {useHistory} from 'react-router-dom';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import MUIInput from '../MUI/MUIInput/MUIInput';
import Currency from '../MUI/MUIInput/Currency';
import { IconButton } from '@mui/material';
import {  FaSave } from "react-icons/fa";
import { AiTwotoneEdit } from "react-icons/ai";
import { IoClose } from "react-icons/io5";


const InvoiceItemPlain = (props) => {


/***********************************************************************************************************************************************************
 * Config
 */
 const controls = {
    currency: {
        elementConfig: {
            type: 'currency',
        },
    },
    anzahl: {
        elementConfig: {
            type: 'number',
            step: '1'
        }
    }
};         
/*
const styleConfig = {
    inputStyle: 'InputDetail', //InvItemInputWrapper',
    labelStyle: 'LabelLight',//InvItemLabel',
    inputElementStyle:'InputElementDetail'//'InvItemInputElement'
};     
*/       

const styleConfigLabelNone = {
    inputStyle: 'InputDetail', //InvItemInputWrapper',
    labelStyle: 'LabelNone',//InvItemLabel',
    inputElementStyle:'InputElementDetail4'//'InvItemInputElement'
};         

const styleConfigLabelMobileOnly = {
    inputStyle: 'InputDetail', //InvItemInputWrapper',
    labelStyle: 'LabelLightMobileOnly',//InvItemLabel',
    inputElementStyle:'InputElementDetail3'//'InvItemInputElement'
};  



/***********************************************************************************************************************************************************
* Buttons
*/
    //render delete button in case of not readOnly

    // state for managing the confirmation popup visiblity
    const [showConfirmation, setShowConfirmation] = useState(false);

    const history = useHistory();
    //const navigateToServiceChain = useCallback(() => history.push('/setup/serviceChain/' + props.invoiceItem.ID ), [history, props.value]);


    let confirmationPopup = null;
    if (showConfirmation){
        
        const message = 'Willst du die Leistung "' +   props.invoiceItem.Einzelleistung + '" wirklich löschen?';
        confirmationPopup = (  <YesCancelModal  clickYes={() => {  setShowConfirmation(false);
                                                                   props.onDelete(props.invoiceItem.LEISTUNG)}} 
                                                clickCancel={() => setShowConfirmation(false)}
                                                title="Abfrage" >{message}</YesCancelModal>  );
    }

    
    var addButton = null;
    if (props.addButton){
        var disabled = false;
        if (props.disabledItems){
            var objIndex = props.disabledItems.findIndex((obj => obj.LEISTUNG === props.invoiceItem.ID));              
            if (objIndex >= 0){ disabled = true };
        }

        addButton = (
            <IconButton onClick={() => props.onAdd(props.invoiceItem)} disabled={disabled}><MdAddCircle  className={classes.Icon}/></IconButton>
        );
    }

    const handleEdit = () =>{
        if (props.invoiceItem.LK){
            //service chain
            history.push('/setup/serviceChain/' + props.invoiceItem.ID )
        }
        else{
            //single item
            props.setReadOnly(false);
        }
    }

    let editButton = null;
    let deleteDisabled = false;
    if (props.editButton ){
        if (props.readOnly){
            editButton = <IconButton onClick={() => handleEdit()} > <AiTwotoneEdit /> </IconButton>    ;
        }
        else{
            editButton = <div>
                            <IconButton onClick={() => { props.onSave() }} > <FaSave /> </IconButton>  
                            <IconButton onClick={() => { props.setReadOnly(true); 
                                                         props.onReset()}} > 
                                <IoClose /> 
                            </IconButton>  
                        </div>  ;
            deleteDisabled = true;
        }
    }

    var deleteButton = null;
    if (props.deletable ){
        deleteButton = (
            <IconButton onClick={() => setShowConfirmation(true)}  disabled={deleteDisabled}><RiDeleteBin6Line  className={classes.Icon}/></IconButton>
            
        );
    }        

    //console.log(props.invoiceItem);


    let ziffer = null;
    let einzelleistung = null;
    let count = null;
    let singleAmount = null;
    let totalAmount = null;
    let sortId = null;
    if (props.readOnly ){
        // don't use MUI due to performance
        
            ziffer =  <Input  label='Ziffer' 
                                styleConfig= {styleConfigLabelMobileOnly} 
                                value={props.invoiceItem.ZIFFER}   
                                readOnly={props.readOnly} />;
        
        

        einzelleistung =   <Input  styleConfig= {styleConfigLabelNone}   
                                        value={props.invoiceItem.Einzelleistung}   
                                        readOnly={props.readOnly} />
        const anzahl =     props.invoiceItem.ANZAHL + " x";                       
        count =   <Input label='Anzahl' 
                        styleConfig= {styleConfigLabelMobileOnly} 
                        
                        value={anzahl}   
                        readOnly={props.readOnly} />;
        
        singleAmount = <Input label='Einzelpreis' 
                            styleConfig= {styleConfigLabelMobileOnly} 
                            elementConfig={controls.currency.elementConfig} 
                            value={props.invoiceItem.PREIS}   
                            readOnly={props.readOnly} /> ;
        totalAmount  =  <Input label='Gesamtpreis' 
                            styleConfig= {styleConfigLabelMobileOnly} 
                            elementConfig={controls.currency.elementConfig} 
                            value={props.invoiceItem.ANZAHL * props.invoiceItem.PREIS}   
                            readOnly={true} />      
        sortId  =   <Input label='Sortierung' 
                        styleConfig= {styleConfigLabelMobileOnly} 
                        elementConfig={controls.anzahl.elementConfig} 
                        value={props.invoiceItem.ORDNR}   
                        readOnly={props.readOnly} />;
    }
    else{
        ziffer = <MUIInput   id="ziffer"
                    label="Ziffer"
                    value={props.invoiceItem.ZIFFER} 
                    fullWidth={false}
                    readOnly={props.readOnly || props.editChain} 
                    onChange={(event) => props.onChange(props.invoiceItem.LEISTUNG , 'ZIFFER', event.target.value)}
                    />

        einzelleistung =  <MUIInput   id='einzelLeistung'
                                label='Leistungsbeschreibung'
                                value={props.invoiceItem.Einzelleistung} 
                                readOnly={props.readOnly || props.editChain} 
                                multiline={true}
                                onChange={(event) => props.onChange(props.invoiceItem.LEISTUNG , 'Einzelleistung', event.target.value)} 
                                />   
        count =  <MUIInput   id='Anzahl'
                    label='Anzahl'
                    value={props.invoiceItem.ANZAHL} 
                    readOnly={props.readOnly} 
                    type="numeric" 
                    onChange={(event) => props.onChange(props.invoiceItem.LEISTUNG , 'ANZAHL', event.target.value)}
                    />     
        singleAmount=  <Currency   id='amount'
                        label='Einzelpreis'
                        value={props.invoiceItem.PREIS} 
                        readOnly={props.readOnly || props.editChain}  
                        onChange={(event) => props.onChange(props.invoiceItem.LEISTUNG , 'PREIS', event.target.value)}
                        />      
        totalAmount = <Currency   id='einzelLeistung'
                        label='Gesamtpreis'
                        value={props.invoiceItem.ANZAHL * props.invoiceItem.PREIS} 
                        readOnly={true}  
                        />    
        sortId  =   <MUIInput   id='sortID'
                        label='Sortierung'
                        value={props.invoiceItem.ORDNR} 
                        readOnly={props.readOnly || props.editChain} 
                        type="numeric" 
                        onChange={(event) => props.onChange(props.invoiceItem.LEISTUNG , 'ORDNR', event.target.value)}
                        />   ;                    
    }
    
    


    //console.log('render item');

    return (
        <div className={classes.InvoiceItem}>
            {confirmationPopup}
            {addButton}
            {editButton}         
            { ziffer &&
                <div className={classes.Ziffer}>{ziffer}</div>
            }
            <div className={classes.Text}>{einzelleistung}</div>                                    
            {!props.reduced && 
                <div className={classes.Anzahl}>{count}</div>                
            }           
            <div className={classes.Price}>{singleAmount}</div>
            {!props.reduced &&   
                <div className={classes.Total}>{totalAmount}</div>
            }
            {props.setup &&   
                <div className={classes.Anzahl}>{sortId}</div>
            }                
            {deleteButton}
        </div>
    );

};



export default InvoiceItemPlain;
