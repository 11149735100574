import React from 'react';
import classes from './Logo.module.css';
import theraSoftLogo from '../../assets/images/theraSoft.png'


const logo = (props) => (

    <div className={classes.Logo} style={{ height: props.height }}>  
        <a href={process.env.REACT_APP_URL}><img src={theraSoftLogo} alt="TheraSoft" /></a>
    </div>
);

export default logo;

