import React, {useContext } from 'react';
import NavigationItem from './NavigationItem';
import classes from './NavigationItem.module.css';
import GlobalContext from '../../../context/GlobalContext';
import SessionHandler from '../../../context/SessionHandler';

import {BiLogOut} from "react-icons/bi";
import {AiOutlineSetting} from "react-icons/ai";
import { LuListTodo } from "react-icons/lu";


import { RiTodoLine } from "react-icons/ri";
import { Badge } from '@mui/material';

const NavigationItems = (props) => {
    
    const globalContext = useContext(GlobalContext);
    const iconSize = 22;


    let linkTalk = null;
    if (SessionHandler.authTalk()){
        linkTalk = (
            <NavigationItem link="/talk" active={true}  clicked={props.clicked}>
                Chat
            </NavigationItem>            
        );
    }

    let linkSetup = null;
    if (SessionHandler.authRelease()){
        linkSetup = (
            <NavigationItem link="/setup" active={true}  clicked={props.clicked}>
                <AiOutlineSetting size={iconSize} />
            </NavigationItem>            
        );
    }

    let linkPrescription = null;
    if (SessionHandler.authSensitiveData() ){

        linkPrescription = (<NavigationItem link="/prescriptions" active={false} clicked={props.clicked}>
                                Rezepte
                            </NavigationItem>);
    }

    let linkFinance = null;
    if (SessionHandler.authFinance() ){
        linkFinance = (<NavigationItem link="/financeMenu" active={false} clicked={props.clicked}>
                                Finanzen
                            </NavigationItem>);        
    }

    let linkHr = null;
    if (SessionHandler.authHR() ){
        linkHr = (<NavigationItem link="/hrMenu" active={false} clicked={props.clicked}>
                                HR
                            </NavigationItem>);        
    }  


    let navigation = null;
    if (SessionHandler.getUser()){
        navigation =    <ul className={classes.NavigationItems}>
                            <NavigationItem link="/appointments" active={false} clicked={props.clicked}>
                                Termine
                            </NavigationItem>        
                            <NavigationItem link="/today" active={false} clicked={props.clicked}>
                                Heute
                            </NavigationItem>     
                            <NavigationItem link="/appQueue" active={true}  clicked={props.clicked}>
                                Warteliste
                            </NavigationItem>
                            <NavigationItem link="/patients" active={true}  clicked={props.clicked}>
                                Patienten
                            </NavigationItem>
                            {linkPrescription}
                            {linkFinance}
                            {linkHr}
                            {linkTalk}
                            <NavigationItem link="/task" active={true}  clicked={props.clicked}>
                                <Badge badgeContent={globalContext.myOpenTasksCnt} color="warning" invisible={globalContext.myOpenTasksCnt === 0}>
                                    <LuListTodo size={iconSize} />
                                </Badge>
                            </NavigationItem>            
                            {linkSetup}
                            <NavigationItem link="/logout" active={false}  clicked={globalContext.logout} >
                                <BiLogOut size={iconSize} />
                            </NavigationItem>
                        
                    </ul>
    }



    return navigation;
}
    
export default NavigationItems;