class AppointmentUtil{



    static C_STAT_DRAFT = 10;
    static C_STAT_OPEN = 11;
    static C_STAT_RELEASED =  20;
    static C_STAT_PART_INVOICED = 28;
    static C_STAT_WRITEOFF = 29;
    static C_STAT_SAVED = 90;
    static C_STAT_DELETED = 99;
    static C_STAT_INVOICED = 30 ;

    static C_TYPE_PATIENT_APPOINTMENT = 1;
    static C_TYPE_NORMAL_APPOINTMENT = 2;
    static C_TYPE_ABSENCE = 3;
    static C_TYPE_HOLIDAY = 4;
    static C_TYPE_ATTENDANCE = 5;


    static C_FREQ_WEEKLY = "WEEKLY";
    static C_FREQ_NONE = "-";
  

    static C_COLOR_HOLIDAY = "#D6B461";

    static isRecurrent(appointment){
        if (appointment.frequency){
            return true;
        }
        else{
            return false;
        }
    }

    static calculatePrice(appointment){
        let total = 0;
        if (appointment.invoice_items){
            for (var i=0; i < appointment.invoice_items.length; i++){
                const anzahl = appointment.invoice_items[i]['ANZAHL'];
                const price = appointment.invoice_items[i]['PREIS'];
                let faktor = appointment.invoice_items[i]['FAKTOR']
                if (!faktor || faktor === ''){
                    faktor = 1;
                }
                total = total + anzahl * faktor * price;
            }
        }
        if (!total){
            total = 0;
        }
        return total;
    }



}


export default AppointmentUtil;