import React, {Component} from 'react';
import classes  from './Appointment.module.css';
import {Link} from 'react-router-dom';
import RichText from '../UI/Input/RichText';
import Layout from '../Layout/Layout';
import Button from '../UI/Button/Button';
import Badge from '@mui/material/Badge';
import AuthContext from '../../context/GlobalContext';
import { MdEvent } from "react-icons/md";
import {FaAddressCard} from "react-icons/fa";
import Tooltip from '../UI/Tooltip/Tooltip';
import Caption from '../UI/Card/Caption';
import SessionHandler from '../../context/SessionHandler';
import AppointmentUtil from './AppointmentUtil';
import DateUtility from '../../hoc/DateUtility';

class AppointmentListItem extends Component{

    static contextType = AuthContext;

    render(){  

        var richText = null;
        var patient = null;
        var caption = null;
        var date = null;
        var borderStyle = classes.Therapist;
        const iconSize = 20;
 
        if (this.props.patientDetail  ){
            //show documentation
            if (SessionHandler.authSensitiveData() && !this.props.hideDocu){
                richText = (<RichText htmlText={this.props.appointment.documentation} label='Termindokumentation'/>);
            }

            // show date 
            date = Layout.formatDate(this.props.appointment.date) + ',';
            //dateTime = (<div  className={classes.DateTime}>{editButton} {Layout.formatDate(this.props.appointment.date)}, {this.props.appointment.from} - {this.props.appointment.to} </div>);
        }
        else{

            // for appointments today show also the prescription info
            var prescriptionData = "";
            var today = new Date(  );
            today.setHours(0,0,0,0);  
            //today.setDate(today.getDate() - 5);              

            //show patient name
            var sheetTag = null;
            if (this.props.appointment.sheetNo !== null){
                sheetTag = ' ('.concat(this.props.appointment.sheetNo).concat(')');
                //sheetNo = (sheetTag );

            }
            if (this.props.appointment.patientName !== null){

                let cardIcon = <FaAddressCard className={classes.Icon} size={iconSize}/>;
                const pat = this.context.getPatient(this.props.appointment.patientID);
                if (!pat.dataProtectionAgreement){
                    cardIcon = <Badge badgeContent={"!"} color="warning" variant="dot" anchorOrigin={{ vertical: 'top',horizontal: 'left', }}>
                                    <FaAddressCard className={classes.Icon} size={iconSize}/>
                                </Badge>;
                }

                patient = ( <div className={classes.fieldValue}> 
                                <label className={classes.label}>Patient: </label>
                                <Link className={classes.Link} to={{ pathname: '/patients/' + this.props.appointment.patientID }}>
                                    <Tooltip content={'Patientenakte'}>
                                        {cardIcon}
                                    </Tooltip>
                                    {this.props.appointment.patientName} 
                                </Link>
                                
                                {sheetTag} 
                            </div>);                           
            }
            else{
                let subjLable = 'Betreff: ';
                if (this.props.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
                    subjLable = "Patient: ";
                }
                patient = (<div className={classes.fieldValue}><label className={classes.label}>{subjLable}</label>{this.props.appointment.subject}</div>);
            }

            var praxisName = null;
                        
        }
        /*************************************************************************************************************************************** */
        /*header*/ 
        let link = <React.Fragment>{date} {this.props.appointment.from} - {this.props.appointment.to} {praxisName}</React.Fragment>
        
        if (this.props.appointment.appType === AppointmentUtil.C_TYPE_HOLIDAY){
            link =  "Feiertag";//this.props.appointment.subject;// Layout.formatDate(this.props.appointment.date) ;// this.props.appointment.subject;
        }    
        else if (this.props.appointment.fullDay){
            link =  "24 h";
            if (this.props.appointment.date !== this.props.appointment.dateTo){
                link = DateUtility.formatDate(this.props.appointment.date, DateUtility.TYPE_SHOWDATE) + ' - ' + DateUtility.formatDate(this.props.appointment.dateTo, DateUtility.TYPE_SHOWDATE);
            }
            
            
        }

        caption = (    <Caption > 
            <Link className={classes.LinkHeader} to={{ pathname: '/appointments/' + this.props.appointment.ID }}>
                <Tooltip content={'Termin Details'}>
                    <Button btnType="EditButton"><MdEvent size={iconSize} className={classes.Icon}/></Button>
                </Tooltip>
                {link}
            </Link>
        </Caption>);        

        


        /*************************************************************************************************************************************** */
        /*status*/
        let status = null;
        if (this.props.appointment.appType === AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
            // Show status only for patient appointment 
            status = <div className={classes.fieldValue}><label className={classes.label}>Status: </label>{this.props.appointment.statusText}</div>;
        }


        /*************************************************************************************************************************************** */
        /*Therapeut*/       
        let therapeut = null;
        
        if   (this.props.appointment.appType !== AppointmentUtil.C_TYPE_HOLIDAY){
            let praxis = <div className={classes.fieldValue}><label className={classes.label}>Praxis: </label>{this.props.appointment.praxisName}</div>;
            if (this.props.appointment.appType !== AppointmentUtil.C_TYPE_PATIENT_APPOINTMENT){
                praxis = null;
            }
            therapeut = <React.Fragment>                   
                            <div className={classes.fieldValue}><label className={classes.label}>Therapeut: </label>{this.props.appointment.therapeutName}</div>
                            {praxis}
                            
                        </React.Fragment>;

        }
        

        /*************************************************************************************************************************************** */
        /*border color*/              
        const therapist = this.context.getTherapist(this.props.appointment.therapeutID);
        let borderColor = {'borderColor' : '#F5F5F5'};
        if (therapist){
            borderColor = {'borderColor' : therapist.color};
        }
        if (this.props.appointment.appType === AppointmentUtil.C_TYPE_HOLIDAY){
            borderColor = {'borderColor' : AppointmentUtil.C_COLOR_HOLIDAY};
        }

        return(
                
                
                <div className={[classes.AppointmentListItem, borderStyle].join(' ')} style={borderColor}>
                    {caption}    
                    {patient}
                    {prescriptionData}
                    {therapeut}
                    {status}
                    {richText}
                </div>
        );
    }
}
export default AppointmentListItem;
