import React , { useState, useCallback }from 'react';
import '../../../globalStyle.css';
import { IconButton } from '@mui/material';
import {FaAddressCard} from "react-icons/fa";
import { RiEdit2Fill } from "react-icons/ri";
import PatientSearchHelp from '../../Patients/SearchHelp';
import {useHistory} from 'react-router-dom';
import MUIInput from './MUIInput';
import Button from '../../UI/Button/Button';


const MUIPatientInput = (props) =>  {

    const { patientID, selectPatientHandler, createPatientFunction, ...rest } = props;

    const [showSearchHelp, setShowSearchHelp] = useState(0);
    
    const patientSelect = (id) => {
        // do something with a, b and props.x
        setShowSearchHelp(false);
        selectPatientHandler(id);
    };


    
    const history = useHistory();
    const navigateToPatient = useCallback(() => history.push('/patients/' + patientID), [history, patientID]);

    let editButton = null;
    if (!props.readOnly){
        editButton =    <IconButton onClick={() => setShowSearchHelp(true)}
                                    edge="end" >
                            <RiEdit2Fill className='IconMUI' /> 
                        </IconButton>;
    }
    
    let patIcon = null;
    if (patientID ){
        patIcon =  <IconButton
                        onClick={() => navigateToPatient()}
                        edge="end" >
                        <FaAddressCard className='IconMUI' />
                    </IconButton>;
    }

    let createPatientButton = null;
    if (createPatientFunction){
        createPatientButton = <Button btnType="EditMenuButton" click={() => { setShowSearchHelp(false); createPatientFunction()}} >Patient anlegen</Button>;

    }


    return (    <React.Fragment>
                    <PatientSearchHelp showSearchHelp={showSearchHelp}
                                        modalClosed={() => setShowSearchHelp(false)}
                                        patientClickHandler={patientSelect}
                                        function1={createPatientButton}/>    
                    <MUIInput {...rest} prefix={patIcon} suffix={editButton} onChange={() => {/* direct input not possible */}}/> 
                </React.Fragment>  );

         
}
export default MUIPatientInput;