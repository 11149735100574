import React, { useContext, useEffect } from 'react';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';

import {useHistory} from 'react-router-dom';
import ListMenu from './ListMenu';
import GlobalContext from '../../context/GlobalContext';
import UserListItem from './UserListItem';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';

const Users = (props) => {

    const history = useHistory();

    const globalContext = useContext(GlobalContext);

        /* initialize component ********************************************************/
        useEffect(() => {
            if (!globalContext.usersLoaded  &&
                !globalContext.usersLoading){

                    globalContext.loadUsers();
                }
            }, []);
        /***end: initialize component *************************************************** */

    let list = null;


    //if (globalContext.usersLoaded){
        list = globalContext.users.map((user, index) => (
            <UserListItem
                user={user}
                therapists={globalContext.therapeut}
                key={user.userName}/>
        ))
    //}
    //else if (!globalContext.usersLoading){

        //globalContext.loadUsers();

    //}

    
    

    return  <React.Fragment>
                <SubMenuMui  history={history}>
                        <ListMenu     /> 
                </SubMenuMui>   
                <Modal show={globalContext.usersLoading}><Spinner/></Modal>
                <h3>Benutzerkonten bearbeiten</h3>
                {list}
            </React.Fragment>

}

export default Users;
