import React from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';

import {Link} from 'react-router-dom';
import { FiPlusCircle } from "react-icons/fi";
import { IconButton, Tooltip } from '@mui/material';

const ListMenu = (props) => {

        let buttonsLeft = null;
        let buttonsRight = null;

    return (
        <div className={classes.SubMenuContainer}>
            <div className={classes.SubMenuLeft}>
                <Tooltip title="Neu">
                    <span>
                        <Link to={{ pathname: '/setup/praxis/new/'}}>
                            <IconButton  >
                                <FiPlusCircle />
                            </IconButton>  
                        </Link>
                    </span>
                </Tooltip>
                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
}

export default ListMenu;