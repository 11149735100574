import React, {useContext} from 'react';
import GlobalContext from '../../context/GlobalContext';
import DetailContainer from '../Layout/DetailContainer';

import Facts from '../UI/Card/Facts';
import Input from '../UI/Input/Input';


const AbsenceRequest = (props) => {
    
    const globalContext = useContext(GlobalContext);

    const styleConfig = {   inputStyle: 'InputDetail',
                            labelStyle: 'LabelLight',
                            inputElementStyle:'InputElementDetail' };        

    const configDate = { type: 'dateWithoutTime' };

    const absenceConfig = { 
        options: [{value: null, displayValue: '-' }]
    };
    globalContext.absenceTypes.forEach(absenceType => {
        const elem = { value: absenceType.TYPE, displayValue: absenceType.Name };
        absenceConfig.options.push(elem);      
    });

    const employeeConfig = { 
        options: [ {value: null, displayValue: '-' }]
    };
    globalContext.employees.forEach(employee => {
        const elem = { value: employee.PERNR, displayValue: employee.firstName };
        employeeConfig.options.push(elem);      
    });




    return (
        <DetailContainer>
        <Facts>
            <Input  key= {'PERNR'} 
                    elementType= 'select' 
                    elementConfig = {employeeConfig}
                    styleConfig= {styleConfig} 
                    value={props.newAbsence.PERNR} 
                    label='Mitarbeiter' 
                    readOnly={false} 
                    changed={(event) => props.inputChangedHandler(event, 'PERNR')}
                    />
            <Input  key= {'absenceType'} 
                    elementType= 'select' 
                    elementConfig = {absenceConfig}
                    styleConfig= {styleConfig} 
                    value={props.newAbsence.absenceType} 
                    label='Abwesenheitsart' 
                    readOnly={false} 
                    changed={(event) => props.inputChangedHandler(event, 'absenceType')}
                    />                    
            <Input  key= {'date'} 
                    elementType= 'input' 
                    elementConfig={configDate}
                    styleConfig= {styleConfig} 
                    value={props.newAbsence.date} 
                    changed={(event) => props.inputChangedHandler(event, 'date')}
                    label='Datum' 
                    readOnly={false} 
                    touched={true}
                    invalid={props.controls &&  !props.controls.dateValid}
                    />          
        </Facts>
        </DetailContainer>
    );

}

export default AbsenceRequest;