import { Box, Tab, Tabs } from '@mui/material';
import React, {  useState } from 'react';
  

import PropTypes from 'prop-types';

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const MuiTabs = (props) => {


    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const tabTitles = props.tabTitles.map( (tabTitle, index) => <Tab key={tabTitle} label={tabTitle } {...a11yProps(index)} /> )

    
    return <Box sx={{  width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs   value={value} 
                            onChange={handleChange} 
                            aria-label="basic tabs example"   
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            >
                        {  tabTitles   }
                    </Tabs>
                </Box>
                {props.tabContents[value]}           
            </Box>  


}

MuiTabs.propTypes = {
  tabTitles: PropTypes.array.isRequired,  // title of tabs
  tabContents: PropTypes.array.isRequired, // array with content of tabs
  };

export default MuiTabs;