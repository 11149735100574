import React, { Component } from "react";
import PropTypes from "prop-types";
import classes from './Tab.module.css';

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string,
    label: PropTypes.string.isRequired,
    icon: PropTypes.element,
    onClick: PropTypes.func,
  };

  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { activeTab, label, icon },
    } = this;

    
    let tabClasses = [classes.TabListItem];
    if (activeTab === label) {
      tabClasses = classes.TabListActiveItem;
        /*tabClasses.push(classes.TabListActiveItem);*/
    }

    let caption = label;
    
    if (icon){
      caption = icon;
    }

    return (
      <li className={tabClasses} onClick={onClick} >
        {caption}
      </li>
    );
  }
}

export default Tab;