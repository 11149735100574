import React from 'react';
import classes from './Setup.module.css';
import TileContainer from '../UI/Tile/TileContainer';
import { MdOutlineSettings,  MdEventAvailable , MdLocationOn} from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { FaRegIdBadge } from "react-icons/fa";
import { BiSolidUser } from "react-icons/bi";





import Tile from '../UI/Tile/Tile';
import Caption from '../UI/Card/Caption';
import SessionHandler from '../../context/SessionHandler';

const setup = (props) => (
    <div>
        <h3>{process.env.REACT_APP_NAME + ' v.'  + process.env.REACT_APP_VERSION}</h3>
        <div>Environment: {process.env.REACT_APP_ENVIRONMENT}</div>
        <TileContainer>
            { SessionHandler.authSetup() && 
                <Tile link='/setup/client'>
                    <Caption>Praxiseinstellungen</Caption>
                    <MdOutlineSettings  className={classes.Icon}/>                         
                    <div>Praxiseinstellungen</div>
                </Tile>
            }
            { SessionHandler.authSetup() && 
                <Tile link='/setup/serviceCatalogue'>
                    <Caption>Leistunskatalog</Caption>
                    <IoPricetagsOutline  className={classes.Icon}/>                         
                    <div>Leistungkatalog, Leistungsketten und Preiseliste berarbeiten</div>
                </Tile>
            }
            { SessionHandler.authRelease() && 
                <Tile link='/setup/attendances'>
                    <Caption>Anwesenheiten</Caption>
                    <MdEventAvailable  className={classes.Icon}/>                         
                    <div>Anwesenheiten der Therapeuten berarbeiten</div>
                </Tile>    
            }   
            { SessionHandler.authSetup() && 
                <Tile link='/setup/therapists'>
                    <Caption>Therapeuten</Caption>
                    <FaRegIdBadge  className={classes.Icon}/>                         
                    <div>Therapeuten anlegen, ändern und löschen</div>
                </Tile>
            }                 
            { SessionHandler.authSetup() && 
                <Tile link='/setup/praxis'>
                    <Caption>Standorte/Praxen</Caption>
                    <MdLocationOn  className={classes.Icon}/>                         
                    <div>Standorte/Praxen anlegen, ändern und löschen</div>
                </Tile>
            }       
            { SessionHandler.authSetup() && 
                <Tile link='/setup/users'>
                    <Caption>Benutzerkonten</Caption>
                    <BiSolidUser  className={classes.Icon}/>                         
                    <div>Benutzerkonten (user) anlegen, ändern und löschen</div>
                </Tile>
            }                               
        </TileContainer>
    </div>
);


export default setup;

