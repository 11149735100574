import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import Caption from '../UI/Card/Caption';
import Tile from '../UI/Tile/Tile';
import classes from './HrMenu.module.css';
import TileContainer from '../UI/Tile/TileContainer';
import {FaRegCalendarTimes} from "react-icons/fa";
import { CgCalculator} from "react-icons/cg";


const HrMenu = (props) => {

    return (
    <Auxiliary>
        <h3>Personalwirtschaft</h3>
        <TileContainer>
            <Tile link='/hrMenu/absences'>
                <Caption>Abwesenheiten</Caption>
                <FaRegCalendarTimes  className={classes.Icon}/>
                <div>Abwesenheiten erfassen</div>
            </Tile>           
            <Tile link='/hrMenu/payrollList'>
                <Caption>Entgeltberechung</Caption>
                <CgCalculator  className={classes.Icon}/>
                <div>Provisionsberechnung &amp; Abwesenheitsentgelt</div>
            </Tile>    
        </TileContainer   >    
    </Auxiliary>
);

    }

export default HrMenu;

