import React, { useContext } from 'react';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';

import {useHistory} from 'react-router-dom';
import ListMenu from './ListMenu';
import GlobalContext from '../../context/GlobalContext';
import TherapistsListItem from './TherapistListItem';

const Therapists = (props) => {

    const history = useHistory();

    const globalContext = useContext(GlobalContext);

    
    const list = globalContext.therapeut.map((therapist, index) => (
        <TherapistsListItem
            therapist={therapist}
            key={therapist.PERNR}/>
    ))
   

    return  <React.Fragment>
                <SubMenuMui  history={history}>
                        <ListMenu    /> 
                </SubMenuMui>   
                <h3>Therapeuten bearbeiten</h3>
                {list}
            </React.Fragment>

}

export default Therapists;
