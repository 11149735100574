import React, { useContext } from 'react';
import classes from '../Therapists.module.css';
import GlobalContext from '../../../context/GlobalContext';
import TherapistChip from './TherapistChip';

const TherapistChipList = (props) => {

    const globalContext = useContext(GlobalContext);
    const list2 = globalContext.therapeut.map((t, index) => {

        const objIndex = props.therapists.findIndex((obj => obj.PERNR === t.PERNR));
        let inactive = true;
        if (objIndex !== -1){
            inactive = false;
        }

        if (!t.inactive &&  !(t.vorname === '-')){
            return <TherapistChip therapist={t} 
                                    key={t.PERNR}
                                    inactive={inactive} 
                                    readOnly={props.readOnly}
                                    handleChangeTherapists={props.handleChangeTherapists}/>

        }





        

    });
    return <React.Fragment>
                {!props.headless &&
                    <h5>Therapeuten</h5>
                }
                <div  className={classes.PraxisList}>{list2}</div>
            </React.Fragment>
   

}

export default TherapistChipList;