import React from 'react';
import Auxiliary from '../../hoc/Auxiliary';

import classes from './Talk.module.css';
import TalkMessage from './TalkMessage';

const talkMessages = (props) => {
    
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var dateTmp = new Date(0);
        
    var list = null;

    list =  props.messages
                .filter(message => message.messageType === 'comment')
                .map((message, index) => {
                
                var dateElement = null;
                let date = new Date(message.timestamp * 1000);
                            
                if (date.getFullYear() !== dateTmp.getFullYear() ||
                    date.getMonth() !== dateTmp.getMonth() || 
                    date.getDay() !== dateTmp.getDay()   ){

                    
                    let dateFormatted = date.toLocaleDateString("de-DE", options);
                    dateElement = (<div className={classes.Date} >{dateFormatted}</div>);

                    
                    dateTmp = date;
                }      


                return ( <Auxiliary key={index}>
                            {dateElement}
                            <TalkMessage  message={message}   />
                        </Auxiliary> );
                });


        return(
            <div className={classes.Messages}>
                {list}   
            </div>
        );

}


export default talkMessages;