import React, { useEffect, useState, useContext } from 'react';
import '../../globalStyle.css'; 
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import ClientMenu from './ClientMenu';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import DetailContainer from '../Layout/DetailContainer';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import { Box, FormControl, FormControlLabel, FormHelperText, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import GlobalContext from '../../context/GlobalContext';
import { BsInfoCircleFill } from  "react-icons/bs";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


const Client = (props) => {
    const history = useHistory();

    const globalContext = useContext(GlobalContext);
    const [updClient, setUpdClient] = useState({ });
    const [client, setClient] = useState({
        BIC:"",
        IBAN: "",
        address: "",
        bankName: "",
        basicLoadDaysPast: 0, 
        city: "",
        clientName: "",      
        defaultDuration: 0,
        dunningD1OpenToD1Overdue: 0,
        dunningD2OpenToD2Overdue: 0,
        dunningDue: 0,
        dunningOpenToOverdue: 0,
        email: "",
        footerLeft: "",
        footerRight: "",
        header: "",
        homepage: "", 
        invoiceOverdue: 0,
        invoicingLastApp: 0,
        invoicingMaxAge: 0,
        lookupDaysTimeslot: 0,
        mailReminderDays: 0,
        maximumDuration: 0,
        minimumDuration: 0,
        name: "",
        name2: "",
        owner: "",  
        phone: "",
        postCode: "",
        regLinkValidTo: 0,
        subTitle: "",
        taxId: "",
        dataprotectionUrl: "",
        onlineBookingUrl: "",
        onlineBooking: false
    });
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    //load client data 
    useEffect(() => {

        axios.get('/clients/me' )   
        .then (response => {
            setLoading(false);
            setClient(response.data.data);
        })
        .catch (error => {
            console.log(error); 
            setLoading(false);
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Laden der Praxisdaten");
        });      


      }, []);
    

    
    const saveHandle = () =>{
        setLoading(true);
        axios.put('/clients/' + client.clientName, updClient )   
        .then (response => {
            setLoading(false);

            setClient(response.data.data);
            setUpdClient({});

            const message = {
                type: 'S',
                text: 'Praxisdaten gespeichert'
            }
            globalContext.showMessage(message);      

        })
        .catch (error => {
            console.log(error); 
            setLoading(false);
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Speichern der Praxisdaten");
        });      

    }

    const changeHandler = (field, value) => {
        const copy = {...client};
        copy[field] = value;
        setClient(copy);

        const copy2 = {...updClient};
        copy2[field] = value;
        setUpdClient(copy2);
    }

    const tab1 = ( <DetailContainer> 
                        <FactsMui>               
                            <h5>Stammdaten</h5>                                                               
                            <MUIInput id={'name'}
                                    label='Praxisname' 
                                    value={client.name}
                                    fullWidth
                                    multiline
                                    onChange={(event) => changeHandler('name', event.target.value)}
                                    />   
                            <MUIInput id={'name2'}
                                    label='Praxisname (2)' 
                                    value={client.name2}
                                    fullWidth
                                    multiline
                                    onChange={(event) => changeHandler('name2', event.target.value)}
                                    />      
                            <MUIInput id={'owner'}
                                    label='EigentümerIn' 
                                    value={client.owner}
                                    fullWidth
                                    onChange={(event) => changeHandler('owner', event.target.value)}
                                    />       
                            <MUIInput id={'taxId'}
                                    label='Steuernummer' 
                                    value={client.taxId}
                                    fullWidth
                                    onChange={(event) => changeHandler('taxId', event.target.value)}
                                    />                                           


                            <h5>Bankdaten</h5>                                                                                                                                
                            <MUIInput id={'IBAN'}
                                    label='IBAN' 
                                    value={client.IBAN}
                                    fullWidth
                                    onChange={(event) => changeHandler('IBAN', event.target.value)}
                                    />    
                            <MUIInput id={'BIC'}
                                    label='BIC' 
                                    value={client.BIC}
                                    fullWidth
                                    onChange={(event) => changeHandler('BIC', event.target.value)}
                                    />    
                            <MUIInput id={'bankName'}
                                    label='Bankname' 
                                    value={client.bankName}
                                    fullWidth
                                    multiline
                                    onChange={(event) => changeHandler('bankName', event.target.value)}
                                    />                                                                                                     
                            </FactsMui>
                            <FactsMui>                                         
                                    
                            <h5>Adresse/Kontaktdaten</h5>     
                            <MUIInput id={'address'}
                                    label='Straße' 
                                    value={client.address}
                                    fullWidth
                                    onChange={(event) => changeHandler('address', event.target.value)}
                                    />                                                                
                            <DoubleField>
                                <MUIInput id={'postCode'}
                                        label='Postleitzahl' 
                                        value={client.postCode}
                                        onChange={(event) => changeHandler('postCode', event.target.value)}
                                        />                     
                                <MUIInput id={'city'}
                                        label='Stadt' 
                                        value={client.city}
                                        onChange={(event) => changeHandler('city', event.target.value)}
                                        />                                                                                                        
                            </DoubleField>                                                                        
                            <MUIInput id={'homepage'}
                                    label='Homepage' 
                                    value={client.homepage}
                                    fullWidth
                                    onChange={(event) => changeHandler('homepage', event.target.value)}
                                    />                               
                            <MUIInput id={'phone'}
                                    label='Telefon' 
                                    value={client.phone}
                                    fullWidth
                                    onChange={(event) => changeHandler('phone', event.target.value)}
                                    />                               
                            <MUIInput id={'email'}
                                    label='E-Mail' 
                                    value={client.email}
                                    fullWidth
                                    onChange={(event) => changeHandler('email', event.target.value)}
                                    />                                               

                        </FactsMui>
                    </DetailContainer>  );

    const tab2 = (
        <DetailContainer>
                        <FactsMui>
                        <h5>Formularelemente</h5>                                                                                                                                                                    
                            <MUIInput id={'header'}
                                    label='Header' 
                                    value={client.header}
                                    fullWidth
                                    multiline
                                    onChange={(event) => changeHandler('header', event.target.value)}
                                    />  
                            <MUIInput id={'subTitle'}
                                    label='Sub-Titel' 
                                    value={client.subTitle}
                                    fullWidth
                                    multiline
                                    helperText="Formular Anschrift (Kopfanschrift rechts)"
                                    onChange={(event) => changeHandler('subTitle', event.target.value)}
                                    />                                           
                            <MUIInput id={'footerLeft'}
                                    label='Footer Links' 
                                    value={client.footerLeft}
                                    fullWidth
                                    multiline
                                    onChange={(event) => changeHandler('footerLeft', event.target.value)}
                                    />                                                                                                                
                            <MUIInput id={'footerRight'}
                                    label='Footer Rechts' 
                                    value={client.footerRight}
                                    fullWidth
                                    multiline
                                    onChange={(event) => changeHandler('footerRight', event.target.value)}
                                    />                                         
                        </FactsMui>
                    </DetailContainer> 

    );

    const tab3 = (
        <DetailContainer>
        <FactsMui>                            
        <h5>Rechnungs-/Mahnungsfälligkeit</h5>          
                <MUIInput id={'invoiceOverdue'}
                        label='Fälligkeit Rechnung' 
                        value={client.invoiceOverdue}
                        fullWidth
                        helperText="Anzahl Tage vom Rechnungsdatum bis zum Fälligkeitsdatum der Rechnung"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('invoiceOverdue', event.target.value)}
                        />    
                <MUIInput id={'dunningDue'}
                        label='Fälligkeit Mahnung' 
                        value={client.dunningDue}
                        fullWidth
                        helperText="Anzahl Tage vom Mahn-Datum bis zum Fälligkeitsdatum der Mahnung"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('dunningDue', event.target.value)}
                        />                                             
        <h5>Abrechnungssteuerung</h5>                                                                                                                                                   
        <MUIInput id={'invoicingLastApp'}
                        label='Anzahl Tage nach letzem Termin' 
                        value={client.invoicingLastApp}
                        fullWidth
                        helperText="Anzahl Tage nach letztem Termin, nach denen das Abrechnungsflag beim Patienten automatisch gesetzt wird"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('invoicingLastApp', event.target.value)}
                        />    
                <MUIInput id={'invoicingMaxAge'}
                        label='Abrechnen nach max. Tagen' 
                        value={client.invoicingMaxAge}
                        fullWidth
                        helperText="Anzahl Tage nach Behandlungstermin, dach denen der Patient abgerechnet werden soll - auch wenn noch immer eine laufende Behandlung stattfindet"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('invoicingMaxAge', event.target.value)}
                        />                                             
        </FactsMui>
        
        <FactsMui>
                <h5>Statusübergänge Rechnung</h5>                                                                                                                                                                    
                <MUIInput id={'dunningOpenToOverdue'}
                        label='Statuswechsel Überfällig' 
                        value={client.dunningOpenToOverdue}
                        fullWidth
                        helperText="Anzahl Tage nach denen eine Rechnung von 'offen' nach 'überfällig' wechselt"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('dunningOpenToOverdue', event.target.value)}
                        />     
                <MUIInput id={'dunningD1OpenToD1Overdue'}
                        label='Statuswechsel Überfällig Mahnst. 1' 
                        value={client.dunningD1OpenToD1Overdue}
                        fullWidth
                        helperText="Anzahl Tage nach denen eine Rechnung von 'Mahnst. 1' nach 'M1 überfällig' wechselt"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('dunningD1OpenToD1Overdue', event.target.value)}
                        />     
                <MUIInput id={'dunningD2OpenToD2Overdue'}
                        label='Statuswechsel Überfällig Mahnst. 2' 
                        value={client.dunningD2OpenToD2Overdue}
                        fullWidth
                        helperText="Anzahl Tage nach denen eine Rechnung von 'Mahnst. 2' nach 'M2 überfällig' wechselt"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('dunningD2OpenToD2Overdue', event.target.value)}
                        />                            
                
                <h5>Termineinstellungen</h5>                                                                                                                                                                    
            
                <MUIInput id={'defaultDuration'}
                        label='Standarddauer ' 
                        value={client.defaultDuration}
                        fullWidth
                        helperText="Standard Dauer für einen Behanldungstermin in Minuten"
                        type="number"
                        suffix="min"
                        onChange={(event) => changeHandler('defaultDuration', event.target.value)}
                        />    
                <MUIInput id={'lookupDaysTimeslot'}
                        label='Terminsuche Tage' 
                        value={client.lookupDaysTimeslot}
                        fullWidth
                        helperText="Anzahl Tage die bei der Terminsuche in die Zukunft geschaut wird"
                        type="number"
                        suffix="d"
                        onChange={(event) => changeHandler('lookupDaysTimeslot', event.target.value)}
                        />                                             
            
        </FactsMui>        
    </DetailContainer>   
    );



    const tooltipSmtp = ( <React.Fragment>
                                <Typography color="inherit">Servereinstellungen</Typography>
                                {"Praxisspezifischen SMTP Servereinstellungen sind nicht notwendig um E-Mails zu verschicken."} 
                                <br/> <br/> {"Falls diese Einstellung leer bleibt, dann wird die Standard Absender Adresse (noreply@theradmin.de) verwendet."}
                                <br/><br/> {"Die 'E-Mail Absender' Konfiguration bleibt davon unberührt. D.h. Absendername und 'Antworten an' Adresse können auch ohne Praxisspezifischen SMTP Server verwendet werden."}
                                        </React.Fragment>);

    const tab4 =  (     <DetailContainer>
                                <FactsMui>
                                <h5>E-Mail Absender</h5>                                                                                                                                                                    
                                        <MUIInput id={'smtpFromName'}
                                                label='Absendername ' 
                                                value={client.smtpFromName}
                                                fullWidth
                                                helperText="E-Mail Absender Name (wird anstatt der Absender Adresse angezeigt)"
                                                onChange={(event) => changeHandler('smtpFromName', event.target.value)}
                                                />    
                                        <MUIInput id={'smtpReplyToEmail'}
                                                label='Antworten an' 
                                                value={client.smtpReplyToEmail}
                                                fullWidth
                                                helperText="'Antwort an' E-Mail Adresse"
                                                onChange={(event) => changeHandler('smtpReplyToEmail', event.target.value)}
                                                />  
                                                {/*  
                                        <MUIInput id={'smtpReplyToName'}
                                                label='Antworten an Name' 
                                                value={client.smtpReplyToName}
                                                fullWidth
                                                helperText="'Antworten an' Name"
                                                onChange={(event) => changeHandler('smtpReplyToName', event.target.value)}
                                                /> */}                                                                                                   
                                </FactsMui>  
                                <FactsMui>
                                <h5>    <Tooltip title={tooltipSmtp}>
                                                <span>
                                                SMTP Server Einstellungen 
                                                <BsInfoCircleFill className='IconInfo'/>
                                                </span>
                                        </Tooltip> 
                                </h5>       
                                                                                                                                                                              
                                        <MUIInput id={'smtpHost'}
                                                        label='SMTP host' 
                                                        value={client.smtpHost}
                                                        fullWidth
                                                        helperText="SMTP host server (z.B. smtp.mailserver.com)"
                                                        onChange={(event) => changeHandler('smtpHost', event.target.value)}
                                                        />                                              
                                        <MUIInput id={'smtpPort'}
                                                        label='SMTP port' 
                                                        value={client.smtpPort}
                                                        fullWidth
                                                        type="number"
                                                        helperText="SMTP Port Nummer (z.B. 587)"
                                                        onChange={(event) => changeHandler('smtpPort', event.target.value)}
                                                        />          
                                        <MUIInput id={'smtpEncryption'}
                                                        label='Verschlüsselung' 
                                                        value={client.smtpEncryption}
                                                        fullWidth
                                                        helperText="SMTP Verschlüsselung (z.B. tls)"
                                                        onChange={(event) => changeHandler('smtpEncryption', event.target.value)}
                                                        />                                        
                                        <MUIInput id={'smtpUser'}
                                                        label='SMTP username' 
                                                        value={client.smtpUser}
                                                        fullWidth
                                                        helperText="SMTP Benutzername"
                                                        onChange={(event) => changeHandler('smtpUser', event.target.value)}
                                                        />       
                                        <MUIInput id={'smtpPassword'}
                                                        label='SMTP Passwort' 
                                                        value={client.smtpPassword}
                                                        fullWidth
                                                        type='password'
                                                        helperText="SMTP Passwort"
                                                        onChange={(event) => changeHandler('smtpPassword', event.target.value)}
                                                        />    
                                        <MUIInput id={'smtpFromEmail'}
                                                label='Absender E-Mail' 
                                                value={client.smtpFromEmail}
                                                fullWidth
                                                helperText="Absender E-Mail Adresse"
                                                onChange={(event) => changeHandler('smtpFromEmail', event.target.value)}
                                                />                                                                                                                                                                                                                                                                                        
                                </FactsMui>                                  
                        </DetailContainer> );



const tab5 = (
        <DetailContainer>
                        <FactsMui>
                        <h5>Konfiguration Onlinebuchungstool</h5>  

                            <FormControl>
                                <FormControlLabel control={  <Switch    checked={client.onlineBooking}
                                                                        onChange={(event) => changeHandler('onlineBooking', event.target.checked)}
                                                                        />    } 
                                                label="Onlinebuchungstool" />   
                                <FormHelperText>Aktivierung Onlinebuchungstool - bei Nicht-Nutzung sollte dieser Schalter aus Sicherheitsgründen deaktiviert werden</FormHelperText>      
                            </FormControl>                                                                                                                                                                                              
                            <MUIInput id={'onlineBookingUrl'}
                                    label='Onlinebuchungstool URL' 
                                    value={client.onlineBookingUrl}
                                    disabled={!client.onlineBooking}
                                    fullWidth
                                    onChange={(event) => changeHandler('onlineBookingUrl', event.target.value)}
                                    helperText="Link zum Onlinebuchungstool. Falls dieses in einer externe Web-Site eingebettet ist, 
                                    muss hier die URL hinterlegt werden. Diese Url wird für die E-Mail 
                                    Kommuniktion mit den Usern genutzt. Falls das Tool Standalone genutzt wird, 
                                    muss dieser Wert nicht geändert werden"
                                    />  
                            <MUIInput id={'dataprotectionUrl'}
                                    label='Datenschutz URL' 
                                    value={client.dataprotectionUrl}
                                    disabled={!client.onlineBooking}
                                    fullWidth
                                    onChange={(event) => changeHandler('dataprotectionUrl', event.target.value)}
                                    helperText="Link zu der Datenschutzerklärung der Praxis. Dieser Link wird im Onlinebuchungstool angezeigt"
                                    />                                                                          
                        </FactsMui>
                    </DetailContainer> 

    );

    return  (<React.Fragment>
                <SubMenuMui  history={history}>
                        <ClientMenu    saveHandler={() => saveHandle()}  /> 
                </SubMenuMui>   
                <Modal show={loading}><Spinner /></Modal>
                <ApiErrorMessage    show={showError} 
                                    modalClosed={() => { setShowError(false); history.goBack() }} 
                                    errorResponse={errorResponse} 
                                    title={errorTitle}
                                    />    

                    <h3>Praxiseinstellungen </h3>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs   value={value} 
                                onChange={handleChange} 
                                aria-label="basic tabs example"   
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                >
                            <Tab label="Stammdaten" {...a11yProps(0)} />
                                                          
                            <Tab label="Formular" {...a11yProps(1)} />
                                                       
                            <Tab label="Einstellungen" {...a11yProps(2)} />

                            <Tab label="E-Mail" {...a11yProps(3)} />

                            <Tab label="Online" {...a11yProps(4)} />
                                                    
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {tab1}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {tab2}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {tab3}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {tab4}
                    </TabPanel>      
                    <TabPanel value={value} index={4}>
                        {tab5}
                    </TabPanel>                                     
                </Box>                      
                    

                   
                    </React.Fragment> );
}

export default Client;