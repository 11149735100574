import React from 'react';
import DaySelector from './DaySelector';

const DayList = (props) => {


    let dayList = null;
    if (props.appQueueItem && props.appQueueItem.days){
        dayList = props.appQueueItem.days.map((day, index) => {


            return <DaySelector day={day} 
                                key={day.day} 
                                readOnly={props.readOnly}
                                handleChangeDay={props.changeDayHandler}
                                />

        });

    }


    return <React.Fragment>
                {!props.headless &&
                <h5>Tag-/Zeitauswahl</h5>    }
                {dayList}
            </React.Fragment>


}

export default DayList;