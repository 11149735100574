import { C_PERIOD_MONTHS, C_PERIOD_QUARTERS, C_PERIOD_YEARS } from "./Statistics";



/************************Trendlines ********************************* */
/**
 * Calculate the simple moving average from stock prices.
 * @param {Array} prices - The list of prices.
 * @param {number} interval - The number of periods to calculate.
 * @return {Array} The list of SMA value.
 */
const simpleMovingAverage = (data, interval) => {
    let index = 1;
    const length = data.length + 1;
    let results = [];


    let intTmp = 1;

    while (index < length ) {
      //console.log('****************' + index + '****************');

      //minimum between interval and index
        if (index < interval ){
            intTmp = index;
        }
        else{
            intTmp = interval ;
        }
      
      const intervalSlice = data.slice(index - intTmp, index);

      const sum = intervalSlice.reduce((prev, curr) => prev + curr, 0);
      results.push(sum / intTmp);

      index++;
    }
    return results;
    
  }


/****************** index calc ************************************** */

const getStartYear = (noPeriods, period) => {
      //calc start year

      const latestStartYear = '2017';

      let d1 = new Date();
      switch (period) {
        case C_PERIOD_MONTHS: //months
            d1.setMonth(d1.getMonth() - noPeriods );   
            break;
        case C_PERIOD_QUARTERS: //quarters
            d1.setMonth(d1.getMonth() - ( noPeriods * 3 ) );  
            break;
        case C_PERIOD_YEARS: //years
            d1.setMonth(d1.getMonth() - ( noPeriods * 12 ) );  
            break;
        default:
          //nop
    } 

    const year = d1.getFullYear();

    if (year < latestStartYear){
        return year;
    }
    else{
        return latestStartYear;
    }

    
}

const getIndexLastPeriod = ( startYear, period) =>{

  let year = null;
  let month = null; 

  switch (period) {
    case C_PERIOD_MONTHS: //months
      // last period is last month
      const lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      year = lastMonth.getFullYear();
      month = lastMonth.getMonth() + 1;
      return getIndexForMonth(year, month, startYear, period);

    case C_PERIOD_QUARTERS: //quarters
      // last period is last quarter
      const lastQuarter = new Date();
      lastQuarter.setMonth(lastQuarter.getMonth() - 3);
      year = lastQuarter.getFullYear();
      month = lastQuarter.getMonth() + 1;
      return getIndexForMonth(year, month, startYear, period);

    case C_PERIOD_YEARS: //years
      // last period is last year
      const lastYear = new Date();
      lastYear.setMonth(lastYear.getMonth() - 12);
      year = lastYear.getFullYear();
      month = lastYear.getMonth() + 1;
      return getIndexForMonth(year, month, startYear, period);

    default: 
      return 0;
  }


}

const getIndexForMonth = (year, month, startYear, period ) => {
  //console.log('*** getIndexForMonth ***');
  //console.log('year: ' + year + ' month: ' + month + ' startYear: ' + startYear + ' period: ' + period);

  switch (period) {
    case C_PERIOD_MONTHS: //months
      //console.log('index: ' + ((year - startYear) * 12 + month - 1));
      return (year - startYear) * 12 + month - 1;
    case C_PERIOD_QUARTERS: //quarters
      //console.log('index: ' + ((year - startYear) * 4 + Math.ceil(month / 3) - 1));
      return (year - startYear) * 4 + Math.ceil(month / 3) - 1;
    case C_PERIOD_YEARS: //years
      //console.log('index: ' + (year - startYear));  
      return (year - startYear);
    default: 
      return 0;
  }
     
}


  
/******************lables************************************** */
  const getLabels = (noPeriods, period) => {
    let labels = [];
    //case statement with props.period
    switch (period) {
        case C_PERIOD_MONTHS: //months
            labels = getMonthLabels(noPeriods );
            break;
        case C_PERIOD_QUARTERS: //quarters
            labels = getQuarterLabels(noPeriods );
            break;
        case C_PERIOD_YEARS: //years
            labels = getYearLabels(noPeriods );
            break;
        default:
              //nop            
    } 
    return labels;
  }
  
  const getMonthLabels = (noPeriods) => {

    const today = new Date();
    const labelsStep = 1;
    const labelsOptions = { month: 'short', year: 'numeric' };
    const labelsLocale = 'de-DE';
    const labels = [];
    let date = new Date(today.getFullYear(), today.getMonth(), 1);
    date.setMonth(date.getMonth() - (noPeriods ) );
    
    for (let i=0; i < noPeriods; i++){
        labels.push(date.toLocaleDateString(labelsLocale, labelsOptions));
        date.setMonth(date.getMonth() + labelsStep);
    }

    return labels;
  }

  const getQuarterLabels = (noPeriods) => {
      
      const today = new Date();
      const labelsStep = 3;
      //set labelsOptions for quarter
      
      const labels = [];
      let date = new Date(today.getFullYear(), today.getMonth(), 1);
      //console.log('date: ' + date);
      //date.setMonth(date.getMonth() - (noPeriods * 3 ) );
      
      date.setMonth(date.getMonth() - (noPeriods * 3 ) );
      //console.log('date: ' + date);     
      for (let i=0; i < noPeriods; i++){
          labels.push(getQuarterOfYear(date.getMonth() ) + ' ' + date.getFullYear());
          date.setMonth(date.getMonth() + labelsStep);
      }
  
      return labels;
    }

    const getYearLabels = (noPeriods) => {
      
        const today = new Date();
        const labelsStep = 1;
        const labelsOptions = { year: 'numeric' };
        const labelsLocale = 'de-DE';
        const labels = [];
        let date = new Date(today.getFullYear(), today.getMonth(), 1);
        date.setFullYear(date.getFullYear() - noPeriods  );
        for (let i=0; i < noPeriods; i++){
            labels.push(date.toLocaleDateString(labelsLocale, labelsOptions));
            date.setFullYear(date.getFullYear() + labelsStep);
        }
    
        return labels;
      }

    const getQuarterOfYear = (month) => {
        return 'Q' + (Math.ceil(month / 3));

    }


  export { simpleMovingAverage, 
            getMonthLabels, 
            getQuarterLabels, 
            getYearLabels, 
            getLabels,
            getStartYear,
            getIndexForMonth,
            getIndexLastPeriod};