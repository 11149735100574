import React from 'react';



import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GlobalContextProvider } from './context/GlobalContext';
//import registerServiceWorker, {unregister} from './registerServiceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import swConfig from './swConfig'


ReactDOM.render(    <GlobalContextProvider>
                            <App />
                    </GlobalContextProvider>
                , document.getElementById('root'));
serviceWorkerRegistration.register(swConfig);
//registerServiceWorker();
//unregister();
