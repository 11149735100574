import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary';

import { MdPhone, MdSms } from "react-icons/md";
import MUIInput from './MUIInput';
import classes from './Phone.module.css';

const MuiPhone = (props) => {
    
    var icons = null;
    if (props.value && props.readOnly){
        const phoneLink = 'tel:' + props.value;
        const smsLink = 'sms:' + props.value;
        const iconSize = '17';
        icons = (<Auxiliary>
            <div className={classes.ActionIcon}>
                <a href={phoneLink}><MdPhone className={classes.Icon} size={iconSize}/></a>
            </div>
            <div className={classes.ActionIcon}><a href={smsLink}> <MdSms className={classes.Icon} size={iconSize}/></a></div>
        </Auxiliary>);
    }


    return (

        <MUIInput   
                id = {props.idx}       
                value={props.value}
                label={props.label} 
                readOnly={props.readOnly}
                prefix={icons}
                onChange={(event) => props.onChange(event, props.key)} 
                required={props.requrired}
        />
        
            )

}


export default MuiPhone;