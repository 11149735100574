import {  IconButton } from '@mui/material';
import React from 'react';
import classes from './Therapists.module.css';
import TherapistAvatar from './TherapistAvatar';
import FotoUpload from '../UI/FotoUpload/FotoUpload';
import { RiDeleteBin6Line } from "react-icons/ri";

const TherapistFoto = (props) => {


    let content = ( <img src={props.therapist.imageUrl} alt="Foto Therapeut"   height="200"></img> );

    let delButton =  <IconButton onClick={props.handleDeleteFoto} >
                        <RiDeleteBin6Line   />
                    </IconButton>  

    if (!props.therapist.imageUrl || props.therapist.imageUrl === ''){
        content = <TherapistAvatar therapist={props.therapist} big/>;
        delButton = null;
    }

    return <div>
                <div className={classes.Foto}>
                    {content}
                    {delButton}
                </div>
                <FotoUpload  storeFile={props.handleStoreFoto} />
               
            </div>;

}

export default TherapistFoto;