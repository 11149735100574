import React, {useContext } from 'react';
import GlobalContext from '../../../context/GlobalContext';
import DropDown from './DropDown';


const therapeutConfig = {
        id: "therapeut",
        label: "Therapeut"
        
    };


const TherapeutDD = (props) =>  {
 
    const globalContext = useContext(GlobalContext);

    therapeutConfig.options = globalContext.therapeutOptions;

    let value = props.value;
    if (!value){
        value = "";
    }

    if (props.label){
        therapeutConfig.label = props.label;
    }

    therapeutConfig.error = props.error;
    
    return (  <DropDown {...therapeutConfig}
                        value={value}
                        readOnly={props.readOnly}
                        onChange={props.onChange} />
                );
                
}
export default TherapeutDD;