import React  from 'react';
import { PureComponent } from 'react/cjs/react.production.min';
import Auxiliary from '../../hoc/Auxiliary';
import classes from './Attachments.module.css';
import TablePaginated from '../UI/Table/TablePaginated';
import Spinner from '../UI/Spinner/Spinner';
import FotoUpload from '../UI/FotoUpload/FotoUpload';
import Attachment from './Attachment';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import Facts from '../UI/Card/Facts';

class Attachments extends PureComponent{


    state = {  
        loading : false,
        confirmDelete: false,
        message: "",        
        deleteItem: null,       
        requestPopup: false, 
        showError: false,
        errorTitle: null,
        errorResponse: null,        
        tableConfig: {
            columns : [
                { 
                    colName:  'fileName',
                    colLabel: 'Name' 
                },
                { 
                    colName:  'description' ,
                    colLabel: 'Beschreibung' 
                },
                { 
                    colName:  'created_at' ,
                    colLabel: 'Datum' 
                },
                { 
                    colName:  'funcDownload' ,
                    colLabel: ''                         
                },                
                { 
                    colName:  'funcDelete' ,
                    colLabel: ''                         
                },                
            ],
            maxLines : 10,         
            renderLines : (items) => this.renderLines(items),
        }
    }

    showRequestPopup = (attachment) =>{
        this.setState({ requestPopup: true,
                        deleteItem: attachment});
    }

    renderLines = (items) => {
        return (
            <Auxiliary>
            {items && items.map((attachment, index) => {
                return (
                    <Attachment tableConfig={this.state.tableConfig}
                                key={index}
                                item={attachment}
                                deleteAttachmentHandler={(attachment) => this.showRequestPopup(attachment)} 
                                downloadAttachmentHandler={this.props.downloadAttachmentHandler} 
                                />
                    
            )})}
            </Auxiliary>
        );
    }


         


    render() {

        let items = this.props.attachments;
        let content = (<Spinner/>);
        if (!this.props.loading){

            let deletePopup = null;
            if (this.state.requestPopup){
               deletePopup = ( <YesCancelModal     clickYes={() => { this.setState({ requestPopup: false }) ; 
                                                                     this.props.deleteAttachmentHandler(this.state.deleteItem )}}
                                                   clickCancel={() =>  this.setState({ requestPopup: false } )}
                                                   title="Abfrage" >
                                           {'Möchtest du die Datei ' + this.state.deleteItem.fileName  + ' wirklich löschen?'}
                             </YesCancelModal> );
            }


            content = (<div className={classes.Content}>   
                                {deletePopup}
                                <Facts> 
                                    <legend>Datei hochladen/fotografieren</legend>
                                    <FotoUpload storeFile={this.props.storeAttachmentHandler} 
                                                disabled={false}
                                                withDescription={true}
                                                />
                                </Facts>
                                <TablePaginated tableConfig={this.state.tableConfig}  items={items}/>
                        </div>  )
            }

        return content;
    }

   

}

export default Attachments;