import React from 'react';
import {Link} from 'react-router-dom';
import { FaFileInvoice} from "react-icons/fa";

import classes from './InvoiceLink.module.css';
import SessionHandler from '../../../context/SessionHandler';
import { Tooltip } from '@mui/material';
import MUIInput from '../../MUI/MUIInput/MUIInput';


const InvoiceLink = (props) => {
    

    const iconSize = '20';
    let invoiceDetailButton = null;
    
    if (props.value && SessionHandler.authFinance() ){
        invoiceDetailButton = ( 
            <Tooltip title={'Rechnung Detail'}>
                <Link  to={{ pathname: '/financeMenu/invoices/' + props.value }}>
                    <FaFileInvoice  className={classes.Icon} size={iconSize}/>
                </Link>                        
            </Tooltip>);
    }

    return (    <MUIInput   id={props.idx}  
                            value={props.value} 
                            label={props.label}
                            readOnly
                            prefix={invoiceDetailButton}
                            /> 
               
            )

}


export default InvoiceLink;