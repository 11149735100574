import React, { Component } from 'react';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Layout from './components/Layout/Layout';
import GlobalContext from './context/GlobalContext';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider  } from '@mui/material/styles';





const theme = createTheme({
  palette: {
    primary: {
        main: '#BA9318',
        contrastText: "#fff" //button text white instead of black
    }
  },
});

class App extends Component {

  static contextType = GlobalContext;

  render() {
    
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'de'}>
        <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE}>
          <ThemeProvider theme={theme}>
            <Layout loading={this.context.loading} />
          </ThemeProvider>
        </BrowserRouter>
      </LocalizationProvider>
      
      
    );
  }

  componentDidMount(){
    //perform the login -accessing the localStorage 
    this.context.refreshLogin();
  }
}

export default App;
