import React from 'react';
import classes from './AppointmentSearch.module.css';


const timeslotItem = (props) => {

    
    return (
        <div  className={classes.Timeslot}   onClick={() => props.select(props.slot['date'], props.slot['from'], props.slot['to'])} >{props.slot['from']} - {props.slot['to']}</div>
    );    

}

export default timeslotItem;