import { Avatar } from '@mui/material';
import React from 'react';

const TherapistAvatar = (props) => {

    let src = null;
    //let image = null; 

    if ( props.therapist.imageUrl  ){
        src = props.therapist.imageUrl ;
    }
    const sx = {};

    if (props.small){

        sx.width = 30;
        sx.height = 30;
        sx.fontSize =  "12pt";
    }
    else if (props.medium){
        sx.width = 60;
        sx.height = 60;
        sx.fontSize =  "12pt";
    }
    else if (props.big){
        sx.width = 120;
        sx.height = 120;
    }

    sx.bgcolor = props.therapist.color;

    return <Avatar  sx={sx} src={src}>{props.therapist.prefix}</Avatar>

}

export default TherapistAvatar;