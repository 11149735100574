import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import Layout from '../../Layout/Layout';
import classes from './AppointmentSearch.module.css';
import TimeslotItem from './TimeslotItem';


const timeslotList = (props) => {
    var dateTmp = null;
    var content = null;
    
    content = props.slots.map((slot, index) => {

        var printDate = null;
        if (dateTmp !== slot['date']){
            const date= Layout.formatDate(slot['date']);
            printDate = (<Auxiliary><hr/><div className={classes.Date}>{date}</div></Auxiliary>);
            dateTmp = slot['date'];
        }
        else{
            printDate = null;
        }

        return ( <Auxiliary key={index}>
                    {printDate}
                    <TimeslotItem  slot={slot}  select={props.select}/>
                </Auxiliary>);
    });

    //content =  ( <div>Keine Termine frei...</div> );

    return (
        <div className={classes.Timeslots}>
            {content}
        </div>
    );    

}

export default timeslotList;