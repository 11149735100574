
import React from 'react';
import classes  from './PrescriptionLink.module.css';
import {FaPrescription} from "react-icons/fa";
import { Link } from 'react-router-dom';

const PrescriptionLink  = (props) => { 
    let prescription = null;
    if (props.prescriptionID){
        prescription = <div>
                            <Link to={{ pathname: '/prescriptions/' + props.prescriptionID}}>
                                <div className={classes.Prescription}>
                                        <FaPrescription className={classes.Icon} />
                                        { props.prescriptionID}    
                                </div>    
                            </Link>    
                        </div>
    }


    return  prescription;
}

export default PrescriptionLink;

