import React, { useState, useEffect }  from 'react';
import { FaSearchPlus } from "react-icons/fa";

import { RiEdit2Fill } from "react-icons/ri";


import classes from './AppDateInput.module.css';
import { Tooltip } from '@mui/material';
import DateField from '../DateField/DateField';
import TimeField from '../DateField/TimeField';
import AppointmentDetailMui from '../../Appointments/AppointmentDetailMui';
import dayjs from 'dayjs';
import DateUtility from '../../../hoc/DateUtility';
import MUIInput from './MUIInput';
import DoubleField from '../MUIContainer/DoubleField';
import AppointmentUtil from '../../Appointments/AppointmentUtil';
import CheckBox from './CheckBox';


const getToggelFullDay = (appType) => {
    if (appType  === AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT){
        return true;
    }
    else{
        return false;
    }
}

const MuiAppDateInput = (props) => {

    const [showToggelFullDay, setShowToggelFullDay] = useState(() => getToggelFullDay(props.appointment.appType) );


    //Set showToggleFullDay
    useEffect(() => {
        const tmp = getToggelFullDay(props.appointment.appType);
        if (tmp !== showToggelFullDay){
            setShowToggelFullDay(tmp);
        }

    }, [props.appointment.appType] );

    const iconSize = '20';
    //var linkButton = null;
    let content =  null;

    if (props.UICT.dateTimeReadOnly  || !props.UICT.dateTimeEditable ){
        //read only
        let text = DateUtility.formatDate(new Date(props.appointment.date), DateUtility.TYPE_LONG_CHAR)
        //if (props.appointment.appType !== AppointmentUtil.C_TYPE_HOLIDAY) {
        if (!props.appointment.fullDay){
            text = text  + ' ' + props.appointment.from + ' - ' + props.appointment.to;
        }
        else if (props.appointment.appType === AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT){
            //full day + normal appointment
            text = text + ' - ' + DateUtility.formatDate(new Date(props.appointment.dateTo), DateUtility.TYPE_LONG_CHAR)
        }
        let editToggel = null; 

        if (props.UICT.dateTimeEditable){
            editToggel =  <Tooltip title='Datum/Uhrzeit ändern'> 
                            <div onClick={props.UICT.dateTimeToggelHandler}  className={classes.Search}>
                                <RiEdit2Fill  className={classes.Icon} size={iconSize}/>                            
                            </div>
                        </Tooltip>
        }
        content =   <React.Fragment>
                        <MUIInput   label="Datum/Uhrzeit"
                                    value={text} 
                                    readOnly={true} 
                                    suffix={editToggel}
                                    />
                    </React.Fragment>
    }
    else{
        //edit mode
        let searchHelp = null;
        if (!props.UICT.searchDisable){
            searchHelp =  <Tooltip title='Termin Suche'> 
                            <div onClick={props.UICT.searchHandler}  className={classes.Search}>
                                <FaSearchPlus  className={classes.Icon} size={iconSize}/>                            
                            </div>
                        </Tooltip>
        }

        let from = null;      
        let to = null; 
        
        if (props.appointment.from) {
            const fromTimeArray = props.appointment.from.split(":");
            from = new Date();
            from.setHours(fromTimeArray[0], fromTimeArray[1]);
        }
        if (props.appointment.to) {
            const toTimeArray = props.appointment.to.split(":");
            to = new Date();
            to.setHours(toTimeArray[0], toTimeArray[1]);
        }

        const showDateTo = props.appointment.appType === AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT && props.appointment.fullDay;

        content = <React.Fragment>
                    <div className={classes.DateInputContainer}>
                        <DoubleField>
                            <DoubleField>
                                {searchHelp}
                                <DateField  label='Datum'
                                            value={props.appointment.date} 
                                            key={AppointmentDetailMui.DATE}
                                            readOnly={props.UICT.dateTimeReadOnly} 
                                            required
                                            onChange={(event) => props.changed(event, AppointmentDetailMui.DATE)}       
                                    /> 
                                    { showDateTo &&
                                        <DateField  label='Datum bis'
                                                    key={AppointmentDetailMui.DATE_TO}
                                                    value={props.appointment.dateTo} 
                                                    readOnly={props.UICT.dateTimeReadOnly} 
                                                    required
                                                    onChange={(event) => props.changed(event, AppointmentDetailMui.DATE_TO)}       
                                        /> 
                                    }
                                </DoubleField>
                            {!props.appointment.fullDay && 
                                <DoubleField>
                                    <TimeField  key= {AppointmentDetailMui.FROM}  
                                                value={from} 
                                                label='von'  
                                                required
                                                readOnly={props.UICT.dateTimeReadOnly} 
                                                minutesStep={15}
                                                minTime={dayjs().set('hour', 8)}
                                                maxTime={dayjs().set('hour', 20)}
                                                onChange={(event) => props.changed(event, AppointmentDetailMui.FROM)} 
                                                />
                                    <TimeField   key= {AppointmentDetailMui.TO}  
                                                value={to} 
                                                label='bis'  
                                                required
                                                minutesStep={15}
                                                minTime={dayjs().set('hour', 8)}
                                                maxTime={dayjs().set('hour', 20)}
                                                readOnly={props.UICT.dateTimeReadOnly} 
                                                onChange={(event) =>  props.changed(event, AppointmentDetailMui.TO)} 
                                                /> 
                                </DoubleField>
                            }
                        </DoubleField>
                        {showToggelFullDay &&
                            <div>
                            <CheckBox   checked={props.appointment.fullDay} 
                                        label="Ganztägiger Termin" 
                                        onChange={(event) =>  props.changed(event, AppointmentDetailMui.FULLDAY)} />
                            </div>
                        
                        }
                        </div>
                    </React.Fragment>
        
    }    


    return content;
}


export default MuiAppDateInput;