import React, { useContext } from 'react';
import classes from '../Therapists.module.css';
import GlobalContext from '../../../context/GlobalContext';
import PraxisChip from './PraxisChip';

const TheraCalendars = (props) => {
    
    const globalContext = useContext(GlobalContext);

    const list2 = globalContext.praxis.map((praxis, index) => {

        const objIndex = props.theraCalendars.findIndex((obj => obj.PRXID === praxis.PRXID));
        let inactive = true;
        if (objIndex !== -1){
            inactive = false;
        }

        if (!praxis.inactive){
            return <PraxisChip praxis={praxis} 
                                key={praxis.PRXID}
                                inactive={inactive} 
                                readOnly={props.readOnly}
                                handleChangeTheraCalender={props.handleChangeTheraCalender}/>

        }


        

    });
    return <React.Fragment>
                {!props.headless &&
                <h5>Standorte/Praxen</h5> }
                <div  className={classes.PraxisList}>{list2}</div>
            </React.Fragment>
   

}

export default TheraCalendars;