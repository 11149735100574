import React from 'react';

import classes from './Talk.module.css';

 



const talkMessage = (props) => {


    let date = new Date(props.message.timestamp * 1000);
    let dateFormatted =  String(date.getHours()).padStart(2, "0") + ':' + String(date.getMinutes()).padStart(2, "0"); 

    return(
        <div className={classes.Message}>
            <div className={classes.Caption}>   
                    <div className={classes.Element}>{props.message.actorDisplayName}</div>   
                    <div className={classes.Element}>{dateFormatted}</div>   
            </div>
            {props.message.message}
            
        </div>
    );

}


export default talkMessage;