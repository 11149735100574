import React, {useState} from 'react';
import classes from './InvItemCnt.module.css';
import InvoiceItems from '../InvoiceItems';
import Input from '../../UI/Input/Input';
import Modal from '../../UI/Modal/Modal';
import ModalHeader from '../../UI/Modal/ModalHeader';
import ModalContent from '../../UI/Modal/ModalContent';
import ServiceCatalogueMui from '../../ServiceCatalogue/ServiceCatalogueMui';
import { IoAddCircleOutline } from "react-icons/io5";
import { ImMagicWand } from "react-icons/im";
import { IconButton, Tooltip } from '@mui/material';


const InvItemAppointmentCnt = (props) => {
    
    const [modalIsOpen, setModalIsOpen] = useState(false);

    function closeModal(){
        setModalIsOpen(false);
    }

    const styleConfigSmall = {
        inputStyle: 'InputDetail',
        labelStyle: 'LabelLight',
        inputElementStyle:'InputElemMenu'
    };          

    var total = 0;
    if (props.appointment.invoice_items){
        for (var i=0; i < props.appointment.invoice_items.length; i++){
            total = total + props.appointment.invoice_items[i]['ANZAHL'] * props.appointment.invoice_items[i]['PREIS'];
        }
    }
    if (!total){
        total = "0";
    }
    
    
    
    var buttons = null;
    if (!props.config.readOnly){
        
        var propsalDisable = true;
        if (props.appointment.patientID > 0 && props.appointment.invoice_items.length === 0){
            propsalDisable = false;

        }
        /*
        buttons = ( <div className={classes.Buttons}>
                        <Button btnType="EditMenuButton" click={() => setModalIsOpen(true)} disabled={false} ><MdAddCircle/>Neu</Button>
                        <Button btnType="EditMenuButton" click={props.config.onGetProposal} disabled={propsalDisable} ><CgPlayListAdd/>Vorschlag</Button>
                    </div>           
        );
        */
        buttons = ( <div className={classes.Buttons}>
            <Tooltip title="Neue Leistung hinzufügen">
                <span>
                    <IconButton onClick={() => setModalIsOpen(true)} >
                        <IoAddCircleOutline />
                    </IconButton>  
                </span>
            </Tooltip>
            { !props.config.hideProposal &&
                <Tooltip title="Leistungen vorschlagen">
                    <span>
                        <IconButton onClick={() => props.config.onGetProposal()} disabled={propsalDisable}>
                            <ImMagicWand />
                        </IconButton>  
                    </span>
                </Tooltip>                        
               

            }
        </div>);        
    }

    var serviceCatalogue = null;
    if (modalIsOpen){
        serviceCatalogue = (
            <Modal show={modalIsOpen} modalClosed={closeModal} type='BigModal'>
                <ModalHeader onClose={closeModal} title="Leistungskatalog"></ModalHeader>
                <ModalContent>
                    <ServiceCatalogueMui onAdd={props.config.onAdd} disabledItems={props.appointment.invoice_items}  modalClosed={closeModal}></ServiceCatalogueMui>
                </ModalContent>
            </Modal>
        );
    }

    
    return  (
        <fieldset className={classes.InvoiceItemsAppointment}> 
            
            <div  className={classes.ItemsTitle}>Leistungen</div>
            <div className={classes.Menu}>
                
                {buttons}
                <div className={classes.Price}>
                    <Input label='Gesamtpreis' styleConfig= {styleConfigSmall}  elementConfig={{type:'currency'}} 
                    changed={null} value={total}   readOnly={true} />
                </div>
            </div>
            
            <InvoiceItems   invoiceItems={props.appointment.invoice_items} 
                            config={props.config} ></InvoiceItems>
            {serviceCatalogue}
        </fieldset>            
        );
    };
    
export default InvItemAppointmentCnt;
