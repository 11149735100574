import React, { useMemo, useState } from 'react';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar  } from 'react-chartjs-2';
import classes from './Statistics.module.css';
import { C_HOURS_PER_MONTH, C_REVENUE_PER_MONTH } from './Statistics';
import { getIndexForMonth, getIndexLastPeriod, getLabels, getStartYear,  simpleMovingAverage } from './StatisticsUtils';


Chart.register(CategoryScale);

/*
  const getIndexForMonth = (year, month, startYear ) => {
        const index = (year - startYear) * 12 + month - 1;
        return index;   
  }
  */

  const subSet = (data, noPeriods, idxLastMonth) => {
        const min = Math.min(idxLastMonth, data.length);
        const ret =  data.slice(min - noPeriods + 1, min + 1);
        /*
        console.log('**************************************');
        console.log( data);
        console.log('min: ' + min + ' noPeriods: ' + noPeriods + ' idxLastMonth: ' + idxLastMonth);
        console.log('data.length: ' + data.length );
        console.log('from: ' + (min - noPeriods + 1) + ' to: ' + (min + 1));
        console.log( ret);
        */
        return ret ;
  }


const StatsTherapist = (props) => {

  const [chartData, setChartData] = useState({
    labels: [], 
    datasets: [ ]

  });
  //const [startYear, setStartYear] = useState(2017);
  //const [idxLastMonth, setIdxLastMonth] = useState(0);


      /* initialize charData ********************************************************/  
      useMemo(() => {

            //calc start year
            const startYear = getStartYear(props.noPeriods, props.period);    
            // store last month
            /*
            const lastMonth = new Date();
            lastMonth.setMonth(lastMonth.getMonth() - 1);

            const idxLastMonth = getIndexForMonth(lastMonth.getFullYear(), lastMonth.getMonth() + 1, startYear, props.period);   
            console.log('idxLastMonth: ' + idxLastMonth);
            */
            const idxLastMonth = getIndexLastPeriod(startYear, props.period);
            /********** prepare labels *************************** */
            let labels = getLabels(props.noPeriods, props.period);
            /********** prepare datasets *************************** */
            let bars = [];
            let lines = [];
            let datasets = [];

            let dataSet = null;
            const overallData = [];
            let sum = 0;
            
            for (const kpi of props.performanceStats){
                //init dataset
                if (!dataSet || dataSet.therapeut !== kpi.therapeut){
                    if (dataSet){
                        //store dataset
                        if (props.average && !props.stacked){
                            //create average per therapist if requested
                            const SMA = simpleMovingAverage(dataSet.data, props.movAvg);
                            /*
                            console.log(`***` + dataSet.label + `***`  );
                            console.log('data: ' + dataSet.data);
                            console.log('SMA: ' + SMA);
                            console.log('subset SMA: ' + subSet(SMA, props.noPeriods, idxLastMonth));
                            */
                            const col = dataSet.backgroundColor[0] ;
                            const av =      {
                                label: '⌀ ' + dataSet.label,
                                data: subSet(SMA, props.noPeriods, idxLastMonth),
                                borderColor: col,
                                borderWidth: 2,
                                type: 'line', // specify the dataset type as a line
                                cubicInterpolationMode: 'monoton',
                                tension: 0.4,
                                pointStyle : 'dash',
                                fill: false,
                                };
                            lines.push(av);
                        }

                        if (props.showBars){
                            dataSet.data = subSet(dataSet.data, props.noPeriods, idxLastMonth);
                            bars.push(dataSet);
                        }
                        sum = 0;
                    }
                    //create new dataset
                    const therapeut = props.therapists.find(therapeut => therapeut.PERNR === kpi.therapeut);
                    if (therapeut)  {

                        dataSet = {     therapeut: kpi.therapeut,
                                        label: therapeut.vorname, 
                                        data: [], 
                                        backgroundColor: [therapeut.color] };
                    }
                    else{
                        dataSet = null; 
                        continue;
                    }
                }

                //add data 
                let val = 0;
                if (props.reportType === C_HOURS_PER_MONTH){
                    val = parseFloat(kpi.hours);
                }
                else{
                    val = parseFloat(kpi.amount);
                }

                const idx = getIndexForMonth(kpi.year, kpi.month, startYear, props.period);
                //console.log('idx: ' + idx + ' year: ' + kpi.year + ' month: ' + kpi.month + ' startYear: ' + startYear + ' period: ' + props.period);

                dataSet.data[idx] = dataSet.data[idx] ? dataSet.data[idx] + val : val;
                sum = sum + val;
                overallData[idx] = overallData[idx] ? overallData[idx] + val : val;
            }
          
            // add last dataset
            if (dataSet){
                dataSet.data = subSet(dataSet.data, props.noPeriods, idxLastMonth);
                bars.push(dataSet);
            }

            //add overall data
            if (props.average && props.stacked){
              
                //const trend = logaritmicTrendline(overallData);
                //average overall therapists (in case of stacked chart)
                const SMA = simpleMovingAverage(overallData , props.movAvg);
                            
                const av =      {
                    label: '⌀ Gleitender Durchschnitt',
                    data: subSet(SMA, props.noPeriods, idxLastMonth),
                    borderColor: '#BA9318',
                    borderWidth: 2,
                    type: 'line', 
                    cubicInterpolationMode: 'monoton',
                    tension: 0.4,
                    pointStyle : 'dash',
                    stack: 'stack1',
                    //lineTension: 0.4,
                    fill: false,
                    };

                lines.push(av);
            }

            datasets = lines.concat(bars);

            setChartData({
                labels: labels, 
                datasets: datasets
            });

      }, [props]);


  let axisLabel = 'Stunden (h)';
  if (props.reportType === C_REVENUE_PER_MONTH){
        axisLabel = 'Umsatz (€)';
  }

  const options = {
    maintainAspectRatio : false,
    plugins: {
      legend: {
        position: 'top' ,
      },
      title: {
        display: true,
        text: props.title,
      },
    },
    responsive: true,
    scales: {
        x: {
            stacked: props.stacked,
        },
        y: {
            stacked: props.stacked,
            display: true,
            beginAtZero: true,
            title: {
              display: true,
              text: axisLabel }
        }
    }
    
  };

  //transform perfomanceStats to chartData

    return (
        <div>
             <div className={classes.BarChart}>
                <Bar 
                    data={chartData}
                    options={options}
                />
            </div>
        </div>
    );


}


export default StatsTherapist;