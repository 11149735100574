import React, {Component} from 'react';

import axios from 'axios';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';

import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import TalkMessages from './TalkMessages';
import classes from './Talk.module.css';
import { IoMdSend } from "react-icons/io";
import GlobalContext from '../../context/GlobalContext';


class Talk extends Component{

    bottomRef = React.createRef();

    static contextType = GlobalContext;

    //nextcloudChatUrl = process.env.REACT_APP_TALK_URL;

    state = { 
        messages: [],
        message: null,
        loading: true,
        styleConfig: {
            inputStyle: 'InputDetail',
            labelStyle: 'LableNone',
            inputElementStyle:'TextArea'
        },
        elementConfig: {
            type: 'text',
            placeholder: 'Schreibe eine neue Nachricht...' 
        },
        newMessage: ''

    };

    inputChangedHandler = (event) =>{
        
        
        this.setState({ newMessage: event.target.value });
        
    }

    richTextChangeHandler = (htmlText, elemID) =>{


        
        this.setState({ newMessage: htmlText });

    }      

    newMessageHandler = (event) =>{

        let text = this.state.newMessage;
        this.sendMessages(text);

    }
        
    render() {

        return (<div className={classes.Container}>
                    <Modal show={this.state.loading}><Spinner/></Modal>
                    <TalkMessages messages={this.state.messages}/>
                    <hr className={classes.HL}/>
                    <div className={classes.NewMessageBox} ref={this.bottomRef}>
                        <div className={classes.TextBox}>
                            <Input
                             elementType= 'textArea'                             
                             changed={(event) => this.inputChangedHandler(event)} 
                             value={this.state.newMessage} 
                             styleConfig= {this.state.styleConfig}
                             elementConfig = {this.state.elementConfig}
                             readOnly={false} />
                        </div>
                        <Button btnType="TableButton" 
                                click={this.newMessageHandler} 
                                disabled={!this.state.newMessage} 
                                tooltip={'Nachricht senden'}><IoMdSend  className={classes.Icon}/></Button>
                    </div>
                </div>
            );
    }

    componentDidMount(){

        this.loadMessages();
        this.scrollToBottom();

    }
    
    componentDidUpdate(){
        this.scrollToBottom();
    }    

    scrollToBottom = () => {
        if (this.bottomRef != null){
            if (this.bottomRef.current != null){
                this.bottomRef.current.scrollIntoView();
            }
        } 
      }    

    sendMessages = (message) => {

        const formData = {};
        
        formData['message'] = message;        
        //const url = this.nextcloudChatUrl + process.env.REACT_APP_TALK_TOKEN;
        this.setState({ loading: true});

        //axios.post(url, formData)
        axios.post('/talk', formData)
            .then(response => {
                
                this.setState({ newMessage: null});

                this.loadMessages(true);

                //this.props.history.replace('/talk');
            })
            .catch( error => {
                console.log(error.response); 
                this.setState({ loading: false});
                //TODO: Error popup
              });
    }


    loadMessages = (noPrefix = false) =>{

        /*
        const url = this.nextcloudChatUrl + process.env.REACT_APP_TALK_TOKEN + '?lookIntoFuture=0&limit=20&format=json';
        console.log(url);
        const config = {
            method: 'get',
            url: url,
            headers: this.headers
        }        

        */

        //axios.get(config.url, config)
        axios.get('/talk')
            .then(response => {
                let messages = response.data.ocs.data;
                messages.sort(function(x, y){
                    return x.timestamp - y.timestamp;
                });

                //if patient prefix
                let newMessage = null;
                if (this.props.match.path === '/talk/:id' && !noPrefix){
                    const patientID = this.props.match.params.id;
                    //read from global context
                    
                    const pat = this.getPatient(patientID);
                    newMessage = this.getPrefix(pat);  
                }

                this.setState({ loading: false, 
                                newMessage: newMessage,
                                messages: messages });

            })
            .catch( error => {
                console.log(error); 
                //TODO: Error popup
              });
    }

    getPrefix(patient){

        return patient.firstName + ' ' + patient.lastName + ' (' + patient.phone + '):';
    }

    getPatient( id) {
        for (var i=0, iLen=this.context.patients.length; i<iLen; i++) {   
          if (Number(this.context.patients[i].ID) === Number(id)) {
              return this.context.patients[i];
        }
      }
    }





}


export default Talk;