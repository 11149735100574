
import React from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';

import GlobalContext from '../../context/GlobalContext';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DetailContainer from '../Layout/DetailContainer';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import UserMenu from './UserMenu';
import TherapeutDD from '../MUI/DropDown/TherapeutDD';
import Password from './Password';
import SessionHandler from '../../context/SessionHandler';
import PasswordDialog from './PasswordDialog';

const User = (props) => {

    const history = useHistory();
    const { username } = useParams();
    const globalContext = useContext(GlobalContext);
    
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');

    const [pwdPopup, setPwdPopup] = useState(false);

    /* initialize component ********************************************************/
    useEffect(() => {
        
        if (props.new){
            const newUser = {
                prmDocu: false,
                prmEmail: false,
                prmInvoices: false,
                prmPatienten: false,
                prmPayroll: false,

                prmRegisterService:false, 
                prmRelease: false,
                prmSetup: false,
                prmTalk: false,
                inactive: false
            };
            setUser(newUser);
        }
        else{
            // read therapist from context

            const objIndex = globalContext.users.findIndex((obj => obj.userName === username));
            if (objIndex !== -1){
                const u = globalContext.users[objIndex];
                setUser(u);
            }
            else if (   !globalContext.usersLoaded ){
                history.push("/setup/users");
            }
            else{
                //error 

            }
        }
        }, [username]);
    /***end: initialize component *************************************************** */

    const saveHandler = () =>{
        
        if (props.new){
            createUser();
            return;
        }
        
        // update logic 
        setLoading(true);
        axios.put('/users/' + user.userName   , user)
        .then (response => {
            const updatedUser = response.data.data;

            globalContext.updateUser(updatedUser);

            setUser(updatedUser);
            setLoading(false);
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Speichern des Benutzerkontos");
            setLoading(false);
        });  
    }
        
    const deleteHandler = () => {
    
            // update logic 
            setLoading(true);
            axios.delete('/users/' + user.userName )
            .then (response => {
    
                globalContext.deleteUser( user.userName );
    
                setLoading(false);
    
                const message = {
                    type: 'S',
                    text: 'Benutzerkonto gelöscht'
                }
                globalContext.showMessage(message);                             
                history.goBack();
            })
            .catch (error => {
                console.log(error); 
                setShowError(true);
                setErrorResponse(error.response);
                setErrorTitle("Fehler beim Löschen des Benutzerkontos");
                setLoading(false);
            });  
        }
   
    

    const createUser = () =>{

        setLoading(true);
        axios.post('/users'  , user)
        .then (response => {
            const newUser = response.data.data;

            globalContext.updateUser(newUser);

            //setTherapist(newTherapist);
            //setLoading(false);
            history.replace('/setup/users/' + newUser.userName);

        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Anlegen des Benutzerkontos");
            setLoading(false);
        });  
        
    }

    const savePassword = () =>{

        // update logic 
        setPwdPopup(false);
        setLoading(true);
        
        axios.put('/users/' + user.userName  + '/password' , user)
        .then (response => {
            const message = {
                type: 'S',
                text: 'Neues Passwort gespeichert'
            }
            globalContext.showMessage(message);    
            const copyUser = { ...user };
            copyUser.password  = '';
            copyUser.password_confirmation = '';
            setUser(copyUser);
            setLoading(false);
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Speichern des Passworts");
            setLoading(false);
        });  

    }

    const inputChangedHandler = (value, inputID) =>{
        const updatedForm = { ...user };
        updatedForm[inputID] = value;
        setUser(updatedForm);
    }



        let content = null; 

        if (user) {
            let readOnlyUserName = true;
            if (props.new){
                readOnlyUserName = false;
            }
            content = <div>{user.name}</div>;
            content = <React.Fragment>
                            
                            <ApiErrorMessage    show={showError} 
                                                modalClosed={() => { setShowError(false) }} 
                                                errorResponse={errorResponse} 
                                                title={errorTitle}
                                                />    
                            <DetailContainer> 
                                <FactsMui>               
                                    <MUIInput       id={'userName'}
                                                    label='Benutzername' 
                                                    value={user.userName}
                                                    fullWidth
                                                    readOnly={readOnlyUserName}
                                                    required
                                                    onChange={ (event) => inputChangedHandler(event.target.value, 'userName') }
                                                    />   
                                    <MUIInput       id={'name'}
                                                    label='Name' 
                                                    value={user.name}
                                                    fullWidth
                                                    required
                                                    onChange={ (event) => inputChangedHandler(event.target.value, 'name') }
                                                    />   
                                    { props.new &&  
                                       <Password user={user} inputChangedHandler={inputChangedHandler} />
                                    }
                                    <FormControl fullWidth>
                                        <TherapeutDD    value={user.pernr}
                                                        label="Zugeordneter Therapeut (optional)"
                                                        onChange={(event) => inputChangedHandler(event.target.value, 'pernr')} />  
                                        <FormHelperText>Bei der Anlage neuer Termine wird der zugeordnete Therapeut vorbelegt. Die "heute-Ansicht" wird auf diesen Therapeuten gefiltert</FormHelperText>                                                              
                                    </FormControl>                                           
                                    <FormControlLabel control={  <Switch    checked={user.inactive}
                                                                            disabled={SessionHandler.user.userName === user.userName}
                                                                            onChange={(event) => inputChangedHandler(event.target.checked, 'inactive')}
                                                                            />    } 
                                                                           
                                                    label="Inaktiv" />                                                       
                                    <h3>Berechtigungen</h3>
                                    <FormControl>
                                        <FormControlLabel control={  <Switch    checked={user.prmPatienten}
                                                                                onChange={(event) => inputChangedHandler(event.target.checked, 'prmPatienten')}
                                                                                />    } 
                                                        label="Patienten anlegen, ändern, löschen" />   
                                        <FormHelperText>Patienten anlegen, ändern, löschen</FormHelperText>      
                                    </FormControl>   
                                    <FormControl>
                                        <FormControlLabel control={  <Switch    checked={user.prmDocu}
                                                                                onChange={(event) => inputChangedHandler(event.target.checked, 'prmDocu')}
                                                                                />    } 
                                                        label="Patientendokumentation anzeigen, ändern" />   
                                        <FormHelperText>Anzeige und Änderung von Patientdokumentation (Patientenstammblatt und Termindokumentation).</FormHelperText>      
                                    </FormControl>       
                                    <FormControl>
                                        <FormControlLabel control={  <Switch    checked={user.prmRelease}
                                                                                onChange={(event) => inputChangedHandler(event.target.checked, 'prmRelease')}
                                                                                />    } 
                                                        label="Patiententermine freigeben" />   
                                        <FormHelperText>Patiententermine für die Abrechnung freigeben</FormHelperText>      
                                    </FormControl>    
                                    <FormControl>
                                        <FormControlLabel control={  <Switch    checked={user.prmInvoices}
                                                                                onChange={(event) => inputChangedHandler(event.target.checked, 'prmInvoices')}
                                                                                />    } 
                                                        label="Rechnungen anlegen, ändern, löschen" />   
                                        <FormHelperText>Zugriff auf das Abrechnungsmodul</FormHelperText>      
                                    </FormControl>     
                                    <FormControl>
                                        <FormControlLabel control={  <Switch    checked={user.prmSetup}
                                                                                disabled={SessionHandler.user.userName === user.userName}
                                                                                onChange={(event) => inputChangedHandler(event.target.checked, 'prmSetup')}
                                                                                />    } 
                                                        label="Administrator" />   
                                        <FormHelperText>Administrationszugriff: Praxiseinstellung, Leistungskatalog, etc</FormHelperText>      
                                    </FormControl>                                                                                                                                           
                                </FactsMui>
                            </DetailContainer>
                        </React.Fragment>

        }

        let title = "Benutzerkonto bearbeiten";
        if (props.new){
            title = "Benutzerkonto anlegen";
        }

        let pwdDialog = null;
        if (pwdPopup){

            pwdDialog = <PasswordDialog user={user} 
                                        modalClose={() => setPwdPopup(false)}
                                        inputChangedHandler={inputChangedHandler}
                                        savePassword={savePassword}/>
        }

        return <React.Fragment>
                    <Modal show={loading}><Spinner/></Modal>
                    {pwdDialog}
                    <SubMenuMui  history={history}>
                            <UserMenu   new={props.new}
                                        disabled={user && SessionHandler.user.userName === user.userName}
                                        saveHandler={() => saveHandler()}
                                        deleteHandler={() => deleteHandler()}  
                                        pwdHandler={() => setPwdPopup(true)}/> 
                    </SubMenuMui>  
                    <h2>{title} </h2> 
                    {content}
                </React.Fragment>    

}
export default User; 