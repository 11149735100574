import React,   { useCallback } from 'react';
import '../../../globalStyle.css';
import {useHistory} from 'react-router-dom';
import {FaPrescription} from "react-icons/fa";



import { IconButton } from '@mui/material';
import SessionHandler from '../../../context/SessionHandler';
import MUIInput from '../MUIInput/MUIInput';
import DropDown from './DropDown';


const MUIPrescriptionInput = (props) => {
    const history = useHistory();
    const navigateToPrescription = useCallback(() => history.push('/prescriptions/' + props.value ), [history, props.value]);

    let preIcon = null;
    if (props.value && SessionHandler.authSensitiveData() ){
        preIcon =   <IconButton
                        onClick={() => navigateToPrescription()}
                        edge="end"
                        >
                        <FaPrescription className='IconMUI' />
                    </IconButton>;
    }

   

    let element = null;
    if (props.readOnly){
        element = <MUIInput {...props}  prefix={preIcon}/>;
    }
    else{

        let val = props.value;
        if (!val){
            val = '';
        }
    
        element = <DropDown {...props}
                        value={val}
                        readOnly={false}
                        prefix={preIcon}
                        onChange={props.onChange} />
    }

    return element;
}


export default MUIPrescriptionInput;