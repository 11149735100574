import React from 'react';
import Card from '../UI/Card/Card';
import { MdLocationOn} from "react-icons/md";
import {Link} from 'react-router-dom';
import classes from './Praxis.module.css';

const PraxisListItem = (props) => {

    let style = classes.Praxis;

    let name = props.praxis.PRAXIS_NAME ;


    if (props.praxis.inactive){
        
        style = classes.PraxisInactive;    
        name = name + ' (inaktiv)'
    }


    return <Card style={style} >
                <div className={classes.CardContent}>&nbsp; 
                <Link  to={{ pathname: '/setup/praxis/' + props.praxis.PRXID }}><MdLocationOn />{name}</Link></div>
            </Card>

}

export default PraxisListItem;