import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import GlobalContext from '../../context/GlobalContext';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import InvoiceItemPlain from './InvoiceItemPlain';


const InvoiceItemSetup = (props) => {

    const globalContext = useContext(GlobalContext);
    //console.log(globalContext.services.single);
    
    // state for managing the confirmation popup visiblity
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [invoiceItem, setInvoiceItem] = useState({...props.invoiceItem});
    const [readOnly, setReadOnly] = useState({...props.readOnly});

    useEffect(() => {
        setInvoiceItem({...props.invoiceItem});
    }, [props.invoiceItem]);


    const  invoiceChangeHandler = (invID, field, val) => {
        const invoiceClone = {...invoiceItem};
        invoiceClone[field] = val;
        setInvoiceItem(invoiceClone);
    }

    const invoiceResetHandler = ( )  => {
        setInvoiceItem({...props.invoiceItem});
    }


    const deleteHandler = () =>{
        setLoading(true);
        if (invoiceItem.LK){
            axios.delete('/serviceChains/' +  invoiceItem.ID)   
            .then (response => {
                setLoading(false);
                globalContext.deleteServiceChain(invoiceItem.ID);
                
                })
            .catch (error => {
                console.log(error); 
                setErrorTitle("Fehler bei Löschen der Leistungskette");
                setErrorResponse(error.response );
                setShowError(true);
                setLoading(false);
            });     
        }
        else{
            axios.delete('/invoiceItems/' +  invoiceItem.ID)   
            .then (response => {
                globalContext.deleteSingleService(invoiceItem);
                setLoading(false);
                setReadOnly(true);
                })
            .catch (error => {
                console.log(error); 
                setErrorTitle("Fehler bei Löschen der Leistung");
                setErrorResponse(error.response );
                setShowError(true);
                setLoading(false);
            });             
        }
    }

    const saveHandler = () => {
        setLoading(true);
        const formData = {};
        formData['ZIFFER'] = invoiceItem.ZIFFER;
        formData['Einzelleistung'] = invoiceItem.Einzelleistung;
        formData['PREIS'] = invoiceItem.PREIS;
        formData['ORDNR'] = invoiceItem.ORDNR;

        axios.put('/invoiceItems/' +  invoiceItem.ID,  formData)   
        .then (response => {
            globalContext.updateSingleService(response.data.data);
            setLoading(false);
            setReadOnly(true);
            })
        .catch (error => {
            console.log(error); 
            setErrorTitle("Fehler bei Speichern der Leistung");
            setErrorResponse(error.response );
            setShowError(true);
            setLoading(false);
        });     
    }

/***********************************************************************************************************************************************************
* Buttons
*/
    //render delete button in case of not readOnly

    const closeErrorPopup = () => {
        setShowError(false);
        setErrorResponse(null);
        setErrorTitle('');
    }
    

    return (
        <React.Fragment>
            <InvoiceItemPlain   {...props}
                                onSave={saveHandler}
                                onDelete={deleteHandler}
                                invoiceItem={invoiceItem}
                                onChange={invoiceChangeHandler}
                                onReset={invoiceResetHandler}
                                readOnly={readOnly}
                                setReadOnly={setReadOnly}
                                />
                <Modal show={loading}><Spinner/></Modal>
                <ApiErrorMessage    show={showError} 
                                    modalClosed={closeErrorPopup} 
                                    errorResponse={errorResponse} 
                                    title={errorTitle}
                                    />    
        </React.Fragment>
    );

};



export default InvoiceItemSetup;
