import React from 'react';
import MUIInput from '../MUIInput/MUIInput';
import { TimePicker } from '@mui/x-date-pickers';


const TimeField = (props) =>  {
    
    return      <TimePicker
                    {...props}
                    renderInput={(params) =>  <MUIInput {...params} 
                                                        readOnly={props.readOnly} 
                                                        prefix={props.prefix} 
                                                        required={props.required}
                                                        value={props.value}
                                                        onChange={() => {/* direct input not possible */} }
                                                        />   } 
                    
                    />    
            
}
export default TimeField;