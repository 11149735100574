import {  RiCheckboxBlankCircleLine, RiErrorWarningLine , RiCheckboxCircleLine, RiCloseCircleLine } from  "react-icons/ri";
import {  AiOutlineFileExclamation } from  "react-icons/ai";

import classes from './Invoice.module.css';

class InvoiceUtil{


    static C_STAT_OPEN  = 50;
    static C_STAT_OVERDUE = 51; 
    static C_STAT_PAID = 52;
    static C_STAT_WRITEOFF = 53;

    static C_STAT_DUNN_L1 = 55;
    static C_STAT_OVERDUE_L1 = 56;
    static C_STAT_DUNN_L2 = 57;
    static C_STAT_OVERDUE_L2 = 58;    
  


    static C_TYPE_HP = 'HP';
    static C_TYPE_PHYSIO = 'Physio';
    static C_TYPE_SZ = 'SZ';
    static C_TYPE_SZ_HP = 'SZ-HP';

    static C_DUNNING_LEVEL_1 = 'L1';
    static C_DUNNING_LEVEL_2 = 'L2';

    static isOverDue(status){
        if (
            status === InvoiceUtil.C_STAT_OVERDUE ||
            status === InvoiceUtil.C_STAT_OVERDUE_L1 ||
            status === InvoiceUtil.C_STAT_OVERDUE_L2
        ){
            return true;
        }
        else{
            
            return false;
        }
    }

    static isDunningStatus(status){
        if (
            status === InvoiceUtil.C_STAT_DUNN_L1 ||
            status === InvoiceUtil.C_STAT_DUNN_L2 
        ){
            
            return true;
        }
        else{
            
            return false;
        }
    }    


    static getStatusIcon(status) {
        
        switch (status) {
            case  InvoiceUtil.C_STAT_OPEN:
                return (<RiCheckboxBlankCircleLine className={classes.IconOpen}/>);
            //case  InvoiceUtil.C_STAT_OVERDUE:
            //    return (<RiErrorWarningLine className={classes.IconOverdue}/>);
            case  InvoiceUtil.C_STAT_PAID:
                return (<RiCheckboxCircleLine className={classes.IconSuccess}/>);     
            case  InvoiceUtil.C_STAT_WRITEOFF:
                return (<RiCloseCircleLine className={classes.IconWriteOff}/>);     
            default:
                if ( InvoiceUtil.isOverDue(status) ){
                    return (<RiErrorWarningLine className={classes.IconOverdue}/>);
                }
                else if ( InvoiceUtil.isDunningStatus(status)){
                    return (<AiOutlineFileExclamation className={classes.IconDunned}/>);
                }
                else{
                    return null;
                }
                
        } 
    }      


}


export default InvoiceUtil;