import React from 'react';
import classes from './Status.module.css';

import { Tooltip } from '@mui/material';


const Status = (props) =>  {
  
    const inputClasses = [];
    
    inputClasses.push(classes.Status);
    inputClasses.push(classes['Status'+ props.lookupValue]);

    let content = props.value;
 
    const status = <Tooltip  title={props.value}><div className={inputClasses.join(' ')}>{content}</div></Tooltip>;
    
    return status;  

}

export default Status;