import React from 'react';
import DropDown from './DropDown';
import AppointmentUtil from '../../Appointments/AppointmentUtil';


const frequencyConfig = {
        id: "frequencyId",
        options: [  {value: AppointmentUtil.C_FREQ_NONE, label: 'nie'},
                    {value: AppointmentUtil.C_FREQ_WEEKLY, label: 'wöchtenlich'}, ]      
    };


const Frequency = (props) =>  {
 
    let value = props.value;
    if (!value ){
        value = "-";
    }

    let label = "Wiederholung";
    
    if (props.label){
        label = props.label;
    }

    frequencyConfig.error = props.error;
    
    return (  <DropDown {...frequencyConfig}
                        label={label}
                        value={value}
                        readOnly={props.readOnly}
                        onChange={props.onChange} />
                );
                
}
export default Frequency;