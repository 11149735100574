import React from 'react';
import classes from './InvoiceItems.module.css';
import InvoiceItemPlain from './InvoiceItemPlain';
import InvoiceItemSetup from './InvoiceItemSetup';


const invoiceItems = (props) => {

    var items = null;
    if (props.invoiceItems){
        items =  props.invoiceItems.map((invoiceItem, index) => {
            let item = null;
            if (props.config.setup){
                item = <InvoiceItemSetup
                                invoiceItem={invoiceItem}
                                key={index}
                                {...props.config}
                        /> 
            }
            else{
                item = <InvoiceItemPlain
                                invoiceItem={invoiceItem}
                                key={index}
                                {...props.config}
                        /> 
            }
            return item;
            });
    }


    return (
        <div className={classes.InvoiceItems}> 
            {items}
        </div>
        );
    };

export default invoiceItems;

