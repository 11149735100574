import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MUIInput, { getTextFieldVariant } from '../MUIInput/MUIInput';
import { InputAdornment } from '@mui/material';



const DropDown = (props) =>  {
 
    let value = props.value;
    if (!value){
        value = "";
    }

    const labelId = props.id + 'lab';
    let control = null;
    let startAdornment = null;

    if (props.prefix){
        startAdornment = <InputAdornment position="start">{props.prefix}</InputAdornment>;
    }

    if (props.readOnly){

        let value = null; 
        const objIndex = props.options.findIndex((obj => obj.value === props.value));        
        if (objIndex !== -1){
            value = props.options[objIndex].label;
          }

        control =   <MUIInput 
                        readOnly={true} 
                        {...props}
                        value={value} 
                         />;

    }
    else{        
        const variant = getTextFieldVariant(props.readOnly);
        control = <FormControl fullWidth  variant={variant}  size="small" margin="dense"  sx={{ mr: 1 }}>
                    <InputLabel  id={labelId} >{props.label}</InputLabel >
                    <Select
                        labelId={labelId}
                        value={props.value}
                        startAdornment={startAdornment}
                        {...props}  >
                        {props.options && props.options.map((option, index) => {    
                                    return ( <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem> )}
                            )}
                    </Select>
                </FormControl>;
    }
    
    return control;           
}
export default DropDown;