
import React, {Component} from 'react';
import AppointmentListItem from './AppointmentListItem';
import Auxiliary from '../../hoc/Auxiliary';
import classes  from './Appointment.module.css';
import Layout from '../Layout/Layout';
import {Link} from 'react-router-dom';
import SessionHandler from '../../context/SessionHandler';


class AppointmentListToday extends Component{

    dateTmp = null;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }    

    isToday = (date) => {
        var today = new Date();

        if ( today.getDate() === date.getDate() &&
             today.getMonth() === date.getMonth() &&
             today.getFullYear() === date.getFullYear() ){
                return true;
             }
        else{
            return false;
        }
    }  

    render() {
        this.dateTmp = null;
        // appointment list today: show only app. from today
        var today = new Date(  );
        today.setHours(0,0,0,0);
        // filter for the current user (if the user is assigned to a therapeut)
        var filteredApp = [];
        if (SessionHandler.getUser().pernr === null){
            filteredApp = this.props.appointments.filter(appointment => this.isToday(new Date(appointment.date)));
        }
        else{
            filteredApp = this.props.appointments
                        .filter(appointment => this.isToday(new Date(appointment.date)) && 
                                appointment.therapeutID === SessionHandler.getUser().pernr );
        }

        if(filteredApp.length === 0){
            var datePrint = Layout.formatDate(today);
            return (    <Auxiliary>
                            <div className={classes.Date} ref={this.myRef}>{datePrint}</div>
                            <div className={classes.ErrorMessage}> Es liegen heute keine Termine für dich vor. <br/>
                                <Link to={{ pathname: '/appointments' }}>
                                    Terminübersicht
                                </Link>
                            </div>
                        </Auxiliary>
                        );
        }
        else{
            return filteredApp.map((appointment, index) => {                        
                var dateElement = null;
                    
                if (this.dateTmp !== appointment.date ){
                    var datePrint = Layout.formatDate(appointment.date);
                    dateElement = (<div className={classes.Date} ref={this.myRef}>{datePrint}</div>);
                    this.dateTmp = appointment.date;
                }      

                return (
                    <Auxiliary key={appointment.ID}> 
                    {dateElement}
                    <AppointmentListItem 
                            appointment={appointment}
                            patientDetail={this.props.patientDetail}
                            key={appointment.ID}                        
                            />
                    </Auxiliary>
                    );
            });
        }
    }
    
}

export default AppointmentListToday;