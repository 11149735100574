import React from 'react';
import classes from './Status.module.css';

import { BsCalendar, BsCalendar2Check, BsCalendar2CheckFill } from "react-icons/bs";
import { Tooltip } from '@mui/material';


const StatusIcon = (props) =>  {
  
    const inputClasses = [];
    
    inputClasses.push(classes.StatusIcon);
    
    
    inputClasses.push(classes['StatusIcon'+ props.lookupValue]);

    let content = props.value;
    if (props.lookupValue <= 30 ){

        if ( props.lookupValue === 10 ) {
            content = <BsCalendar />;
        }
        else if ( props.lookupValue === 20 ) {
            
            content = <BsCalendar2Check />;
        }
        else if ( props.lookupValue === 30 ) {
            
            content = <BsCalendar2CheckFill />;
        }        

    }
    
    return (    

        <div><Tooltip  title={props.value}><div className={inputClasses.join(' ')}>{content}</div></Tooltip></div>

    ); 

}

export default StatusIcon;