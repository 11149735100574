import React from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";

import Button from '../UI/Button/Button';
import TableLine from '../UI/Table/TableLine';

const Absence = (props) => {
    
    
    const absence = {...props.item };
    if (!absence.MAABR){
        absence.MAABR = '-';
        absence.funcDelete = ( 
            <Button btnType="TableButton"  
                    tooltip='Abwesenheit löschen'
                    click={() => props.deleteHandler(props.item )}
                    disabled={false} >
                        <RiDeleteBin6Line />
            </Button>
        );        
    }

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(absence.date);              
    absence.date = date.toLocaleDateString("de-DE", options);     
     
    return (

        <TableLine tableConfig={props.tableConfig}
                   item={absence} />
        );
    }


export default Absence;