
import React from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import TherapistMenu from './TherapistMenu';
import GlobalContext from '../../context/GlobalContext';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DetailContainer from '../Layout/DetailContainer';
import FactsMui from '../UI/Card/FactsMui';
import MUIInput from '../MUI/MUIInput/MUIInput';
import RichText from '../UI/RichText/RichText';
import TheraCalendars from './TheraCalendar/TheraCalendars';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import { ColorPicker } from 'material-ui-color';
import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import TherapistFoto from './TherapistFoto';

const Therapist = (props) => {

    const history = useHistory();
    const { pernr } = useParams();
    const globalContext = useContext(GlobalContext);
    
    const [therapist, setTherapist] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');

    /* initialize component ********************************************************/
    useEffect(() => {
        
        if (props.new){
            const newTherapist = {
                PERNR : null,
                color : '#008001',
                description : "",
                email : "",
                imageUrl : '',
                inactive : false,
                online : false,
                nachname : "", 
                vorname : "",
                prefix : "",
                praxen: []

            };
            setTherapist(newTherapist);

        }
        else{
            // read therapist from context

            const objIndex = globalContext.therapeut.findIndex((obj => obj.PERNR === parseInt(pernr)));
            if (objIndex !== -1){
                const t = globalContext.therapeut[objIndex];
                setTherapist(t);
            }
        }

        
        }, [pernr]);
    /***end: initialize component *************************************************** */
    
    const inputChangedHandler = (event, inputID) =>{

        const updatedForm = { ...therapist };

        updatedForm[inputID] = event.target.value;

        setTherapist(updatedForm);
    }

    const deleteFoto = () =>{

        const updatedForm = { ...therapist };

        updatedForm.imageUrl = null;

        setTherapist(updatedForm);
    }


    const inputChangedHandlerSwitch = (event, inputID) =>{
        const updatedForm = { ...therapist };
        updatedForm[inputID] = event.target.checked;
        setTherapist(updatedForm);
    }    
        
    const richTextChangeHandler = (htmlText, elemID) =>{
        const updatedForm = { ...therapist };
        updatedForm[elemID] = htmlText;
        setTherapist(updatedForm);
    }

    const handleChangeColor = (value) =>{
        const updatedForm = { ...therapist };
        updatedForm.color = '#'  + value.hex;
        setTherapist(updatedForm);
    }

    const handleChangeTheraCalender = (PRXID, add) => {

        const updatedForm = { ...therapist };
        let newList = [];
        if (add){
            // old list + new entry
            newList = updatedForm.praxen;
            const newItem = { 
                                PRXID : PRXID, 
                                Therapeut : therapist.PERNR
                             }
            newList.push(newItem);
        }
        else{
            //remove: old list - this entry
            for (let i = 0; i <  therapist.praxen.length; i++ ){

                if (parseInt(therapist.praxen[i].PRXID) !== parseInt(PRXID)){
                    newList.push(therapist.praxen[i]);
                }
            }
        }

        updatedForm.praxen = newList;

        setTherapist(updatedForm);
    }

    const deleteHandler = () =>{


        // update logic 
        setLoading(true);
        axios.delete('/therapists/' + therapist.PERNR )
        .then (response => {

            globalContext.deleteTherapist( therapist.PERNR);

            setLoading(false);

            const message = {
                type: 'S',
                text: 'Therapeut gelöscht'
            }
            globalContext.showMessage(message);                             
            history.goBack();
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Löschen des Therapeuten");
            setLoading(false);
        });  

    }

    const saveHandler = () =>{
        if (props.new){
            createTherapist();
            return;
        }

        // update logic 
        setLoading(true);
        axios.put('/therapists/' + therapist.PERNR   , therapist)
        .then (response => {
            const updatedTherapist = response.data.data;

            globalContext.updateTherapist(updatedTherapist);

            setTherapist(updatedTherapist);
            setLoading(false);
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Speichern des Therapeuten");
            setLoading(false);
        });  

    }

    const handleStoreFoto = (file) => {
        
        setLoading(true);
        const formData = new FormData();
        
        // Update the formData object
        formData.append(
            "file",
            file,
            file.name            
        );
        
        axios.post('/therapists/' + therapist.PERNR + '/foto', 
            formData,
            )
        .then (response => {
            //this.context.login(response.data); 
            const updatedTherapist = response.data.data;

            globalContext.updateTherapist(updatedTherapist);

            setTherapist(updatedTherapist);
            setLoading(false);
        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Hochladen des Fotos");
            setLoading(false);
        });  

  }        

    const createTherapist = () =>{

        setLoading(true);
        axios.post('/therapists'  , therapist)
        .then (response => {
            const newTherapist = response.data.data;

            globalContext.updateTherapist(newTherapist);

            //setTherapist(newTherapist);
            //setLoading(false);
            history.replace('/setup/therapists/' + newTherapist.PERNR);

        })
        .catch (error => {
            console.log(error); 
            setShowError(true);
            setErrorResponse(error.response);
            setErrorTitle("Fehler beim Anlegen des Therapeuten");
            setLoading(false);
        });  
    }

    let content = null;
    if (therapist){

        content = <React.Fragment>
                    <h1>{therapist.vorname} {therapist.nachname}</h1>
                    <ApiErrorMessage    show={showError} 
                                    modalClosed={() => { setShowError(false) }} 
                                    errorResponse={errorResponse} 
                                    title={errorTitle}
                                    />    
                    <DetailContainer> 
                        <FactsMui>               
                            <MUIInput       id={'vorname'}
                                            label='Vorname' 
                                            value={therapist.vorname}
                                            fullWidth
                                            required
                                            onChange={ (event) => inputChangedHandler(event, 'vorname') }
                                            />    
                            <MUIInput       id={'nachname'}
                                            label='Nachname' 
                                            value={therapist.nachname}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'nachname') }
                                            />   
                            <MUIInput       id={'email'}
                                            label='E-Mail' 
                                            value={therapist.email}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'email') }
                                            />
                            <MUIInput       id={'prefix'}
                                            label='Abkürzung' 
                                            value={therapist.prefix}
                                            fullWidth
                                            onChange={ (event) => inputChangedHandler(event, 'prefix') }
                                            /> 
                            { !props.new && 
                            <React.Fragment>
                            <FormControl>
                                <FormControlLabel control={  <Switch    checked={therapist.online}
                                                                        onChange={(event) => inputChangedHandlerSwitch(event, 'online')}
                                                                        />    } 
                                                label="Onlinebuchungstool" />   
                                <FormHelperText>Falls Onlinebuchungstool aktiviert ist, ist der Therapeut im Onlinebuchungstool sichtbar</FormHelperText>      
                            </FormControl>    
                            <FormControl>                  
                                <FormControlLabel control={  <Switch    checked={therapist.inactive}
                                                                        onChange={(event) => inputChangedHandlerSwitch(event, 'inactive')}
                                                                        />    } 
                                                label="Inaktiv" />         
                                <FormHelperText>Falls der Therapeut inaktiv ist, kann dieser nicht mehr in Terminen verwendet werden und wird auch nicht mehr in der Liste der Therapeuten angezeigt.</FormHelperText>                                                                                  
                            </FormControl>    
                            </React.Fragment>
                            }
                            
                                                                                                                                                                                                                                  
                        </FactsMui>
                        
                        <FactsMui>   
                        { !props.new && 
                        <React.Fragment>
                        <TherapistFoto therapist={therapist} 
                                        handleStoreFoto={handleStoreFoto}
                                        handleDeleteFoto={deleteFoto}/>
                        {/*<img src={therapist.imageUrl} alt="Foto Therapeut" ></img>*/}
                        <h5>Farbe</h5>
                        <ColorPicker value={therapist.color} onChange={handleChangeColor}/>
                            <TheraCalendars  theraCalendars={therapist.praxen} 
                                             handleChangeTheraCalender={handleChangeTheraCalender}/>
                        </React.Fragment>
                        }

                        </FactsMui>
                       

                    </DetailContainer> 
                    <RichText elemID={'description'} 
                                htmlText={therapist.description} 
                                label='Beschreibung (sichtbar im Onlinebuchungstool)' 
                                onChange={richTextChangeHandler}/>                        

                </React.Fragment>



    }

    return <React.Fragment>
                <Modal show={loading}><Spinner/></Modal>
                <SubMenuMui  history={history}>
                        <TherapistMenu    saveHandler={() => saveHandler()}
                                          deleteHandler={() => deleteHandler()}  /> 
                </SubMenuMui>   
                {content}
            </React.Fragment>    

}

export default Therapist;