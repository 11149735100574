import React, { useState, useContext } from 'react';
import classes from './InvoiceItems.module.css';
import MUIInput from '../MUI/MUIInput/MUIInput';
import Currency from '../MUI/MUIInput/Currency';
import DoubleField from '../MUI/MUIContainer/DoubleField';
import Button from '../UI/Button/Button';
import axios from 'axios';
import GlobalContext from '../../context/GlobalContext';
import InvoiceType from '../MUI/DropDown/InvoiceType';

import Spinner from '../UI/Spinner/Spinner';
import Modal from '../UI/Modal/Modal';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';


const InvoiceItemCreate = (props) => {
    const globalContext = useContext(GlobalContext);

    const [invItem, setInvItem] = useState(
                { 
                    LeistungsArt: '',
                    ZIFFER: '',
                    Einzelleistung: '',
                    PREIS: 0,
                    ORDNR: 999
                  });

    const [textError, setTextError] = useState(true);
    const [typeError, setTypeError] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');    

    
    const changeHandler = (field, value) => {
        const copyItem = {...invItem};
        copyItem[field] = value;
        setInvItem(copyItem);
        if (copyItem.Einzelleistung !== ''){
            setTextError(false);
        }
        else{
            setTextError(true);
        }

        if (copyItem.LeistungsArt === ''){
            setTypeError(true);
        }
        else{
            setTypeError(false);
        }
    }

    const closeErrorPopup = () => {
        setShowError(false);
        setErrorResponse(null);
        setErrorTitle('');
    }
        

    const createHandler = () =>{
        
        setLoading(true);
        const formData = {};
        formData['ZIFFER'] = invItem.ZIFFER;
        formData['Einzelleistung'] = invItem.Einzelleistung;
        formData['LeistungsArt'] = invItem.LeistungsArt;
        formData['PREIS'] = invItem.PREIS;
        formData['ORDNR'] = invItem.ORDNR;
        axios.post('/invoiceItems' ,  formData)   
        .then (response => {
            
            globalContext.insertSingleService(response.data.data);
            setLoading(false);
            props.onClose();

            })
        .catch (error => {
            console.log(error); 
            setErrorTitle("Fehler beim Anlegen der Leistung");
            setErrorResponse(error.response );
            setShowError(true);
            setLoading(false);
        });     
        

    }
    

    return ( <div className={classes.CreateContainer}>
                <Modal show={loading} ><Spinner/></Modal>
                <ApiErrorMessage    show={showError} 
                                    modalClosed={closeErrorPopup} 
                                    errorResponse={errorResponse} 
                                    title={errorTitle}
                                    />    
                <InvoiceType  value={invItem.LeistungsArt}
                            label="Leistungsart"
                            error={typeError}
                            onChange={(event) => changeHandler( 'LeistungsArt', event.target.value)}
                />

                
                <MUIInput   id="ziffer"
                            label="Ziffer"
                            value={invItem.ZIFFER} 
                            fullWidth={false}
                            onChange={(event) => changeHandler( 'ZIFFER', event.target.value)}
                            />
                <MUIInput   id='einzelLeistung'
                            label='Leistungsbeschreibung'
                            value={invItem.Einzelleistung} 
                            multiline={true}
                            required={true}
                            onChange={(event) => changeHandler( 'Einzelleistung', event.target.value)}
                            />   
                <DoubleField>   
                    <Currency   id='amount'
                                label='Einzelpreis'
                                value={invItem.PREIS} 
                                fullWidth={false}
                                onChange={(event) => changeHandler( 'PREIS', event.target.value)}
                        /> 
                    <MUIInput   id='sortID'
                                label='Sortierung'
                                value={invItem.ORDNR} 
                                type="numeric" 
                                fullWidth={false}
                                onChange={(event) => changeHandler( 'ORDNR', event.target.value)}
                                /> 
                </DoubleField>
                <div className={classes.PopupButtons}>
                    <Button btnType="EditMenuButton" click={() => createHandler()} disabled={textError || typeError }>Speichern</Button>
                    <Button btnType="EditMenuButton" click={props.onClose} >Abbrechen</Button>
                </div>
            </div> );
};



export default InvoiceItemCreate;
