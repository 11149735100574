import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import AuthContext from '../../context/GlobalContext';
import Tooltip from '../UI/Tooltip/Tooltip';
import Caption from '../UI/Card/Caption';
import Card from '../UI/Card/Card';
import DetailContainer from '../Layout/DetailContainer';
import Facts from '../UI/Card/Facts';
import Input from '../UI/Input/Input';
import InvoiceUtil from './InvoiceUtil';



class InvoiceListItem extends Component{

    styleConfig = {     inputStyle: 'InputDetail',
                        labelStyle: 'LabelLightFixed',
                        inputElementStyle:'InputElementDetail' };            

    configDate = { type: 'date' };
    configDecimal = { type: 'currency' };

    static contextType = AuthContext;

    render(){  

        const header = (    <Caption > 
                            <Link  to={{ pathname: '/financeMenu/invoices/' + this.props.invoice.invoiceID }}>
                                <Tooltip content={'Rechnung Detail'}>
                                    {this.props.invoice.invoiceID + ' - ' + this.props.invoice.displayname} 
                                </Tooltip>
                            </Link>
                            
                        </Caption>);


        
        const statusIcon = this.getStatusIcon(this.props.invoice.status);
        return(
                <Card >
                    {header}   
                    
                    {this.props.invoice.DIAGNOSE}
                    <DetailContainer> 
                    <Facts>                                                                               
                            <Input  key= {'invoiceDate'} 
                                    elementType= 'input' 
                                    elementConfig={this.configDate}
                                    styleConfig= {this.styleConfig} 
                                    value={this.props.invoice.invoiceDate} 
                                    label='Rechnungsdatum' 
                                    readOnly={true} 
                                    />                                
                            <Input  key= {'invoiceType'} 
                                    elementType= 'input' 
                                    
                                    styleConfig= {this.styleConfig} 
                                    value={this.props.invoice.invoiceType} 
                                    label='Rechnungstyp' 
                                    readOnly={true} 
                                    />  
                            <Input  key= {'status'} 
                                    elementType= 'input' 
                                    
                                    styleConfig= {this.styleConfig} 
                                    value={this.props.invoice.statusText} 
                                    label='Status' 
                                    readOnly={true} 
                                    suffix={statusIcon}
                                    />                                            
                            
                        </Facts>                                            
                        <Facts>                                                                               
                            <Input  key= {'amount'} 
                                    elementType= 'input' 
                                    elementConfig={this.configDecimal}
                                    styleConfig= {this.styleConfig} 
                                    value={this.props.invoice.amount} 
                                    label='Rechnungsbetrag' 
                                    readOnly={true} 
                                    />            
                            <Input  key= {'openAmount'} 
                                    elementType= 'input' 
                                    elementConfig={this.configDecimal}
                                    styleConfig= {this.styleConfig} 
                                    value={this.props.invoice.openAmount} 
                                    label='Offener Betrag' 
                                    readOnly={true} 
                                    />       
                            <Input  key= {'payDuration'} 
                                    elementType= 'input' 
                                    
                                    styleConfig= {this.styleConfig} 
                                    value={this.props.invoice.payDuration} 
                                    label='Zahlungsdauer' 
                                    readOnly={true} 
                                    />                                                                    
                                                                                    
                            
                        </Facts>                                
                                                                                                                                                            
                        </DetailContainer>          
                    </Card>
        );
    }

    
    getStatusIcon = (status) => {

        return InvoiceUtil.getStatusIcon(status);
                
    }

}
export default InvoiceListItem;
