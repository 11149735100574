import axios from 'axios';



//global axios defaults
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
//axios.defaults.headers.common['Authorization'] = this.state.authObject.access_token;

axios.interceptors.request.use(request => {
    //edit request, e.g. add authtentication token
      //therasoft api calls
      request.headers.common['Therasoft-Secret'] = process.env.REACT_APP_THERASOFT_SECRET;        

    //console.log(request);
    return request;    
}, error => {
    //handle error globally 
    console.log(error);
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  }, 
  function (error) {
    // Try to handle 401 with refresh process
    const originalRequest = error.config;
    
    if (!error.response){
      console.log(error);
      return Promise.reject(error);
    }
    else if (error.response.status === 401 && !SessionHandler.retry  && originalRequest.url !== '/oauth/token'){
      SessionHandler.retry = true;
        console.log('start refresh token process');     
        return axios.post(SessionHandler.getRefreshUrl(), SessionHandler.getRefreshData())
              .then (response => {
                  SessionHandler.setAuthObject(response.data);
                  return axios(originalRequest);
                })
              .catch (error => {      
                  //SessionHandler.logout( );  // for the time being: stay on this page to show the error (for analysis purpose)
                  console.log(error);
                  return Promise.reject(error);
                });    
    }
    else{
      if (error.response.status === 401){
        //-> refresh failed: force logout
        SessionHandler.logout();
      }
      else{
        //console.log('Reset retry-flag')
        SessionHandler.retry = false;
        return Promise.reject(error);
      }

    }
  });


class SessionHandler{

    static EXPIRATION_DATE = 'expirationDate';

    static expiryDate = null;
    static user = null;

    static retry = false;

    static logout = () => {};


    static setLogoutFunction(logoutFunction){
      SessionHandler.logout = logoutFunction;
    }

  /**
   * Store tokens in localStorage -> could be changed here to memory
   * @param {*} authObject 
   */
  static setAuthObject(authObject){
    this.expiryDate = new Date(new Date().getTime() + authObject.data.access_token_expires_in * 1000);
    this.user = authObject.data.user;
  }

  static deleteAuthObject(){

    
    this.expiryDate = null;
    this.user = null;
  }

  static getAuthObject(){
    var authObject = {};
    authObject['access_token_expirs_in'] = this.expiryDate;
    authObject['user'] = this.user;

    return authObject;
  }  

  static getUser(){
    return this.user;
  }

  static getRefreshUrl(){
    return '/oauth/token';
  }

  static authSensitiveData(){

    return this.user && this.user.prmDocu ;
  }

  static authRelease(){

    return this.user && this.user.prmRelease ;
  }  

  static authTalk(){
    return this.user && this.user.prmTalk;
  }

  static authSetup(){
    return this.user && this.user.prmSetup ;
  }  

  static authFinance(){
    return this.user && this.user.prmInvoices ;
  }    

  static authHR(){
    return this.user && this.user.prmPayroll ;
  }    

  static authRegisterService(){
    return this.user && this.user.prmRegisterService ;
  }      
  
  static authEmailService(){
    return this.user && this.user.prmEmail ;
  }      

  static getRefreshData(){
    var formData = {};
    formData['grant_type'] = 'refresh_token';
        
    return formData;
  }

  static accessTokenExpired(){
      
    //return false;
    const expiryDate = new Date(SessionHandler.getAuthObject().access_token_expirs_in);
    if (expiryDate < new Date()){
      return true;
    } else{
      return false;
    }
    
  }

  static refreshSession(originalRequest){
    //if (SessionHandler.getAuthObject().refresh_token){
      axios.post(SessionHandler.getRefreshUrl(), SessionHandler.getRefreshData())
      .then (response => {
          console.log(response);
          const authObject = response;
          SessionHandler.setAuthObject(authObject);

        })
      .catch (error => {
          console.log(error);             
        });    
    //}    
  }    
}


export default SessionHandler;