import React from 'react';
import { PureComponent } from 'react/cjs/react.production.min';
import GlobalContext from '../../context/GlobalContext';
import Auxiliary from '../../hoc/Auxiliary';
import SubMenu from '../Navigation/SubMenu/SubMenu';
import PaginatedList from '../UI/PaginatedList/PaginatedList';
import Spinner from '../UI/Spinner/Spinner';
import InvoiceListItem from './InvoiceListItem';
import InvoiceUtil from './InvoiceUtil';
import ListMenu from './ListMenu';


class InvoiceList extends PureComponent{

    static contextType = GlobalContext;

    state = { 
        controls: {
            filter: {
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Filter...'
                },
            }, 
            
        },
        filteredItems: null, 
    }


    renderItems = (items) => {
        return (
            <Auxiliary>
            {items && items.map((invoice, index) => (
                <InvoiceListItem
                    invoice={invoice}
                    key={invoice.invoiceID}/>
            ))}
            </Auxiliary>
        );
    }    

    render() {

            let items = this.props.invoices;
            if (this.state.filteredItems){
                items = this.state.filteredItems;
            } 

            let menu = null;
            if (!this.props.embedded){
                menu = (    <SubMenu popupMenu={false}>
                                <ListMenu   controls={this.state.controls} 
                                            filter={this.context.invoiceFilter}
                                            filterText={(event) => this.filterHandler(event)}
                                            filterStatus={(status) => this.statusFilterHandler(status)} 
                                            />
                            </SubMenu>
                            );
            }            

            let content = (<Spinner/>);
            if (!this.context.invoicesLoading){
                content = ( <PaginatedList 
                                items={items}
                                renderItems={this.renderItems} 
                                itemsPerPage={30}>
                            </PaginatedList>   )
            }
            return(
                <Auxiliary>
                    {menu}
                    {content}
                </Auxiliary>
            );
    }

    statusFilterHandler = (status) => {
        const filter = { ...this.context.invoiceFilter };
        const index = filter.statusFilter.indexOf(status);
        
        if (index > -1 ){
            filter.statusFilter.splice(index, 1);
        }   
        else{
            filter.statusFilter.push(status);
        }
        const filteredItems = this.getFilteredItems(filter);
        this.setState({ filteredItems: filteredItems});        
        this.context.setInvoiceFilter(filter);
    }

    /**
     * Filter handler for Input field filter
     * @param {*} event 
     */
    filterHandler = (event)  => {
        
        
        let filter = { ...this.context.invoiceFilter}; 
        filter.textFilter = event.target.value;
        const filteredItems = this.getFilteredItems(filter);
        this.setState({ filteredItems: filteredItems});                
        this.context.setInvoiceFilter(filter);
        
    }

    getFilteredItems = (filter) => {
        const regex = RegExp(filter.textFilter, 'i');
        return this.props.invoices
                    .filter(   invoice =>   ( regex.test(invoice.displayname) || regex.test(invoice.invoiceID)) && // name/number filter
                                            ( this.filterStatus(filter, invoice) ) 
                    );        
    }    


    filterStatus = (filter, invoice) => {

        let status = invoice.status;
        if (InvoiceUtil.isOverDue(invoice.status)){
            // filter with overdue status
            status = InvoiceUtil.C_STAT_OVERDUE;
        }
        if (InvoiceUtil.isDunningStatus(invoice.status)){
            // filter with overdue status
            status = InvoiceUtil.C_STAT_DUNN_L1;
        }        
        return filter.statusFilter.includes(status);



    }


    componentDidMount(){
        if (this.props.invoices.length > 0 && !this.props.embedded){
            const filteredItems = this.getFilteredItems(this.context.invoiceFilter);
            this.setState({ filteredItems: filteredItems});                  
        }
    }


}


export default InvoiceList;