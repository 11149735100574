import React, { useState, useEffect, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import axios from 'axios';
import MUIInput from '../MUI/MUIInput/MUIInput';
import { Box  } from '@mui/material';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import { useParams } from 'react-router-dom';
import InvoiceItemContainer from './InvoiceItemContainer/InvItemContainer';
import ServiceChainMenu from './ServiceChainMenu';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';

const ServiceChain = (props) => {

    const [serviceChain, setServiceChain] = useState({ NAME: '', chain_items: []});
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [errorTitle, setErrorTitle] = useState('');
    const [saveDisabled, setSaveDisabled] = useState(true);

    const history = useHistory();
    const params = useParams();
    const globalContext = useContext(GlobalContext);
    
    //load service Chain
    useEffect(() => {

        if (params.id){
            const chain = globalContext.getServiceChain(parseInt(params.id));
            const deep_copy = JSON.parse(JSON.stringify(chain));
            setServiceChain(deep_copy);
            setSaveDisabled(false);
        }

    }, [params.id, globalContext]);

    const  changeHandler = ( field, val) => {
        const chain = { ...serviceChain};
        chain[field] = val;
        setServiceChain(chain);
        manageSaveButton(chain);
    }    

    const manageSaveButton = (chain) => {
        if ( chain.NAME && chain.NAME !== '' && 
             chain.chain_items.length > 0 ){
                setSaveDisabled(false);
             }
        else{
            setSaveDisabled(true);
        }


    }

    const changeItemHandler = (invID, field, val) => {
        const chain = { ...serviceChain};
        const idx = chain.chain_items.findIndex((obj => obj.ID === invID));   
        chain.chain_items[idx][field] = val;
        setServiceChain(chain);
        manageSaveButton(chain);
    }    

    const deleteItemHandler = (invID) => {
        const chain = { ...serviceChain};
        const idx = chain.chain_items.findIndex((obj => obj.ID === invID));   
        chain.chain_items.splice(idx, 1);
        setServiceChain(chain);
        manageSaveButton(chain);
    }

    const addItemHandler = (serviceItem) => {
        const chain = { ...serviceChain};
        let invoiceItemNew = null;
        if (serviceItem.LK){
            // add Leistungskette
            if (serviceItem.chain_items){
                for (let i = 0; i < serviceItem.chain_items.length; i++){
                    invoiceItemNew = {...serviceItem.chain_items[i]};
                    invoiceItemNew['LEISTUNG'] = serviceItem.chain_items[i]['ID'];
                    invoiceItemNew['ANZAHL'] = 1;        
                    chain.chain_items.push(invoiceItemNew);
                }
            }
        }
        else{
            invoiceItemNew = { ...serviceItem };
            invoiceItemNew['LEISTUNG'] = invoiceItemNew['ID'];
            invoiceItemNew['ANZAHL'] = 1;        
            chain.chain_items.push(invoiceItemNew);
        }
        setServiceChain(chain);
        manageSaveButton(chain);
    }    

    const saveHandler = () => {
        setLoading(true);
        const formData = {};
        formData['NAME'] = serviceChain.NAME;
        formData['chain_items'] = [];
        serviceChain.chain_items.forEach(element => {
            const item = {};
            item.LEISTUNG = element.LEISTUNG;
            item.ANZAHL = element.ANZAHL;
            formData['chain_items'].push(item);
        });

        if (serviceChain.ID){
            //update
            axios.put('/serviceChains/' +  serviceChain.ID,  formData)   
            .then (response => {
                globalContext.updateServiceChain(response.data.data);
                setLoading(false);
                const message = {
                    type: 'S',
                    text: 'Leistungskette gespeichert'
                }
                globalContext.showMessage(message);   
                })
            .catch (error => {
                console.log(error); 
                setErrorTitle("Fehler bei Speichern der Leistungskette");
                setErrorResponse(error.response );
                setShowError(true);
                setLoading(false);
            });   
        }
        else{
            //create
            axios.post('/serviceChains' ,  formData)   
            .then (response => {
                globalContext.insertServiceChain(response.data.data);
                setLoading(false);
                const message = {
                    type: 'S',
                    text: 'Leistungskette angelegt'
                }
                globalContext.showMessage(message);   
                })
            .catch (error => {
                console.log(error); 
                setErrorTitle("Fehler bei Anlegen der Leistungskette");
                setErrorResponse(error.response );
                setShowError(true);
                setLoading(false);
            });   
        }

    }

    const closeErrorPopup = () => {
        setShowError(false);
    }
    
    const invConfig = {
            onChange: (invID, field, val) => changeItemHandler(invID, field, val),
            onDelete: (invID) => deleteItemHandler(invID),
            onAdd: (serviceItem) => addItemHandler(serviceItem),
            onGetProposal: () => {}, // not used in this component
            reduced: false,
            deletable: true,
            hideProposal: true,
            readOnly: false,
            editChain: true
        };
    return   (  <React.Fragment>
                    <SubMenuMui  history={history}>
                    <ServiceChainMenu saveHandler={() => saveHandler()} saveDisabled={saveDisabled}/>
                    </SubMenuMui>  
                    <Modal show={loading}><Spinner/></Modal>
                    <ApiErrorMessage    show={showError} 
                                        modalClosed={closeErrorPopup} 
                                        errorResponse={errorResponse} 
                                        title={errorTitle}
                                    />                       
                    <Box sx={{ width: '100%' }}>
                        <h3>Leistungskette bearbeiten</h3>
                        <MUIInput   id="NAME"
                                    label="Name"
                                    required={true}
                                    multiline
                                    value={serviceChain.NAME} 
                                    onChange={(event) => changeHandler('NAME', event.target.value)}
                        />
                        <InvoiceItemContainer   items={serviceChain.chain_items}
                                                config={invConfig}/>
                    </Box> 
                </React.Fragment> );

}


export default ServiceChain;