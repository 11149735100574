import React from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';

import Auxiliary from '../../hoc/Auxiliary';


const listMenu = (props) =>  {
        

    //Patientfilter
    const styleConfig = {
        inputStyle: 'InputDetail', //InvItemInputWrapper',
        labelStyle: 'LabelNone',//InvItemLabel',
        inputElementStyle:'InputElemMenu'//'InvItemInputElement'
    };       

    

    const   create = (  <li className={classes.SubMenuButton}>
                        <Button btnType="EditMenuButton" 
                                disabled={false} 
                                click={props.triggerPayroll}>
                                    Entgeltberechnung</Button>
                    </li>);
    
    
    

    return (
        <Auxiliary >
            <li className={classes.SubMenuInput}>
                <Input  elementType={props.controls.filter.elementTyp}
                        elementConfig={props.controls.filter.elementConfig}
                        value={props.controls.filter.value}
                        styleConfig={styleConfig}
                        changed={(event) => props.changed(event)}/>            
            </li>
            {create}
        </Auxiliary>
        );
    }

export default listMenu;