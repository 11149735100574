import React, { useState } from 'react';
import classes from '../Navigation/SubMenu/SubMenu.module.css';
import { FaSave } from "react-icons/fa";
import { MdPassword } from "react-icons/md";

import { RiDeleteBin6Line } from "react-icons/ri";
import { IconButton, Tooltip } from '@mui/material';
import YesCancelModal from '../UI/Modal/YesCancelModal';

const UserMenu = (props) => {

    const [confirmationWindow, setConfirmationWindow] = useState(false);

    let popup = null;
    if (confirmationWindow){

        popup = (  <YesCancelModal  clickYes={() =>  { setConfirmationWindow(false); props.deleteHandler()}} 
                            clickCancel={() => setConfirmationWindow(false)}
                            title=" Benutzerkonto löschen" >
                            Willst du das Benutzerkonto unwideruflich löschen?
                    </YesCancelModal>  );
    }



        let buttonsLeft = <React.Fragment> 
                            <Tooltip title="Speichern">
                                <span>
                                    <IconButton onClick={props.saveHandler} >
                                        <FaSave />
                                    </IconButton>  
                                </span>
                            </Tooltip>
                            { !props.new &&
                            <Tooltip title="Passwort ändern">
                                <span>
                                    <IconButton onClick={props.pwdHandler} >
                                        <MdPassword />
                                    </IconButton>  
                                </span>
                            </Tooltip>                            
                            }
                        </React.Fragment>;

        let buttonsRight = null;

        if (!props.new){
            buttonsRight = <React.Fragment> 
                                    <Tooltip title="Löschen">
                                        <span>
                                            <IconButton onClick={() => setConfirmationWindow(true)}  disabled={props.disabled} >
                                                <RiDeleteBin6Line   />
                                            </IconButton>  
                                        </span>
                                    </Tooltip>
                                </React.Fragment>;
                         }

    return (
        <div className={classes.SubMenuContainer}>
            {popup}
            <div className={classes.SubMenuLeft}>

                {buttonsLeft}
            </div>
            <div className={classes.SubMenuRight}>
                    {buttonsRight}
            </div>
        </div>
        );
}

export default UserMenu;