
import React, {Component} from 'react';
import GlobalContext from '../../context/GlobalContext';

import ApiErrorMessage from '../UI/ErrorMessage/ApiErrorMessage';
import Modal from '../UI/Modal/Modal';
import Spinner from '../UI/Spinner/Spinner';
import axios from 'axios';
import Tabs from '../Navigation/Tab/Tabs';
import Tab from '../Navigation/Tab/Tab';
import PrescriptionList from '../Prescription/PrescriptionList';
import InvoiceList from '../Invoices/InvoiceList';
import SessionHandler from '../../context/SessionHandler';
import YesCancelModal from '../UI/Modal/YesCancelModal';
import PrescriptionDetail from '../Prescription/PrescriptionDetail';
import AppointmentListPaginated from '../Appointments/AppointmentListPaginated';
import Attachments from '../Attachments/AttachmentTab';
import {GrAttachment} from  "react-icons/gr";
import RegLinkWindow from './RegLinkWindow';
import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import PatientDetailViewMui from './PatientDetailViewMui';
import DateUtility from '../../hoc/DateUtility';
import PatientMenuMui from './PatientMenuMui';


class PatientDetail extends Component{

    static contextType = GlobalContext;

    static SHEET_NO = 'sheetNo';
    static ORIGIN_CODE = 'originCode';
    static ORIGIN_TEXT = 'originText';
    static INSURANCE = 'insurance';
    static INVOICING = 'invoicing';
    static NOTE = 'note';
    static FIRST_NAME = 'firstName';
    static LAST_NAME = 'lastName';
    static DISPLAY_NAME = 'displayName';
    static BIRTHDAY = 'birthday';
    static ADDRESS = 'address';
    static CITY = 'city';
    static POSTCODE = 'postCode';
    static COUNTRY = 'country';
    static EMAIL = 'email';
    static EMAIL2 = 'email2'
    static PHONE = 'phone';
    static PHONE2 = 'phone2';
    static TITLE = 'title';
    static BILL_TO_NAME = 'billToName';
    static FOLLOW_UP_DATE = 'followUpDate';
    static INVOICE_DELIVERY = 'invoiceDelivery';
    static PHOTO_AGREEMENT = 'photoAgreement';
    static DATA_PROTECTION_DATE = 'dataProtectionAgreement';
    static EMAIL_NOTIFICATION = 'emailNotification';
    static UPDATED_AT = 'updated_at';


    isComponentMounted = false;


    state = { 
        patient: null ,
        patientCopy: null,
        appointments: [],
        prescriptions: [],
        attachments: [],
        activePrescription: null,
        invoices: [],
        appLoading: false,
        readOnly: true,
        saveDisabled: false,
        confirmDelete: false,
        loading: false,
        showQRCode: false,
        regLink: null,
        regLinkHash: null,
        showError: false,
        errorMessage: '',
        errorTitle: '',    
        errorResponse: null,
        controls: {
            firstName: {
                valid: true,
            },
            lastName: {
                valid: true,
            },
            phone: {
                valid: true,
            },
            email: {
                valid: true,
            }            
        }

        }
    
    newPatient = false;
/////////////////////////////////////////////////////////////////////////////////////////////
// event handler
    setFormAttribut = (fieldName, formData) => {

        if (this.state.patientCopy){
            if (this.state.patient[fieldName] !== this.state.patientCopy[fieldName]){
                formData[fieldName] = this.state.patient[fieldName];
            }
        }
        else{
            // new patient - take over all fields
            formData[fieldName] = this.state.patient[fieldName];
        }
    }

    saveHandler = (event)  => {
        //prevents a server http cycle
        event.preventDefault();
        this.setState({loading: true});
        const formData = {};
        this.setFormAttribut(PatientDetail.SHEET_NO, formData);
        this.setFormAttribut(PatientDetail.INSURANCE, formData);
        this.setFormAttribut(PatientDetail.ORIGIN_CODE, formData);
        this.setFormAttribut(PatientDetail.ORIGIN_TEXT, formData);
        this.setFormAttribut(PatientDetail.INVOICING, formData);
        this.setFormAttribut(PatientDetail.FOLLOW_UP_DATE, formData);
        this.setFormAttribut(PatientDetail.NOTE, formData);
        this.setFormAttribut(PatientDetail.DISPLAY_NAME, formData);
        this.setFormAttribut(PatientDetail.FIRST_NAME, formData);
        this.setFormAttribut(PatientDetail.LAST_NAME, formData);
        this.setFormAttribut(PatientDetail.EMAIL, formData);
        this.setFormAttribut(PatientDetail.EMAIL2, formData);
        this.setFormAttribut(PatientDetail.PHONE, formData);
        this.setFormAttribut(PatientDetail.PHONE2, formData);
        this.setFormAttribut(PatientDetail.ADDRESS, formData);
        this.setFormAttribut(PatientDetail.POSTCODE, formData);
        this.setFormAttribut(PatientDetail.CITY, formData);
        this.setFormAttribut(PatientDetail.COUNTRY, formData);
        this.setFormAttribut(PatientDetail.BIRTHDAY, formData);
        this.setFormAttribut(PatientDetail.TITLE, formData);
        this.setFormAttribut(PatientDetail.BILL_TO_NAME, formData);
        this.setFormAttribut(PatientDetail.INVOICE_DELIVERY, formData);
        this.setFormAttribut(PatientDetail.PHOTO_AGREEMENT, formData);
        this.setFormAttribut(PatientDetail.EMAIL_NOTIFICATION, formData);
        this.setFormAttribut(PatientDetail.DATA_PROTECTION_DATE, formData);
        
        formData[PatientDetail.UPDATED_AT] = this.state.patient[PatientDetail.UPDATED_AT];

        
        if (this.newPatient){
            axios.post('/patients'  , formData)
            .then (response => {
                
                //this.context.login(response.data);
                
                const patientRefreshed = response.data.data;
                if (patientRefreshed){
                    this.context.updatePatient(patientRefreshed);
                    const message = {
                        type: 'S',
                        text: 'Patient gespeichert'
                    }
                    this.context.showMessage(message);                                                                                        
                    this.newPatient = false;

                    if (!patientRefreshed.originCode){
                        patientRefreshed.originCode = "";
                    }
                    if (!patientRefreshed.invoiceDelivery){
                        patientRefreshed.invoiceDelivery = "";
                    }                                

                    this.setState({ loading: false, readOnly: true, patient: patientRefreshed});      
                    
                    this.props.history.replace('/patients/' + patientRefreshed.ID);
                    //var objIndex = this.context.patients.findIndex((obj => obj.ID === patientRefreshed.ID));        
                    //this.context.patients[objIndex] = patientRefreshed;                
                }      

            })
            .catch (error => {
                console.log(error); 
                
                var errorTitle = "Fehler beim Speichern des Patienten";
                this.setState({loading: false,
                               showError: true,
                               errorResponse:  error.response,
                               errorTitle: errorTitle }); 
            });            
        }
        else{
            axios.put('/patients/' + this.state.patient.ID , formData)
            .then (response => {
                
                //this.context.login(response.data);
                const patientRefreshed = response.data.data;
                if (patientRefreshed){
                    if (!patientRefreshed.originCode){
                        patientRefreshed.originCode = "";
                    }
                    if (!patientRefreshed.invoiceDelivery){
                        patientRefreshed.invoiceDelivery = "";
                    }                         
                    this.setState({ loading: false, readOnly: true, patient: patientRefreshed});      
                    this.context.updatePatient(patientRefreshed);
                    const message = {
                        type: 'S',
                        text: 'Patient gespeichert'
                    }
                    this.context.showMessage(message);                                                                    
                    //var objIndex = this.context.patients.findIndex((obj => obj.ID === patientRefreshed.ID));        
                    //this.context.patients[objIndex] = patientRefreshed;                
                }

            })
            .catch (error => {
                console.log(error); 
                var errorTitle = "Fehler beim Speichern des Patienten";
                this.setState({loading: false,
                               showError: true,
                               errorResponse:  error.response,
                               errorTitle: errorTitle });              
            });
        }

    }   

    editHandler = (event)  => {
        event.preventDefault();
        this.setState({readOnly: false, patientCopy: this.state.patient });
    }       

    deleteHandler = () => {
        
        this.setState({ confirmDelete: true } );

    }

    storeAttachmentHandler = (file) => {
        
        this.setState({loading: true});
        const formData = new FormData();
        
        // Update the formData object
        formData.append(
            "file",
            file,
            file.name
        );
        formData.append(
            "fileDescription",
            file.description
        );        
        
        axios.post('/patients/' + this.state.patient.ID + '/file', 
            formData,
            )
        .then (response => {
            
            const attachment = response.data.data;
            this.context.addAttachment(attachment);
            
            let attachments = [...this.state.attachments];
            
            attachments.unshift(attachment);
            
            this.setState({ loading: false, 
                            attachments: attachments
                        });      
            const message = {
                type: 'S',
                text: 'Datei hochgeladen'
            }
            this.context.showMessage(message);     


        })
        .catch(err => {
            console.log(err);
            this.setState({ loading: false });
        })
        ;
    }    

    deleteAttachmentHandler = (attachment) => {
        this.setState({ loading: true } );      
        axios.delete('/attachments/' + attachment.ID  )   
            .then (response => {

                this.context.deleteAttachment(attachment);

                const objIndex = this.state.attachments.findIndex((obj => obj.ID === attachment.ID));      
                let attachments = [ ...this.state.attachments];
                // replace element
                attachments.splice(objIndex, 1);
                // set state
                this.setState({ attachments: attachments,
                                loading: false });    

                const message = {
                    type: 'S',
                    text: 'Anhang gelöscht'
                }
                this.context.showMessage(message);                      

            })
            .catch (error => {
                console.log(error); 
                this.setState({loading: false});

                var errorTitle = "Fehler beim Löschen des Anhangs";                
                this.setState({ loading: false,
                                deleteItem : null,
                                showError: true,
                                errorResponse: error.response ,
                                errorTitle: errorTitle });              
            });  

    }

  
    downloadAttachmentHandler = (attachment) => {
        this.setState({loading: true});
        axios.get('/attachments/' + attachment.ID + '/file', { responseType: 'arraybuffer' } )   
        .then (response => {
            this.setState({loading: false});
            this.downloadBinary(response, attachment.fileName);
        })
        .catch (error => {
            console.log(error); 
            this.setState({loading: false});
            
            var errorTitle = "Fehler beim Download der Datei";                
            this.setState({loading: false,
                            showError: true,
                            errorResponse: error.response ,
                            errorTitle: errorTitle });              
        });              
    }

    downloadBinary = (response, fileName) => {
        const url = window.URL.createObjectURL(
            new Blob([response.data], {/*type: 'application/pdf'*/}),
         );
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute(
            'download',
            fileName
            
         );
   
         // Append to html link element page
         document.body.appendChild(link);
   
         // Start download
         link.click();
   
         // Clean up and remove the link
         link.parentNode.removeChild(link);
    }


    deletePatient = () =>{
        this.setState({});
        
        this.setState({ confirmDelete: false, loading: true } );
        const patientID = this.state.patient.ID;

        axios.delete('/patients/' + patientID )
        .then (response => {

                this.context.deletePatient(patientID);
                const message = {
                    type: 'S',
                    text: 'Patient gelöscht'
                }
                this.context.showMessage(message);                              
                this.props.history.goBack();
        })
        .catch (error => {
            console.log(error); 
            var errorTitle = "Fehler beim Löschen des Patienten";
            this.setState({loading: false,
                           showError: true,
                           errorResponse:  error.response,
                           errorTitle: errorTitle });              
        });
    

    }

    cancelHandler = (event)  => {
        event.preventDefault();
        if (this.newPatient){
            this.props.history.goBack();
        }
        else{
            this.setState({
                    readOnly: true, 
                    patient: this.state.patientCopy ,
                    controls: this.getControls(this.state.patientCopy )      
                });
        }
        
    }      
    

    inputChangedHandler = (event, inputID) =>{
        
        const updatedForm = {
            ...this.state.patient
        }

        if (inputID === PatientDetail.BIRTHDAY || inputID === PatientDetail.FOLLOW_UP_DATE || inputID === PatientDetail.DATA_PROTECTION_DATE){

            updatedForm[inputID] = DateUtility.getISODate(new Date(event));
        }        
        else if (event.target.type === "checkbox"){
            //special handling for checkboxes... 
            updatedForm[inputID] = event.target.checked;
        }
        else if (inputID === PatientDetail.PHOTO_AGREEMENT){
            updatedForm[inputID] = ( event.target.value === "true" );
        }
        else{
            updatedForm[inputID] = event.target.value;
        }

        updatedForm[PatientDetail.DISPLAY_NAME] = this.getDisplayName(updatedForm);
        var saveDisabled = this.getSaveDisabled(updatedForm);        
        this.setState(  {patient: updatedForm,
                        saveDisabled: saveDisabled,
                        controls: this.getControls(updatedForm)   
                    });
        
    }     


    qrHandler = () => {
        this.setState({ loading: true } );
        
        axios.get('/patients/' + this.state.patient.ID + '/regLink' )
        .then (response => {
                this.setState({ loading: false,
                                showQRCode: true,
                                regLink : response.data.data.regLinkUrl ,
                                regLinkHash: response.data.data.regHash
                            } );
                
        })
        .catch (error => {
            console.log(error); 
            var errorTitle = "Fehler beim Generieren des Registrierungslinks";
            this.setState({loading: false,
                           showError: true,
                           errorResponse:  error.response,
                           errorTitle: errorTitle });              
        });
    }
    
    sendRegLink = () =>{

        this.setState({ loading: true, showQRCode: false } );
        
        axios.post('/regLink/' + this.state.regLinkHash + '/email' )
        .then (response => {
                this.setState({ loading: false
                            } );

                const message = {
                    type: 'S',
                    text: 'Link an die E-Mail Adresse des Patienten geschickt'
                }
                this.context.showMessage(message);                    
    
        })
        .catch (error => {
            console.log(error); 
            var errorTitle = "Fehler beim Senden des Registrierungslinks";
            this.setState({loading: false,
                           showError: true,
                           errorResponse:  error.response,
                           errorTitle: errorTitle });              
        });
    }

    getDisplayName = (patient) => {
        var f = patient[PatientDetail.FIRST_NAME];
        if (!f){ f = '';}
        var n = patient[PatientDetail.LAST_NAME];
        if (!n){ n = '';}

        if (patient[PatientDetail.TITLE]){
            return patient[PatientDetail.TITLE] + ' ' + f + ' ' + n ;
        } 
        else{
            return f + ' ' + n ;
        }
        
    }
    
    richTextChangeHandler = (htmlText, elemID) =>{
        const updatedForm = { ...this.state.patient };
        updatedForm[elemID] = htmlText;
        this.setState({patient: updatedForm});
    }

    modalClosed = (event) => {
        this.setState({ showError: false, 
                        confirmDelete: false,
                        showQRCode: false } );
    }    
/////////////////////////////////////////////////////////////////////////////////////////////

    render(){
        let content = null;
        if (this.state.patient){


            var confirmationPopup = null;
            if (this.state.confirmDelete){
                confirmationPopup = (  <YesCancelModal  clickYes={this.deletePatient} 
                                                        clickCancel={this.modalClosed}
                                                        title="Patienten Löschen" >
                                            Willst du diesen Patienten wirklich löschen? <br/><br/> Der Patient wird unwiderruflich von der Datenbank gelöscht. 
                                        </YesCancelModal>  );
            }            

            let list = (<Spinner/>);//
            if (!this.state.appLoading){
                //list = <AppointmentList  appointments={this.state.appointments} patientDetail={true}/>
                list = <AppointmentListPaginated  appointments={this.state.appointments} 
                                                  patientDetail={true}
                                                  view='patientDetail'
                                                  embedded={true}
                                                  pageSize={15}/>
            }

            let tabPrescription = (<div></div>);
            let tabAttachment = (<div></div>);
            if (SessionHandler.authSensitiveData()){
                tabPrescription = (
                    <Tab label='Rezepte' >
                        <PrescriptionList prescriptions={this.state.prescriptions} embedded={true}  />
                    </Tab> );
                tabAttachment = ( 
                    <Tab label="Attachments" icon={<GrAttachment/>} >
                                    <Attachments    patient={this.state.patient} 
                                                    attachments={this.state.attachments}
                                                    loading={this.state.appLoading}                                                        
                                                    storeAttachmentHandler={this.storeAttachmentHandler} 
                                                    deleteAttachmentHandler={this.deleteAttachmentHandler} 
                                                    downloadAttachmentHandler={this.downloadAttachmentHandler} 
                                                    >
                                    </Attachments>
                    </Tab> );
                
                
            }

            let tabInvoices = (<div></div>);
            if (SessionHandler.authFinance()){
                tabInvoices = (
                    <Tab label="Rechnungen" >
                        <InvoiceList invoices={this.state.invoices} embedded={true}  />
                    </Tab> );
            }

            content = (                
            <React.Fragment>
                <Modal show={this.state.loading}><Spinner/></Modal>
                {confirmationPopup}
                <RegLinkWindow showQRCode={this.state.showQRCode}
                               regLink={this.state.regLink}
                               modalClosed={this.modalClosed}
                               sendRegLink={this.sendRegLink}
                    />
                <ApiErrorMessage 
                            show={this.state.showError} 
                            modalClosed={this.modalClosed} 
                            errorResponse={this.state.errorResponse}  
                            title={this.state.errorTitle} 
                            />                
                <SubMenuMui history={this.props.history}> 
                    <PatientMenuMui editHandler={this.editHandler} 
                                    saveHandler={this.saveHandler} 
                                    deleteHandler={this.deleteHandler}
                                    cancelHandler={this.cancelHandler} 
                                    qrHandler={this.qrHandler}
                                    patient={this.state.patient}
                                    readOnly={this.state.readOnly} 
                                    saveDisabled={this.state.saveDisabled}
                                    deleteDisabled={this.state.deleteDisabled}
                                    history={this.props.history}/>
                </SubMenuMui>
                <PatientDetailViewMui patient={this.state.patient} 
                                    activePrescription={this.state.activePrescription}
                                    onChange={this.inputChangedHandler}
                                    richTextChangeHandler={this.richTextChangeHandler}
                                    controls={this.state.controls}
                                    readOnly={this.state.readOnly}/>
                <Tabs>
                    <Tab label="Termine"  >{list}</Tab>
                    {tabPrescription}
                    {tabInvoices}
                    {tabAttachment}
                </Tabs>
                </React.Fragment>
        );
    }
    else{
            content = (<div>Patient nicht gefunden...</div>);
    }
    return content;
    }


  

    componentDidMount(){
        this.isComponentMounted = true;
        window.scrollTo(0, 0);
        this.loadState();
    }

    componentWillUnmount(){
        this.isComponentMounted = false;
    }

    loadState(){
        var patientDet = {};
        if (this.props.match.path === '/patients/:id'){
            var patID = this.props.match.params.id;
            patientDet = this.context.getPatient( patID);
            
            if (!patientDet.originCode){
                patientDet.originCode = "";
            }
            if (!patientDet.invoiceDelivery){
                patientDet.invoiceDelivery = "";
            }            
            
            let appLoading = true;
            this.newPatient = false;
            let appointments = [];
            let attachments = [];
            if (patientDet.appLoaded){
                //get appointments from context
                appointments = this.context.getAppointmentForPatient(patID);
                attachments = this.context.getAttachmentsForPatient(patID);
                appLoading = false;
            }
            let prescriptions = this.context.getPrescriptionForPatient(patID);

            // get perscription from the context - no longer from the database view
            const activePrescription = PatientDetail.getActivePrescription(patID, prescriptions);
            



            let invoiceList = this.context.getInvoicesForPatient(patID);

            let deleteDisabled = true;
            if (!appLoading  && 
                appointments.length === 0  &&
                prescriptions.length === 0 ){
                    deleteDisabled = false;
                }


            this.setState({
                patient : patientDet, 
                saveDisabled: false,
                deleteDisabled : deleteDisabled,
                appLoading: appLoading,
                appointments : appointments,
                attachments: attachments,
                prescriptions: prescriptions,
                activePrescription: activePrescription,
                invoices: invoiceList,
                controls: this.getControls(patientDet)
                });
            
            if (!patientDet.appLoaded){
                //load  appointments from api
                this.loadAppointments(patientDet);
            }
                
        }
        else{
            //new Patient
            patientDet = {  invoicing:    false,
                            photoAgreement: false,
                            originCode: "" , 
                            invoiceDelivery: "", 
                            emailNotification: true, 
                            birthday: null,
                            followUpDate: null,
                            dataProtectionAgreement: null
                        };
            this.newPatient = true;
            this.setState({
                patient : patientDet, 
                controls: this.getControls(patientDet),
                appointments : [],
                saveDisabled: true,
                appLoading: false,
                readOnly: false});

        }

    }

    loadAppointments( patient ){
        this.context.loadAppointmentsForPatient(patient)
        .then (response => {
            if (this.isComponentMounted){
                
                //const appointments = response.data.data;
                const appointments = this.context.getAppointmentForPatient(patient.ID);
                const attachments = this.context.getAttachmentsForPatient(patient.ID);
                
                patient.appLoaded = true;   
                
                let deleteDisabled = true;
                if (appointments.length === 0  &&
                    this.state.prescriptions.length === 0 ){
                        deleteDisabled = false;
                    }                
                
                this.setState({ appLoading: false,
                                deleteDisabled: deleteDisabled,
                                patient: patient,
                                appointments: appointments,
                                attachments: attachments });      
            }
        })
        .catch (error => {
            if (this.isComponentMounted){
                console.log(error); 
                var errorTitle = "Fehler beim Laden der Termine";
                this.setState({appLoading: false,
                            showError: true,
                            errorResponse:  error.response,
                            errorTitle: errorTitle });              
            }
        });
    }
  
    getSaveDisabled(patient){
        if (!patient[PatientDetail.FIRST_NAME] || patient[PatientDetail.FIRST_NAME] === '' ||
            !patient[PatientDetail.LAST_NAME] || patient[PatientDetail.LAST_NAME] === '' ||
            !patient[PatientDetail.PHONE] || patient[PatientDetail.PHONE] === ''  ||
            !patient[PatientDetail.EMAIL] || patient[PatientDetail.EMAIL] === '' 
            ){
            return true;
        }        
        else{
            return false;
        }
    }

    getControls(patient){
        let controls = {...this.state.controls};
        controls.firstName.valid = (patient.firstName) ? true : false;
        controls.lastName.valid = (patient.lastName) ? true : false;
        controls.phone.valid = (patient.phone) ? true : false;
        controls.email.valid = (patient.email) ? true : false;
        return controls;
    }

    static getActivePrescription(patientID, prescriptions, ){
        let activePre = null;
        if (prescriptions){
            prescriptions.forEach(prescription => {
                if (parseInt(patientID) === parseInt(prescription.patient) &&   // same patient
                   (prescription.status ===  PrescriptionDetail.C_STAT_OPEN ||  prescription.status === PrescriptionDetail.C_STAT_PART_INVOICED ) && // only open status
                   ( !activePre  || prescription.ID < activePre.ID ))  // lowest number

                   activePre = prescription;
            });
        }
        return activePre
    }

    static formatPrescription(prescription){
        var formattedNo = null;
        if (prescription){

            var preConsumedNum = parseFloat(prescription.consumed);
            formattedNo = preConsumedNum.toLocaleString().concat('/').concat(prescription.hours);
        }
        return formattedNo;
    }
    /*
    static formatPrescriptionNo(patient){
        var formattedNo = null;
        if (patient && patient.prescriptionID){

            var preConsumedNum = parseFloat(patient.preConsumed);
            formattedNo = preConsumedNum.toLocaleString().concat('/').concat(patient.preHours);
        }
        return formattedNo;
    }
    */
}

export default PatientDetail;
