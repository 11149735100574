
import React from 'react';
import classes from './Modal.module.css';


const ModalFooter = (props) => (
    <div className={classes.ModalFooter}>
        {props.children}
    </div>
);

export default ModalFooter;