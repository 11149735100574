import React from 'react';
import Modal from '../UI/Modal/Modal';
import ModalHeader from '../UI/Modal/ModalHeader';
import ModalContent from '../UI/Modal/ModalContent';
import ModalFooter from '../UI/Modal/ModalFooter';
import Button from '../UI/Button/Button';
import Password from './Password';

const PasswordDialog = (props) => {


    return  <Modal show={true}  >
                <ModalHeader title="Passwort ändern" onClose={props.modalClose}>
                </ModalHeader>
                <ModalContent >
                   <Password  user={props.user} inputChangedHandler={props.inputChangedHandler}/> 
                </ModalContent> 
                <ModalFooter>
                    <Button btnType="EditMenuButton" click={props.savePassword} >Speichern</Button>
                    <Button btnType="EditMenuButton" click={props.modalClose} >Abrechen</Button>
                </ModalFooter>
            </Modal>;


}

export default PasswordDialog;