import React, { useContext } from 'react';
import classes from './Calendar.module.css';
import GlobalContext from '../../../context/GlobalContext';
import DateUtility from '../../../hoc/DateUtility';
import { useHistory } from "react-router";

import { IoCloudDoneOutline, IoCloudOfflineOutline } from "react-icons/io5";
import { BsArrowRepeat } from "react-icons/bs";



import AppointmentUtil from '../AppointmentUtil';

const getText = (appointment) => {
    
   

    let text = appointment.patientName;
    if (!text){
        text = appointment.subject;
    }

    return text;
}

const  isDark = (color) => {
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
  
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
  
      r = color[1];
      g = color[2];
      b = color[3];
    } 
    else {
  
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'
      )
               );
  
      r = color >> 16; 
      g = ( color >> 8 ) & 255;
      b = color & 255;
    }
  
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
  
    // Using the HSP value, determine whether the color is light or dark
    
    if (hsp>130) {
  
      return false;
    } 
    else {
  
      return true;
    }
  }


const Event = (props) => {
  const history = useHistory();
    const globalContext = useContext(GlobalContext);
    
    const cellHeigth = 30;

    const appointment = props.appointment;
    if (!appointment){
      return null;
    }

    let height = 30 ;
    let name = '';
    let backgroundColor = '#F5F5F5';
    let color = 'black';
    let fromTo = '';

    name = getText(appointment);
    //calculate hight of the event
    fromTo = appointment.from + '-'+ appointment.to;
    let duration = DateUtility.calcDuration( appointment.from, appointment.to);
    if (appointment.fullDay ){
      duration = 0.5;
      fromTo = "24h";
    }
    height = (duration * 2 * cellHeigth) 

    // border color
    const therapist = globalContext.getTherapist(appointment.therapeutID);
    let filter = 'none';
    if (therapist){
        backgroundColor = therapist.color;


        /*
        if (appointment.appType === AppointmentUtil.C_TYPE_NORMAL_APPOINTMENT){
          //make color lighter
          //filter = 'brightness(1.5)';
          //filter = 'opacity(95%) brightness(125%)';
          //filter = 'grayscale(80%)';
          
        }
        */
        //console.log(backgroundColor + ' : ' + isDark(backgroundColor));
        if (isDark(backgroundColor)){
          color = 'white';
              }
    }    
    else if (appointment.appType === AppointmentUtil.C_TYPE_HOLIDAY){
      backgroundColor = AppointmentUtil.C_COLOR_HOLIDAY;
      color = 'white';
    }



    height = height - 2;
    const heightProperty = height + 'px';

    const classArray = [classes.Event];

    const navigate = () =>{
      if (props.navAppointment){
        history.push({  pathname:  '/appointments/' + props.appointment.ID });
      }
      
    }

    let iconLeft = <div></div>;
    if (appointment.frequency){
      iconLeft = <BsArrowRepeat />
    }

    let iconRigth = <div></div>;
    if (appointment.appType === AppointmentUtil.C_TYPE_ATTENDANCE ){
      if (appointment.online){
        iconRigth = <IoCloudDoneOutline />
      }
      else{
        iconRigth = <IoCloudOfflineOutline />
      }
    }    

    return <div className={classArray.join(' ')} 
                style={{height : heightProperty, 
                        backgroundColor : backgroundColor, 
                        color : color,
                        filter: filter }} 
                onClick={() => navigate()}
                >
                <div className={classes.EventHeadline}>{iconLeft}<div>{name}</div>{iconRigth}</div>
                <div className={classes.TimeFromTo}>{fromTo}</div>
            </div>
           
}

export default Event;