import React from 'react';
import classes from './SubMenu.module.css';
import { IoIosUndo } from "react-icons/io";
import Auxiliary from '../../../hoc/Auxiliary';
import { IconButton } from '@mui/material';




const SubMenuMui = (props) =>  {


    function navigateBack(props){
        props.history.goBack();
    }

    var navBack = null;
    if (props.history){
        navBack = (<IconButton onClick={() => navigateBack(props)} disabled={false} >
                        <IoIosUndo />
                    </IconButton>
                    );
    }


    return (
        <Auxiliary>
            <div className={classes.SubMenuMui}>
            <div className={classes.SubMenuBar}>
                    {navBack}
                    {props.children}
            </div>
            </div>
            <div className={classes.Placeholder}></div>
        </Auxiliary>
        );
    }

export default SubMenuMui;