
import React from 'react';
import classes from './Modal.module.css';


const ModalContent = (props) => {
    
    let className = classes.ModalContent;
    if (props.type ){
        className = className + ' ' + classes[props.type];
    }


    return (
        <div className={className}>
            {props.children}
        </div>
    );
}

export default ModalContent;