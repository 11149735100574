import React  from 'react';
import ListMenu from './ListMenu';
import SubMenu from '../Navigation/SubMenu/SubMenu';
import Auxiliary from '../../hoc/Auxiliary';
import PaginatedList from '../UI/PaginatedList/PaginatedList';
import PrescriptionListItem from './PrescriptionListItem';
import { PureComponent } from 'react/cjs/react.production.min';


class PrescriptionList extends PureComponent{

    //static contextType = GlobalContext;

    state = { 
        controls: {
            filter: {
                value: '',
                elementTyp: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Patient...'
                },
                valid: true,
                touched: false
            },     
        },
        birthdayFilter: false,
        filteredItems: null, 
    }

    renderItems = (items) => {
        return (
            <Auxiliary>
            {items && items.map((prescription, index) => (
                <PrescriptionListItem 
                    prescription={prescription}
                    key={prescription.ID}
                    showDetail={false}
                    />
            ))}
            </Auxiliary>
        );
    }

    render() {
        let items = this.props.prescriptions;
        if (this.state.filteredItems){
            items = this.state.filteredItems;
        }

        let menu = null;
        if (!this.props.embedded){
            menu = (    <SubMenu popupMenu={false}>
                            <ListMenu   controls={this.state.controls} 
                                        changed={(event) => this.filterHandler(event)}
                                        createButton={!this.props.searchHelp} 
                                        />
                        </SubMenu>
                        );
        }
       
        return(
            <Auxiliary>
                {menu}
                <PaginatedList 
                    items={items}
                    renderItems={this.renderItems} 
                    itemsPerPage={30}>
                </PaginatedList>
            </Auxiliary>
        );
    }

    filterHandler = (event)  => {
        
        let controls = this.state.controls;
        controls.filter.value = event.target.value;
        const filteredItems = this.getFilteredItems(controls.filter.value);
        this.setState({ controls: controls,
                        filteredItems: filteredItems});
    }

    getFilteredItems = (filter) => {

        const regex = RegExp(filter, 'i');
        return this.props.prescriptions.filter(prescription => regex.test(prescription.displayname) || regex.test(prescription.ID )  );        
    }

    
    componentDidMount(){
        if (!this.props.embedded){
            window.scrollTo(0, 0);  
        }
        
    }

}

export default PrescriptionList;
        