import React, {useContext}  from 'react';
import GlobalContext from '../../../context/GlobalContext';
import classes from './Message.module.css';
import { FaInfoCircle} from "react-icons/fa";
import { ImWarning } from "react-icons/im";

const Message = (props) => {
    const globalContext = useContext(GlobalContext);
    let message = null;
    const inputClasses = [classes.Message];
    if (props.message){
        setTimeout(() => {  globalContext.hideMessage();  },  3000);
          
        let icon = null;
        if (props.message.type === 'S'){
            inputClasses.push(classes.MessageSuccess);
            icon = (<FaInfoCircle className={classes.SuccessIcon}/>);
        }
        if (props.message.type === 'W'){
            inputClasses.push(classes.MessageWarning);
            icon = (< ImWarning className={classes.WarningIcon}/>);
        }        


        message = (
            <div className={inputClasses.join(' ')}>
                {icon}
                {props.message.text}
            </div>
        );
    }

    
    return message;
}


export default Message;