import React from 'react';

import SubMenuMui from '../Navigation/SubMenu/SubMenuMui';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ListMenu from './ListMenu';
import AppQueueListItem from './AppQueueListItem';
import GlobalContext from '../../context/GlobalContext';

const AppQueue= (props) => {
    
    const history = useHistory();

    const globalContext = useContext(GlobalContext);

    
    const list = globalContext.appQueue.map((item, index) => {

        const patient = globalContext.getPatient(item.patientID);

        return <AppQueueListItem
                        appQueueItem={item}
                        patient={patient}
                        key={item.id}/>
    })

    return  <React.Fragment>
                <SubMenuMui  history={history}>
                        <ListMenu     /> 
                </SubMenuMui>   
                <h3>Warteliste</h3>
                {list}
            </React.Fragment>


}

export default AppQueue;
