
import React from 'react';
import classes  from './AppointmentLink.module.css';

import { Tooltip } from '@mui/material';
import MUIInput from '../../MUI/MUIInput/MUIInput';

import StatusIcon from '../Status/StatusIcon';
import { Link } from 'react-router-dom';

const AppointmentLinkMui  = (props) => { 

    let datum = props.appointment.date;
    if (props.invoiceDate && props.appointment.invoiceDate){

        datum = props.appointment.invoiceDate;
    }

    const options = {  weekday: 'short' , year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date( datum);

    let printDate = date.toLocaleDateString('de-DE', options);

    const iconSize = '20';
    let appDetailButton = null;
    
    
        appDetailButton = ( 
            <Tooltip title={'Termin Detail'}>
                <Link  to={{ pathname: '/appointments/' + props.appointment.ID }}>
                    <StatusIcon value={props.appointment.statusText} lookupValue={props.appointment.statusID}  />
                </Link>                        
            </Tooltip>);
    
    return (    <MUIInput   id={props.idx}  
                            value={printDate} 
                            label={"Termin"}
                            readOnly
                            prefix={appDetailButton}
                            /> 
               
            )

}

export default AppointmentLinkMui;

